import axiosCodeigniter from "../api/axiosCodeigniter";
import { SEND_PSI_MESSAGE } from "../utils/constants";

interface MessageReceived {
  mensaje: string;
  es_cliente: string;
}

interface ClientChat {
  mensajes: MessageReceived[];
}

interface MessageSent {
  mensaje: string;
  TypePlan: string;
}

export async function sendClientMessage(messageSent: MessageSent): Promise<ClientChat> {
  const token = localStorage.getItem("token");
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'multipart/form-data'
    },
  };
  const formData = new FormData();
  formData.append('txt_msg', JSON.stringify(messageSent));
  try {
    const response = await axiosCodeigniter.post(SEND_PSI_MESSAGE, formData ,config);
    console.log(response);
    return response.data;
  } catch (error) {
    console.log("Error al enviar el mensaje", error);
    throw error;
  }
}