import dayjs, { Dayjs } from "dayjs";
import axiosCodeigniter from "../api/axiosCodeigniter";
import {GET_DIET_CALENDAR_URL} from "../utils/constants";

export type IAppointmentEmployee = {
    first_name: string,
}

export type IBookedAppointment = {
    id: number,
    start_dayjs: Dayjs,
    end_dayjs: Dayjs,
    instruc: string,
    first_name: string,
    plan:string,
}

type InitialResponse = {
    empleado: IAppointmentEmployee,
    citaPendiente: null|{
        id: number,
        start_date: string,
        end_date: string,
        instruc: string,
        first_name: string,
        plan:string
    },
    citas: IAppointmentSlot[],
    noVisible: boolean
}

export type ICompleteResponse = {
    empleado: IAppointmentEmployee,
    citaPendiente: IBookedAppointment|null,
    citas: IAppointmentSlot[],
    noVisible: boolean
}

export type IAppointmentSlot = {
    id: string,
    start: Dayjs,
    end: Dayjs,
    className: string,
}

export async function getDietCalendarService(): Promise<ICompleteResponse> {
    const token = localStorage.getItem('token');
    const headers =  {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }
    const reply = await axiosCodeigniter.get<InitialResponse>(`${GET_DIET_CALENDAR_URL}`, headers);
    const data = reply.data;
    const citas= reply.data.citas;

    if(reply.data.citaPendiente){
        switch (true) {
            case reply.data.citaPendiente.plan === "die_mes":
              reply.data.citaPendiente.plan="Plan de dieta";
              break;
            case reply.data.citaPendiente.plan === "par_die_mes":
              reply.data.citaPendiente.plan="Plan de dieta para pareja";
              break;
            case reply.data.citaPendiente.plan === "mes":
              reply.data.citaPendiente.plan="Plan de dieta + entrenamiento en Gym";
              break;
            case reply.data.citaPendiente.plan === "mes_dev":
              reply.data.citaPendiente.plan="Plan de dieta + entrenamiento en Entrena virtual";
              break;
            case reply.data.citaPendiente.plan === "mes_cas":
              reply.data.citaPendiente.plan="Plan de dieta + entrenamiento en Casa";
              break;
            case reply.data.citaPendiente.plan === "par_mes":
              reply.data.citaPendiente.plan="Plan de dieta para pareja + entrenamiento en Gym";
              break;
            case reply.data.citaPendiente.plan === "par_mes_cas":
              reply.data.citaPendiente.plan="Plan de dieta para pareja + entrenamiento en Casa";
              break;
            case reply.data.citaPendiente.plan === "die_mes_psi":
              reply.data.citaPendiente.plan="Vital Wellness";
              break;
        }
    }
    console.log(data)
    return {
        ...data,
        citaPendiente: data.citaPendiente && data.citaPendiente.id ? {
            ...data.citaPendiente,
            start_dayjs: dayjs(data.citaPendiente.start_date),
            end_dayjs: dayjs(data.citaPendiente.end_date),
        }: null,
        citas: citas.map(
            (cita) => ({
                id:cita.id,
                start: dayjs(cita.start),
                end: dayjs(cita.end),
                className: cita.className,
            })
        ),
    }


}