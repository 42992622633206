import axiosCodeigniter from "../api/axiosCodeigniter";
import { GET_PSI_SESSIONS,GET_PSI_COUPLE_SESSIONS } from "../utils/constants";

export interface PsiSessionsModel {
    id: number;
    id_cliente: number;
    nsesiones: number;
    nusadas: number;
    nacumuladas: number;
    activo: number;
    ndisponibles: number;
}

export async function getPsiSessionsService(): Promise<PsiSessionsModel> {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
  
    try {
      const response = await axiosCodeigniter.get(GET_PSI_SESSIONS, config);
      return response.data;
    } catch (error) {
      console.log("Error al recuperar el número de sesiones del plan de psicología individual.", error);
      throw error;
    }
}

export async function getPsiCoupleSessionsService(): Promise<PsiSessionsModel> {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
  
    try {
      const response = await axiosCodeigniter.get(GET_PSI_COUPLE_SESSIONS, config);
      return response.data;
    } catch (error) {
      console.log("Error al recuperar el número de sesiones del plan de psicología en pareja.", error);
      throw error;
    }
}