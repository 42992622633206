import dayjs, { Dayjs } from "dayjs";
import axiosCodeigniter from "../api/axiosCodeigniter";
import {GET_PSI_CALENDAR_URL, GET_PSI_CALENDAR_COUPLE_URL} from "../utils/constants";

export type IAppointmentEmployee = {
    first_name: string,
}

export type IPsiPlanData = {
    id: number,
    id_cliente: number,
    nsesiones: number,
    nusadas: number,
    nacumuladas: number,
    ndisponibles:number,
    activo:number,
    fProxRenovacion:number|null
}

export type IBookedAppointment = {
    id: number,
    start_date: Dayjs,
    end_date: Dayjs,
    instruc: string,
    first_name: string,
}

type InitialResponse = {
    datosPlan: IPsiPlanData|null,
    empleado: IAppointmentEmployee|null,
    citasPendientes: IBookedAppointment[],
    citas: IAppointmentSlot[],
}

export type ICompleteResponse = {
    datosPlan: IPsiPlanData|null,
    empleado: IAppointmentEmployee|null,
    citasPendientes: IBookedAppointment[],
    citas: IAppointmentSlot[]
}

export type IAppointmentSlot = {
    id: string,
    idPlan:string,
    start: Dayjs,
    end: Dayjs,
    className: string,
    free: number,
}

export async function getPsiCalendarService(type:string): Promise<ICompleteResponse> {
    const token = localStorage.getItem('token');
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        },
        params: {
            typePlan: type
        }
    };

    const reply = await axiosCodeigniter.get<InitialResponse>(`${GET_PSI_CALENDAR_URL}`, config);
    const data = reply.data;
    //console.log(reply.data.datosPlan?.activo);
    const citas= reply.data.citas;

    return {
        ...data,
        citasPendientes: reply.data.citasPendientes.map(
            (citaPendiente)=>({
                id: citaPendiente.id,
                start_date: dayjs(citaPendiente.start_date),
                end_date: dayjs(citaPendiente.end_date),
                instruc: citaPendiente.instruc,
                first_name: citaPendiente.first_name,
            })
        ),
        citas: citas.map(
            (cita) => ({
                id:cita.id,
                idPlan:cita.idPlan,
                start: dayjs(cita.start),
                end: dayjs(cita.end),
                className: cita.className,
                free: cita.free,
            })
        ), 
    }

}

export async function getPsiCalendarCoupleService(): Promise<ICompleteResponse> {
    const token = localStorage.getItem('token');
    const headers =  {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }

    const reply = await axiosCodeigniter.get<InitialResponse>(`${GET_PSI_CALENDAR_COUPLE_URL}`, headers);
    const data = reply.data;
    console.log(reply.data);
    const citas= reply.data.citas;

    return {
        ...data,
        citasPendientes: reply.data.citasPendientes.map(
            (citaPendiente)=>({
                id: citaPendiente.id,
                start_date: dayjs(citaPendiente.start_date),
                end_date: dayjs(citaPendiente.end_date),
                instruc: citaPendiente.instruc,
                first_name: citaPendiente.first_name,
            })
        ),
        citas: citas.map(
            (cita) => ({
                id:cita.id,
                idPlan:cita.idPlan,
                start: dayjs(cita.start),
                end: dayjs(cita.end),
                className: cita.className,
                free: cita.free,
            })
        ), 
    }

}