import React from 'react';
import restart from '../../../../../assets/img/restart.png'
import './New.css'
const Novedad = () => {
  return (
    <div className='new-product-home'>
        <img src={restart} alt="new_product" />
        <span></span>
        <div className='new-product-home-title'>¡NOVEDAD!</div>
    </div>
  );
};

export default Novedad;