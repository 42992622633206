import {  createStyles, Theme, WithStyles, withStyles } from '@material-ui/core/styles';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';


const styles = (theme: Theme) =>
  createStyles({
    root: {
      color: ' #BAD0A2',
    },
  });

interface Props extends WithStyles<typeof styles> {}



 const Loading =  withStyles(styles)((props: Props) => {
  return (
    <Box sx={{         
        position: 'fixed',
        top: 0,
        left: 0,
        height: '100%',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: 9999, 
        }}>
      <CircularProgress {...props} classes={{ root: props.classes.root }} />
    </Box>
  );
})

export default Loading;