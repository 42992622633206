import {AxiosResponse} from "axios";
import axios from "../api/axios";
import {JSON_CONTENT_TYPE, PAYMENT_INTENT_URL} from "../utils/constants";
import {ClientSecret} from "../models/ClientSecret";

interface FetchClientData {
    amount: number;
    email: string;
    password: string;
    first_name: string;
    last_name: string;
    address_1: string;
    address_2: string;
    city: string;
    postcode: string;
    country: string;
    phone: string;
    partner_name: string;
    partner_last_name: string;
    partner_email: string;
    product_id: string;
    variant_id: string;
    product_subscription: boolean;
    quantity: number;
    price: number;
    cupon_code: string;
    state:string;
}

export const fetchClientSecretService = async (data: FetchClientData): Promise<AxiosResponse<ClientSecret>> => {
    return axios.post(PAYMENT_INTENT_URL,
        JSON.stringify({
            amount: data.amount,
            email: data.email,
            password: data.password,
            first_name: data.first_name,
            last_name: data.last_name,
            address_1: data.address_1,
            address_2: data.address_2,
            city: data.city,
            postcode: data.postcode,
            country: data.country,
            phone: data.phone,
            partner_name: data.partner_name,
            partner_last_name: data.partner_last_name,
            partner_email: data.partner_email,
            product_id: data.product_id,
            variant_id: data.variant_id,
            quantity: data.quantity,
            product_subscription: data.product_subscription,
            price: data.price,
            state:data.state
        }),
        {
            headers: {'Content-Type': JSON_CONTENT_TYPE},
            withCredentials: true
        }
    );
};
