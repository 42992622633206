import {Link, useNavigate} from 'react-router-dom';
import logo from './../../../assets/img/logo_mejorado.png';
import accountIcon from './../../../assets/img/user.svg';
import './Header.css';
import MediaQuery from 'react-responsive';
import MobileNav from './mobile-nav/MobileNav';
import UserLogo from './user-logo/userLogo';
import {useState, useEffect, useRef, useContext} from 'react';
import { useLocation } from 'react-router-dom';
import Icon from '../../../assets/img/arrow-1.svg';
import IconLock from '../../../assets/img/lock.svg';
import Circulo from '../../../assets/img/circulo.jpg';
import IconCart from '../../../assets/img/iconoir_cart.svg';
import IconPage from '../../../assets/img/iconoir_multiple-pages-empty.svg';
import IconHelp from '../../../assets/img/iconoir_help-circle.svg';
import IconLogout from '../../../assets/img/iconoir_log-out.svg';
import ReloadContext from '../../../providers/ReloadProvider';

const Header = () => {
  const ref = useRef<HTMLUListElement | null>(null);
  const { setReload } = useContext(ReloadContext);
  const [active, setActive] = useState("");
  const [hidden, setHidden] = useState(false);
  const [showNavBarMobile, setShowNavBarMobile] = useState(true);
  const navigate = useNavigate(); 

  const firstName = localStorage.getItem('firstName');
  const lastName = localStorage.getItem('lastName');
  const status = localStorage.getItem('status');

  const location = useLocation();
  const privateSections = (/^\/private/.test(location.pathname))
  const [showSubMenu, setShowSubMenu] = useState(false);
  const [showSubMenuUser, setShowSubMenuUser] = useState(false);
  const [closeSubMenu, setCloseSubMenu] = useState(false);

  const pon_user_info = localStorage.getItem('pon_user_info');
  const encodedEmail = localStorage.getItem('encodedEmail');
  const encodedPassword = localStorage.getItem('encodedPassword');

  const logout = () => {
    localStorage.clear();
  }

  const infoToSideBar = (selectedOption:string) => {
    let status = {
      menu: 'user',
      selectedOption,
      psychologyExpanded: false,
      dietExpanded: false,
    }
    localStorage.setItem('privateSelectedView', JSON.stringify(status));
    setReload(true);
    if(location.pathname==="/private"){
      window.location.reload();
    }
  }

  function handleClick() {
    setShowSubMenu(!showSubMenu);
  }

  function handleClickProduct(routeToRedirect: string) {
    navigate(`/contacto`);
    setTimeout(() => {
      navigate(`/productos/${routeToRedirect}`);
    }, 1)
    setShowSubMenu(!showSubMenu);
  }
// eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    switch (true) {
      case location.pathname === "/faq":
        setActive("faq");
        setShowNavBarMobile(true);
        setHidden(false);
        break;
      case location.pathname === "/pago":
        setActive("");
        setShowNavBarMobile(true);
        setHidden(false);
        break;
      case location.pathname === "/equipo":
        setActive("team");
        setShowNavBarMobile(true);
        setHidden(false);
        break;
      case location.pathname === "/blog":
        setActive("blog");
        setShowNavBarMobile(true);
        setHidden(false);
        break;
      case location.pathname === "/contacto":
        setActive("contact");
        setShowNavBarMobile(true);
        setHidden(false);
        break;
      case location.pathname === "/":
        setActive("home");
        setShowNavBarMobile(true);
        setHidden(false);
        break;
      case location.pathname === "/iniciar-sesion":
        setHidden(true);
        setShowNavBarMobile(true);
        break;
      case location.pathname === "/register":
        setActive("");
        setShowNavBarMobile(true);
        break;
      case location.pathname === "/productos/dieta":
        setActive("#");
        setShowNavBarMobile(true);
        setHidden(false);
        break;
      case location.pathname === "/productos/psicologia":
        setActive("#");
        setShowNavBarMobile(true);
        setHidden(false);
        break;
      case location.pathname === "/productos/talleres":
        setActive("#");
        setShowNavBarMobile(true);
        setHidden(false);
        break;
      case location.pathname === "/plans_subscriptions":
        setShowNavBarMobile(false);
        setHidden(false);
        break;
      case privateSections:
        setShowNavBarMobile(false);
        setHidden(false);
        break;
    }
  }, [location]);

  const handleLogoClick = () => {
    navigate('/')
  }

  return (
    <>
      {hidden ?
        <div className='header-hidden'></div>
        : (
          <header className="header">
            <img src={logo} className="logo" alt="Logo" onClick={handleLogoClick} />
            {/* Menú para dispositivos de escritorio */}
            <nav className="desktop-nav">
              <MediaQuery minWidth={768}>
                <ul className="ul" ref={ref}>
                  {!privateSections && (
                  <li className="li">
                    <Link to="/" className={`a ${active === "home" && "active"}`} id="home"
                      onClick={() => {
                        handleClick();
                        setShowSubMenu(false);
                      }}>HOME</Link>
                  </li>
                    )}
                  {!privateSections && (
                    <li className="li">
                      <Link
                        to="#"
                        className={`a ${active === "#" && "active"}`}
                        id="products"
                        onClick={handleClick}
                      >
                        PRODUCTOS
                        <img
                          alt='icon'
                          className="icon-collapse"
                          src={Icon}
                          style={{ transform: showSubMenu ? 'rotate(180deg)' : undefined }}
                        />
                      </Link>

                      {showSubMenu && (
                        <ul className="submenu" onClick={() => setShowSubMenu(false)}>
                          <li key={'diet'} style={{cursor: 'pointer'}} onClick={() => {
                            handleClickProduct('dieta');
                            setShowSubMenu(false);
                          }}>
                            <a>
                              Dieta
                            </a>
                            <div className='divider-submenu'></div>
                          </li>
                          <li key={'psychology'} style={{cursor: 'pointer'}} onClick={() => {
                            handleClickProduct('psicologia');
                            setShowSubMenu(false);
                          }}>
                            <a>
                              Psicología
                            </a>
                            <div className='divider-submenu'></div>
                          </li>
                          <li key={'workshop'} style={{cursor: 'pointer'}} onClick={() => {
                            handleClickProduct('talleres');
                            setShowSubMenu(false);
                          }}>
                            <a>
                              Talleres
                            </a>
                          </li>
                        </ul>
                      )}
                    </li>
                  )}
                  {!privateSections && (
                  <li className="li">
                    <Link to="/faq" className={`a ${active === "faq" && "active"}`} id="faq"
                      onClick={() => {
                        handleClick();
                        setShowSubMenu(false);
                      }}>FAQ</Link>
                  </li>
                    )}
                  {!privateSections && (
                    <li className="li">
                      <Link to="/equipo" className={`a ${active === "team" && "active"}`} id="team"
                        onClick={() => {
                          handleClick();
                          setShowSubMenu(false);
                        }}>EQUIPO</Link>
                    </li>
                  )}

                  {!privateSections && (
                    <li className="li">
                      <a href='https://www.vikika.es/blog/' target={"_blank"} className={`a ${active === "blog" && "active"}`} id="blog"
                        onClick={() => {
                          handleClick();
                          setShowSubMenu(false);
                        }}>BLOG</a>
                    </li>
                  )}

                  {!privateSections && (
                  <li className="li">
                    <Link to="/contacto" className={`a ${active === "contact" && "active"}`} id="contact"
                      onClick={() => {
                        handleClick();
                        setShowSubMenu(false);
                      }}>CONTACTO</Link>
                  </li>
                  )}
                </ul>
              </MediaQuery>
              <MediaQuery maxWidth={767}>
                {showNavBarMobile && (
                  <MobileNav closeSubMenu={closeSubMenu} setCloseSubMenu={setCloseSubMenu} setShowSubMenuUser={setShowSubMenuUser} showSubMenuUser={showSubMenuUser}/>
                )}
              </MediaQuery>
            </nav>
            {status !== 'isLoggedIn' && (
              <Link to="/iniciar-sesion" className="login"><img src={accountIcon} className="accountIcon" alt="Cuenta" />INICIAR SESIÓN</Link>
            )}
            {status === 'isLoggedIn' && (    
              <UserLogo setShowSubMenuUser={setShowSubMenuUser} infoToSideBar={infoToSideBar} logout={logout} showSubMenuUser={showSubMenuUser}/>
            )}
          </header>
        )
      }
    </>
  )











}
export default Header;
