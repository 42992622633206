import { FaqDescriptionProps } from "../../../../models/componentProps/FaqDescriptionProps";
import parse from "html-react-parser";

const FaqDescription = (faqDescriptionProps: FaqDescriptionProps) => {

    return (
        <div className="div-container-description">
            <p>
                {parse(faqDescriptionProps.text)}
            </p>
        </div>
    )
}

export default FaqDescription
