interface ComponentProps {
    url: string;
}

const VideoCall = (componentProps: ComponentProps) => {
    return (
        <div style={{width: '100%', height: '65vh'}}>
            <iframe
                src={componentProps.url}
                width="100%"
                height="100%"
                frameBorder="0"
                title="Mi Iframe"
                allowFullScreen
            ></iframe>
        </div>
    )
} 

export default VideoCall
