import React, { useState } from "react";
import "../../diet/calendar/hours.css";
import IconDown from "../../../../../assets/img/iconoir_fast-arrow-down.png";
import { IAppointmentSlot } from "../../../../../services/getPsiCalendarService";

interface HoursProps {
  title: string;
  subtitle: string;
  options: IAppointmentSlot[];
  setSelectedSlot: (appointment: IAppointmentSlot) => void;
}

/**
 * Este componente recibe un conjunto de slots (correspondientes a un mismo día) y permite elegir entre una de ellas. Cuando
 * se presiona CONTINUAR, pasa la selección al componente padre.
 */

const Hours: React.FC<HoursProps> = ({
  title,
  subtitle,
  options,
  setSelectedSlot,
}) => {
  const [currentAppointmentSlot, setCurrentAppointmentSlot] =
    useState<IAppointmentSlot | null>(null);

  return (
    <div className="container_text_hours">
      <h1 className="title_hours">{title}</h1>
      <h2 className="subtile_hours">{subtitle}</h2>

      <div className="videocall-type-info-vW">
        <div className="type-info-container">
          <div className="span-psychology-videocall-long"></div>
          <span className="type_info_long">Videollamada de 55 minutos</span>
        </div>
        <div className="type-info-container">
          <div className="span-psychology-videocall-short"></div>
          <span className="type_info_short">Videollamada de 30 minutos</span>
        </div>
      </div>
      <div style={{ height: "30vh", overflowY: "auto" }}>
        {options.map((option, index) => (
          <div
            className="hour-item-div"
            style={{
              background:
                currentAppointmentSlot &&
                option.start === currentAppointmentSlot.start
                  ? "rgba(186, 208, 162, 1)"
                  : option.free == 2
                  ? "rgba(255, 246, 240, 1)"
                  : "rgba(246, 255, 234, 1)",
                  borderColor: currentAppointmentSlot &&
                  option.start === currentAppointmentSlot.start
                    ? "#F6FFEA"
                    : option.free == 2
                    ? "rgba(243, 228, 220, 1)"
                    : "rgba(186, 208, 162, 1)",
            }}
            onClick={() => setCurrentAppointmentSlot(option)}
            key={index}
          >
            <p>{option.start.format("HH:mm")}</p>
          </div>
        ))}
      </div>
      {options.length > 4 && (
        <img src={IconDown} alt="icon-down" className="icon-down" />
      )}
      <div
        className="button_hours modal-success-button"
        onClick={() => {
          currentAppointmentSlot && setSelectedSlot(currentAppointmentSlot);
        }}
      >
        <button disabled={!currentAppointmentSlot}>CONTINUAR</button>
      </div>
    </div>
  );
};

export default Hours;
