import React from "react";
import "./video_call.css";
import { useState } from "react";
import {
  IAppointmentEmployee,
  IAppointmentSlot,
  IBookedAppointment,
} from "../../../../../services/getDietCalendarService";

interface ComponentProps {
  type: string;
  title: string;
  date: string;
  hour: string;
  specialist: IAppointmentEmployee;
  selected_slot: IAppointmentSlot;
  booked_slot: IBookedAppointment | null;
  information: string;
  noShowButtons?: boolean;
  onAgendar: () => void;
  onNoAgendar: () => void;
}

function VideoCall({
  type,
  onNoAgendar,
  onAgendar,
  title,
  date,
  hour,
  specialist,
  information,
  noShowButtons,
  ...props
}: ComponentProps) {

  return (
    <div className="container_video">
      <h1 className="title_video">{title}</h1>
      <h3>Fecha</h3>
      <p>{date}</p>
      <h3>Hora</h3>
      <p>{hour}</p>
      <h3>Especialista</h3>
      <p>{specialist.first_name}</p>
      <h3>Información</h3>
      <p className="info_video_text">{information}</p>
      {!noShowButtons && (
        <>
          <div className="videocall-buttons-container">
            <div className="button_video_call modal-success-button">
              <button
                className="button_video"
                onClick={() => {
                  onAgendar();
                }}
              >
                AGENDAR VIDEOLLAMADA{" "}
              </button>
            </div>
            {type === "diet" && (
              <p className="final_text_video" onClick={onNoAgendar}>
                No agendar
              </p>
            )}
          </div>
        </>
      )}
    </div>
  );
}

export default VideoCall;
