import {
    ACTIVE_BUTTON,
    INACTIVE_BUTTON
} from "../../../../../utils/constants";
import {TestimonialButtonProps} from "../../../../../models/componentProps/TestimonialButtonProps";

const TestimonialButton = (testimonialButtonProps: TestimonialButtonProps) => {

    return (
        <button
            onClick={() => testimonialButtonProps.handleButtonClick(testimonialButtonProps.categoryToCompare)}
            className={
                testimonialButtonProps.selectedCategory === testimonialButtonProps.categoryToCompare ? ACTIVE_BUTTON : INACTIVE_BUTTON
            }
        >
            {testimonialButtonProps.title}
        </button>
    )
}

export default TestimonialButton
