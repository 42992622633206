import IconBell from "../../../../../assets/img/bell.svg";
import is_couple from '../../../../../assets/img/is_couple2.png';

interface NotificationPrivateProps {
    title: string;
    subtitle: string;
    cta:string;
    id:string;
    type_plan:string;
    pay_plan:string
}

const YourProducts = (notificationPrivateProps: NotificationPrivateProps) => {
    const userInfoString = localStorage.getItem('pon_user_info_diet');
    let planName = ''; 
    
   
    if (userInfoString) {
      const userInfo = JSON.parse(userInfoString);
      if (userInfo && userInfo.length > 0) {
          const lastUserInfo = userInfo[userInfo.length - 1]; 
          planName = lastUserInfo.typePlan; 
      } else {
          console.log("userInfo array is empty.");
      }
  }
    console.log(notificationPrivateProps.type_plan,notificationPrivateProps.cta,notificationPrivateProps.title,notificationPrivateProps.subtitle,notificationPrivateProps.pay_plan);
    return (
        <div className='home-private-msg' id={notificationPrivateProps.id}>
           <div className="is-couple">
                    {
                        
                        notificationPrivateProps?.type_plan == 'EN PAREJA' && (
                            <>  
                                                      <img src={is_couple} alt="plan_en_pareja" className="its-couple"/>
                            </>
                        )
                    }
                </div>
            <div className='home-private-notice'>
                <div className='circular-bell-bg' id={notificationPrivateProps.id=='rouge' ? 'lightrouge':''}>
                    <div className="circular-bell-image"><img src={IconBell} className="" alt="Icon-Bell" /></div>
                </div>
                <div className='notification-content'>
                    
                        {notificationPrivateProps.pay_plan === "SUSCRIPCIÓN" && (
                            <div className="suscription_psycho_msg" style={{marginTop:'0px'}}>
                                <span>SUSCRIPCIÓN</span>
                            </div>
                       )
                       }
                        {notificationPrivateProps.pay_plan === "BONOS" && (
                            <div className="suscription_psycho_msg" style={{marginTop:'0px'}}>
                               <span>BONOS</span> 
                            </div>
                       )
                       }
                        {notificationPrivateProps.type_plan === "Vital Wellness" && (
                            <div className="suscription_psycho_msg" style={{marginTop:'0px', backgroundColor: '#f6ffea'}}>
                               <span>VitalWellness</span> 
                            </div> )
                       }
                       {notificationPrivateProps.pay_plan === "Vital Wellness" && (
                            <div className="suscription_psycho_msg" style={{marginTop:'0px', backgroundColor: 'rgba (254, 246, 240, 1)'}}>
                               <span>VitalWellness</span> 
                            </div> )
                       }
                       
                    <p className={'notification-content-title'}>{notificationPrivateProps.title}</p>
                    <p className="notification-content-subtitle" dangerouslySetInnerHTML={{ __html: notificationPrivateProps.subtitle }}></p>
                </div>
               
            </div>
            
        </div>
    );
}

export default YourProducts
