import CancelModal from "../../../../assets/img/iconoir_cancel.svg";
import { msgsDiet } from "../../../../services/getPlansRecordService";
import './Modal_messages.css';
import './../../private/diet/contact-specialist/Message.css';
import avatar_specialist from '../../../../assets/img/chat/Avatar2.jpg';
import circulo from '../../../../assets/img/circulo.jpg';


type MyProps = {
    onHide: () => void;
    msgs: msgsDiet[];
}

export default function ModalRedirect({onHide,msgs}: MyProps)
{
    const firstName = localStorage.getItem('firstName');
    const lastName = localStorage.getItem('lastName');
    return (
    <div className={"modal-success-container"}>
        <div className={"modal-success modal-success-messages"}>
            <div>
                <img
                alt='Cancel'
                src={CancelModal}
                style={{
                    position: "absolute",
                    right: "1.5rem",
                    top: "1.5rem",
                    cursor: "pointer",
                }}
                onClick={onHide}
                />{" "}
            </div>
            <h1 className={"custom-modal-title-unified"}>
                CHAT DEL PLAN ID {msgs[0].id_plan}
            </h1>
            <div className={"modal-success-scroll"}>
                        
                        {msgs.map((item, index2) => (
                            <>
                            <h3>{item.fecha}</h3>
                            <div className="message" style={{display: "flex", justifyContent: item.es_cliente === '1' ? "end" : 'start'}}>
                                <div className="message-container" style={{width: '80%', display: "flex", flexDirection: item.es_cliente === '1' ? "row-reverse" : "row"}}>
                                {item.es_cliente === '0'
                                    ? 
                                    <div className='employee-icon'>
                                        <img src={avatar_specialist} alt="img" style={{}}/>
                                    </div>
                                : 
                                <div className='client-icon'>
                                    <div className='header-background-letters-chat'>
                                    <p>{firstName || lastName ? `${firstName?.substring(0, 1)}${lastName?.substring(0, 1)}` : 'U'}</p>
                                    </div>
                                    <img src={circulo} className="" alt="img" />
                                </div>
                                }   
                                    <p className={item.es_cliente === '1' ? 'message-green' : 'message-white'}>{item.mensaje}</p>
                                </div>
                            </div>
                            </>
                        ))}
            </div>
        </div>
    </div>);
}