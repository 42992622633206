import axios from "../api/axios";
import {GET_SUBSCRIPTIONS_URL} from "../utils/constants";

export interface WoocomerceSubscription {
    categories: Array<string>;
    next_payment_on: string;
    product_id: string;
    status: string;
    subscription_id: number;
}

export async function getSubscriptionService(): Promise<WoocomerceSubscription[]> {
    const token = localStorage.getItem('token');
    const headers =  {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }
    const response = await axios.get(GET_SUBSCRIPTIONS_URL, headers);
    return response.data;
}
