export const STRIPE_DEFAULT_AMOUNT = 100;
export const STRIPE_CURRENCY = 'EUR';
export const PAYMENT_INTENT_URL = '/payments/payment-intent';
export const USER_SUBSCRIPTIONS_URL = '/order';
export const PRODUCTS_URL = '/products';
export const GET_COUPLE_PSI_CHAT_URL = '/my-psi-chat-couple'
export const GET_ORDERS_URL = '/private/orders';
export const GET_DIET_URL = '/my-diet';
export const CHECK_DIET_URL = '/my-diet-check';
export const GET_DIET_GIFT_CALENDAR_URL= '/my-diet-interactive-calendar';
export const GET_CLIENT_CHAT_URL = '/my-diet-chat';
export const GET_PSI_CHAT_URL = '/my-psi-chat';
export const SEND_DIET_MESSAGE = '/send-diet-message';
export const SEND_PSI_MESSAGE = '/send-psi-message';
export const SEND_COUPLE_PSI_MESSAGE = '/send-couple-psi-message';
export const CHANGE_BILLING_DETAILS = '/profile';
export const CHANGE_PASSWORD = '/profile/password';
export const ACTIVE_PLANS_URL = '/my-active-plan';
export const GET_MY_NOTIFICATIONS = '/my-notifications';
export const GET_PSI_SESSIONS = '/my-psi-sessions';
export const GET_PSI_SPECIALIST_URL = '/check-specialist';
export const GET_PSI_SPECIALIST_COUPLE_URL = '/check-specialist-couple';
export const GET_PSI_COUPLE_SESSIONS = '/my-psi-couple-sessions';
export const GET_DIET_CALENDAR_URL = '/my-diet-calendar';
export const GET_PSI_CALENDAR_URL = '/my-psi-calendar';
export const GET_PSI_CALENDAR_COUPLE_URL = '/my-psi-calendar-couple';
export const GET_PLAN_RECORDS_URL='/my-plans-record';
export const GET_DIET_PLAN_PDF_URL='/my-plans-record-pdf';
export const GET_DIET_VIDEOCALL_URL='/my-diet-citas-videollamada';
export const RENOUNCE_VIDEOCALL_URL = '/ajax-app-citas-renunciar-videollamada-api';
export const UPDATE_VIDEOCALL_URL ='/ajax-app-citas-reservar-videollamada-api';
export const UPDATE_VIDEOCALL_PSI_URL='ajax-app-citas-reservar-cita-api';
export const UPDATE_VIDEOCALL_PSI_COUPLE_URL='ajax-app-citas-reservar-cita-pareja-api';
export const GET_TRAINING_URL='/my-training';
export const GET_TRAINING_VIDEOCALL_URL='/my-training-citas-videollamada';
export const GET_TRANSFORMATIONS_URL = '/my-transformation';
export const GET_MESSSAGES_USER_DIET_SPECIALIST_URL = '/app-planes/chat-api/';
export const SUBSCRIPTION_CHECK_OLD_CLIENT_URL='/check-old-client-api';
export const SUBSCRIPTION_CHECK_TYPE_PLAN_URL='/check-type-plan-api';
export const SUBSCRIPTION_CHECK_ACCESS_NEW_PLAN_URL='/check-access-api';
export const SUBSCRIPTION_NEW_PLAN_NOT_CLIENT_URL='/do-register-not-client-api';
export const SUBSCRIPTION_NEW_PLAN_URL='/complete-subscription-new-plan-api';
export const SUBSCRIPTION_RENEW_PLAN_URL='/complete-renew-plan-api';
export const SUBSCRIPTION_REVIEW_PLAN_URL='/complete-review-plan-api';
export const GET_SUBSCRIPTIONS_URL = '/private/subscriptions';
export const TEAM_URL = '/teams';
export const PRODUCT_DETAIL_URL = '/product/';
export const FAQ_URL = '/faqs';
export const VERIFY_WP_CLIENT_URL = '/wp-customers/verify';
export const PROCESS_ORDER_URL = '/order/process';
export const CREATE_ORDER_URL = '/orders';
export const COUPON_VALIDATE_URL_1 = '/coupons/';
export const COUPON_VALIDATE_URL_2 = '/validate';
export const ORDER_URL = '/payment/order';
export const CUSTOMER_URL = '/customers/create';
export const TESTIMONIALS_URL = '/testimonials';
export const FORGOT_PASSWORD_URL = '/auth/recover-password';
export const RESET_PASSWORD_URL = '/auth/reset-password';
export const TESTIMONIALS_SLIDER_URL = '/testimonials/slider';
export const SLIDES_URL = '/slides';
export const PDF_DIET_PLANS_DOWNLOAD = '/uploads/planes/'           // TODO poner camino correcto
export const PDF_TRAINING_PLANS_DOWNLOAD = '/download/training-plans/'  // TODO poner camino correcto
export const JSON_CONTENT_TYPE = 'application/json';
export const CARD_ERROR = 'card_error';
export const VALIDATION_ERROR = 'validation_error';
export const UNEXPECTED_ERROR = 'An unexpected error occurred.';
export const LOGIN_URL = '/auth/login';
export const CONTACT_URL = '/contact';
export const HOME_ROUTE = '/';
export const HOME_USER_ROUTE = '/profile';
export const RESET_PASSWORD_ROUTE = '/reset';
export const LOGIN_ROUTE = '/login';
export const TESTIMONIALS_ROUTE = '/testimonials';
export const FAQS_ROUTE = '/faq';
export const TEAM_ROUTE = '/team';
export const PAYMENT_ROUTE = '/payment';
export const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%*-]).{8,24}$/;
export const RESET_PASSWORD = '/resetpassword';
// eslint-disable-next-line
export const EMAIL_REGEX = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
export const PHONE_REGEX = /^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,5})|(\(?\d{2,6}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/;
export const PHONE_REGEX2 = /^\d{9}$/;
export const REGISTER_URL = '/auth/register';
export const NO_SERVER_RESPONSE_ERROR = 'No Server Response';
export const USERNAME_TAKEN_ERROR = 'Username Taken';
export const REGISTRATION_FAILED_ERROR = 'Registration Failed';
export const CATEGORY_DIETA = 'diet';
export const CATEGORY_PSICOLOGIA = 'psychology';
export const CATEGORY_TALLER = 'workshop';
export const CATEGORY_MIS_DATOS = 'data';
export const CATEGORY_MI_SUSCRIPCION = 'subscriptions';
export const CATEGORY_MIS_PRODUCTOS = 'products';
export const ACTIVE_BUTTON = 'active-button';
export const INACTIVE_BUTTON = 'inactive-button';
export const VER_MENOS = 'Ver menos';
export const VER_TESTIMONIO_COMPLETO = 'Ver testimonio completo';
export const ENTRENADORES_Y_NUTRICIONISTAS = 'ENTRENADORES Y NUTRICIONISTAS';
export const PSICOLOGAS = 'PSICÓLOGAS';
export const ROLE_TRAINER = 'diet';
export const ROLE_PSYCHOLOGIST = 'psychology';
export const PROVINCES = ['Álava', 'Albacete', 'Alicante', 'Almería', 'Asturias', 'Ávila', 'Badajoz', 'Barcelona', 'Burgos', 'Cáceres', 'Cádiz', 'Cantabria', 'Castellón', 'Ciudad Real', 'Córdoba', 'Cuenca', 'Gerona', 'Granada', 'Guadalajara', 'Guipúzcoa', 'Huelva', 'Huesca', 'Islas Baleares', 'Jaén', 'La Coruña', 'La Rioja', 'Las Palmas', 'León', 'Lérida', 'Lugo', 'Madrid', 'Málaga', 'Murcia', 'Navarra', 'Orense', 'Palencia', 'Pontevedra', 'Salamanca', 'Santa Cruz de Tenerife', 'Segovia', 'Sevilla', 'Soria', 'Tarragona', 'Teruel', 'Toledo', 'Valencia', 'Valladolid', 'Vizcaya', 'Zamora', 'Zaragoza'];
export const BACK_IMAGE_TEXT = 'BACK';
export const SIDE_IMAGE_TEXT = 'SIDE';
export const FRONT_IMAGE_TEXT = 'FRONT';
export const PROVINCES_NEW = [{"code":"ES","name":"España","states":[{"code":"C","name":"A Coruña"},{"code":"VI","name":"Araba/Álava"},{"code":"AB","name":"Albacete"},{"code":"A","name":"Alicante"},{"code":"AL","name":"Almería"},{"code":"O","name":"Asturias"},{"code":"AV","name":"Ávila"},{"code":"BA","name":"Badajoz"},{"code":"PM","name":"Baleares"},{"code":"B","name":"Barcelona"},{"code":"BU","name":"Burgos"},{"code":"CC","name":"Cáceres"},{"code":"CA","name":"Cádiz"},{"code":"S","name":"Cantabria"},{"code":"CS","name":"Castellón"},{"code":"CE","name":"Ceuta"},{"code":"CR","name":"Ciudad Real"},{"code":"CO","name":"Córdoba"},{"code":"CU","name":"Cuenca"},{"code":"GI","name":"Girona"},{"code":"GR","name":"Granada"},{"code":"GU","name":"Guadalajara"},{"code":"SS","name":"Gipuzkoa"},{"code":"H","name":"Huelva"},{"code":"HU","name":"Huesca"},{"code":"J","name":"Jaén"},{"code":"LO","name":"La Rioja"},{"code":"GC","name":"Las Palmas"},{"code":"LE","name":"León"},{"code":"L","name":"Lleida"},{"code":"LU","name":"Lugo"},{"code":"M","name":"Madrid"},{"code":"MA","name":"Málaga"},{"code":"ML","name":"Melilla"},{"code":"MU","name":"Murcia"},{"code":"NA","name":"Navarra"},{"code":"OR","name":"Ourense"},{"code":"P","name":"Palencia"},{"code":"PO","name":"Pontevedra"},{"code":"SA","name":"Salamanca"},{"code":"TF","name":"Santa Cruz de Tenerife"},{"code":"SG","name":"Segovia"},{"code":"SE","name":"Sevilla"},{"code":"SO","name":"Soria"},{"code":"T","name":"Tarragona"},{"code":"TE","name":"Teruel"},{"code":"TO","name":"Toledo"},{"code":"V","name":"Valencia"},{"code":"VA","name":"Valladolid"},{"code":"BI","name":"Bizkaia"},{"code":"ZA","name":"Zamora"},{"code":"Z","name":"Zaragoza"}],"_links":{"self":[{"href":"https:\/\/www.vikika.es\/wp-json\/wc\/v3\/data\/countries\/es"}],"collection":[{"href":"https:\/\/www.vikika.es\/wp-json\/wc\/v3\/data\/countries"}]}}];
