import { useState, useEffect } from 'react'
import { Toast, ToastContainer } from 'react-bootstrap'
import { ToastPosition } from 'react-bootstrap/esm/ToastContainer';
import { ToastMsgProps } from '../../../models/componentProps/ToastMsgProps'
import './ToastMsg.css';

const ToastMsgComponent = (toastProps: ToastMsgProps) => {
  const [showToast, setToast] = useState(false)
  let toastPosition: ToastPosition | undefined;

  switch (toastProps.position) {
    case 'top-start':
    case 'top-center':
    case 'top-end':
    case 'middle-start':
    case 'middle-center':
    case 'middle-end':
    case 'bottom-start':
    case 'bottom-center':
    case 'bottom-end':
      toastPosition = toastProps.position;
      break;
    default:
      toastPosition = undefined;
      break;
  }
  useEffect(() => {
    const timer = setTimeout(() => {
      setToast(true);
    }, 500);
    return () => clearTimeout(timer);
  }, []);
  return (
    <div>
      <ToastContainer
        position={toastPosition} className="p-3" style={{zIndex: 99999}}
      >
        <Toast
          className={toastProps.type}
          
          onClose={() => setToast(false)}
          show={showToast}
          delay={5000}
          bg={toastProps.type}
        >
          <Toast.Header>
            <strong className='mr-auto'>{toastProps.titleHeader}</strong>
            <small></small>
          </Toast.Header>
          <Toast.Body className='msg-body'>{toastProps.info}</Toast.Body>
        </Toast>
      </ToastContainer>
    </div>

  )
}

export default ToastMsgComponent
