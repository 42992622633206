import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import {trackView} from "../../../utils/FacebookPixelEvents";
import "./Warning.css";

function Warning() {

  useEffect(() => {
    trackView();
  }, []);

  return (
    <>
    <Helmet>
      <link rel="canonical" href={`${process.env.REACT_APP_FRONT_URL}`+"/aviso-legal"} />
    </Helmet>
    <div className="container-warning">
      <h2>Aviso legal</h2>
      <p>
        De conformidad con lo dispuesto en la Ley 34/2002, de 11 de julio, de
        Servicios de la Sociedad de la Información y de Comercio Electrónico, se
        pone a disposición de los usuarios y visitantes la información relativa
        a la entidad propietaria de la página web https://www.vikika.es (en
        adelante, la “Web”).
      </p>

      <p className={'strong'}><strong>TITULAR DE LA PÁGINA WEB</strong></p>
      <ul>
        <li>Razón social: COSTA LIFE FIT, S.L. </li>
        <li>CIF: B87767257 </li>
        {/* <li>Domicilio: Calle
          Alcalá, n.º 155 esc, dcha. 3-1 Madrid (28009)</li> */}
        <li>Correo electrónico:
          direccion@vikika.es</li>
        <li>INSCRITA EN EL REGISTRO MERCANTIL DE MADRID al Tomo 35.694, Folio 185, Hoja M-641484, inscripción 1ª.</li>
      </ul>

      <p className={'strong'}><strong>USUARIO Y RÉGIMEN DE RESPONSABILIDADES</strong></p>
      <p>
        La navegación, acceso y uso por la Web de COSTA LIFE FIT, S.L. confiere
        la condición de usuario, por la que se aceptan, desde la navegación por
        la página de COSTA LIFE FIT, S.L., todas las condiciones de uso aquí
        establecidas sin perjuicio de la aplicación de la correspondiente
        normativa de obligado cumplimiento legal según el caso. </p>
      <p>La página web de
        COSTA LIFE FIT, S.L. proporciona gran diversidad de información,
        servicios y datos. El usuario asume su responsabilidad en el uso
        correcto de la Web. Esta responsabilidad se extenderá a:</p>
      <ul>
        <li>La veracidad y
          licitud de las informaciones aportadas por el usuario en los formularios
          extendidos por COSTA LIFE FIT, S.L. para el acceso a ciertos contenidos
          o servicios ofrecidos por la Web. También será responsable del uso que
          le dé a las claves de acceso que haya obtenido con la cumplimentación de
          los formularios mencionados.</li>
        <li>El uso de la información, servicios y datos
          ofrecidos por COSTA LIFE FIT, S.L. contrariamente a lo dispuesto por las
          presentes condiciones, la Ley, la moral, las buenas costumbres o el
          orden público, o que de cualquier otro modo puedan suponer lesión de los
          derechos de terceros o del mismo funcionamiento de la Web.</li>
        </ul>
      <p>Los datos
        proporcionados por el usuario serán registrados en una base de datos
        propiedad de COSTA LIFE FIT, S.L. Solo se pedirán al usuario los datos
        personales necesarios para el fin recabado. Los datos proporcionados por
        el usuario serán utilizados para la elaboración del perfil de usuario de
        la Web. Así mismo, el usuario da su consentimiento para el envío de
        información comercial sobre los servicios de COSTA LIFE FIT, S.L., a
        menos que así lo haya indicado expresamente a través del formulario
        correspondiente. Para ampliar más información, visite nuestra Política
        de Privacidad.
      </p>

      <p className={'strong'}><strong>PRIVACIDAD </strong></p>
      <p>
        El acceso a los contenidos y/o elementos de la Web, no otorga al usuario
        el derecho de alteración, modificación, explotación, reproducción,
        distribución o comunicación pública de dichos contenidos, derechos y/o
        elementos. </p>
      <p>El usuario se compromete a utilizar los contenidos y/o
        elementos a los que acceda a través de los servicios de la Web para su
        propio uso y necesidades, y a no realizar en ningún caso una explotación
        comercial, directa o indirecta de los mismos. Se prohíbe el uso
        comercial de los contenidos del sitio web sin previa consulta previa y
        autorización por parte de COSTA LIFE FIT, S.L.
      </p>

      <p className={'strong'}><strong>PROPIEDAD INTELECTUAL E INDUSTRIAL:</strong></p>
      <p>
        Los derechos de propiedad intelectual e industrial derivados de todos el
        material publicado en la app/web incluyendo, sin limitación textos,
        imágenes, fotografías, dibujos, música, marcas o logotipos, estructura y
        diseño de la composición de cada una de las páginas individuales que
        componen la totalidad del sitio, combinaciones de colores, código fuente
        de los programas que generan la composición de las páginas, y el
        software necesario para su funcionamiento, acceso y uso son propiedad
        exclusiva de COSTA LIFE FIT, S.L. o cedidas bajo licencia por la marca
        del proveedor. Se advierte a los clientes y usuarios de que los derechos
        de COSTA LIFE FIT, S.L. sobre el contenido, diseño y código fuente de
        esta Web están protegidos por la legislación española e internacional
        vigente y relativa a la propiedad intelectual e industrial. Queda
        expresamente prohibida la reproducción total o parcial de esta Web, ni
        de cualquiera de sus contenidos, sin el permiso expreso y por escrito de
        COSTA LIFE FIT, S.L. Asimismo, queda totalmente prohibida la copia,
        reproducción, adaptación, modificación, distribución, comercialización,
        comunicación pública y/o cualquier otra acción que comporte una
        infracción de la normativa vigente española y/o internaciones en materia
        de propiedad intelectual y/o industrial, así como el uso de los
        contenidos del portal. En caso de duda, Ud., como visitante de nuestro
        sitio entiende y acepta que no puede adaptar, editar, cambiar,
        transformar, publicar, re-publicar, distribuir, re-distribuir, realizar
        cualquier tipo de emisión por medio ondas o cualquier otra tecnología de
        transmisión, mostrar en lugares públicos o privados mediante el uso de
        elementos de visión o escucha comunitarios esta Web o el material
        publicado en él (con independencia de tipo de archivo o elemento) ni
        realizar cualquier otra acción que comporte una infracción de la
        normativa vigente española y/o internaciones en materia de propiedad
        intelectual y/o industrial sin nuestro permiso previo por escrito. COSTA
        LIFE FIT, S.L. informa que no concede licencia o autorización implícita
        alguna sobre los derechos de propiedad intelectual y/o industrial o
        sobre cualquier otro derecho o propiedad relacionados, directa o
        indirectamente, con los contenidos incluidos en el dominio de propiedad
        de COSTA LIFE FIT, S.L. </p>
      <p>Todos los contenidos de COSTA LIFE FIT, S.L. se
        encuentran debidamente registrados y protegidos por la normativa de
        Propiedad Intelectual e Industrial, así como preceptivamente inscrita en
        los registros públicos correspondientes. Independientemente de la
        finalidad para la que fueran destinados, la reproducción total, parcial,
        uso, explotación, distribución y comercialización requiere en todo caso
        de la autorización previa, expresa y por escrito por parte de COSTA LIFE
        FIT, S.L. </p>
        <p>Todos los derechos reservados. En cumplimiento de la Ley de la
        Propiedad Intelectual se prohíbe expresamente la reproducción,
        distribución, comunicación pública y utilización, de la totalidad o
        parte de los contenidos de la Web sin el consentimiento expreso de COSTA
        LIFE FIT, S.L.
      </p>

      <p className={'strong'}><strong>EXCLUSIÓN DE GARANTÍAS Y RESPONSABILIDAD. ENLACE DE TERCEROS.</strong></p>
      <p>
        COSTA LIFE FIT S.L. no se hace responsable, en ningún caso, de los daños
        y perjuicios de cualquier naturaleza que se pudieran ocasionar, a título
        enunciativo: errores u omisiones en los contenidos, falta de
        disponibilidad del portal o la transmisión de virus o programas
        maliciosos o lesivos en los contenidos, a pesar de haber adoptado todas
        las medidas tecnológicas necesarias para evitarlo. El contenido,
        información y consejos expresados en este portal web deben entenderse
        como simplemente orientativos. </p>
      <p>COSTA LIFE FIT, S.L. no responde de
        ninguna forma de la efectividad o exactitud de los mismos, quedando
        exento de cualquier responsabilidad con los usuarios y usuarias que
        hagan uso de ellos. </p>
      <p>En esta Webse pueden publicar contenidos y
        comentarios aportados por terceras personas. COSTA LIFE FIT, S.L. no
        responde de la veracidad y exactitud de los mismos, quedando exenta de
        cualquier responsabilidad con los usuarios y con las usuarias que hagan
        uso de ellos. Así mismo, la empresa declina cualquier responsabilidad
        por los eventuales daños y perjuicios que puedan ocasionarse por la
        falta de disponibilidad o continuidad de este sitio y de los servicios
        que se ofrecen en él. Tampoco podemos garantizar la ausencia de virus ni
        de otros elementos en la Web que puedan producir alteraciones en su
        sistema informático. </p>
      <p>El presente Aviso Legal se refiere únicamente a la
        Web y contenidos de la entidad, y no se aplica a los enlaces o alas
        páginas web de terceros accesibles a través de esta Web. De modo que
        COSTA LIFE FIT, S.L. no es responsable del contenido de ninguna de las
        páginas web de destino de un enlace, ni de ningún enlace incluido en una
        página web a la que se llegue desde la Web. </p>
      <p>Si Ud. cree que cualquier
        contenido o información de este Sitio vulnera un derecho legítimo o la
        legalidad vigente le estaríamos muy agradecidos que se pusiera en
        contacto con nosotros a través de los medios que considere oportunos
        bien a la dirección de correo electrónico direccion@vikika.es para que
        podamos tomar las medidas oportunas.
      </p>

      <p className={'strong'}><strong>MODIFICACIONES:</strong></p>
      <p>
        COSTA LIFE FIT, S.L. se reserva el derecho de efectuar sin previo aviso
        las modificaciones que considere oportunas en su portal, pudiendo
        cambiar, suprimir o añadir tanto los contenidos y servicios que se
        presten a través de la misma como la forma en la que estos aparezcan
        presentados o localizados en su portal.
      </p>

      <p className={'strong'}><strong>DERECHO DE EXCLUSIÓN:</strong></p>
      <p>
        COSTA LIFE FIT, S.L. se reserva el derecho a denegar o retirar el acceso
        a portal o a los servicios ofrecidos sin necesidad de preaviso, a
        instancia propia o de terceras personas, a aquellos usuarios y a
        aquellas usuarias que incumplan las presentes Condiciones Generales de
        Uso.
      </p>

      <p className={'strong'}><strong>GENERALIDADES:</strong></p>
      <p>
        COSTA LIFE FIT S.L. perseguirá el incumplimiento de las presentes
        condiciones, así como cualquier utilización indebida de su portal
        ejerciendo todas las acciones civiles y penales que le puedan
        corresponder en derecho.
      </p>

      <p className={'strong'}><strong>NOTIFICACIONES</strong></p>
      <p>
        A los efectos del presente Aviso Legal, y para cualquier comunicación
        que se precisa entre la empresa y el usuario, podrá dirigirse por correo
        electrónico a dirección@vikika.es. Las comunicaciones de COSTA LIFE FIT,
        S.L. al usuario se realizarán conforme a los datos aportados por éste al
        registrarse en la Web. El Usuario acepta expresamente y para todas las
        comunicaciones relacionadas con la utilización de la Web y/o la
        contratación de los servicios en él ofertados, la utilización del correo
        electrónico como procedimiento válido para la remisión de dichas
        comunicaciones.
      </p>

      <p className={'strong'}><strong>LEGISLACIÓN APLICABLE Y JURISDICCIÓN:</strong></p>
      <p>
        La relación entre COSTA LIFE FIT, S.L. y la usuaria o el usuario se
        regirá por la normativa española vigente. Y siempre que la normativa
        vigente al efecto establezca la posibilidad para las partes la sumisión
        a un fuero determinado cualquier controversia entre la persona
        responsable del sitio web y la usuaria o el usuario se someterá a los
        Juzgados y tribunales de la ciudad de Madrid
      </p>

      <p className={'strong'}><strong>PLATAFORMA EUROPEA DE RESOLUCIÓN DE CONFLICTOS EN LÍNEA:</strong></p>
      <p>
        Le informamos que ante cualquier problema que pueda derivarse del
        servicio contratado o producto vendido, puede utilizar la plataforma
        europea de resolución de disputas online, esta es:
        https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home.show&lng=ES.
      </p>
    </div>
    </>
  );
}

export default Warning;
