import {useEffect, useState} from "react";
import {Helmet} from 'react-helmet-async';
import "./Faq.css";
import {CardState} from "../../../models/CardState";
import FaqTitle from "./faqTitle/FaqTitle";
import FaqDescription from "./faqDescription/FaqDescription";
import FaqButtons from "./faqButtons/faqButtons";
import {FaqData} from "../../../models/FaqData";
import {faqsService} from '../../../services/faqService'
import '../../../assets/css/Main.css'
import {CATEGORY_DIETA} from "../../../utils/constants";

const Faq = () => {
    const [selectedCategory, setSelectedCategory] = useState(CATEGORY_DIETA);
    const [faqsData, setFaqsData] = useState<FaqData[]>([]);
    const [filteredFaqsData, setFilteredFaqsData] = useState<FaqData[]>([]);

    async function fetchFaqs() {
        const faqsData = await faqsService(selectedCategory);
        setFaqsData(faqsData);
    }

    useEffect(() => {
        fetchFaqs().then(() => {
            console.log("Fetched faqs data successfully!");
        }).catch(err => {
            console.error("An error occurred while fetching faqs data: ", err);
        });
    }, [selectedCategory]);


    useEffect(() => {
        const filteredFaqsData = faqsData.filter((faq) => faq.category === selectedCategory);
        setFilteredFaqsData(filteredFaqsData);
    }, [selectedCategory, faqsData]);

    const handleButtonClick = (category: string) => {
        setSelectedCategory(category);
    };
    const [cardState, setCardState] = useState<CardState>({
        0: false, 1: false,
    });

    const toggleExpanded = (cardIndex: number) => {
        setCardState((prevState) => ({
            ...prevState, [cardIndex]: !prevState[cardIndex],
        }));
    };

    const faqContent = (filteredFaqsData.map((fa, index) => (<div key={index} className="card-container">
                <FaqTitle toogleExpanded={toggleExpanded} cardState={cardState[index]} index={index} title={fa.question}
                          selectedCategory={selectedCategory}/>
                {cardState[index] && (<FaqDescription text={fa.answer}/>)}
            </div>))

    )

    return (<>
            <Helmet>
                <title>FAQ | Todas las preguntas y respuestas sobre Vikika Team</title>
                <link rel="canonical" href={`${process.env.REACT_APP_FRONT_URL}` + "/faq"}/>
                <meta name="description" content="Respuestas completas sobre Vikika Team. Resuelve tus dudas y obtén información clave. ¡Explora nuestras FAQs hoy!"/> 
            </Helmet>
            <main className="main-content">
                <div className="faqs-container">
                    <div className="title-section-faqs">
                        <h2>PREGUNTAS FRECUENTES</h2>
                    </div>
                    <div className="header-section-faqs">
                        <h1>Todas las preguntas y respuestas sobre Vikika Team</h1>
                    </div>
                    <FaqButtons selectedCategory={selectedCategory} handleButtonClick={handleButtonClick}/>
                    <>
                        {faqContent}
                    </>
                </div>
            </main>
        </>

    )
}

export default Faq;
