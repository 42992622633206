import React, { useRef, useState, useEffect, SyntheticEvent } from 'react';
import { Helmet } from 'react-helmet-async';
import Questions from '../../shared/questions/Questions'
import './Contact.css'
import {
    EMAIL_REGEX,
    NO_SERVER_RESPONSE_ERROR
} from "../../../utils/constants";

import { isAxiosError } from "axios";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { contactService } from '../../../services/contactService';
import ToastMsgComponent from "../../shared/toast/ToastMsg";
import iconCheck from "../../../assets/img/mapas-y-banderas_1.png";
import {Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";

const Contact = () => {
    const navigate = useNavigate();
    const errRef = useRef<HTMLInputElement>(null);
    const emailRef = useRef<HTMLInputElement>(null);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [validEmail, setValidEmail] = useState(false);
    const [emailFocus, setEmailFocus] = useState(false);
    const [phone, setPhone] = useState('');
    const [message, setMessage] = useState('');
    const [acceptedPrivacy, setAcceptedPrivacy] = useState(false);
    const [disableButtonOnContact, setDisableButtonOnContact] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [showFailureToast, setShowFailureToast] = useState(false);

    const handleFinishContact = () => {
        setShowSuccessModal(false);
        navigate('/');
    }

    const [formValid, setFormValid] = useState(false);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [errMsg, setErrMsg] = useState('');

    useEffect(() => {
        setValidEmail(EMAIL_REGEX.test(email));
    }, [email])

    const validateForm = () => {
        return validEmail && acceptedPrivacy;
    }

    useEffect(() => {
        setValidEmail(EMAIL_REGEX.test(email));
        setFormValid(validateForm());
    }, [email, acceptedPrivacy])

    const handleSubmit = async (e: SyntheticEvent) => {
        e.preventDefault();
        setDisableButtonOnContact(true);
        if (!acceptedPrivacy) {
            setErrMsg('Debe aceptar la política de privacidad');
            setDisableButtonOnContact(false);
            return;
        }
        try {
            await contactService({ name, email, phone_number: phone, message });
            setDisableButtonOnContact(false);
            setShowSuccessModal(true);
        }
        catch (err) {
            setDisableButtonOnContact(false);
            if (isAxiosError(err)) {
                if (!err.response) {
                    setErrMsg(NO_SERVER_RESPONSE_ERROR);
                }
            }
            setShowFailureToast(true);
            setTimeout(() => {
                setShowFailureToast(false);
            }, 10000);
            errRef.current?.focus();
        }
    }

    return (
        <>
        <Helmet>
            <link rel="canonical" href={`${process.env.REACT_APP_FRONT_URL}`+"/contacto"} />
            <meta name="description" content="Contacta con nosotros e infórmate sobre nuestros planes o cualquiera de nuestros productos."/>
            <title>Contacto | ¿Quieres información sobre nuestros planes?</title>
        </Helmet>
        <main className='main-content'>
            {showSuccessModal && (
                <div className={'modal-success-container'}>
                    <div className={'modal-success'}>
                        <img src={iconCheck} alt={'checked'} className={'modal-success-image'}/>
                        <h2 className={'custom-modal-title-unified'}>Tu contacto ha sido recibido</h2>
                        <Typography className={'modal-success-text'}>
                            En breve nos comunicaremos contigo
                        </Typography>
                        <button className={'modal-success-button'} onClick={handleFinishContact}>
                            Entendido
                        </button>
                    </div>
                </div>
            )}
            {showFailureToast && (
                <ToastMsgComponent
                    type='danger'
                    titleHeader='No hemos podido procesar el contacto'
                    info='Inténtalo más tarde otra vez'
                    position='top-end' />
            )}
            <div className='contact-container'>
                <div className='contact-title'>
                    <h1>
                        ¡CONTÁCTA CON VIKIKA TEAM!
                    </h1>
                    <p>Resolveremos todas tus dudas.</p>
                </div>
                <div className='contact-form-container'>
                    {/* <div className='contact-form'>
                        <form onSubmit={handleSubmit} className='contact-form-input'>
                            <label htmlFor="name">Nombre </label>
                            <input
                                type='text'
                                id='name'
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                            <label htmlFor='email'>Correo </label>
                            <input
                                type='text'
                                id='email'
                                ref={emailRef}
                                autoComplete="off"
                                onChange={(e) => setEmail(e.target.value)}
                                value={email}
                                aria-invalid={validEmail ? "false" : "true"}
                                aria-describedby="emailnote"
                                onFocus={() => setEmailFocus(true)}
                                onBlur={() => setEmailFocus(false)}
                            />
                            <p id="emailnote" className={emailFocus && email && !validEmail ? "instructions" : "offscreen"}>
                                <FontAwesomeIcon icon={faInfoCircle} />
                                Debe introducir un correo válido.<br />
                            </p>
                            <label htmlFor='phone'>Teléfono </label>
                            <input
                                type='text'
                                id='phone'
                                placeholder='+34'
                                value={phone}
                                onChange={(e) => setPhone(e.target.value)}
                            />
                            <label htmlFor='message'>Consulta </label>
                            <textarea
                                id='consulta'
                                value={message}
                                style={{ height: '100px' }}
                                onChange={(e) => setMessage(e.target.value)}
                            />

                            <div className='checkbox-contact-container'>
                                <input
                                    type='checkbox'
                                    id='privacy'
                                    checked={acceptedPrivacy}
                                    onChange={(e) => setAcceptedPrivacy(e.target.checked)} />
                                <label htmlFor='rememberme'>Acepto la política de privacidad</label>
                            </div>
                            <div className='centered-btn-contact'>
                                <button className='btn-send' disabled={!formValid || disableButtonOnContact}>
                                    ENVIAR
                                </button>
                            </div>
                        </form>
                    </div> */}
                    <div className="contact-redirection-container">
                        <div className="contact-redirection-box">
                            <p>Escríbenos y te ayudaremos a resolver cualquier duda o incidencia que te haya surgido.</p>
                            <button  onClick={() => {
                                window.open(`${process.env.REACT_APP_WP_CUSTOMER_AREA}/contacto/`)
                                        }}>CONTACTAR</button>
                        </div>
                    </div>
                </div>

                <Questions />
            </div>
        </main>
        </>
    )
}

export default Contact

