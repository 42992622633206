import { Helmet } from 'react-helmet-async';
import {trackView} from "../../../utils/FacebookPixelEvents";
import './Welcome.css'
import IconTime from '../../../assets/img/24-horas.svg'
import Interesting from '../productDetails/interesting/Interesting';
import Questions from '../../shared/questions/Questions';
import { useState, useEffect } from 'react';
import { ProductType } from '../../../models/ProductType';
import { productsService } from '../../../services/productsService';
import { useNavigate } from 'react-router-dom';

const Welcome = () => {
  const [products, setProducts] = useState<ProductType[]>([]);
  const navigate = useNavigate();
  useEffect(() => {
    trackView();
    async function fetchProducts() {
      const products = await productsService();
      setProducts(products);
    }
    fetchProducts();
  }, []);

  const handleProductClick = (product: ProductType) => {
    navigate(`/pago`);

  };
  return (
    <>
    <Helmet>
      <link rel="canonical" href={`${process.env.REACT_APP_FRONT_URL}`+"/welcome"} />
    </Helmet>
    <main className="main-content">
      <div className='welcome-container'>
        <div className='welcome-hello'>
          <h1>¡HOLA! CLAUDIA GARCIA</h1>
        </div>
        <div className='welcome-msg'>
          <div className='circular-hours-bg '>
            <img src={IconTime} className="" alt="Slider" />
          </div>
          <div className='welcome-notice'>
            <h2>Estamos revisando tu suscripción,</h2>
            <h2>en breve estará activa</h2>
            <h5>Lorem ipsum dolor sit amet consectetur. Ornare pharetra massa ut sed a diam suspendisse egestas.
            </h5>
            <h5> Volutpat cras euismod sem pellentesque sit sit at in risus. Est arcu sit pellentesque morbi et nisi feugiat ac.
            </h5>
          </div>


        </div>
        <div className='welcome-others-products'>
          <div className='welcome-others-products-title'>
            <h1>TAMBIÉN TE PUEDE INTERESAR </h1>
          </div>
          <div className='product-cards-containers'>
              {
                // eslint-disable-next-line  array-callback-return
                products.map((product) => {
                  if (product.id !== 1) {
                    return (
                      <Interesting
                        key={product.id}
                        {...product}
                        onClick={() => handleProductClick(product)}
                      />
                    )
                  }
                }

                )
              }
          </div>
        </div>
      </div>
      <Questions />
    </main >
    </>
  )
}

export default Welcome;