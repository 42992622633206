import axiosCodeigniter from "../api/axiosCodeigniter";
import {GET_TRAINING_URL,GET_TRAINING_VIDEOCALL_URL} from "../utils/constants";
import {AxiosResponse} from "axios";

export const getTrainingPlanService = async (): Promise<Blob|null> => {

  const token = localStorage.getItem('token');
  try{
    const response = await axiosCodeigniter.get(`${GET_TRAINING_URL}`,{
      responseType: 'blob',
      headers:{
          Authorization: `Bearer ${token}`,
      }
    });
    const pdfData = response.data;
    if(response.data.size>1){
      const blob = new Blob([pdfData], { type: 'application/pdf' });
      return blob;
    }
    return null;
  }catch{
    return null;
  }
 
}

export async function verifyVideocallTrainingService(): Promise<AxiosResponse> {
  const token = localStorage.getItem('token');
  const headers =  {
      headers: {
          Authorization: `Bearer ${token}`,
      },
  }
  const response = await axiosCodeigniter.get(`${GET_TRAINING_VIDEOCALL_URL}`, headers);
  return response;
}
