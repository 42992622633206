import { useState } from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import './ProductCard.css';
import { useNavigate } from "react-router-dom";
import Novedad from './New';

interface CardProps {
    id: number
    name: string;
    image: string;
    alt: string;
    description: string;
    price: number;
    subscription: number;
    button_text: string;
    category?: string;
}

const ProductCard = ({ id, name, image, alt, description, price, subscription, button_text, category }: CardProps) => {
    const navigate = useNavigate();
    const formattedPrice = category === "workshop" ? "" : price % 1 === 0 ? price : price.toLocaleString('es-ES', { minimumFractionDigits: 2 });


    const handleClick = () => {
      let routeCategory;
      switch (category) {
          case 'psychology':
              routeCategory = 'psicologia';
              break;
          case 'workshop':
              routeCategory = 'talleres';
              break;
          default:
              routeCategory = 'dieta';
              break;
      }
      navigate(`/productos/${routeCategory}`);
  };
  

    return (
      <Card sx={{ maxWidth: 1024 }} className={"card-content"}>
          {category === "psychology" && (
            <Novedad/>
          )}
        <CardMedia
          component="img"
          alt={alt}
          height="100"
          src={image}
          className={category === "workshop" ? "workshop-card-container" : ""}
        />
        <div className={"card-content-product"}>
          <CardContent className="card-container-area">
            <Typography gutterBottom={true} variant="h5" component="div">
              <span
                className={`letter-type ${
                  category === "workshop" ? "workshop-card-container" : ""
                }`}
              >
                {name}
              </span>
            </Typography>
            <Divider className="divider-color" />
            <Typography
              gutterBottom={true}
              variant="body1"
              color="text.secondary"
            >
              <span className="description-product">
                <p>{description}</p>
              </span>
            </Typography>
            <Divider className="divider-color" />
            <Typography className="container-price" variant="h2">
              {category !== "workshop" && (
                <div className="normal" style={{ paddingTop: "0.7em" }}>
                  {" "}
                  Desde{" "}
                </div>
              )}
            </Typography>
            <Typography
              className="container-price"
              variant="h2"
              style={{ marginTop: "-0.5em" }}
            >
              <div className="large">
                  {formattedPrice}
                <span className="small">
                  {category === "diet" ? "€/mes" : ""}
                  {category === "psychology" ? "€/sesión" : ""}
                  {category === "workshop" ? "" : ""}
                </span>
              </div>
              {subscription !== 0 ? (
                <div className="subscription">primer mes {subscription} €</div>
              ) : (
                <div className="subscription">
                  <br></br>
                </div>
              )}
            </Typography>
          </CardContent>
          <CardActions style={{ marginTop: "-0.5em" }}>
            <div
              className="btn-product"
              style={{ marginTop: category === "workshop" ? "98px" : "0px" }}
            >
              <Button
                size="large"
                fullWidth={true}
                className="btn-product-btn"
                onClick={handleClick}
              >
                <span className="spacer">
                  {category === "workshop" ? "Próximamente" : button_text}
                </span>
              </Button>
            </div>
          </CardActions>
        </div>
      </Card>
    );
}

export default ProductCard
