import React, { Component } from "react";
import Slider, { Settings } from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './SlideHowWork.css';


export default class SlideHowWork extends Component {
    render() {
        const settings: Settings = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            initialSlide: 0,
            autoplaySpeed: 3000,
            arrows: false,            
        };
        return (

            <div className="works-container">
                <Slider {...settings}>
                    <div className="text-works-h3">
                        <span>Únete al Team</span>
                        <span className='line-green'> - </span>
                        <span> Rellenas tus datos </span>
                    </div>
                    <div className="text-works-h3">
                        <span>Accede a tu panel <br />personal </span>
                        <span className='line-green'> - </span>
                        <span>¡Disfruta la experiencia!</span>
                    </div>
                </Slider>
            </div>


        )
    }
}
