import { Radio} from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

// Definimos estilos apara los radiobuttons que usamos en los formularios
const useRadioButtonStyles = makeStyles({

    root: {
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    icon: {
      borderRadius: '50%',
      width: 18,
      height: 18,
      boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
      backgroundColor: '#f5f8fa',
      backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
      '$root.Mui-focusVisible &': {
        outline: '2px auto rgba(19,124,189,.6)',
        outlineOffset: 2,
      },
      'input:hover ~ &': {
        backgroundColor: '#ebf1f5',
      },
      'input:disabled ~ &': {
        boxShadow: 'none',
        background: 'rgba(206,217,224,.5)',
      },
    },
    checkedIcon: {
      backgroundColor: '#fff',
      backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
      '&:before': {
        display: 'block',
        width: 18,
        height: 18,
        backgroundImage: 'radial-gradient(#BAD0A2,#BAD0A2 28%,transparent 32%)',
        content: '""',
      },
      'input:hover ~ &': {
        backgroundColor: '#fff',
      },
    },
  
});


const useRadioOptionUnchekedStyles = makeStyles({

    root: {
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    icon: {
      borderRadius: '50%',
      width: 16,
      height: 16,
      boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
      backgroundColor: '#fff',
      backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
      '&:before': {
        display: 'block',
        width: 16,
        height: 16,
        backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
        content: '""',
      },
      'input:hover ~ &': {
        backgroundColor: '#fff',
      },
    },
});

const useRadioOptionChekedStyles = makeStyles({

    root: {
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    icon: {
      borderRadius: '50%',
      width: 16,
      height: 16,
      boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
      backgroundColor: '#fff',
      backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
      '&:before': {
        display: 'block',
        width: 16,
        height: 16,
        backgroundImage: 'radial-gradient(#BAD0A2,#BAD0A2 28%,transparent 32%)',
        content: '""',
      },
      'input:hover ~ &': {
        backgroundColor: '#fff',
      },
    },
});

export function StyledRadio(props:any) {
  const classes = useRadioButtonStyles();
  return (
    <Radio
      className={classes.root}
      disableRipple
      /* color="default" */
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      {...props}
    />
  );
}

export function StyledSelectRadioUncheked(props:any) {
    const classes = useRadioOptionUnchekedStyles();
    return (
      <Radio
        className={classes.root}
        disableRipple
        /* color="default" */
        /* checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />} */
        icon={<span className={classes.icon} />}
        {...props}
      />
    );
}

export function StyledSelectRadioCheked(props:any) {
    const classes = useRadioOptionChekedStyles();
    return (
      <Radio
        className={classes.root}
        disableRipple
        /* color="default" */
        /* checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />} */
        icon={<span className={classes.icon} />}
        {...props}
      />
    );
}
