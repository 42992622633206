import { Button, Card, CardActions, CardContent, Typography } from '@mui/material';
import { CardProductProps } from '../../../../models/componentProps/CardProductProps';
import { Checkbox, FormControlLabel } from '@mui/material';
import {addToCart} from "../../../../utils/FacebookPixelEvents";
import TrainingRadioButton from './training/TrainingRadioButton';
import { Link, useNavigate, useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import IconGift from '../../../../assets/img/caja-de-regalo.svg'
import IconInfo from '../../../../assets/img/info-icon.svg'
import { isArray } from "jquery";
import { isPropertyDeclaration } from 'typescript';


const MenuProduct = (productProps: CardProductProps) => {
    const navigate = useNavigate();
    const [savedRadioProduct, setSavedRadioProduct] = useState<any>(null);
    const [productPrice, setProductPrice] = useState<number>(0);
    const [productPrice2, setProductPrice2] = useState<number | string>(
      productProps.product2?.base_price ? productProps.product2.base_price : ''
    );   
    const [isDisabled, setIsDisabled] = useState<boolean>(false);
    const [productCategory, setProductCategory] = useState<String>();
    const [isPsychoProdChecked, setIsPsychoProdChecked] = useState<number>(0);
    const [productSubscription, setProductSubscription] = useState<number>(productProps.product.subscription_price);
    const [selectedProductId, setSelectedProductId] = useState<string>('');
    const [selectedVariantId, setSelectedVariantId] = useState<string>(productProps.product2?.idBasic ? productProps.product2.idBasic : '');
    const { id } = useParams();
    const formattedProductPrice = productPrice % 1 === 0 ? productPrice : productPrice.toLocaleString('es-ES', { minimumFractionDigits: 2 });
    const totalprice = productPrice + productSubscription;
    const formattedTotalPrice = totalprice % 1 === 0 ? totalprice : totalprice.toLocaleString('es-ES', { minimumFractionDigits: 2 });
    const [resetRadioSelection, setResetRadioSelection] = useState(false);

    useEffect(() => {
        if (id == 'dieta'){
              setProductCategory('diet')
            }
            if (id == 'psicologia'){
              setProductCategory('psychology')
            }
            if (id == 'talleres'){
              setProductCategory('workshop')
            }
        }, [id]);

    const handleClick = () => {
        if(isPsychoProdChecked === 1 || isPsychoProdChecked === 2){
            addToCart();
            localStorage.removeItem('payment_intent');
            localStorage.removeItem('client_secret');
            localStorage.removeItem('facturationData');
            localStorage.removeItem('savedSelectedProduct');
            if(isPsychoProdChecked === 1){
                localStorage.setItem('savedSelectedProduct', JSON.stringify({
                    value: savedRadioProduct,
                    productId: selectedProductId,
                    variantId: selectedVariantId,
                    productPrice: productPrice,
                    name: productProps.product2?.name,
                    img: productProps.product2?.img,
                    alt: productProps.product2?.alt,
                    productCategory: productCategory, 
                    isEnPareja: isChecked,
                    productSubscription: productProps.product.product_subscription,
                    productSubscriptionPrice: productSubscription
                }));
            }else{
            localStorage.setItem('savedSelectedProduct', JSON.stringify({
                value: savedRadioProduct,
                productId: selectedProductId,
                variantId: selectedVariantId,
                productPrice: productPrice,
                name: productProps.product.name,
                img: productProps.product.img,
                alt: productProps.product.alt,
                productCategory: productCategory, 
                isEnPareja: isChecked,
                productSubscription: productProps.product.product_subscription,
                productSubscriptionPrice: productSubscription
            }));
        }
            navigate('/pago');
        }
    };
    useEffect(() => {
        if(productPrice === 0 && isPsychoProdChecked === 0){
            setProductPrice(productProps.product2?.radio_options[2].new_price_reduced_lonelly?productProps.product2?.radio_options[2].new_price_reduced_composed : 0);
        }
    },
    );

    const handleDoubtTraining = () => {
        if (id === 'dieta') {
            productProps.openAcordeonSection(4);
            const section = document.getElementById('accordion3');
            if (section) {
                section.scrollIntoView({ behavior: 'smooth' });
            }
        } else if (id === 'psicologia') {
            productProps.openAcordeonSection(2);
            const section = document.getElementById('accordion1');
            if (section) {
                section.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }

    const handleDoubtCouple = () => {
        if (id === 'dieta') {
            productProps.openAcordeonSection(5);
            const section = document.getElementById('accordion4');
            if (section) {
                section.scrollIntoView({ behavior: 'smooth' });
            }
        } else if (id === 'psicologia') {
            productProps.openAcordeonSection(6);
            const section = document.getElementById('accordion2');
            if (section) {
                section.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }

    const [isChecked, setIsChecked] = useState(false);
    const [isActiveRadioChecked, setIsActiveRadioChecked] = useState(id === 'psicologia');

    const handleActiveRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            setIsActiveRadioChecked(true);
            if(isPsychoProdChecked === 1){
                setIsPsychoProdChecked(0)
                setProductPrice(productProps.product2?.radio_options[2].new_price_reduced_lonelly?productProps.product2?.radio_options[2].new_price_reduced_composed : 0);
            }else{
                setIsPsychoProdChecked(1)
                handlePrice();
                setIsChecked(false)
            }
    };
    const handleActiveRadioChangeSubscription = (event: React.ChangeEvent<HTMLInputElement>) => {
      setIsActiveRadioChecked(true);
      if(isPsychoProdChecked === 2){
        setIsPsychoProdChecked(0);
        setProductPrice(productProps.product2?.radio_options[2].new_price_reduced_lonelly?productProps.product2?.radio_options[2].new_price_reduced_composed : 0);
    }else{
        setIsPsychoProdChecked(2)
        handlePrice();
    }
  };


    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsChecked(!isChecked);
    };

    // eslint-disable-next-line  react-hooks/exhaustive-deps
    useEffect(() => {
        setIsActiveRadioChecked(id === 'psicologia');
        setIsChecked(false)
    }, [id]);

    useEffect(() => {
        if (savedRadioProduct){
            const selectedValue = productProps.product.radio_options.find(x => x.value === savedRadioProduct.value)?.value || '';
            if (selectedValue === 'option3' && isChecked) {
                setSavedRadioProduct(null);
                setResetRadioSelection(true);
            }
        }
        handlePrice();
    }, [isChecked]);

    useEffect(() => {
        handlePrice();
    }, [savedRadioProduct]);

    const handleRadioChange = (value: string) => {
      if(isPsychoProdChecked === 2){
         const selectedValue = productProps.product.radio_options.find(x => x.value === value)
        setSavedRadioProduct(selectedValue);
      }else{
        const selectedValue = productProps.product2?.radio_options.find(x => x.value === value)
        setSavedRadioProduct(selectedValue);
      }
        
    };
    

    const handlePrice = () => {
        if (savedRadioProduct && isChecked) {
            if(isPsychoProdChecked === 1){
            setSelectedVariantId(id !== 'dieta' ? savedRadioProduct.idComposed || '' : '');
            setSelectedProductId(id !== 'dieta' ? productProps.product2?.idCouple || '' : savedRadioProduct.idComposed || '');
            }else{
            setSelectedVariantId(id !== 'dieta' ? savedRadioProduct.idComposed || '' : '');
            setSelectedProductId(id !== 'dieta' ? productProps.product.idCouple || '' : savedRadioProduct.idComposed || '');
            }
            setProductPrice(savedRadioProduct.new_price_reduced_composed || 0)
            setProductSubscription(savedRadioProduct.feeComposed || 0);
            
        } else if (savedRadioProduct) {
            if(isPsychoProdChecked === 1){
                setSelectedVariantId(id !== 'dieta' ? savedRadioProduct.idLonelly : '');
                setSelectedProductId(id !== 'dieta' ? productProps.product2?.idBasic || '' : savedRadioProduct.idLonelly || '');
            }else{
            setSelectedVariantId(id !== 'dieta' ? savedRadioProduct.idLonelly : '');
            setSelectedProductId(id !== 'dieta' ? productProps.product.idBasic || '' : savedRadioProduct.idLonelly || '');
            }
            setProductPrice(savedRadioProduct.new_price_reduced_lonelly || 100);
            setProductSubscription(savedRadioProduct.feeLonely || 0);
            
        } else {
            setProductPrice(productProps.product.base_price);
            setProductSubscription(productProps.product.subscription_price);
            setSelectedProductId(productProps.product.idBasic);
            setSelectedVariantId('');
        }
    }
    

    return (
        <div className='product-sesion'>
            <Card sx={{ maxWidth: 1024 }} className='card-content'>
                <div className='card-content-product-details'>
                    <CardContent>
                        <Typography className='container-price-plan-Details' variant="h2">
                            {isPsychoProdChecked == 0 &&(
                            <div className='FromMenuProduct'>
                                                            Desde
                                                        </div>
                            )
                            }
                            
                            <div className='large-myproduct'>
                                {formattedProductPrice}
                               { isPsychoProdChecked == 0 ? <span className='small-price'>€ /sesión</span> : isPsychoProdChecked == 1 ? <span className='small-price'>€ /mes</span> : <span className='small-price'>€</span> }
                            </div>
                            {productProps.product.product_subscription && (
                                <div className='subscription-price-details'>primer mes {formattedTotalPrice} €</div>
                            )}
                        </Typography>
                    </CardContent>                    
                    <CardActions>
                    { isPsychoProdChecked == 0 ? (
                    <span className='btn-myproduct-disabled'><Button size="large" onClick={handleClick} disabled={true}><span className='spacer-prod'>comprar</span></Button ></span>
                    ):(
                        <span className='btn-myproduct'><Button size="large" onClick={handleClick}><span className='spacer-prod'>comprar</span></Button ></span>
                    )
                    }
                    </CardActions>
                    <div className="dashed-line"></div>
                    <div className="product-menu-title-card">
                        <span>Elige el tipo de plan</span>
                    </div>
                    <div className='training-container'>
                        {productProps.product2?.radioLabel && (
                            <div className={`container-training-add ${isPsychoProdChecked === 2 ? 'container-training-add-check-disabled' : ''}`}>
                                <FormControlLabel className='container-training-add-check'
                                    control={<Checkbox checked={isActiveRadioChecked && isPsychoProdChecked === 1} onChange={handleActiveRadioChange} defaultChecked={false} />}
                                    label={
                                        <>
                                            {productProps.product2?.radioLabel}
                                        </>
                                    }
                                />
                            </div>
                        )}
                        {isPsychoProdChecked === 1 && (
                            <>
                            <TrainingRadioButton
                            options={productProps.product2?.radio_options ? productProps.product2.radio_options : []}
                            isActiveRadioChecked={isActiveRadioChecked && isPsychoProdChecked === 1}
                            isChecked={isChecked}
                            onChange={handleRadioChange}
                            resetRadioSelection={resetRadioSelection}
                            setResetRadioSelection={setResetRadioSelection}
                        />
                        <div className='help'>
                            <span><img src={IconInfo} alt='info' /></span>
                                <h6>Si tienes dudas de como funciona te lo explicamos <a className='here' onClick={handleDoubtTraining} >aquí</a> </h6>
                        </div>
                            </>
                        )
                        }       
                    </div>
                    <div className='divider-green-options'><span>o</span></div>
                    {/* First psyhco prod */}
                    <div className='training-container'>
                        {productProps.product.radioLabel && (
                            <div className={`container-training-add ${isPsychoProdChecked === 0 ? 'isNotChecked-training' : ''} ${isPsychoProdChecked === 1 ? 'container-training-add-check-disabled' : ''}`}>
                                <FormControlLabel className='container-training-add-check'
                                    control={<Checkbox checked={isActiveRadioChecked  && isPsychoProdChecked === 2} onChange={handleActiveRadioChangeSubscription} defaultChecked={false} />}
                                    label={
                                        <>
                                            {productProps.product.radioLabel}
                                        </>
                                    }
                                />
                            </div>
                        )}
                        { isPsychoProdChecked === 2 && (
                            <> 
                            <TrainingRadioButton
                            options={productProps.product.radio_options}
                            isActiveRadioChecked={isActiveRadioChecked  && isPsychoProdChecked === 2}
                            isChecked={isChecked}
                            onChange={handleRadioChange}
                            resetRadioSelection={resetRadioSelection}
                            setResetRadioSelection={setResetRadioSelection}
                        />
                        <div className='help'>
                            <span><img src={IconInfo} alt='info' /></span>
                                <h6>Si tienes dudas de como funciona te lo explicamos <a className='here' onClick={handleDoubtTraining} >aquí</a> </h6>
                        </div>
                            <div className='couple-plan'>
                        <FormControlLabel
                            control={<Checkbox className='color-check-couple' onChange={handleCheckboxChange} />}
                            label={productProps.product.checkbox_option.option_name}
                        />
                    </div>
                    <div className='help'>
                        <span><img src={IconInfo} alt='info' /></span>
                        <h6>Si tienes dudas de como funciona o que incluye el plan en pareja te lo explicamos  <a className='here' onClick={handleDoubtCouple} >aquí</a> </h6>
                    </div>  
                            </>
                        )
                        }
                       
                    </div>
                    
                </div>


                <div className='help-you'>
                    <h3>¡Te ayudamos!</h3>
                    <h4>¿Tienes dudas y no sabes cual es el plan que mejor se adapta a ti?</h4>
                    <h5 style={{ cursor: "pointer" }} onClick={() => navigate('/contacto')}>Mándanos un mensaje</h5>
                </div>

            </Card>

        </div>
    )
}

export default MenuProduct
