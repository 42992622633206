import { useState, useEffect } from "react";
import "./PartnerForm.css"
import {PartnerData} from "../../../../models/PartnerData";
import { FacturationData } from "../../../../models/FacturationData";
import {EMAIL_REGEX} from "../../../../utils/constants";

interface PartnerFormProps {
    setPartnerData: (value: any) => void;
    onValidation: (isValid: boolean) => void;
    facturationData:FacturationData;
}

const PartnerForm = (partnerFormProps: PartnerFormProps) => {
    const { onValidation } = partnerFormProps;

    const [formData, setFormData] = useState<PartnerData>({
        partner_name: '',
        partner_last_name: '',
        partner_email: ''
    });

    const [partner_nameFocus, setPartner_nameFocus] = useState(false);
    const [partner_nameTouched, setPartner_nameTouched] = useState(false);

    const [partner_last_nameFocus, setPartner_last_nameFocus] = useState(false);
    const [partner_last_nameTouched, setPartner_last_nameTouched] = useState(false);

    const [partner_email, setPartner_Email] = useState('');
    const [partner_validEmail, setPartner_ValidEmail] = useState(false);
    const [partner_emailFocus, setPartner_EmailFocus] = useState(false);
    const [partner_emailTouched, setPartner_EmailTouched] = useState(false);

    useEffect(() => {
        setPartner_ValidEmail(EMAIL_REGEX.test(partner_email));
    }, [partner_email,partnerFormProps.facturationData.email])


    const CheckIsValid = () => {
        const resultCheckEmail = (formData.partner_email !== '') && partner_validEmail
        const isValid = (
            (formData.partner_name !== '') &&
            (formData.partner_last_name !== '') &&
            (formData.partner_email !== '')&&
            (formData.partner_email !== partnerFormProps.facturationData.email)&&
            partner_validEmail
        )
        onValidation(isValid)
    }



    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>, name: string) => {
        const { value } = event.target;
        setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    };

    useEffect(() => {
        partnerFormProps.setPartnerData(formData);
        CheckIsValid();
    }, [formData,partnerFormProps.facturationData.email])

    useEffect(() => {
        const savedFormdata = localStorage.getItem('facturationData');
        if (savedFormdata) {
            const parsedFormData = JSON.parse(savedFormdata);
            setFormData({
                partner_name: parsedFormData.partner_name,
                partner_last_name: parsedFormData.partner_last_name,
                partner_email: parsedFormData.partner_email,
            });
            partnerFormProps.setPartnerData(formData);
        } else {
            setFormData({
                partner_name: localStorage.getItem('partner_name') && localStorage.getItem('partner_name') != 'undefined' ? localStorage.getItem('partner_name')! : '',
                partner_last_name: localStorage.getItem('partner_last_name') && localStorage.getItem('partner_last_name') != 'undefined' ? localStorage.getItem('partner_last_name')! : '',
                partner_email: localStorage.getItem('partner_email') && localStorage.getItem('partner_email') != 'undefined' ? localStorage.getItem('partner_email')! : ''
            });
        }
    }, []);  // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div >
            <div className='facturation-form-title'>
                <p>DATOS DE PAREJA</p>
            </div>

            <div className="facturation-form-container" >
                <div className='facturation-form-container-header'></div>
                <div className='form-container'>
                    <div className='facturation-form-subtitle'>
                        <p>Para la creación del plan en nuestra app necesitamos que nos indiques los datos de la otra persona que va a realizar el plan contigo.</p>
                    </div>

                    <form >
                        <div className='row-double-input' >
                            <div className={!partner_nameFocus && partner_nameTouched && formData.partner_name === '' ? 'input-short1 invalidInput' : 'input-short1'} >
                                <label htmlFor="partnerfirstName">Nombre <span className="red">*</span></label>
                                <input
                                    type="text"
                                    id="partnerfirstName"
                                    defaultValue={formData.partner_name}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => handleInputChange(event, 'partner_name')}
                                    onInput={(event: React.ChangeEvent<HTMLInputElement>) => handleInputChange(event, 'partner_name')}
                                    required
                                    onFocus={() => {
                                        setPartner_nameFocus(true)
                                        setPartner_nameTouched(true)
                                    }}
                                    onBlur={() => {
                                        setPartner_nameFocus(false)
                                        CheckIsValid()
                                    }}
                                />
                            </div>

                            <div className={!partner_last_nameFocus && partner_last_nameTouched && formData.partner_last_name === '' ? 'input-short2 invalidInput' : 'input-short2'}>
                                <label htmlFor="partnerlastName">Apellido <span className="red">*</span></label>
                                <input
                                    type="text"
                                    id="partnerlastName"
                                    defaultValue={formData.partner_last_name}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
                                        handleInputChange(event, 'partner_last_name')
                                        CheckIsValid()
                                    }}
                                    onInput={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        handleInputChange(event, 'partner_last_name')
                                        CheckIsValid()
                                    }}
                                    required
                                    onFocus={() => {
                                        setPartner_last_nameFocus(true)
                                        setPartner_last_nameTouched(true)
                                    }}
                                    onBlur={() => {
                                        setPartner_last_nameFocus(false)
                                        CheckIsValid()
                                    }}
                                />
                            </div>
                        </div>
                        <div className={(!partner_emailFocus && partner_email && !partner_validEmail) 
                            ||(!partner_emailFocus && partner_emailTouched && formData.partner_email === '') ? 'input-large invalidInput' : 'input-large'}>
                            <label htmlFor="partneremail">Correo electrónico <span className="red">*</span></label>
                            <input
                                type="text"
                                id="partneremail"
                                defaultValue={formData.partner_email}
                                onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
                                    handleInputChange(event, 'partner_email');
                                    setPartner_Email(event.target.value);
                                    CheckIsValid();
                                }}
                                onInput={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    handleInputChange(event, 'partner_email');
                                    setPartner_Email(event.target.value);
                                    CheckIsValid();
                                }}
                                required
                                aria-invalid={partner_validEmail ? "false" : "true"}
                                aria-describedby="emailnote"
                                onFocus={() => {
                                    setPartner_EmailFocus(true)
                                    setPartner_EmailTouched(true)
                                }}
                                onBlur={() => {
                                    setPartner_EmailFocus(false)
                                    CheckIsValid()
                                }}
                            />
                            <p id="emailnote" className={(partner_emailFocus && partner_email && !partner_validEmail) || (formData.partner_email === partnerFormProps.facturationData.email)? "instructions" : "offscreen"} style={{marginBottom: '0.5rem'}}>
                                    Debe introducir un correo válido y distinto del de su pareja.<br />
                                    
                            </p>
                        </div>
                    </form>
                </div>

            </div>
        </div>
    )
}

export default PartnerForm

