
import {useState, useEffect} from 'react';
import {HelmetProvider} from 'react-helmet-async';
import MenuContext from './providers/MenuProvider';
import Login from './components/views/login/Login';
import Header from './components/shared/header/Header';
import Footer from './components/shared/footer/Footer';
import Home from './components/views/home/Home';
import ProductDetails from './components/views/productDetails/PlanDetails';
import Payment from './components/views/payment/Payment';
import Testimonials from './components/views/testimonials/Testimonials';
import Faq from './components/views/faqs/Faq';
import Team from './components/views/team/Team';
import Reset from './components/views/reset/Reset';
import Private from './components/views/private/Private';
import {
    BrowserRouter as Router, Routes, Route
} from 'react-router-dom';
import './App.css';
import Contact from './components/views/contact/Contact';
import Welcome from './components/views/welcome/Welcome';
import Profile from './components/views/profile/Profile';
import ScrollToTopOnNavigate from './components/shared/scrollToTop/ScrollToTopOnNavigate';
import Warning from './components/views/warning/Warning';
import Privacy from './components/views/privacy/Privacy';
import Conditions from './components/views/conditions/Conditions';
import Cookies from './components/views/cookies/Cookies';
import MyData from "./components/views/private/myData/MyData";
import Work from './components/views/work/Work';
import PlansSubscriptions from "./components/views/plans-subscriptions/plans-subscriptions";
import BuySubscriptions from './components/views/private/buySubscriptions/BuySubscriptions';
import NewClientBuySubscriptions
    from './components/views/private/buySubscriptions/noClientBuySubscriptions/BuySubscriptions';
import NewClientBuySubscriptionsVitalWellness
    from './components/views/private/buySubscriptions/noClientBuySubscriptions/BuySubscriptionsVitalWellness';
import RenewSubscriptions from './components/views/private/buySubscriptions/renewSubscriptions/RenewSubscriptions';
import ReviewSubscriptions from './components/views/private/buySubscriptions/reviewSubscriptions/ReviewSubscriptions';
import { CookiesProvider, useCookies } from 'react-cookie';
// react query
import {QueryClient, QueryClientProvider} from 'react-query';
import CookieBanner from './components/views/cookies/CookieBanner';
import './utils/sentry';

function App() {

    const [showMenuPrincipal, setShowMenuPrincipal] = useState<boolean>(false);
    return (<HelmetProvider>
            <QueryClientProvider client={new QueryClient()}>
               <CookiesProvider>
               <main className="App notranslate">
                    <Router>
                        <MenuContext.Provider value={{showMenuPrincipal, setShowMenuPrincipal}}>
                            <Header/>
                            <ScrollToTopOnNavigate/>
                            <CookieBanner/>
                            <Routes>
                                <Route path="/" element={<Home/>}/>
                                <Route path="/iniciar-sesion" element={<Login/>}/>
                                <Route path="/productos/:id" element={<ProductDetails/>}/>
                                <Route path="/pago" element={<Payment/>}/>
                                <Route path="/testimonios" element={<Testimonials/>}/>
                                <Route path="/faq" element={<Faq/>}/>
                                <Route path="/equipo" element={<Team/>}/>
                                <Route path="/contacto" element={<Contact/>}/>
                                <Route path="/welcome" element={<Welcome/>}/>
                                <Route path="/profile/:id" element={<MyData/>}/>
                                <Route path="/recover-password" element={<Reset/>}/>
                                <Route path="/private" element={<Private/>}/>
                                <Route path="/privacidad" element={<Privacy/>}/>
                                <Route path="/aviso-legal" element={<Warning/>}/>
                                <Route path="/condiciones-generales" element={<Conditions/>}/>
                                <Route path="/cookies" element={<Cookies/>}/>
                                <Route path="/trabaja-con-nosotros" element={<Work/>}/>
                                <Route path="/plans_subscriptions" element={<PlansSubscriptions/>}/>
                                <Route path="/buy_subscriptions" element={<BuySubscriptions/>}/>
                                <Route path="/renew_subscriptions" element={<RenewSubscriptions/>}/>
                                <Route path="/review_subscriptions" element={<ReviewSubscriptions/>}/>
                                <Route path="/cuestionario_inicial" element={<NewClientBuySubscriptions/>}/>
                                <Route path="/cuestionario_inicial_vital_wellness" element={<NewClientBuySubscriptionsVitalWellness/>}/>
                            </Routes>
                        </MenuContext.Provider>
                        <Footer/>
                    </Router>
                </main>
               </CookiesProvider>
            </QueryClientProvider>
        </HelmetProvider>
        
        );
}

export default App;