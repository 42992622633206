import { Member } from "../../../../../models/TeamMember";
import { useEffect, useState } from "react";
import Instagram_logo from "../../../../../assets/img/Instagram logo.png";
import Default from '../../../../../assets/img/TeamMemberDefault.jpg'
import parse from "html-react-parser";

const TeamCard = (teamCardProps: Member) => {
    const { image, name, position, instagram_url, description } = teamCardProps;    

    const [expandedText, setExpandedText] = useState<Boolean>(false);
    const moreInfo = () => {
        setExpandedText(!expandedText)
    }

    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => setWidth(window.innerWidth);
        window.addEventListener("resize", handleResize);
        return () => {
        window.removeEventListener("resize", handleResize);
        };
    }, []);


    return (
        <div className="card">
            <div className="floating-logo">
                <a href={instagram_url} target="_blank" rel="noreferrer"><img src={Instagram_logo} alt='Instagram_logo' /></a>
            </div>
            <div className='card-image'>
                <img src={image !== '' ? image : Default} alt={name} />
            </div>
            <div className={expandedText ? 'card-info-complete' : 'card-info-brief'}>
                <div className="card-title">{name}</div>
                <div className="card-subtitle">{position}</div>
                <div className="card-description" style={{display: width < 475 && !expandedText ? 'none' : 'block'}}>{expandedText ? parse(description) :
                    parse(description.slice(0, 100) + '...') }</div>
                <div className="card-footer"></div>

            </div>
            <div className="card-action"><a onClick={moreInfo}> {expandedText ? 'Ver menos' : 'Saber más'} de {name}</a></div>
        </div>
    );
}

export default TeamCard
