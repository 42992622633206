import Icon from "../../../../assets/img/icon-collapse.svg";
import IconQ from "../../../../assets/img/question.png";
import {
    CATEGORY_DIETA,
    CATEGORY_PSICOLOGIA,
    CATEGORY_TALLER,
} from "../../../../utils/constants";
import { FaqTitleProps } from "../../../../models/componentProps/FaqTitleProps";

const FaqTitle = (faqTitleProps: FaqTitleProps) => {
    let categoryBackground
    const categoryBackgrounds = {
        [CATEGORY_DIETA]: 'bg-dieta',
        [CATEGORY_PSICOLOGIA]: 'bg-psicologia',
        [CATEGORY_TALLER]: 'bg-taller',
      };
    if (faqTitleProps.selectedCategory === CATEGORY_DIETA) {
        categoryBackground = categoryBackgrounds[CATEGORY_DIETA];
    } else if (faqTitleProps.selectedCategory === CATEGORY_PSICOLOGIA) {
        categoryBackground = categoryBackgrounds[CATEGORY_PSICOLOGIA];
    } else if (faqTitleProps.selectedCategory === CATEGORY_TALLER) {
        categoryBackground = categoryBackgrounds[CATEGORY_TALLER];
    }
    
    return (
        <div className="container-title">
            <div className="title-icon-container" style={{cursor:"pointer"}} onClick={() => faqTitleProps.toogleExpanded(faqTitleProps.index)}>
                <div className={`${categoryBackground}`}>
                    <img src={IconQ} alt="Card" />
                </div>

                <h2> {faqTitleProps.title} </h2>
            </div>
            <img
                className="faq-icon-collasep"
                src={Icon}
                alt='icon'
                onClick={() => faqTitleProps.toogleExpanded(faqTitleProps.index)}
                style={{
                    transform: faqTitleProps.cardState ? "rotate(180deg)" : undefined,
                }}
            />
        </div>
    )
}

export default FaqTitle
