import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import parse from "html-react-parser";
import "./../Banner.css";

interface BannerSlideProps {
    index: number;
    title: string;
    subtitle: string;
    button_href: string;
    button_text: string;
    image: string;
}

const BannerSlide = (bannerSlideProps: BannerSlideProps) => {

    return (
        <div className="slider-container" key={bannerSlideProps.index}>
            <div className='slider-text'>
                <h1>{parse(bannerSlideProps.title)}</h1>
                <span className='slider-text-small'>
                <h3>{parse(bannerSlideProps.subtitle)}</h3>
              </span>
                <button><a href={bannerSlideProps.button_href}>{bannerSlideProps.button_text}</a></button>
            </div>
            <div className='slider-img'>
                <div className="mask"></div>
                <img src={bannerSlideProps.image} className="slider" alt="Slider"/>
            </div>
        </div>
    )
}

export default BannerSlide

