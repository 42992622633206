import { useState, useEffect } from 'react';
import { TestimonialType } from '../../../../models/TestimonialType';
import { testimonialsService } from '../../../../services/testimonialsService';
import { TESTIMONIALS_SLIDER_URL} from '../../../../utils/constants';
import Testimonial from '../../../shared/testimonial/Testimonial';
import Slider, { Settings } from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useNavigate } from 'react-router-dom';
import "./ProductTestimonials.css";

interface TestimonialCategoryProps {
    category: any;
}

const ProductTestimonials = ( categoryProp : TestimonialCategoryProps) => {
    const [testimonials, setTestimonials] = useState<TestimonialType[]>([]);
    const [expanded, setExpanded] = useState(-1);
    const handleClick = (index: number) => {
        setExpanded(index === expanded ? -1 : index);
    };

    async function fetchTestimonials(category: string) {       
        const testimonials = await testimonialsService(`${TESTIMONIALS_SLIDER_URL}?category=${category}`);
        setTestimonials(testimonials);
    }
  
    const navigate = useNavigate();
    const moreTestimonial = () => {
        navigate(`/testimonios`);
    };


    useEffect(() => {
        fetchTestimonials(categoryProp.category);        
    }, [categoryProp.category]);


    const settings: Settings = {
        dots: true,
        dotsClass: "slidertestimonials-product-dots",
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        arrows: false,
        customPaging: (i) => <div className="custom-dot-testimonios"></div>,
    };

    const testimonialsContent = (
        // eslint-disable-next-line  array-callback-return
        testimonials.map((testimonial, index) => (
            <Testimonial
                key={index}
                title={testimonial.title}
                image={testimonial.image}
                subtitle={testimonial.subtitle}
                description={testimonial.description}
                expanded={index === expanded}
                handleClick={() => handleClick(index)}
                moreTestimonial={moreTestimonial}
                showMoreTestimonial={false}
                showMoreTestimonialTop={true}
                category={testimonial.category}
                type_of_sessions={testimonial.type_of_sessions || ''}
                number_of_sessions={testimonial.number_of_sessions || ''}
                current_weight={testimonial.current_weight || ''}
                psychologist={testimonial.psychologist || ''}
                duration_in_months={testimonial.duration_in_months || ''}
                previous_weight={testimonial.previous_weight || ''}
            />
        ))
    );
    return (

        <Slider {...settings}>
            {testimonialsContent}
        </Slider>



    )
}

export default ProductTestimonials
