import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import {trackView} from "../../../utils/FacebookPixelEvents";
import "./Work.css";

function Work() {

  useEffect(() => {
    trackView();
  }, []);

  return (
    <>
    <Helmet>
      <link rel="canonical" href={`${process.env.REACT_APP_FRONT_URL}`+"/trabaja-con-nosotros"} />
    </Helmet>
    <div className="work-container">
      <h1>Trabaja con nosotros</h1>
      <h2>
        <strong>¿Te gustaría formar parte del TEAM VIKIKA?</strong>
      </h2>
        <div style={{width: '86%', marginLeft: 'auto', marginRight: 'auto'}}>
            <h4>Si eres:</h4>
            <ul>
                <li>
                    <span style={{ textDecoration: "underline" }}>Nutricionista**</span> y
                    entrenador (<b style={{fontFamily: 'Montserrat SemiBold'}}>necesario ambas titulaciones</b>)
                </li>
                <li>Profesional del marketing</li>
                <li>Redactor</li>
                <li>Diseñador</li>
                <li>Diseñador web</li>
                <li>Community manager</li>
            </ul>
            <p>
                Y quieres formar parte de un equipo joven y dinámico <b style={{fontFamily: 'Montserrat SemiBold'}}>envíanos tu CV y
                las titulaciones correspondientes junto con una breve explicación</b> de
                porque te gustaría formar parte del team. Puedes enviarnos tu
                presentación en cualquier formato, la creatividad puntúa 😉 a <b style={{fontFamily: 'Montserrat SemiBold'}}>rrhh@vikika.es</b>
            </p>
            <p>
                *Títulos de nutricionista aceptados: Graduado en Nutrición humana y
                dietética, Técnico superior en dietética.
            </p>
        </div>
    </div>
    </>
  );
}

export default Work;
