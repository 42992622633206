import Arrow from "../../../../../assets/img/arrow-right.png";
import "./calendar.css";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import 'dayjs/locale/es';
import VideoCall from "./video_call";
import { IAppointmentEmployee, IAppointmentSlot, IBookedAppointment } from "../../../../../services/getDietCalendarService";

type MyProps = {
    type: string;
    onHideDrawer: () => void;
    specialist: IAppointmentEmployee;
    selected_slot: IAppointmentSlot;
    booked_slot: IBookedAppointment|null;
    onVideoCallNoAgendar: () => void;
    onVideoCallAgendarClicked: () => void;
    noShowButtons: boolean;
}

export default function CalendarVideocallArea({
    type,
    onHideDrawer,
    specialist,
    selected_slot,
    booked_slot,
    onVideoCallNoAgendar,
    onVideoCallAgendarClicked,
    noShowButtons
}: MyProps) {
    return (
        <Box
          style={{ top: "60px" }}
          role="presentation"
        >
          <Divider />
          <List>
            <img
              className="arrow_calendar"
              src={Arrow}
              alt="arrow"
              onClick={onHideDrawer}
            />
            <VideoCall
              type = {type}
              title="Gestión de videollamada"
              date={selected_slot.start.format("DD / MM / YYYY")}
              specialist={specialist}
              hour={`Desde ${selected_slot.start.format('HH:mm')} Hasta ${selected_slot.end.format('HH:mm')} (hora peninsular española)`}
              selected_slot={selected_slot}
              booked_slot={booked_slot}
              information="El especialista te estará esperando a la hora de la cita. Te aconsejamos revisar previamente que puedes acceder a la aplicación donde realizarás la videollamada y que tu micrófono y cámara funcionan. Recuerda ser puntual, de lo contrario perderás tu videollamada"
              noShowButtons={noShowButtons}
              onNoAgendar={onVideoCallNoAgendar}
              onAgendar={onVideoCallAgendarClicked}
            />
          </List>
        </Box>
      );

}