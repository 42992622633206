import { useState, useEffect, RefObject} from "react";
import { PersonalData } from "../../../../models/formsModels/PersonalData";
import {EMAIL_REGEX, PHONE_REGEX, PHONE_REGEX2} from "../../../../utils/constants";

import { FormControlLabel, RadioGroup } from '@mui/material';
import {StyledRadio} from "./formComponents/StyledRadio";
import nextArrow from '../../../../assets/img/arrow-right-form.svg';
import nextArrowGrey from '../../../../assets/img/arrow-right-form-grey.svg';
import './PersonalDataBuySuscriptions.css';

interface PersonalDataProps {
  personalData: PersonalData;
  setPersonalData: (value: any) => void;
  setNext: (value: any) => void;
  clientData:Record<string, string> | null;
  miComponenteRef:RefObject<HTMLDivElement>;
}

const PersonalDataBuySubscriptions = (personalDataProps: PersonalDataProps) => {


    //const [validMatchEmail, setValidMatchEmail] = useState(false);
    const [selectedSex, setSelectedSex] = useState('');
    const [validationPassOne, setValidationPassOne] = useState(false);

    const [personalDataValidation, setPersonalDataValidation] = useState<PersonalData>({
      nombre: '',
      apellidos: '',
      email: '',
      dptelefono: '',
      dpnacimiento: '',
      dpaltura: '',
      dppeso: '',
      dpsexo: '',
      dpmetapas: '',
      dpmetapadetalle: '',
      dpmembarazada: '',
      dpmtrimestre:'',
    });

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { value,name } = event.target;
        personalDataProps.setPersonalData((prevFormData: PersonalData) => ({ ...prevFormData, [name]: value }));
        setPersonalDataValidation((prevFormData: PersonalData) => ({ ...prevFormData, [name]: value }));
    };

    const CheckIsValid = () => {

      const isValid = (          
        (personalDataProps.personalData.dptelefono !== '') &&
        (personalDataProps.personalData.dpnacimiento !== '') &&
        (personalDataProps.personalData.dpaltura !== '') &&
        (personalDataProps.personalData.dppeso !== '') &&
        (Number(personalDataProps.personalData.dppeso) > 0) &&
        (personalDataProps.personalData.dpsexo !== '') &&
        (PHONE_REGEX.test(personalDataProps.personalData.dptelefono) || PHONE_REGEX2.test(personalDataProps.personalData.dptelefono)) 


      )
      setValidationPassOne(isValid);
    }

    useEffect(() => {
      CheckIsValid();
    },[personalDataValidation]);

    useEffect(() => {
      if(personalDataProps.clientData){
        personalDataProps.personalData.dptelefono=personalDataProps.clientData["telefono"];
        personalDataProps.personalData.dpnacimiento=personalDataProps.clientData["anio"];
        personalDataProps.personalData.dpaltura=personalDataProps.clientData["altura"];
        personalDataProps.personalData.dpsexo=personalDataProps.clientData["sexo"];
      } 
      personalDataProps.miComponenteRef.current?.scrollIntoView({block: "start",behavior: 'smooth'});
    },[]);

    if(personalDataProps.clientData){
    return (
        <>
        <div className="phone-birth-fields"> 
          <div className="fam-groups">
            <div className="confirm-phone-container">
              <label htmlFor="telefonoCliente">*Teléfono</label>
              <input type="tel" 
              name="dptelefono" 
              id="telefonoCliente" 
              defaultValue={personalDataProps.personalData.dptelefono} 
              disabled
              />
            </div>
          </div>
        
          <div className="fam-groups">
            <div className="confirm-date-container">
              <label htmlFor="fechaNacimientoCliente">*Año de nacimiento</label>
              <input type="text" 
              name="dpnacimiento" 
              id="fechaNacimientoCliente" 
              value={personalDataProps.personalData.dpnacimiento}
              onChange={handleInputChange}
              disabled
              />
              <></>
            </div>
          </div>
        </div>
          
        <div className="height-weight-fields">  
          <div className="fam-groups">
            <label htmlFor="alturaCliente">*Altura</label>
            <input type="text" 
            name="dpaltura" 
            id="alturaCliente" 
            placeholder="En CM" 
            defaultValue={personalDataProps.personalData.dpaltura}
            disabled
            />
          </div>
          <div className="fam-groups">
            <label htmlFor="pesoCliente">*Peso</label>
            <input 
            type="text" 
            name="dppeso" 
            id="pesoCliente" 
            className="pesoCliente" 
            placeholder="En KG" 
            defaultValue={personalDataProps.personalData.dppeso}
            onChange={handleInputChange}
            required
            />
            {!(Number(personalDataProps.personalData.dppeso)>0) && personalDataProps.personalData.dppeso!==''?
                <p className="instructions pesoCliente">El peso no puede ser 0.</p>:<></>
            }    
          </div>
        </div>  
        <label className="sexo-label" style={{fontSize:'14px', marginBottom:'0px'}}>*Sexo</label>
          <div id="sexo-fields"> 
            <RadioGroup name="dpsexo" className="materialui-fields-check">
              <FormControlLabel className="materialui-fields-label" control={
                <StyledRadio 
                  id="sexoHombre" 
                  value="h"
                  checked={personalDataProps.personalData.dpsexo==="h"}
                  disabled
                />} label="Hombre" />
              <FormControlLabel className="materialui-fields-label" control={
                <StyledRadio 
                  id="sexoMujer" 
                  value="m"
                  checked={personalDataProps.personalData.dpsexo==="m"}
                  disabled
                />} label="Mujer" />
            </RadioGroup> 
          </div>
          { personalDataProps.personalData.dpsexo==="m" && (
            <div className="sexo-mujer">
              <p>¿Estás en alguna de estas etapas?</p>
              <div>
                <RadioGroup name="dpmetapas" className="materialui-fields-check">
                  <FormControlLabel className="materialui-fields-label" control={
                    <StyledRadio 
                      id="etapasEmbarazoLactancia" 
                      value="Lactancia"
                      checked={personalDataProps.personalData.dpmetapas==="Lactancia"}
                      onChange={handleInputChange}  
                    />} label="Lactancia" />
                  <FormControlLabel className="materialui-fields-label" control={
                    <StyledRadio 
                      id="etapasEmbarazoMenopausia" 
                      value="Menopausia"
                      checked={personalDataProps.personalData.dpmetapas==="Menopausia"}
                      onChange={handleInputChange}
                    />} label="Menopausia" />
                    <FormControlLabel className="materialui-fields-label" control={
                    <StyledRadio 
                      id="etapasEmbarazoNo" 
                      value="No"
                      checked={personalDataProps.personalData.dpmetapas==="No"}
                      onChange={handleInputChange}
                    />} label="No" />
                </RadioGroup>
                <input 
                type="text" 
                name="dpmetapadetalle" 
                placeholder="¿Quieres darnos algún detalle concreto sobre ello?"
                defaultValue={personalDataProps.personalData.dpmetapadetalle}
                onChange={handleInputChange} 
                maxLength={200}
                />
              </div>
              <p>¿Estás embarazada?</p>
              <div>
                <RadioGroup name="dpmembarazada" className="materialui-fields-check">
                <FormControlLabel className="materialui-fields-label" control={
                  <StyledRadio 
                    id="embarazoSi" 
                    value="Si"
                    checked={personalDataProps.personalData.dpmembarazada==="Si"}
                    onChange={handleInputChange}
                  />} label="Si" />
                <FormControlLabel className="materialui-fields-label" control={
                  <StyledRadio 
                    id="embarazoNo" 
                    value="No"
                    checked={personalDataProps.personalData.dpmembarazada==="No"}
                    onChange={handleInputChange}
                  />} label="No" />
                </RadioGroup>
                <input 
                type="text" 
                name="dpmtrimestre" 
                placeholder="¿En qué trimestre te encuentras?"
                defaultValue={personalDataProps.personalData.dpmtrimestre}
                onChange={handleInputChange}
                />
              </div>
            </div>                
          )}
          <hr />
          <button className="next-field next-field-personal-data" disabled={!validationPassOne} onClick={()=>personalDataProps.setNext(2)}>Siguiente {!validationPassOne?<img src={nextArrowGrey} alt={'next'} />:<img src={nextArrow} alt={'next'} />}</button>
        </>
      );
      }else{
      return (
        <>
        <div className="phone-birth-fields"> 
          <div className="fam-groups">
            <div className="confirm-phone-container">
              <label htmlFor="telefonoCliente">*Teléfono</label>
              <input type="tel" 
              name="dptelefono" 
              id="telefonoCliente" 
              placeholder="+34" 
              defaultValue={personalDataProps.personalData.dptelefono}
              onChange={handleInputChange} 
              required
              />
              {!(PHONE_REGEX.test(personalDataProps.personalData.dptelefono) || PHONE_REGEX2.test(personalDataProps.personalData.dptelefono)) && personalDataProps.personalData.dptelefono!==''?
                <p className="instructions">El teléfono no es correcto.</p>:<></>
              }
            </div>
          </div>
        
          <div className="fam-groups">
            <div className="confirm-date-container">
              <label htmlFor="fechaNacimientoCliente">*Año de nacimiento</label>
              <input type="text" 
              name="dpnacimiento" 
              id="fechaNacimientoCliente" 
              placeholder="YYYY" 
              defaultValue={personalDataProps.personalData.dpnacimiento}
              onChange={handleInputChange}
              minLength={4}
              maxLength={4}
              required
              />
              <></>
            </div>
          </div>
        </div>
          
        <div className="height-weight-fields">  
          <div className="fam-groups">
            <label htmlFor="alturaCliente">*Altura</label>
            <input type="text" 
            name="dpaltura" 
            id="alturaCliente" 
            placeholder="En CM" 
            defaultValue={personalDataProps.personalData.dpaltura}
            onChange={handleInputChange}
            required
            />
          </div>
          <div className="fam-groups">
            <label htmlFor="pesoCliente">*Peso</label>
            <input 
            type="text" 
            name="dppeso" 
            id="pesoCliente"
            className="pesoCliente" 
            placeholder="En KG" 
            defaultValue={personalDataProps.personalData.dppeso}
            onChange={handleInputChange}
            required
            />
            {!(Number(personalDataProps.personalData.dppeso)>0) && personalDataProps.personalData.dppeso!==''?
                <p className="instructions pesoCliente">El peso no puede ser 0.</p>:<></>
            }        
          </div>
        </div>  
        <label className="sexo-label" style={{fontSize:'14px', marginBottom:'0px'}}>*Sexo</label>
          <div id="sexo-fields"> 
            <RadioGroup name="dpsexo" className="materialui-fields-check">
              <FormControlLabel className="materialui-fields-label" control={
                <StyledRadio 
                  id="sexoHombre" 
                  value="h"
                  checked={personalDataProps.personalData.dpsexo==="h"}
                  onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {handleInputChange(event); setSelectedSex("h");}} 
                  required
                />} label="Hombre" />
              <FormControlLabel className="materialui-fields-label" control={
                <StyledRadio 
                  id="sexoMujer" 
                  value="m"
                  checked={personalDataProps.personalData.dpsexo==="m"}
                  onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {handleInputChange(event); setSelectedSex("m");}} 
                  required 
                />} label="Mujer" />
            </RadioGroup> 
          </div>
          { (selectedSex==="m" || personalDataProps.personalData.dpsexo==="m") && (
            <div className="sexo-mujer">
              <p>¿Estás en alguna de estas etapas?</p>
              <div>
                <RadioGroup name="dpmetapas" className="materialui-fields-check">
                  <FormControlLabel className="materialui-fields-label" control={
                    <StyledRadio 
                      id="etapasEmbarazoLactancia" 
                      value="Lactancia"
                      checked={personalDataProps.personalData.dpmetapas==="Lactancia"}
                      onChange={handleInputChange}  
                    />} label="Lactancia" />
                  <FormControlLabel className="materialui-fields-label" control={
                    <StyledRadio 
                      id="etapasEmbarazoMenopausia" 
                      value="Menopausia"
                      checked={personalDataProps.personalData.dpmetapas==="Menopausia"}
                      onChange={handleInputChange}
                    />} label="Menopausia" />
                    <FormControlLabel className="materialui-fields-label" control={
                    <StyledRadio 
                      id="etapasEmbarazoNo" 
                      value="No"
                      checked={personalDataProps.personalData.dpmetapas==="No"}
                      onChange={handleInputChange}
                    />} label="No" />
                </RadioGroup>
                <input 
                type="text" 
                name="dpmetapadetalle" 
                placeholder="¿Quieres darnos algún detalle concreto sobre ello?"
                defaultValue={personalDataProps.personalData.dpmetapadetalle}
                onChange={handleInputChange} 
                maxLength={200}
                />
              </div>
              <p>¿Estás embarazada?</p>
              <div>
                <RadioGroup name="dpmembarazada" className="materialui-fields-check">
                <FormControlLabel className="materialui-fields-label" control={
                  <StyledRadio 
                    id="embarazoSi" 
                    value="Si"
                    checked={personalDataProps.personalData.dpmembarazada==="Si"}
                    onChange={handleInputChange}
                  />} label="Si" />
                <FormControlLabel className="materialui-fields-label" control={
                  <StyledRadio 
                    id="embarazoNo" 
                    value="No"
                    checked={personalDataProps.personalData.dpmembarazada==="No"}
                    onChange={handleInputChange}
                  />} label="No" />
                </RadioGroup>
                <input 
                type="text" 
                name="dpmtrimestre" 
                placeholder="¿En qué trimestre te encuentras?"
                defaultValue={personalDataProps.personalData.dpmtrimestre}
                onChange={handleInputChange}
                />
              </div>
            </div>                
          )}
          <hr />
          <button className="next-field next-field-personal-data" disabled={!validationPassOne} onClick={()=>{
            personalDataProps.setNext(2);
            personalDataProps.miComponenteRef.current?.scrollIntoView({block: "start",behavior: 'smooth'});
          }}>Siguiente {!validationPassOne?<img src={nextArrowGrey} alt={'next'} />:<img src={nextArrow} alt={'next'} />}</button>
        </>
      );
      }
}

export default PersonalDataBuySubscriptions;