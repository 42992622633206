import axios from "../api/axios";
import axiosCodeigniter from "../api/axiosCodeigniter";
import {AxiosResponse} from "axios";
import {SUBSCRIPTION_RENEW_PLAN_URL, SUBSCRIPTION_CHECK_TYPE_PLAN_URL} from "../utils/constants";

import { ProgressData } from '../models/formsModels/RenewProgressData';
import { FeedingData } from "../models/formsModels/RenewFeedingData";
import { InterestData } from "../models/formsModels/RenewInterestData";

interface RenewPlanFormData {
    progressDataToSend:ProgressData;
    feedingDataToSend:FeedingData;
    interestDataToSend:InterestData;
}

export const PlanRenewSubscriptionService = async (renewPlanFormData:RenewPlanFormData,Photo1:File|null,Photo2:File|null,Photo3:File|null):Promise<AxiosResponse> => {
    const token = localStorage.getItem('token');
    const formData= new FormData();
    formData.append("dataSend",JSON.stringify(renewPlanFormData));
    if(Photo1 && Photo2 && Photo3){
        formData.append("photo1", Photo1);
        formData.append("photo2", Photo2);
        formData.append("photo3", Photo3);
    }
    return await axiosCodeigniter.post(SUBSCRIPTION_RENEW_PLAN_URL,
        formData,
        {
            headers: { 'Content-Type':'multipart/form-data', Authorization: `Bearer ${token}` }
        }
    );


}

export async function checkTypePlanService(): Promise<AxiosResponse> {
    const token = localStorage.getItem('token');
    const headers =  {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }
    const response = await axiosCodeigniter.get(`${SUBSCRIPTION_CHECK_TYPE_PLAN_URL}`, headers);
    return response;
  }