import React, { useState } from "react";
import "./hours.css";
import IconDown from "../../../../../assets/img/iconoir_fast-arrow-down.png";
import { IAppointmentSlot } from "../../../../../services/getDietCalendarService";

interface HoursProps {
  title: string;
  subtitle: string;
  options: IAppointmentSlot[];
  setSelectedSlot: (appointment: IAppointmentSlot) => void;
}

/**
 * Este componente recibe un conjunto de slots (correspondientes a un mismo día) y permite elegir entre una de ellas. Cuando 
 * se presiona CONTINUAR, pasa la selección al componente padre.
 */

const Hours: React.FC<HoursProps> = ({
  title,
  subtitle,
  options,
  setSelectedSlot,
}) => {
  const [currentAppointmentSlot, setCurrentAppointmentSlot] = useState<IAppointmentSlot|null>(null);

  return (
    <div className="container_text_hours">
      <h1 className="title_hours">{title}</h1>
      <h2 className="subtile_hours">{subtitle}</h2>
      <div style={{ height: "30vh", overflowY: "auto" }}>
        {options.map((option, index) => (
          <div
            className="hour-item-div"
            style={{
              background: ( currentAppointmentSlot && option.start === currentAppointmentSlot.start) ? "#F6FFEA" : "inherit",
            }}
            onClick={() => setCurrentAppointmentSlot(option)}
            key={index}
          >
            <p>{option.start.format("HH:mm")}</p>
          </div>
        ))}
      </div>
      {options.length > 4 && (
        <img src={IconDown} alt="icon-down" className="icon-down" />
      )}
      <div
        className="button_hours modal-success-button"
        onClick={() => {
          currentAppointmentSlot && setSelectedSlot(currentAppointmentSlot);
        }}
      >
        <button disabled={!currentAppointmentSlot}>CONTINUAR</button>
      </div>
    </div>
  );
};

export default Hours;
