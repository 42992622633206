import {trackView} from "../../../utils/FacebookPixelEvents";
import './plans-subscriptions.css';
import dietImg from '../../../assets/img/diet-img-plans-subscription.png';
import psyImg from '../../../assets/img/psicologia.png';
import activeCircle from '../../../assets/img/active-circle-better.svg';
import React, { useEffect, useState } from "react";
import { getSubscriptionService, WoocomerceSubscription } from "../../../services/getSubscriptionService";
import { getOrdersService, WoocomerceOrder } from "../../../services/getOrdersService";
import { Typography } from "@mui/material";
import PrivateLoading from "../../shared/loading/PrivateLoading";
import CancelModal from "../../../assets/img/iconoir_cancel.svg";

type Categories = 'diet_basic'
    | 'diet_training'
    | 'diet_couple'
    | 'diet_training_couple'
    | 'diet_training_gym'
    | 'diet_training_couple_gym'
    | 'diet_training_virtual'
    | 'diet_training_home'
    | 'diet_training_couple_home'
    | 'psychology_basic'
    | 'psychology_couple'
    | 'psychology_basic_subscription'
    | 'psychology';
type WoocomerceStatus = 'processing'
    | 'failed'
    | 'canceled'
    | 'pending'
    | 'completed'
    | 'on-hold'
    | 'refunded'
    | 'active'
    | 'pending-cancellation' | 'expired' | 'switched';

type WoocomerceSubscriptionStatus = 'pending'
    |'active'
    |'on-hold'
    |'pending-cancellation'
    |'cancelled'
    |'expired';

const PlansSubscriptions = () => {
    const [selectedOption, setSelectedOption] = useState('diet');
    const [activeSubscriptions, setActiveSubscriptions] = useState<Array<WoocomerceSubscription>>([]);
    const [listDietRealData, setListDietRealData] = useState<Array<WoocomerceOrder>>([]);
    const [listPsychologyRealData, setListPsychologyRealData] = useState<Array<WoocomerceOrder>>([]);
    const [idSubscriptionCancelled,setIdSubscriptionCancelled] = useState<Number>();
    const [loaded, setLoaded] = useState(false);
    const [showModalRedirect, setShowModalRedirect] = useState(false);
    const [error, setError] = useState(false);

    const categpriesDictionary: Record<Categories, string> = {
        diet_basic: 'Plan de dieta',
        diet_training: 'Plan de dieta + entrenamiento',
        diet_couple: 'Plan de dieta para pareja',
        diet_training_couple: 'Plan de dieta + entrenamiento para pareja',
        diet_training_gym: 'Plan de dieta + entrenamiento en Gym',
        diet_training_couple_gym: 'Plan de dieta para pareja + entrenamiento en Gym',
        diet_training_virtual: 'Plan de dieta + entrenamiento en Entrena virtual',
        diet_training_home: 'Plan de dieta + entrenamiento en Casa',
        diet_training_couple_home: 'Plan de dieta para pareja + entrenamiento en Casa',
        psychology_couple: 'Psicología para pareja',
        psychology_basic: 'Psicología',
        psychology_basic_subscription: 'Psicología por suscripción',
        psychology: 'Psicología',
    };

    const woocomerceStatusDictionary: Record<WoocomerceStatus, string> = {
        processing: 'Procesando',
        failed: 'Fallido',
        canceled: 'Cancelado',
        pending: 'Pendiente',
        completed: 'Completado',
        'on-hold': 'En espera',
        refunded: 'Reembolsado',
        active: 'Activo',
        'pending-cancellation': 'Cancelación pendiente',
        expired: 'Expirado',
        switched: 'Cambiado',
    };

    const woocomerceSubscriptionStatusDictionary: Record<WoocomerceSubscriptionStatus, string> = {
        pending: 'Pendiente de pago',
        active: 'Activa',
        'on-hold': 'En espera',
        'pending-cancellation': 'Pendiente de cancelación ',
        cancelled: 'Cancelada',
        expired: 'Expiradoa',
    };

    useEffect(() => {
        trackView();
        const fetchSubscription = async () => {
            try {
                const responseData = await getSubscriptionService();
                console.log(responseData);
                if (responseData) {
                    if (responseData.length > 0) {
                        setActiveSubscriptions(responseData);
                    } else {
                        setSelectedOption('psychology');
                    }
                    setLoaded(true);
                } else {
                    setLoaded(true);
                    setError(true);
                }
            } catch (e) {
                setLoaded(true);
                setError(true);
            }
        }
        fetchSubscription();


    }, [])

    useEffect(() => {
        const fetchOrders = async () => {
            try {
                const responseData = await getOrdersService();
                if (responseData) {
                    const dietResponse = responseData.filter((order) => ['diet_basic', 'diet_training', 'diet_couple', 'diet_training_couple', 'diet_training_gym', 'diet_training_couple_gym', 'diet_training_virtual', 'diet_training_home', 'diet_training_couple_home'].includes(order.categories[1]));
                    const psychologyResponse = responseData.filter((order) => ['psychology', 'psychology_couple', 'psychology_basic','psychology_basic_subscription'].includes(order.categories[1]))
                    setListDietRealData(dietResponse);
                    setListPsychologyRealData(psychologyResponse);
                    setLoaded(true);
                } else {
                    setLoaded(true);
                    setError(true);
                }
            } catch (e) {
                setLoaded(true);
                setError(true);
            }
        }
        fetchOrders();
    }, [])

    const handleCancelSubscription = (subscription_id:Number) => {
        setIdSubscriptionCancelled(subscription_id)
        setShowModalRedirect(true);
    }

    return (
        <main className="main-content-plan-subscriptions">
            <div className='plans-subscriptions-container' style={{ width: '90%', marginLeft: 'auto', marginRight: 'auto'}}>
                {showModalRedirect && (
                    <div className={'modal-success-container'}>
                        <div className={'modal-success'}>
                            <div><img src={CancelModal} style={{ position: 'absolute', right: '1.5rem', top: '1.5rem', cursor: 'pointer' }} onClick={() => setShowModalRedirect(false)} /> </div>
                            <h1 className={'custom-modal-title-unified'}>¿QUIERES CANCELAR TU SUSCRIPCIÓN?</h1>
                            <Typography className={'modal-success-text'}>
                                Para cancelar tu suscripción debes confirmar tus credenciales. Una vez que canceles, tu plan caducará al final de tu período de suscripción.
                            </Typography>
                            <button className={'modal-success-button'} onClick={() => {
                                setShowModalRedirect(false);

                                window.open(`${process.env.REACT_APP_WP_CUSTOMER_AREA}/mi-cuenta/view-subscription/${idSubscriptionCancelled}`)

                            }}>
                                COMPLETAR CANCELACIÓN
                            </button>
                        </div>
                    </div>
                )}
                {error && (
                    <div className={'modal-success-container'}>
                        <div className={'modal-success'}>
                            <div><img src={CancelModal} style={{ position: 'absolute', right: '1.5rem', top: '1.5rem', cursor: 'pointer' }} onClick={() => setError(false)} /> </div>
                            <h1>¡Error!</h1>
                            <Typography className={'modal-success-text'}>
                                Lo sentimos, ha habido un error al traer tus datos, inténtalo de nuevo más tarde o ponte en contacto con nosotros.
                            </Typography>
                            <button className={'modal-success-button'} onClick={() => setError(false)}>
                                Cerrar
                            </button>
                        </div>
                    </div>
                )}
                <div className='plans-subscriptions-text'>
                    <h1>Compras y suscripciones</h1>
                </div>
                {!loaded && !error && (<PrivateLoading />)}
                {loaded && (
                    <div className={'active-subscriptions'}>
                        {activeSubscriptions.length > 0 && (
                            <>
                            <h2>Suscripciones</h2>
                            {activeSubscriptions.map((activeSubscription, index) => (
                                <>
                                <div className={'subscription-card-desktop plans-subscription-card-only-desktop'}>
                                    <div className={'subcsription-img'}>
                                        {activeSubscription.categories[0].includes("diet") ? <img src={dietImg} /> : <img src={psyImg} />}
                                        <img src={activeCircle} style={{ width: '20px', height: "auto", marginBottom: '70px', marginLeft: '-10px' }} />
                                    </div>
                                    <div className={'subscription-content'}>
                                        {activeSubscription.categories[0].includes("diet") ?
                                        <h2 style={{ textTransform: "uppercase" }}>Tu Plan de dieta</h2>
                                        :
                                        <h2 style={{ textTransform: "uppercase" }}>Tu Plan de psicología</h2>
                                        }
                                        <p className={'subscription-modality'}>{categpriesDictionary[activeSubscription.categories[1] as Categories] || 'Dieta + Entrenamiento'}</p>
                                        <p className={'subscription-renovation'}>Próxima Renovación: {activeSubscription.next_payment_on || '03/03/2023'}</p>
                                        <p className={'subscription-state'}>Suscripción: {woocomerceSubscriptionStatusDictionary[activeSubscription.status as WoocomerceSubscriptionStatus]}</p>
                                    </div>
                                    {activeSubscription.status==="active" &&
                                    <div className={'subscription-action'}>
                                        <button style={{ textTransform: 'uppercase' }} onClick={()=>handleCancelSubscription(activeSubscription.subscription_id)}>Cancelar Suscripción</button>
                                    </div>
                                    }

                                </div>
                                <div className={'subscription-card-mobile plans-subscription-card-only-mobile'}>
                                    <div className={'subscription-content-mobile'}>
                                        <div style={{ width: '100%' }}>
                                        {activeSubscription.categories[0].includes("diet") ?
                                            <h2 style={{ textTransform: "uppercase" }} className={'tu-plan-de-dieta'}>
                                                Tu plan de Dieta <img src={activeCircle} style={{ height: '15px', width: '15px', marginBottom: '-0.15rem' }} />
                                            </h2> :
                                            <h2 style={{ textTransform: "uppercase" }} className={'tu-plan-de-dieta'}>
                                            Tu plan de Psicología <img src={activeCircle} style={{ height: '15px', width: '15px', marginBottom: '-0.15rem' }} />
                                        </h2> 
                                            }
                                        </div>
                                        <div style={{ width: '100%' }}>
                                            <p className={'subscription-modality'}>{categpriesDictionary[activeSubscription.categories[1] as Categories] || 'Dieta + Entrenamiento'}</p>
                                            <p className={'subscription-renovation'}>Próxima Renovación: {activeSubscription.next_payment_on || '03/03/2023'}</p>
                                            <p className={'subscription-state'}>Suscripción: {woocomerceSubscriptionStatusDictionary[activeSubscription.status as WoocomerceSubscriptionStatus]}</p>
                                        </div>
                                    </div>
                                    {activeSubscription.status==="active" &&
                                    <div className={'subscription-action-mobile'}>
                                        <button style={{ textTransform: 'uppercase' }} onClick={()=>handleCancelSubscription(activeSubscription.subscription_id)}>Cancelar Suscripción</button>
                                    </div>
                                    }
                                </div>
                                <div className="change-payment-method">
                                    <a href="#" onClick={() => {
                                    window.open(`${process.env.REACT_APP_WP_CUSTOMER_AREA}/mi-cuenta/view-subscription/${activeSubscription.subscription_id}/`)
                                    }}>Cambiar método de pago</a>
                                </div>
                                </>
                            ))}
                            </>
                        )}
                        {activeSubscriptions.length <= 0 && (
                            <>
                                <h2>Suscripciones activas</h2>
                                <div className="subscriptions-card-not-active">
                                    <div className="subscription-card-mobile sub-not-active-box">
                                    <h2 className="tu-plan-de-dieta">No tienes suscripciones activas.</h2>
                                    </div>
                                </div>
                            </>
                        )}

                    </div>
                )}
                {(listDietRealData.length > 0 || listPsychologyRealData.length > 0) && (
                    <>
                        <div className={'order-history'}>
                            <h2>Historial de Pedidos</h2>
                            <div className={'order-history-options'}>
                                <p className={selectedOption === 'diet' ? 'active-option' : ''} onClick={() => setSelectedOption('diet')}>Dieta</p>
                                <p className={selectedOption === 'psychology' ? 'active-option' : ''} onClick={() => setSelectedOption('psychology')}>Psicología</p>
                            </div>
                            {selectedOption === 'diet' && (
                                <>
                                    <table className={'table-diet plans-subscription-table-only-desktop'}>
                                     <tbody>
                                        <tr>
                                            <th>Plan</th>
                                            <th>Última renovación</th>
                                            <th>Precio</th>
                                            <th>Estado</th>
                                        </tr>
                                        {listDietRealData.map((item, index) => (
                                            <tr key={index}>
                                                <td>{categpriesDictionary[item.categories[1] as Categories]}</td>
                                                <td>{item.paid_on}</td>
                                                <td>{item.total} €</td>
                                                <td>
                                                    {item.status === 'active' && (
                                                        <img src={activeCircle} />
                                                    )}
                                                    <span>{woocomerceStatusDictionary[item.status as WoocomerceStatus] || 'processing'}</span>
                                                </td>
                                            </tr>
                                        )
                                        )}
                                     </tbody>
                                    </table>
                                    <div className={'table-diet-mobile plans-subscription-table-only-mobile'}>
                                        {listDietRealData.map((item, index) => (
                                            <div className={'table-card-mobile'} key={index}>
                                                <div className={'subscription-data'}>
                                                    <p className={'subscription-data-header'}>Plan:</p>
                                                    <p className={'subscription-data-content'}>{categpriesDictionary[item.categories[1] as Categories]}</p>
                                                </div>
                                                <div className={'subscription-data'}>
                                                    <p className={'subscription-data-header'}>Última renovación:</p>
                                                    <p className={'subscription-data-content'}>{item.paid_on}</p>
                                                </div>
                                                <div className={'subscription-data'}>
                                                    <p className={'subscription-data-header'}>Precio:</p>
                                                    <p className={'subscription-data-content'}>{item.total} €</p>
                                                </div>
                                                <div className={'subscription-data'}>
                                                    <p className={'subscription-data-header'}>Estado:</p>
                                                    <p className={'subscription-data-content'}>
                                                        {item.status === 'active' && (
                                                            <img src={activeCircle} />
                                                        )}
                                                        <span>{woocomerceStatusDictionary[item.status as WoocomerceStatus] || 'processing'}</span>
                                                    </p>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </>
                            )}
                            {selectedOption === 'psychology' && (
                                <>
                                    <table className={'table-psychology plans-subscription-table-only-desktop'}>
                                     <tbody>
                                        <tr>
                                            <th>Plan</th>
                                            <th>Fecha de compra</th>
                                            <th>N de sesiones</th>
                                            <th>Precio</th>
                                            <th>Estado</th>
                                        </tr>
                                        {listPsychologyRealData.map((item, index) => (
                                            <tr key={index}>
                                                <td>{categpriesDictionary[item.categories[1] as Categories]}</td>
                                                <td>{item.paid_on}</td>
                                                <td>{item.number_of_sessions}</td>
                                                <td>{item.total} €</td>
                                                <td>
                                                    {item.status === 'active' && (
                                                        <img src={activeCircle} />
                                                    )}
                                                    <span>{woocomerceStatusDictionary[item.status as WoocomerceStatus] || 'processing'}</span>
                                                </td>
                                            </tr>
                                        )
                                        )}
                                     </tbody>
                                    </table>
                                    <div className={'table-psychology-mobile plans-subscription-table-only-mobile'}>
                                        {listPsychologyRealData.map((item, index) => (
                                            <div className={'table-card-mobile'}>
                                                <div className={'subscription-data'}>
                                                    <p className={'subscription-data-header'}>Plan:</p>
                                                    <p className={'subscription-data-content'}>{categpriesDictionary[item.categories[1] as Categories]}</p>
                                                </div>
                                                <div className={'subscription-data'}>
                                                    <p className={'subscription-data-header'}>Fecha de compra:</p>
                                                    <p className={'subscription-data-content'}>{item.paid_on}</p>
                                                </div>
                                                <div className={'subscription-data'}>
                                                    <p className={'subscription-data-header'}>N de sesiones:</p>
                                                    <p className={'subscription-data-content'}>{item.number_of_sessions}</p>
                                                </div>
                                                <div className={'subscription-data'}>
                                                    <p className={'subscription-data-header'}>Precio:</p>
                                                    <p className={'subscription-data-content'}>{item.total} €</p>
                                                </div>
                                                <div className={'subscription-data'}>
                                                    <p className={'subscription-data-header'}>Estado:</p>
                                                    <p className={'subscription-data-content'}>
                                                        {item.status === 'active' && (
                                                            <img src={activeCircle} />
                                                        )}
                                                        <span>{woocomerceStatusDictionary[item.status as WoocomerceStatus] || 'processing'}</span>
                                                    </p>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </>
                            )}
                        </div>
                    </>
                )}
            </div>
        </main >
    )
}

export default PlansSubscriptions;
