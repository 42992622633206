import axios from "../api/axios";
import axiosCodeigniter from "../api/axiosCodeigniter";
import {AxiosResponse} from "axios";
import {SUBSCRIPTION_NEW_PLAN_URL,SUBSCRIPTION_CHECK_OLD_CLIENT_URL,SUBSCRIPTION_NEW_PLAN_NOT_CLIENT_URL,SUBSCRIPTION_CHECK_ACCESS_NEW_PLAN_URL} from "../utils/constants";

import { PersonalData } from '../models/formsModels/PersonalData';
import { SportActivityData } from "../models/formsModels/SportActivityData";
import { FeedingData } from "../models/formsModels/FeedingData";

interface NewPlanFormData {
    personalDataToSend:PersonalData;
    sportActivityDataToSend:SportActivityData;
    feedingDataToSend:FeedingData;
}

interface NewPlanNotClientFormData {
    selectedTypeFormToSend:string;
    personalDataToSend:PersonalData;
    sportActivityDataToSend:SportActivityData;
    feedingDataToSend:FeedingData;
}

export const PlanNewSubscriptionService = async (newPlanFormData:NewPlanFormData,Photo1:File|null,Photo2:File|null,Photo3:File|null):Promise<AxiosResponse> => {
    const token = localStorage.getItem('token');
    const formData= new FormData();
    formData.append("dataSend",JSON.stringify(newPlanFormData));
    if(Photo1 && Photo2 && Photo3){
        formData.append("photo1", Photo1);
        formData.append("photo2", Photo2);
        formData.append("photo3", Photo3);
    }

    return await axiosCodeigniter.post(SUBSCRIPTION_NEW_PLAN_URL,
        formData,
        {
             headers: { 'Content-Type':'multipart/form-data', Authorization: `Bearer ${token}` }
        }
    );
}

export const NoClientPlanNewSubscriptionService = async (newPlanNotClientFormData:NewPlanNotClientFormData,Photo1:File|null,Photo2:File|null,Photo3:File|null):Promise<AxiosResponse> => {

    const formData= new FormData();
    formData.append("dataSend",JSON.stringify(newPlanNotClientFormData));
    if(Photo1 && Photo2 && Photo3){
        formData.append("photo1", Photo1);
        formData.append("photo2", Photo2);
        formData.append("photo3", Photo3);
    }

    return await axiosCodeigniter.post(SUBSCRIPTION_NEW_PLAN_NOT_CLIENT_URL,
        formData,
        {
            headers: { 'Content-Type':'multipart/form-data' }
        }
    );
}

export const checkOldClientService = async ():Promise<AxiosResponse> => {
    const token = localStorage.getItem('token');
    const headers =  {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }
    const response = await axiosCodeigniter.get(`${SUBSCRIPTION_CHECK_OLD_CLIENT_URL}`, headers);
    return response;
}

export const accessControlService = async (plan:string):Promise<AxiosResponse> => {
    try{
        const token = localStorage.getItem('token');
        const formData= new FormData();
        formData.append("dataSend",JSON.stringify(plan));
        return await axiosCodeigniter.post(SUBSCRIPTION_CHECK_ACCESS_NEW_PLAN_URL,
            formData,
            {
                 headers: { 'Content-Type':'multipart/form-data', Authorization: `Bearer ${token}` }
            }
        );
    }catch (err) {
        throw new Error ();
    }


}



