import {
    CATEGORY_DIETA,
    CATEGORY_PSICOLOGIA,
} from "../../../../utils/constants";
import TestimonialButton from "./testimonialButton/TestimonialButton";
import {ButtonData} from "../../../../models/ButtonData";
import {TestimonialButtonsProps} from "../../../../models/componentProps/TestimonialButtonsProps";

const FaqButtons = (testimonialButtonsProps: TestimonialButtonsProps) => {

    const buttonsData: Array<ButtonData> = [
        {
            title: 'Plan De Dieta',
            asociatedCategory: CATEGORY_DIETA
        },
        {
            title: 'Plan De Psicología',
            asociatedCategory: CATEGORY_PSICOLOGIA
        }
    ]

    const buttonsContent = (
        buttonsData.map((button, index) => (
             <TestimonialButton
                key={index}
                selectedCategory={testimonialButtonsProps.selectedCategory}
                handleButtonClick={testimonialButtonsProps.handleButtonClick}
                title={button.title}
                categoryToCompare={button.asociatedCategory}/>
        ))
    )

    return (
        <div className="plans-type-select">
            <>
                {buttonsContent}
            </>
        </div>
    )
}

export default FaqButtons
