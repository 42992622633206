import { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { trackView } from "../../../utils/FacebookPixelEvents";
import "./Conditions.css";

function Conditions() {
  useEffect(() => {
    trackView();
  }, []);

  return (
    <>
      <Helmet>
        <link
          rel="canonical"
          href={`${process.env.REACT_APP_FRONT_URL}` + "/condiciones-generales"}
        />
      </Helmet>
      <div className="conditions-container">
        <h2>Condiciones generales</h2>
        <p>
          COSTA LIFE FIT, SL, Con N.I.F. número B87767257, inscrita en el
          Registro Mercantil de Madrid a 7 de Marzo de 2017, en el tomo 35694,
          folio 185, inscripción 1 con hoja M-641486, email de contacto:
          direccion@vikika.es de ahora en adelante EMPRESA.
        </p>
        <p>El visitante del portal, de ahora en adelante USUARIO.</p>
        <p>
          La EMPRESA es la licenciataria de este portal en Internet, que tiene
          por objeto proporcionar a los visitantes la información necesaria
          sobre sus productos y servicios. Las presentes Condiciones Generales
          tienen como objeto regular la puesta a disposición de dicha
          información, así como las transacciones comerciales que surjan. Tanto
          la navegación por la tienda online como la adquisición de cualquier
          producto o servicio ofertados en ella suponen la aceptación como
          USUARIO, sin reservas de ninguna clase, de todas y cada una de las
          presentes Condiciones Generales de contratación y utilización. La
          EMPRESA podrá, en todo momento y sin previo aviso, modificar las
          presentes Condiciones Generales.
        </p>
        <ul>
          <li>
            Productos y/o servicios, Contenido y Especificaciones: Todas las
            características, contenido, especificaciones, productos, servicios y
            precios descritos están sujetos a cambios sin previo aviso. La
            inclusión de cualquier producto o servicio en este portal, no
            implica ni garantiza que estén disponibles en cualquier momento.
          </li>
          <li>
            Uso de esta página web: Todos los contenidos del sitio web, tales
            como textos, gráficos, fotografías, logotipos, iconos, imágenes, así
            como el diseño gráfico, código fuente y software, son de la
            exclusiva propiedad, estando por lo tanto protegidos por la
            legislación nacional e internacional. Queda estrictamente prohibido
            la utilización de todos los elementos objeto de propiedad industrial
            e intelectual con fines comerciales así como su distribución,
            modificación, alteración o descompilación.
          </li>
          <li>
            Material inadecuado: Con carácter general el USUARIO se obliga al
            cumplimiento de las presentes Condiciones Generales, así como a
            cumplir las especiales advertencias o instrucciones de uso
            contenidas en las mismas o en la web y a obrar siempre conforme a la
            Ley, a las buenas costumbres y a las exigencias de la buena fe,
            empleando la diligencia adecuada a la naturaleza del servicio del
            que disfruta, absteniéndose de utilizar el portal de cualquier forma
            que pueda impedir, dañar o deteriorar el normal funcionamiento del
            mismo.
          </li>
          <li>
            Descargos de Responsabilidad: La EMPRESA no se hace responsable de
            la veracidad, exactitud y calidad de la página web, sus servicios,
            información y materiales. Dichos servicios, información y materiales
            son presentados “tal cual” y son accesibles sin garantías de ninguna
            clase. La EMPRESA se reserva el derecho a interrumpir el acceso a la
            Web, así como la prestación de cualquiera o de todos los servicios
            que se prestan a través del mismo en cualquier momento y sin previo
            aviso, ya sea por motivos técnicos, de seguridad, de control, de
            mantenimiento, por fallos de suministro eléctrico o por cualquier
            otra causa justificada. En consecuencia, no se garantiza la
            fiabilidad, la disponibilidad ni la continuidad de la web ni de los
            servicios, por lo que la utilización de los mismos por parte del
            USUARIO se lleva a cabo por su propia cuenta y riesgo, sin que, en
            ningún momento, puedan exigirse responsabilidades en este sentido.
            La EMPRESA no asume cualquier responsabilidad ni será responsable
            por cualquier daño o cualquier virus que pueda infectar el sistema,
            equipo de telecomunicación y otra propiedad del USUARIO que pueda
            ser causado o resultante del acceso, uso o exploración de esta Web o
            por la descarga de cualquier información o material de la misma. En
            ningún caso, ninguna parte implicada en la creación, producción o
            transmisión de esta Web serán responsables ante el USUARIO o
            cualquier otra persona, por daños ocasionados, salvo casos de
            imprudencia grave o malintencionada originado por el uso,
            incapacidad para el uso o cualquier resultado del uso de esta Web,
            cualquier Web en link con ésta, o de los materiales, información o
            servicios contenidos en cualquier, una o todas las Web, tanto si los
            daños están basados en garantías, contactos, agravios o cualquier
            otra teoría legal, tanto si el USUARIO está avisado o no sobre la
            posibilidad de tales daños. Las limitaciones sobre responsabilidad
            mencionadas anteriormente son aplicadas de acuerdo con la ley.
          </li>
          <li>
            Productos o servicios con precios nulos: Debido a errores de stock o
            costes nulos en la plataforma web, sin repercusión económica para el
            USUARIO, a tenor del artículo 1274 del Código Civil, una compraventa
            con coste “cero” supone la ausencia de causa en dicho contrato para
            una de las partes, y de acuerdo con el artículo 1275 del Código
            Civil los contratos sin causa, como en este caso, no producen efecto
            jurídico alguno.
          </li>
          <li>
            Derecho de desistimiento: De conformidad con lo dispuesto en el RDL
            1/2007, de 16 de noviembre, por el que se aprueba el texto refundido
            de la Ley General para la defensa de los Consumidores y Usuarios y
            otras Leyes Complementarias, el USUARIO puede ejercer el derecho de
            desistimiento del servicio adquirido. Para más información consulta
            nuestra política de devoluciones.
          </li>
          <li>
            Comunicaciones: A los efectos de las presentes Condiciones
            Generales, y para cualquier comunicación que sea precisa, el USUARIO
            deberá dirigirse al Servicio de Atención al Cliente mediante correo
            electrónico direccion@vikika.es . Las comunicaciones se realizarán
            conforme a los datos aportados por éste al registrarse en la Web. El
            USUARIO acepta expresamente y para todas las comunicaciones
            relacionadas con la utilización la Web, y/o la contratación de los
            servicios en él ofertados, la utilización del correo electrónico
            como procedimiento válido para la remisión de dichas comunicaciones.
          </li>
          <li>
            Hoja de quejas y reclamaciones: El USUARIO podrá solicitar una hoja
            oficial de quejas y reclamaciones.
          </li>
          <li>
            Revisiones a estas Condiciones Generales: Es posible que estas
            Condiciones Generales se modifiquen o incluyan cambios. Por esto, el
            USUARIO queda obligado a acceder a las presente Condiciones
            Generales cada vez que acceda a la Web, asumiendo que les serán de
            aplicación las condiciones correspondientes que se encuentren
            vigentes en el momento de su acceso.
          </li>
          <li>
            Jurisdicción y Tribunales: Estas Condiciones Generales reemplazan
            cualquier acuerdo entre el USUARIO y la EMPRESA, en tanto sea
            necesario resolver cualquier contradicción o ambigüedad entre ambos.
            Las presentes condiciones generales se regirán por lo aquí
            establecido y en su defecto por lo establecido en las Leyes
            españolas. Para cualquier controversia que pueda surgir en la
            interpretación o ejecución de las presentes Condiciones Generales,
            el USUARIO y la EMPRESA acuerdan someterse expresamente a los
            Juzgados y Tribunales que pudieran corresponder de conformidad con
            la legislación aplicable
          </li>
          <li>
            Terminación: Tanto el USUARIO como la EMPRESA puede suspender o
            terminar una cuenta de usuario en esta Web en cualquier momento por
            cualquier razón El USUARIO es personalmente responsable por
            cualquier pedido realizado o por los cargos incurridos antes de
            terminar su cuenta en esta Web. Nos reservamos el derecho a cambiar,
            suspender o paralizar todos y cualquier aspecto de esta Web, en
            cualquier momento, sin previo aviso.
          </li>
          <li>
            Terminación: Tanto el USUARIO como la EMPRESA puede suspender o
            terminar una cuenta de usuario en esta Web en cualquier momento por
            cualquier razón El USUARIO es personalmente responsable por
            cualquier pedido realizado o por los cargos incurridos antes de
            terminar su cuenta en esta Web. Nos reservamos el derecho a cambiar,
            suspender o paralizar todos y cualquier aspecto de esta Web, en
            cualquier momento, sin previo aviso.
          </li>
        </ul>
        <p className={"strong"}>
          <strong>FORMA DE PAGO MANUAL</strong>
        </p>
        <p>En nuestra tienda online existen diversas modalidades de pago: </p>
        <ul>
          <li>
            Tarjeta de crédito: El portal cuenta con la pasarela de pago más
            segura y rápida del mercado (TPV virtual). Con ella se garantiza un
            pago totalmente confidencial y seguro con su banco, ya que los datos
            de la tarjeta viajarán encriptados a través de Internet.{" "}
          </li>
          <li>Transferencia bancaria</li>
          <li>Stripe</li>
        </ul>
        <p>
          Compras seguras mediante Tarjeta de crédito. Una Compra segura en
          Internet consiste básicamente en la Autentificación del titular de la
          tarjeta. Desde CECA (Confederación Española de Cajas de Ahorro) o
          Redsys se impulsa un estándar internacional de Comercio Electrónico
          Seguro desarrollado por Visa y MasterCard, basado en la
          autentificación de la identidad del comercio y titular de la tarjeta.
          Dentro de este marco, el sistema bancario permite autentificarse como
          titular legítimo de la tarjeta en todas las compras.
        </p>
        <p className={"strong"}>
          <strong>ENVÍO Y ENTREGA</strong>
        </p>
        <p>
          El envío de los productos se realizará tras haber verificado el abono
          de la factura generada.{" "}
        </p>
        <p>
          La EMPRESA entregará vía online los productos en un plazo máximo de 5
          días. Si el CLIENTE no la recibe en dicho periodo, deberá comunicarlo
          a la EMPRESA, la cual concederá un plazo razonable para la entrega.{" "}
        </p>
        <p>
          Si terminado este plazo aún no se entregó el producto, el CLIENTE
          tiene derecho a rescindir el contrato o a un reembolso lo antes
          posible.{" "}
        </p>
        <p>
          Si el Producto es defectuoso o no funciona según lo anunciado. La
          EMPRESA reparará, sustituirá, realizará un descuento o reembolsará el
          importe abonado.
        </p>
        <p className={"strong"}>
          <strong>DEVOLUCIONES</strong>
        </p>
        <p>
          Conforme a la legislación vigente, se informa a los CLIENTES que los
          servicios ofrecidos por la EMPRESA es un derecho de uso del contenido
          digital puesto a su disposición y por tanto, una vez accedido al envío
          o descarga del contenido, el CLIENTE renuncia al DERECHO DE
          DESESTIMIENTO una vez haya descargado el contenido.
        </p>
        <p className={"strong"}>
          <strong>Condiciones de devoluciones</strong>
        </p>
        <ul>
          <li>
            No se aceptarán devoluciones de los siguientes productos, tal y como
            establece el Real Decreto Legislativo 1/2007, de 16 de noviembre,
            por el que se aprueba el texto refundido de la Ley General para la
            Defensa de los Consumidores y Usuarios y otras leyes
            complementarias:Toda mercancía debe ser devuelta en su embalaje y
            condiciones originales, en perfecto estado y protegida, evitando
            pegatinas, precintos o cintas adhesivas directamente sobre la
            superficie o embalaje del artículo. En caso contrario la EMPRESA se
            reserva el derecho de rechazar la devolución.
          </li>
          <li>
            En la adquisición del derecho de uso de contenidos digitales el
            CLIENTE renuncia explícitamente al ejercicio de este derecho una vez
            haya accedido a dichos contenidos.
          </li>
          <li>
            En el caso de que el CLIENTE solicite la devolución de una compra de
            un producto de contenidos digitales, y la EMPRESA esté de acuerdo en
            su devolución, se realizará un cargo de 5€ en el importe total de su
            compra por gastos de gestión de la devolución.
          </li>
          <li>
            La prestación de servicios, una vez que el servicio haya sido
            completamente ejecutado, cuando la ejecución haya comenzado, con
            previo consentimiento expreso del consumidor y usuario y con el
            reconocimiento por su parte de que es consciente de que, una vez que
            el contrato haya sido completamente ejecutado por el empresario,
            habrá perdido su derecho de desistimiento.
          </li>
          <li>
            El suministro de bienes o la prestación de servicios cuyo precio
            dependa de fluctuaciones del mercado financiero que el empresario no
            pueda controlar y que puedan producirse durante el periodo de
            desistimiento.
          </li>
          <li>
            El suministro de bienes confeccionados conforme a las
            especificaciones del consumidor y usuario o claramente
            personalizados.
          </li>
          <li>
            El suministro de bienes que puedan deteriorarse o caducar con
            rapidez.
          </li>
          <li>
            El suministro de bienes precintados que no sean aptos para ser
            devueltos por razones de protección de la salud o de higiene y que
            hayan sido desprecintados tras la entrega.
          </li>
          <li>
            El suministro de bienes que después de su entrega y teniendo en
            cuenta su naturaleza se hayan mezclado de forma indisociable con
            otros bienes.
          </li>
          <li>
            El suministro de grabaciones sonoras o de vídeo precintadas o de
            programas informáticos precintados que hayan sido desprecintados por
            el consumidor y usuario después de la entrega.
          </li>
          <li>
            El suministro de contenido digital que no se preste en un soporte
            material cuando la ejecución haya comenzado con el previo
            consentimiento expreso del consumidor y usuario con el conocimiento
            por su parte de que en consecuencia pierde su derecho de
            desistimiento.
          </li>
          <li>
            Los gastos de transporte originados por la devolución correrán a
            cargo del CLIENTE, el cual es libre de elegir y buscar la agencia
            que más se adapte a sus necesidades.
          </li>
          <li>
            Una vez recibida la mercancía y comprobada que está en perfectas
            condiciones, se tramitará la devolución del importe abonado en un
            plazo máximo de 14 días naturales desde que se ejerza el derecho de
            desistimiento.
          </li>
          <li>
            Devolución de productos con regalo o promoción. El CLIENTE deberá
            realizar la devolución completa (pack completo o artículo + regalo)
            para poder proceder al reembolso.
          </li>
          <li>
            El titular del comprobante de adquisición para el servicio tendrá
            derecho a desistir del contrato, siempre que lo comunique dentro del
            plazo de 14 días naturales desde la contratación del servicio,
            siempre que éste no haya sido ejecutado o prestado.
          </li>
        </ul>
        <p className={"strong"}>
          <strong>
            FORMA DE PAGO PARA PRODUCTOS/SERVICIOS ONLINE DE PAGO ÚNICO Y/O BAJO
            SUSCRIPCIÓN{" "}
          </strong>
        </p>
        <p>En nuestra tienda online existen diversas modalidades de pago: </p>
        <ul>
          <li>Stripe</li>
        </ul>
        <p>Compras seguras mediante Tarjeta de crédito </p>{" "}
        <p>
          El CLIENTE acepta en este acto la realización de pagos recurrentes y/o
          pagos por suscripción en aquellos servicios que originan un derecho de
          uso a los contenidos adquiridos de manera recurrente en el tiempo. En
          cada contenido adquirido se especifica el sistema de suscripción
          adoptado por cada cliente mediante la aceptación tácita por parte del
          cliente de dicha forma de pago.{" "}
        </p>
        <p className={"strong"}>
          <strong>ENVÍO Y ENTREGA </strong>
        </p>
        <ol>
          <li>
            La descarga de los contenidos se realizará tras haber verificado el
            abono de la factura generada.
          </li>
          <li>
            La EMPRESA entregará vía online los productos en un plazo máximo de
            5 días. Si el CLIENTE no la recibe en dicho periodo, deberá
            comunicarlo a la EMPRESA, la cual concederá un plazo razonable para
            la entrega. Si terminado este plazo aún no se entregó el producto,
            el CLIENTE tiene derecho a rescindir el contrato o a un reembolso lo
            antes posible.{" "}
          </li>
          <li>
            Si el Producto es defectuoso o no funciona según lo anunciado. La
            EMPRESA reparará, sustituirá, realizará un descuento o reembolsará
            el importe abonado.{" "}
          </li>
        </ol>
        <p className={"strong"}>
          <strong>DEVOLUCIONES</strong>
        </p>
        <p>
          Conforme a la legislación vigente, se informa a los CLIENTES que los
          servicios ofrecidos por la EMPRESA es un derecho de uso del contenido
          digital puesto a su disposición y por tanto, una vez accedido al envío
          o descarga del contenido, el CLIENTE renuncia al DERECHO DE
          DESESTIMIENTO una vez haya descargado el contenido.
        </p>
        <p>Condiciones de devoluciones </p>
        <ul>
          <li>
            No se aceptarán devoluciones de los siguientes productos físicos,
            tal y como establece el Real Decreto Legislativo 1/2007, de 16 de
            noviembre, por el que se aprueba el texto refundido de la Ley
            General para la Defensa de los Consumidores y Usuarios y otras leyes
            complementarias:Toda mercancía debe ser devuelta en su embalaje y
            condiciones originales, en perfecto estado y protegida, evitando
            pegatinas, precintos o cintas adhesivas directamente sobre la
            superficie o embalaje del artículo. En caso contrario la EMPRESA se
            reserva el derecho de rechazar la devolución.
          </li>
          <li>
            En la adquisición del derecho de uso de contenidos digitales el
            CLIENTE renuncia explícitamente al ejercicio de este derecho una vez
            haya accedido a dichos contenidos.
          </li>
          <li>
            En el caso de que el CLIENTE solicite la devolución de una compra de
            un producto de contenidos digitales, y la EMPRESA esté de acuerdo en
            su devolución, se realizará un cargo de 5€ en el importe total de su
            compra por gastos de gestión de la devolución.
          </li>
          <li>
            La prestación de servicios, una vez que el servicio haya sido
            completamente ejecutado, cuando la ejecución haya comenzado, con
            previo consentimiento expreso del consumidor y usuario y con el
            reconocimiento por su parte de que es consciente de que, una vez que
            el contrato haya sido completamente ejecutado por el empresario,
            habrá perdido su derecho de desistimiento
          </li>
          <li>
            El suministro de bienes o la prestación de servicios cuyo precio
            dependa de fluctuaciones del mercado financiero que el empresario no
            pueda controlar y que puedan producirse durante el periodo de
            desistimiento.
          </li>
          <li>
            El suministro de bienes confeccionados conforme a las
            especificaciones del consumidor y usuario o claramente
            personalizados.
          </li>
          <li>
            El suministro de bienes que puedan deteriorarse o caducar con
            rapidez.
          </li>
          <li>
            El suministro de bienes precintados que no sean aptos para ser
            devueltos por razones de protección de la salud o de higiene y que
            hayan sido desprecintados tras la entrega.
          </li>
          <li>
            El suministro de bienes que después de su entrega y teniendo en
            cuenta su naturaleza se hayan mezclado de forma indisociable con
            otros bienes.
          </li>
          <li>
            El suministro de grabaciones sonoras o de vídeo precintadas o de
            programas informáticos precintados que hayan sido desprecintados por
            el consumidor y usuario después de la entrega.
          </li>
          <li>
            El suministro de contenido digital que no se preste en un soporte
            material cuando la ejecución haya comenzado con el previo
            consentimiento expreso del consumidor y usuario con el conocimiento
            por su parte de que en consecuencia pierde su derecho de
            desistimiento.
          </li>
          <li>
            Los gastos de transporte originados por la devolución correrán a
            cargo del CLIENTE, el cual es libre de elegir y buscar la agencia
            que más se adapte a sus necesidades.
          </li>
          <li>
            Una vez recibida la mercancía y comprobada que está en perfectas
            condiciones, se tramitará la devolución del importe abonado en un
            plazo máximo de 14 días naturales desde que se ejerza el derecho de
            desistimiento.
          </li>
          <li>
            Devolución de productos con regalo o promoción. El CLIENTE deberá
            realizar la devolución completa (pack completo o artículo + regalo)
            para poder proceder al reembolso.
          </li>
          <li>
            El titular del comprobante de adquisición para el servicio tendrá
            derecho a desistir del contrato, siempre que lo comunique dentro del
            plazo de 14 días naturales desde la contratación del servicio,
            siempre que éste no haya sido ejecutado o prestado.
          </li>
          <li></li>
        </ul>
        <p className={"strong"}>
          <strong>
            TÉRMINOS Y CONDICIONES PROMOCIÓN 3 MESES EN LA SUSCRIPCIÓN DEL PLAN
            DIETA BÁSICO.{" "}
          </strong>
        </p>
        <p>
          La oferta de 3 meses en la suscripción del plan dieta básico, se trata
          de una promoción para la suscripción al plan dieta básico individual,
          en el cual el usuario adquiere 3 meses de suscripción a este plan por
          un precio menor al precio habitual establecido. Esta oferta por tanto
          sólo será válida para la suscripción al plan de dieta básico
          individual y no es acumulable con otras ofertas existentes.
        </p>
        <p>
          El cliente deberá abonar previamente a través de un único pago, la
          oferta de los 3 meses de suscripción. Adquiriendo esta oferta, el
          cliente acepta las condiciones establecidas y la permanencia durante
          los 3 meses de oferta, por lo que una vez haya adquirido la oferta y
          comenzado el plan, el cliente, no podrá solicitar la devolución total
          o parcial de la oferta.
        </p>
        <p>
          Posteriormente a finalizar los 3 meses de la oferta, el cliente
          continuará con su suscripción quedando suscrito al programa de
          asesoramiento y por tanto queda sujeto a los términos y condiciones
          generales de suscripción del programa de asesoramientos también
          descritas en esta misma página. Si lo desea, el cliente podrá cancelar
          su suscripción una vez haya finalizado los 3 meses de permanencia en
          esta oferta.
        </p>
        <p>
          Los planes de{" "}
          <b>
            <u>suscripción de dieta</u>
          </b>{" "}
          comprados bajo esta oferta, deberán iniciarse en un periodo máximo de
          15 días desde la fecha de su compra en el caso de ser nuevos usuarios.
          Para ello el cliente deberá enviar el cuestionario inicial antes de
          los 15 días naturales tras la compra de su plan bajo esta oferta.
          <u>
            {" "}
            Por lo tanto, NO se podrá realizar la compra de un nuevo plan y
            empezar el mismo más tarde de los 15 días posteriores a la compra
          </u>
          . En el caso de que el cliente no empiece su plan antes de los 15 días
          posteriores a la compra, su suscripción empezará a contar en ese
          instante y por tanto perderá días de su suscripción mensual (30 días).
          Para aquellos usuarios que ya estaban previamente inscritos en su
          suscripción y adquieren esta oferta, deberán continuar su plan con las
          fechas que ya estaban establecidas previamente.
        </p>
        <p>
          Los planes de suscripción contratados bajo esta promoción quedarán
          suscritos al programa de asesoramiento y por tanto quedan sujetas a
          los términos y condiciones generales de suscripción del programa de
          asesoramientos también descritas en esta misma página.
        </p>
        <p>
          Los productos adquiridos bajo esta oferta quedan sujetos a las
          políticas y condiciones de envío, entrega, cancelación y devolución
          anteriormente citadas en este mismo documento.
        </p>
        {/* Oferta plan de suscripción psicología */}
        <p className="strong">
          <strong>TÉRMINOS Y CONDICIONES SUSCRIPCIÓN PSICOLOGÍA</strong>
        </p>
        <p>
        La suscripción de psicología es una suscripción mensual en la cual dispones de 2, 3 o 4
        sesiones cada 30 días. Esta suscripción solo estará disponible para sesiones
        individuales. El usuario deberá realizar las sesiones que incluya su suscripción en un
        plazo de 30 días. En el caso de que el usuario no realice sus sesiones en el plazo de 30
        días, perderá la opción a disfrutar de la sesión/sesiones, sin excepción.
        </p>
        <p>
        Una vez realizada la compra de la suscripción, el usuario tendrá 15 días de plazo para
        empezar los 30 días de la misma, de lo contrario, perderá días de su suscripción.
        </p>
        <p>
        El usuario podrá darse de baja a la suscripción en cualquier momento que lo desee y
        siempre tendrá que hacerlo antes de que llegue la fecha de la renovación. Una vez
        realizada la renovación de la suscripción y por tanto que tenga ya disponibles las
        nuevas sesiones en los próximos 30 días, no podrá cancelarla. 
        </p>
        <strong style={{color: '#050505', fontFamily: 'Montserrat Bold', fontSize: '16px'}}>
        ¿Cómo funciona? 
        </strong>
        <p>
        En el momento en el que el usuario compra la suscripción, recibirá un email con el fin
        de firmar el consentimiento informado donde se le explican los detalles básicos de la
        protección de datos y las condiciones que acepta de la suscripción. Será también en
        este mismo email donde indique a atención al cliente el motivo de su consulta. 
        </p>
        <p>
        Una vez atención al cliente verifique que el documento está cumplimentado y firmado
        correctamente, se procederá a asignar al psicólogo. En el momento en el que se asigna
        al psicólogo, el usuario tiene acceso completo a su perfil y será en este momento
        cuando empiece a contar los días disponibles que tiene para poder agendar las
        sesiones correspondientes a la suscripción que haya comprado.
        </p>
        <p>
        Para los usuarios que ya hayan realizado sesiones de psicología con vikika team
        anteriormente y tengan a su psicólogo asignado, los 30 días de su suscripción
        comenzarán en el momento en el que el usuario compre el producto de suscripción de
        psicología y la compra haya sido realizada con éxito. 
        </p>
        <p>
        El usuario podrá gestionar sus sesiones, así como hablar con la psicóloga desde su
        panel. En su perfil, el usuario podrá ver las sesiones disponibles a agendar durante esos
        30 días y cuantos días le quedan para poder agendarlas. En el panel del cliente, por
        tanto, solo aparecerá disponible para agendar desde el momento que se le asigna a la
        psicóloga hasta 30 días después.
        </p>
        <p>
        Los productos adquiridos bajo esta oferta quedan sujetos a las políticas y condiciones
        de envío, entrega, cancelación y devolución anteriormente citadas en este mismo
        documento
        </p>
        {/* Fin Oferta plan de suscripción psicología */}
        <p className="strong">
          <strong>TÉRMINOS Y CONDICIONES OFERTAS “BLACK FRIDAY” 2023</strong>
        </p>
        <p>
          La oferta “Black Friday” será válida a partir del día 20 de Noviembre
          de 2023 hasta el 27 de Noviembre de 2023 a las 23:59 del mismo.
          (Horario peninsular Española). El descuento aplicado de “Black Friday”
          no es acumulable con otras promociones y/o descuentos.
        </p>
        <p>
          Durante este periodo de tiempo habrá disponible las siguientes ofertas
          en la web:
        </p>
        <p>
          <strong>
            -15% de descuento en los planes de suscripción de dieta y/o
            entrenamiento.
          </strong>
        </p>
        <p>
          Los planes de
          <strong>
            <u>suscripción de dieta y/o entrenamiento </u>
          </strong>
          comprados con la oferta Black Friday, deberán iniciarse en un periodo
          máximo de 15 días desde la fecha de su compra. Para ello el cliente
          deberá enviar el cuestionario inicial antes de los 15 días naturales
          tras la compra de su plan bajo esta oferta. <u>Por lo tanto, NO se podrá
          realizar la compra de un nuevo plan y empezar el mismo más tarde de
          los 15 días posteriores a la compra</u> . En el caso de que el cliente no
          empiece su plan antes de los 15 días posteriores a la compra, su
          suscripción empezará a contar en ese instante y por tanto perderá días
          de su suscripción mensual (30 días).
        </p>
        <p>
          El periodo de plazo de entrega del plan de asesoramiento que hayan
          sido comprados bajo la oferta "Black Friday" podrá variar entre 3 a 10
          días desde el envío del cuestionario del cliente.
        </p>
        <p>
          Los planes de suscripción contratados bajo la oferta “BLACK FRIDAY”
          quedarán suscritos al programa de asesoramiento y por tanto quedan
          sujetas a los términos y condiciones generales de suscripción del
          programa de asesoramientos también descritas en esta misma página.
        </p>
        <p>
          <strong>-10% de descuento en las sesiones de psicología.</strong>
        </p>
        <p>
        Las <strong>sesiones de psicología</strong>  adquiridas bajo esta oferta deberán ser realizadas en un máximo de 15 días desde la fecha de la compra.
         En el caso de los bonos, la primera sesión, deberá ser realizada en un máximo de 15 días desde la fecha de la compra. 
        </p>
        <p>
          <strong>
            -“Plan especial black Friday” con hasta 10% de descuento.
          </strong>
        </p>
        <p>
          Este producto consiste en un plan nutricional personalizado con o sin
          entrenamiento, con una duración flexible entre 3 a 6 meses, según la
          elección del cliente. Al adquirir este producto, el cliente realizará
          un pago único por el plan escogido y se compromete a seguir el
          asesoramiento durante el periodo seleccionado (entre 3 a 6 meses). La
          decisión sobre la duración del plan es responsabilidad exclusiva del
          cliente. Una vez iniciado el plan, no se aceptarán solicitudes de
          devolución total o parcial del producto. El cliente reconoce y acepta
          que la naturaleza del plan impide la devolución después de haber
          comenzado su implementación. El cliente deberá iniciar su plan en un
          periodo máximo de 15 días desde la fecha de su compra. Para ello,
          deberá enviar el cuestionario inicial antes de los 15 días naturales
          tras la compra de su plan bajo esta oferta. <u> Por lo tanto, NO se podrá
          realizar la compra de este plan y empezar el mismo más tarde de los 15
          días posteriores a la compra</u>.
        </p>
        <p>
          <strong>
            -10% de descuento en la siguiente renovación de su suscripción para
            usuarios previamente activos.
          </strong>
        </p>
        <p>
          Los usuarios que tengan una <strong><u> suscripción activa</u></strong> de un plan de dieta y/o
          entrenamiento online antes del día 20 de noviembre de 2023, tendrán un
          10% de descuento en su próxima renovación. Esta promoción no es
          acumulable con otros cupones, ofertas o promociones activas, de tal
          manera que el usuario que tenga un cupón u oferta aplicada previamente
          recibirá la oferta indicada en ese cupón previo.
        </p>
        <p>
          Todos los productos adquiridos bajo la oferta “BLACK FRIDAY”, en el
          momento de la compra quedan sujetos a las políticas y condiciones de
          envío, entrega, cancelación y devolución anteriormente citadas en este
          mismo documento.
        </p>
        <p>
          Nos reservamos el derecho de realizar cambios en estos términos y
          concisiones en cualquier momento. Las modificaciones entrarán en vigor
          inmediatamente después de su publicación en el sitio web.
        </p>
      </div>
    </>
  );
}

export default Conditions;
