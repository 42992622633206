import React, { useEffect, useState, createRef} from "react";
import { useNavigate } from "react-router-dom";
import MediaQuery from "react-responsive";
import Questions from '../../../../shared/questions/Questions';
import { CircularProgress } from '@mui/material';

import './ReviewSubscriptions.css';

import ProgressDataReviewSubscriptions from './ProgressDataReviewSubscriptions';
import FeedingDataReviewSubscriptions from './FeedingDataReviewSubscriptions';


import { ProgressData } from '../../../../../models/formsModels/ReviewProgressData';
import { FeedingData } from "../../../../../models/formsModels/ReviewFeedingData";
import { InterestData } from "../../../../../models/formsModels/ReviewInterestData";

import {PlanReviewSubscriptionService} from '../../../../../services/planReviewSubscriptionService';
import {accessControlService} from '../../../../../services/planNewSubscriptionService';

import nextArrow from '../../../../../assets/img/arrow-right-form.svg';
import previousArrow from '../../../../../assets/img/arrow-left-form.svg';
import okSignal from '../../../../../assets/img/mapas-y-banderas_1.png';


const ReviewSubscriptions = () => {
    // empty string push check
    const [next, setNext] = useState(1);
    const [dataInterestStep, setDataInterestStep] = useState(1);
    const [access, setAccess] = useState(false);
    const [sending, setSending] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);

    const [error, setError] = useState([]);

    const [progressData, setProgressData] = useState<ProgressData>({
      dppeso: '',
      cambiosvolumen: '',
      dietaporcentaje:'',
      dietahambre:'',
      entrenamientocumplido:'',
      entrenamientoporcentaje:'',
      entrenamientonotas:'',
    });

    const [feedingData, setFeedingData] = useState<FeedingData>({
      alimento1: '',
      motivo1: '',
      alimento2: '',
      motivo2: '',
      alimento3: '',
      motivo3: '',
    });

    const [interestData, setInterestData] = useState<InterestData>({
      obstaculobloqueo: '',
    });

    const miComponenteRef = createRef<HTMLDivElement>();

    const navigate = useNavigate();

    useEffect(() => {

      async function AccessControl(){
        try{
          const accessControl= await accessControlService("reviewPlan");
          setAccess(accessControl.data);
          if(!accessControl.data){
            const status = localStorage.getItem('status');
            if (status && (status === "isLoggedIn")) {
              navigate('/private');
            }else{
              navigate('/iniciar-sesion');
            }
          }
        }catch{
          navigate('/private');
        }
      }
      AccessControl();
      miComponenteRef.current?.scrollIntoView({block: "start",behavior: 'smooth'});
    },[]);

    const closeForm = ()=>{
      navigate('/private'); 
    }

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      const { value,name } = event.target;
      interestData.obstaculobloqueo= value;
      setInterestData((formData: InterestData) => ({ ...formData, [name]: value }));
    };
  
    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setError([]);
        setSending(true);
        const reviewPlanResponse = await PlanReviewSubscriptionService({
            progressDataToSend:progressData,
            feedingDataToSend:feedingData,
            interestDataToSend:interestData
        });  
        if(reviewPlanResponse.data["planRevisado"]==="exito"){
          setSending(false);
          setShowSuccess(true);
        }           
        if(reviewPlanResponse.data["planRevisado"]!=="exito"){
          setSending(false);
          setError(reviewPlanResponse.data["planRevisado"]);
        } 
    }

    return (
      <>
       {!access && (<></>)}

       {access && (
        <div className="vikika-suscription-forms" ref={miComponenteRef}>  
            
            {!showSuccess && (
              <div className="begin-form">
                <h2>CUESTIONARIO DE REVISIÓN</h2>
                <div className="data-form-titles">
                  <div className="data-form-titles-checks">
                    <span className='success-step sucess-step-icon'>{next > 1 ? '✔' : 1}</span>
                    <p className='success-step-p1'>Tu progreso</p>
                  </div>
                  {next===1 &&
                    <span className='line-progress line-progress-active'></span>
                  }
                  {next>1 &&
                    <span className='line-progress line-progress-active-full'></span>
                  }
                  <div className="data-form-titles-checks">
                    <span className={next>1 ? 'success-step sucess-step-icon' : 'sucess-step-icon'}>{next>2  ? '✔' : 2}</span>
                    <p className={next>1 ? 'success-step-p2' : ''}>Alimentación</p>
                  </div>
                  {next===1 && (
                    <span className='line-progress'></span>
                  )}
                  {next=== 2 &&(
                    <span className='line-progress line-progress-active'></span>
                  )}
                  {next=== 3 && dataInterestStep===1 && (
                    <span className='line-progress line-progress-active'></span>
                  )}
                  {next=== 3 && dataInterestStep===2 && (
                    <span className='line-progress line-progress-active-full'></span>
                  )}
                  <div className="data-form-titles-checks">
                    <span className={next>2 ? 'success-step sucess-step-icon' : 'sucess-step-icon'}>{dataInterestStep===2 ? '✔' : 3}</span>
                    <p className={next>2 ? 'success-step-p3' : ''}>Datos de interés</p>
                  </div>
                </div>
                <form onSubmit={handleSubmit}>
                    {next===1 && (
                        <ProgressDataReviewSubscriptions miComponenteRef={miComponenteRef} setNext={setNext} progressData={progressData} setProgressData={setProgressData}/>
                    )}
                    {next===2 && (
                        <FeedingDataReviewSubscriptions miComponenteRef={miComponenteRef} setNext={setNext} feedingData={feedingData} setFeedingData={setFeedingData}/>  
                    )}
                    {next===3 && (
                      <>
                        <label htmlFor="obstaculobloqueoCliente" className="data-label">Si hay algo que estés sintiendo que te está costando para seguir avanzando, tanto en la dieta como con el entreno, cuéntanoslo aquí</label><br></br>
                        <input 
                              type="text" 
                              name="obstaculobloqueo" 
                              id="obstaculobloqueoCliente" 
                              placeholder="Max 150 palabras" 
                              className=''
                              value={interestData.obstaculobloqueo}
                              onChange={handleInputChange}
                        /><br></br>

                        {error.length !== 0 &&
                          error.map((message,index)=><p key={index} className="instructions-send-form">{message}</p>)
                        }
                          
                        {sending?
                          <>
                            <div className={'form-data-processing'}>
                              <CircularProgress color="inherit" size={20} thickness={3}/>
                              <p className="instructions-send-form">Procesando, por favor espere.</p>
                            </div> 
                          </>                            
                          :
                          <></>
                        }   
                        <hr />
                        <div className="button-navigation-form">
                          <button onClick={()=>setNext(2)} className="questionnaire-button-left"><img src={previousArrow} alt={'previous'} /> Anterior</button>
                          <button className="questionnaire-button-right send-button" type="submit" value="Enviar cuestionario">ENVIAR CUESTIONARIO</button> 
                        </div>  
                      </>
                    )}   
                </form>
              </div>
            )}
            {showSuccess && (
              <div className="begin-form-end-review">
                  <img className="image-end-review" src={okSignal} alt={'OK'} />
                  <h3 className="header-end-review">¡GRACIAS!</h3>
                  <p className="text-end-review">Ya puedes escoger la videollamada con el especialista y continuar con tu objetivo.</p>
                  <div className="button-navigation-form-end-review">
                    <button onClick={()=>closeForm()} className="questionnaire-button-center send-button" value="OK">OK</button> 
                  </div>
              </div>
            )}    
        </div>)}

        <MediaQuery maxWidth={768}>
            <Questions showAlgunaDuda={true}/>
        </MediaQuery>
        <MediaQuery minWidth={768}>
            <Questions />
        </MediaQuery>
      </>
    )
} 

export default ReviewSubscriptions;