import {useQuery} from 'react-query';
import axiosCodeigniter from '../api/axiosCodeigniter';
import { GET_PSI_CHAT_URL } from '../utils/constants';
interface Mensaje {
    mensaje: string;
    es_cliente: string;
}
interface ClientChat {
    mensajes: Mensaje[];
}
  
export default function usePsiMessageQuery(typePlan:string) {
    return useQuery<ClientChat>('clientMessages', async () => {
        const token = localStorage.getItem("token");
        const config = {
            headers: {
                Authorization: `Bearer ${token}`
            },
            params: {
                typePlan: typePlan
            }
        };
        console.log(config);
        const ret = await axiosCodeigniter.get<ClientChat>(GET_PSI_CHAT_URL, config);
        console.log(ret);
        console.log(ret.data);
        return ret.data;
    },{
        refetchInterval: 2000,
    });
}