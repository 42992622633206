import { Button, Card, CardActions, CardContent, Typography } from '@mui/material';
import { CardProductProps } from '../../../../models/componentProps/CardProductProps';
import { Checkbox, FormControlLabel } from '@mui/material';
import {addToCart} from "../../../../utils/FacebookPixelEvents";
import TrainingRadioButton from './training/TrainingRadioButton';
import { Link, useNavigate, useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import IconGift from '../../../../assets/img/caja-de-regalo.svg'
import IconInfo from '../../../../assets/img/info-icon.svg'
import { isArray } from "jquery";


const MenuProductDiet = (productProps: CardProductProps) => {
    const navigate = useNavigate();
    const [productCategory, setProductCategory] = useState<String>();
    const [savedRadioProduct, setSavedRadioProduct] = useState<any>(null);
    const [productPrice, setProductPrice] = useState<number>(productProps.product.base_price);
    const [productSubscription, setProductSubscription] = useState<number>(productProps.product.subscription_price);
    const [selectedProductId, setSelectedProductId] = useState<string>('');
    const [selectedVariantId, setSelectedVariantId] = useState<string>('');
    const { id } = useParams();
    const formattedProductPrice = productPrice % 1 === 0 ? productPrice : productPrice.toLocaleString('es-ES', { minimumFractionDigits: 2 });
    const totalprice = productPrice + productSubscription;
    const formattedTotalPrice = totalprice % 1 === 0 ? totalprice : totalprice.toLocaleString('es-ES', { minimumFractionDigits: 2 });
    const [resetRadioSelection, setResetRadioSelection] = useState(false);
    const [showDisableTrainingMessage, setShowDisableTrainingMessage] = useState(false);

    useEffect(() => {
        if (id == 'dieta'){
              setProductCategory('diet')
            }
            if (id == 'psicologia'){
              setProductCategory('psychology')
            }
            if (id == 'talleres'){
              setProductCategory('workshop')
            }
        }, [id]);

    const handleClick = () => {
        addToCart();
        localStorage.removeItem('payment_intent');
        localStorage.removeItem('client_secret');
        localStorage.removeItem('facturationData');
        localStorage.removeItem('savedSelectedProduct');
        localStorage.setItem('savedSelectedProduct', JSON.stringify({
            value: savedRadioProduct,
            productId: selectedProductId,
            variantId: selectedVariantId,
            productPrice: productPrice,
            name: productProps.product.name,
            img: productProps.product.img,
            alt: productProps.product.alt,
            productCategory: productCategory,
            isEnPareja: isChecked,
            productSubscription: productProps.product.product_subscription,
            productSubscriptionPrice: productSubscription
        }));
        navigate('/pago');
    };

    const handleDoubtTraining = () => {
        if (id === 'dieta') {
            productProps.openAcordeonSection(4);
            const section = document.getElementById('accordion3');
            if (section) {
                section.scrollIntoView({ behavior: 'smooth' });
            }
        } else if (id === 'psicologia') {
            productProps.openAcordeonSection(2);
            const section = document.getElementById('accordion1');
            if (section) {
                section.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }

    const handleDoubtCouple = () => {
        if (id === 'dieta') {
            productProps.openAcordeonSection(5);
            const section = document.getElementById('accordion4');
            if (section) {
                section.scrollIntoView({ behavior: 'smooth' });
            }
        } else if (id === 'psicologia') {
            productProps.openAcordeonSection(3);
            const section = document.getElementById('accordion2');
            if (section) {
                section.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }

    const [isChecked, setIsChecked] = useState(false);
    const [isActiveRadioChecked, setIsActiveRadioChecked] = useState(id === 'psicologia');

    const handleActiveRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (id !== 'psicologia' && savedRadioProduct === null && !isActiveRadioChecked) {
            setIsActiveRadioChecked(true);
        } else if (isActiveRadioChecked && id !== 'psicologia') {
            setIsActiveRadioChecked(false);
            setSavedRadioProduct(null)
        } else {
            event.preventDefault();
        }
    };

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsChecked(!isChecked);
    };

    // eslint-disable-next-line  react-hooks/exhaustive-deps
    useEffect(() => {
        setIsActiveRadioChecked(id === 'psicologia');
        setIsChecked(false)
    }, [id]);

    useEffect(() => {
        if (savedRadioProduct){
            const selectedValue = productProps.product.radio_options.find(x => x.value === savedRadioProduct.value)?.value || '';
            console.log('selectedValue: ', selectedValue);
            if (selectedValue === 'option3' && isChecked) {
                console.log('entro al if')
                setSavedRadioProduct(null);
                setResetRadioSelection(true);
            }
        }
        handlePrice();
    }, [isChecked]);

    useEffect(() => {
        handlePrice();
    }, [savedRadioProduct]);

    const handleRadioChange = (value: string) => {
        const selectedValue = productProps.product.radio_options.find(x => x.value === value)
        setSavedRadioProduct(selectedValue);
    };

    const handlePrice = () => {
        if (savedRadioProduct && isChecked) {
            setProductPrice(savedRadioProduct.new_price_reduced_composed || 0)
            setProductSubscription(savedRadioProduct.feeComposed || 0);
            setSelectedVariantId(id !== 'dieta' ? savedRadioProduct.idComposed || '' : '');
            setSelectedProductId(id !== 'dieta' ? productProps.product.idCouple || '' : savedRadioProduct.idComposed || '');
        } else if (isChecked && id === 'dieta') {
            setProductPrice(productProps.product.checkbox_option.new_price_reduced_composed || productProps.product.checkbox_option.new_price_composed)
            setProductSubscription(productProps.product.checkbox_option.feeComposed || 0);
            setSelectedProductId(productProps.product.idCouple);
            setSelectedVariantId('');
        } else if (savedRadioProduct) {
            setProductPrice(savedRadioProduct.new_price_reduced_lonelly || 0);
            setProductSubscription(savedRadioProduct.feeLonely || 0);
            setSelectedVariantId(id !== 'dieta' ? savedRadioProduct.idLonelly : '');
            setSelectedProductId(id !== 'dieta' ? productProps.product.idBasic || '' : savedRadioProduct.idLonelly || '');
        } else {
            setProductPrice(productProps.product.base_price);
            setProductSubscription(productProps.product.subscription_price);
            setSelectedProductId(productProps.product.idBasic);
            setSelectedVariantId('');
        }
    }

    return (
        <div className='product-sesion'>
            <Card sx={{ maxWidth: 1024 }} className='card-content'>
                <div className='card-content-product-details'>
                    <CardContent>
                        <Typography className='container-price-plan-Details' variant="h2">
                            <div className='large-myproduct'>
                                {formattedProductPrice}
                                <span className='small-price'>€ /mes</span>
                            </div>
                            {productProps.product.product_subscription && (
                                <div className='subscription-price-details'>primer mes {formattedTotalPrice} €</div>
                            )}
                        </Typography>
                    </CardContent>
                    <CardActions>
                        {!showDisableTrainingMessage ? (
                            <span className='btn-myproduct'><Button size="large" onClick={handleClick}><span className='spacer-prod'>comprar</span></Button ></span>
                        ):(
                            <span className='btn-myproduct' style={{backgroundColor:"#ccc"}}><Button size="large" onClick={handleClick} disabled><span className='spacer-prod'>reserva tu plaza</span></Button ></span>
                        )    
                        }
                    </CardActions>
                    {showDisableTrainingMessage &&
                        <div className='help'>
                            <span><img src={IconInfo} alt='info' /></span>
                            <h6>Actualmente hemos agotado todas las plazas para el PLAN DE DIETA/ENTRENAMIENTO. No obstante, hemos abierto una lista de espera para avisarte y que seas el primero en tener tu plan en cuanto se liberen huecos. ¡Apúntate <a className='here' href="https://entrenavirtual.typeform.com/listadeesperaVT" target="_blank">AQUÍ!</a></h6>                  
                        </div>
                    }
                    <div className="dashed-line"></div>
                    <div className='training-container'>
                        {productProps.product.radioLabel && (
                            <div className='container-training-add'>
                                <FormControlLabel className='container-training-add-check'
                                    control={<Checkbox checked={isActiveRadioChecked} onChange={handleActiveRadioChange} defaultChecked={id === 'psicologia'}/>}
                                    label={
                                        <>
                                            {productProps.product.radioLabel}
                                        </>
                                    }
                                />
                            </div>
                        )}
                        <TrainingRadioButton
                            options={productProps.product.radio_options}
                            isActiveRadioChecked={isActiveRadioChecked}
                            isChecked={isChecked}
                            onChange={handleRadioChange}
                            resetRadioSelection={resetRadioSelection}
                            setResetRadioSelection={setResetRadioSelection}
                        />
                        <div className='help'>
                            <span><img src={IconInfo} alt='info' /></span>
                            {id === 'dieta' && (
                                <h6>Si tienes dudas de como funciona o que incluye el entrenamiento te lo explicamos <a className='here' onClick={handleDoubtTraining} >aquí</a> </h6>
                            )}
                            {id === 'psicologia' && (
                                <h6>Si tienes dudas de como funciona te lo explicamos <a className='here' onClick={handleDoubtTraining} >aquí</a> </h6>
                            )}

                        </div>
                    </div>
                    <div className='divider-green'></div>
                    <div className='couple-plan'>
                        <FormControlLabel
                            control={<Checkbox className='color-check-couple' onChange={handleCheckboxChange}/>}
                            label={productProps.product.checkbox_option.option_name}
                        />
                    </div>
                    <div className='help'>
                        <span><img src={IconInfo} alt='info' /></span>
                        <h6>Si tienes dudas de como funciona o que incluye el plan en pareja te lo explicamos  <a className='here' onClick={handleDoubtCouple} >aquí</a> </h6>
                    </div>
                </div>
                <div className='help-you'>
                    <h3>¡Te ayudamos!</h3>
                    <h4>¿Tienes dudas y no sabes cual es el plan que mejor se adapta a ti?</h4>
                    <h5 style={{ cursor: "pointer" }} onClick={() => navigate('/contacto')}>Mándanos un mensaje</h5>
                </div>

            </Card>

        </div>
    )
}

export default MenuProductDiet
