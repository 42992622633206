import {Link, useNavigate} from 'react-router-dom';
import {useState, useEffect, useRef, useContext} from 'react';
import MenuContext from "../../../../providers/MenuProvider";
import Icon from '../../../../assets/img/arrow-1.svg';
import IconLock from '../../../../assets/img/lock.svg';
import IconHome from '../../../../assets/img/iconoir_home_profile.svg';
import Circulo from '../../../../assets/img/circulo.jpg';
import IconCart from '../../../../assets/img/iconoir_cart.svg';
import IconPage from '../../../../assets/img/iconoir_multiple-pages-empty.svg';
import IconHelp from '../../../../assets/img/iconoir_help-circle.svg';
import IconLogout from '../../../../assets/img/iconoir_log-out.svg';

interface UserLogoProps {
    
    setShowSubMenuUser: (value: boolean) => void;
    infoToSideBar:(value: any) => void;
    logout:() => void;
    showSubMenuUser:boolean;
  }

const UserLogo = (userLogoProps: UserLogoProps) => {

    const firstName = localStorage.getItem('firstName');
    const lastName = localStorage.getItem('lastName');

    const { showMenuPrincipal, setShowMenuPrincipal } = useContext(MenuContext);

    const handleClick=()=>{

        setShowMenuPrincipal(true);
        userLogoProps.setShowSubMenuUser(!userLogoProps.showSubMenuUser);

    }

    return (
    <>
     <div style={{cursor: 'pointer'}}>
      <a onClick={() => handleClick()}>
        <div className='header-profile-icon'>
            <div className='header-background-letters'>
            <p>{firstName || lastName ? `${firstName?.substring(0, 1).toUpperCase()}${lastName?.substring(0, 1).toUpperCase()}` : 'U'}</p>
            </div>
            <img src={Circulo} className="" alt="Logged User" />
        </div>
      </a>
      {userLogoProps.showSubMenuUser && showMenuPrincipal===true &&(
        <ul className="submenuuser" onClick={() => userLogoProps.setShowSubMenuUser(false)}>
          <>
            <li key={'home'} style={{cursor: 'pointer', marginBottom: '14px'}} onClick={() => {
            userLogoProps.setShowSubMenuUser(false);
            }}>
                <Link to={'/private'} onClick={() => userLogoProps.infoToSideBar('Home')}
                    style={{fontSize: 16, textDecoration: 'unset'}}>
                    <img src={IconHome} style={{height: '28px', marginBottom: '-5px', width: 'auto', marginRight: 5, paddingTop: 5}} alt={'home'}/>
                    Mi cuenta
                </Link>
            </li>
            <li key={'session-security'} style={{cursor: 'pointer', marginBottom: '14px'}} onClick={() => {
            userLogoProps.setShowSubMenuUser(false);
            }}>
                <Link to={'/private'} onClick={() => userLogoProps.infoToSideBar('Session and Security')}
                    style={{fontSize: 16, textDecoration: 'unset'}}>
                    <img src={IconLock} style={{height: '28px', marginBottom: '-5px', width: 'auto', marginRight: 5, paddingTop: 5}} alt={'lock'}/>
                    Inicio sesión y seguridad
                </Link>
            </li>
            <li key={'purchase'} style={{cursor: 'pointer', marginBottom: '14px'}} onClick={() => {
            userLogoProps.setShowSubMenuUser(false);
            }}>
                <Link to={'/private'} onClick={() => userLogoProps.infoToSideBar('Buys and Subscriptions')}
                    style={{fontSize: 16, textDecoration: 'unset'}}>
                    <img src={IconCart} style={{height: '28px', marginBottom: '-5px', width: 'auto', marginRight: 5, paddingTop: 5}} alt={'shoppings'}/>
                    Compras y subscripciones
                </Link>
            </li>
            <li key={'plans-record'} style={{cursor: 'pointer', marginBottom: '14px'}} onClick={() => {
            userLogoProps.setShowSubMenuUser(false);
            }}>
                <Link to={'/private'} onClick={() => userLogoProps.infoToSideBar('Plans Record')}
                    style={{fontSize: 16, textDecoration: 'unset'}}>
                    <img src={IconPage} style={{height: '28px', marginBottom: '-5px', width: 'auto', marginRight: 5, paddingTop: 5}} alt={'history'}/>
                    Historial de planes 
                </Link>
            </li>
            <li key={'help'} style={{cursor: 'pointer', marginBottom: '14px'}} onClick={() => {
            userLogoProps.setShowSubMenuUser(false);
            }}>
                <Link to={'/contacto'}
                    style={{fontSize: 16, textDecoration: 'unset'}}>
                    <img src={IconHelp} style={{height: '28px', marginBottom: '-5px', width: 'auto', marginRight: 5, paddingTop: 5}} alt={'help'}/>
                    Ayuda
                </Link>
            </li>
            <li key={'loggout'} style={{cursor: 'pointer', marginBottom: '5px', paddingTop: '10px', borderTop: '1px solid #E9E9E9'}} onClick={() => {
            userLogoProps.setShowSubMenuUser(false);
            }}>
                <Link to={'/'} onClick={() => userLogoProps.logout()} style={{fontSize: 16, textDecoration: 'unset'}}>
                    <img src={IconLogout} style={{height: '28px', marginBottom: '-5px', width: 'auto', marginRight: 5, paddingTop: 5}} alt={'close session'}/>
                    Cerrar sesión
                </Link>
            </li>
          </>
        </ul>
        )}
     </div>
    </>
    )
}

export default UserLogo;

