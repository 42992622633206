import { useState, useEffect } from "react";
import { PsiPlanData } from "../../../../../models/PsiPlanData";
import {
  getPsiSessionsService,
  getPsiCoupleSessionsService,
} from "../../../../../services/getPsiSessionsService";
import "./YourProducts.css";
import Diet from "../../../../../assets/img/diet-greek-salad-in-alternative-version-for-people-2022-07-05-13-05-43-utc1.jpg";
import Icon from "../../../../../assets/img/iconoir_arrow-right.jpg";
import Psycho from "../../../../../assets/img/psicologia.png";
import LinearProgress from "@mui/material/LinearProgress";
import { PsiSuscriptionData } from "../../../../../models/PsiSuscriptionData";
import { VitalWellnessPsiData } from "../../../../../models/VitalWellnessPsiData";

interface YourProductsProps {
  handleOptionClick: (option: string) => void;
  ponUserInfoDietPlan: string | null;
  ponUserInfoPsiPlan: PsiPlanData | null;
  ponUserInfoPsiCouplePlan: PsiPlanData | null;
  ponUserInforPsiSuscriptionPlan: PsiSuscriptionData | null;
  ponUserInfoDieMesPsi: VitalWellnessPsiData | null;
}

const YourProducts = (yourProductsProps: YourProductsProps) => {
  const formatDate = (inputDate: string) => {
    const months = [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre",
    ];

    const [year, month, day] = inputDate.split("-");
    const monthName = months[parseInt(month, 10) - 1];

    return `${parseInt(day, 10)} ${monthName} ${year}`;
  };
  const [psiSessions, setPsiSessions] = useState<number | null>(null);
  const [psiAvaliableSessions, setPsiAvaliableSessions] = useState<
    number | null
  >(null);
  const [psiCoupleSessions, setPsiCoupleSessions] = useState<number | null>(
    null
  );
  const [psiCoupleAvaliableSessions, setPsiCoupleAvaliableSessions] = useState<
    number | null
  >(null);
  const [psiDateRenew, setpsiDateRenew] = useState<string | null>(null);

  useEffect(() => {
    if (yourProductsProps.ponUserInforPsiSuscriptionPlan !== null) {
      setpsiDateRenew(
        formatDate(
          yourProductsProps.ponUserInforPsiSuscriptionPlan.fProxRenovación
        )
      );
      console.log(psiDateRenew); // Log the updated value of psiDateRenew
    }
  }, [yourProductsProps.ponUserInforPsiSuscriptionPlan]);
  useEffect(() => {
    async function fetchPsiSessions() {
      const sessions = await getPsiSessionsService();
      setPsiSessions(sessions.nsesiones);
      setPsiAvaliableSessions(sessions.ndisponibles);
      console.log(sessions);
    }
    if (yourProductsProps.ponUserInfoPsiPlan !== null) {
      fetchPsiSessions();
    }
  }, [yourProductsProps.ponUserInfoPsiPlan]);

  useEffect(() => {
    async function fetchPsiCoupleSessions() {
      const coupleSessions = await getPsiCoupleSessionsService();
      setPsiCoupleSessions(coupleSessions.nsesiones);
      setPsiCoupleAvaliableSessions(coupleSessions.ndisponibles);
      console.log(coupleSessions);
    }

    if (yourProductsProps.ponUserInfoPsiCouplePlan !== null) {
      fetchPsiCoupleSessions();
    }
  }, [yourProductsProps.ponUserInfoPsiCouplePlan]);

  const handleClick = (producto: string) => {
    yourProductsProps.handleOptionClick(producto);
  };
console.log(yourProductsProps.ponUserInfoDietPlan);
  return (
    <div className="card-myproducts-main">
      {(yourProductsProps.ponUserInfoDietPlan !== null ||
        yourProductsProps.ponUserInfoPsiPlan !== null ||
        yourProductsProps.ponUserInfoPsiCouplePlan !== null ||
        yourProductsProps.ponUserInforPsiSuscriptionPlan !== null) && (
        <h1>TUS PRODUCTOS</h1>
      )}

      {yourProductsProps.ponUserInfoDietPlan !== null && (
        <div
          className="home-private-others-products-title"
          onClick={() => handleClick("Your Diet")}
          style={{ cursor: "pointer" }}
        >
          <div className="card-myproducts reduced-size-product">
            <div className="myproduct-img">
              <img src={Diet} className="" alt="MyProduct-Img" />
            </div>

            <div className="myproduct-description">
              <div className="myproduct-description-title">
                {yourProductsProps.ponUserInfoDieMesPsi !== null ? (
                  <h2> Vital Wellness Dieta</h2>
                ) : (
                  <h2> Tu plan de dieta</h2>
                )}
                <span className="myprod-link">
                  <img
                    className="icon-rigth home-private-links prod-diet"
                    src={Icon}
                    alt="icon-rigth"
                  ></img>
                </span>
              </div>
              <div className="myproduct-description-subtitle">
                {(yourProductsProps.ponUserInfoDietPlan === "mes_dev" ||
                  yourProductsProps.ponUserInfoDietPlan === "mes" ||
                  yourProductsProps.ponUserInfoDietPlan === "mes_cas" ||
                  yourProductsProps.ponUserInfoDietPlan === "par_mes" ||
                  yourProductsProps.ponUserInfoDietPlan === "par_mes_cas" || 
                  yourProductsProps.ponUserInfoDietPlan === "die_mes_psi") && (
                  <p>+Entrenamiento</p>
                )}
              </div>
              <div className="tags"></div>
            </div>
          </div>
        </div>
      )}
      {yourProductsProps.ponUserInfoPsiPlan !== null && (
        <div
          className="home-private-others-products-title"
          onClick={() => handleClick("Schedule Session")}
          style={{ cursor: "pointer" }}
        >
          <div className="card-myproducts">
            <div className="myproduct-img">
              <img src={Psycho} className="" alt="MyProduct-Img" />
            </div>
            <div className="myproduct-description">
              <div className="myproduct-description-title">
                <div className="progressbar-container">
                  <h2>Bonos psicología</h2>
                  {yourProductsProps.ponUserInfoPsiPlan && (
                    <>
                      <LinearProgress
                        className="progressbar-line"
                        variant="determinate"
                        value={
                          100 -
                          (yourProductsProps.ponUserInfoPsiPlan.ndisponibles! /
                            yourProductsProps.ponUserInfoPsiPlan.nsesiones) *
                            100
                        }
                      />
                      <p className="progressbar-text">
                        {yourProductsProps.ponUserInfoPsiPlan.ndisponibles} de{" "}
                        {yourProductsProps.ponUserInfoPsiPlan.nsesiones}{" "}
                        sesiones disponibles.
                      </p>
                    </>
                  )}
                </div>
                <span className="myprod-link">
                  <img
                    className="icon-rigth home-private-links prod-psych"
                    src={Icon}
                    alt="icon-rigth"
                  ></img>
                </span>
              </div>
              <div className="tags"></div>
            </div>
          </div>
        </div>
      )}

      {yourProductsProps.ponUserInfoDieMesPsi !== null && (
        <div
          className="home-private-others-products-title"
          onClick={() => handleClick("Schedule Session DietMesPsi")}
          style={{ cursor: "pointer" }}
        >
          <div className="card-myproducts">
            <div className="myproduct-img">
              <img src={Psycho} className="" alt="MyProduct-Img" />
            </div>
            <div className="myproduct-description">
              <div className="myproduct-description-title">
                <div className="progressbar-container">
                  <h2>Vital Wellness Psicología</h2>
                  {yourProductsProps.ponUserInfoDieMesPsi && (
                    <>
                      <LinearProgress
                        className="progressbar-line"
                        variant="determinate"
                        value={
                          100 -
                          (yourProductsProps.ponUserInfoDieMesPsi
                            .ndisponibles! /
                            yourProductsProps.ponUserInfoDieMesPsi.nsesiones) *
                            100
                        }
                      />
                      <p className="progressbar-text">
                        {yourProductsProps.ponUserInfoDieMesPsi.ndisponibles} de{" "}
                        {yourProductsProps.ponUserInfoDieMesPsi.nsesiones}{" "}
                        sesiones disponibles.
                      </p>
                    </>
                  )}
                </div>
                <span className="myprod-link">
                  <img
                    className="icon-rigth home-private-links prod-psych"
                    src={Icon}
                    alt="icon-rigth"
                  ></img>
                </span>
              </div>
              <div className="tags"></div>
            </div>
          </div>
        </div>
      )}
      {yourProductsProps.ponUserInforPsiSuscriptionPlan !== null && (
        <div
          className="home-private-others-products-title"
          onClick={() => handleClick("Schedule Session Suscription")}
          style={{ cursor: "pointer" }}
        >
          <div className="card-myproducts">
            <div className="myproduct-img">
              <img src={Psycho} className="" alt="MyProduct-Img" />
            </div>
            <div className="myproduct-description">
              <div className="myproduct-description-title">
                <div className="progressbar-container">
                  <h2>Suscripción psicología</h2>
                  {yourProductsProps.ponUserInforPsiSuscriptionPlan && (
                    <>
                      <LinearProgress
                        className="progressbar-line"
                        variant="determinate"
                        value={
                          100 -
                          (yourProductsProps.ponUserInforPsiSuscriptionPlan
                            .ndisponibles! /
                            yourProductsProps.ponUserInforPsiSuscriptionPlan
                              .nsesiones) *
                            100
                        }
                      />
                      <p className="progressbar-text">
                        {
                          yourProductsProps.ponUserInforPsiSuscriptionPlan
                            .ndisponibles
                        }{" "}
                        de{" "}
                        {
                          yourProductsProps.ponUserInforPsiSuscriptionPlan
                            .nsesiones
                        }{" "}
                        sesiones disponibles.
                      </p>
                      <p
                        className="progressbar-text text-renovation-product"
                        style={{ paddingTop: "2px" }}
                      >
                        Renovación: {psiDateRenew}
                      </p>
                    </>
                  )}
                </div>
                <span className="myprod-link">
                  <img
                    className="icon-rigth home-private-links prod-psych"
                    src={Icon}
                    alt="icon-rigth"
                  ></img>
                </span>
              </div>
              <div className="tags"></div>
            </div>
          </div>
        </div>
      )}
      {yourProductsProps.ponUserInfoPsiCouplePlan !== null && (
        <div
          className="home-private-others-products-title"
          onClick={() => handleClick("Schedule Session Couple")}
          style={{ cursor: "pointer" }}
        >
          <div className="card-myproducts reduced-size-product">
            <div className="myproduct-img">
              <img src={Psycho} className="" alt="MyProduct-Img" />
            </div>
            <div className="myproduct-description">
              <div className="myproduct-description-title">
                <div className="progressbar-container">
                  <h2>Bonos psicología en pareja</h2>
                  {yourProductsProps.ponUserInfoPsiCouplePlan && (
                    <>
                      <LinearProgress
                        className="progressbar-line"
                        variant="determinate"
                        value={
                          100 -
                          (yourProductsProps.ponUserInfoPsiCouplePlan
                            .ndisponibles! /
                            yourProductsProps.ponUserInfoPsiCouplePlan
                              .nsesiones) *
                            100
                        }
                      />
                      <p className="progressbar-text">
                        {
                          yourProductsProps.ponUserInfoPsiCouplePlan
                            .ndisponibles
                        }{" "}
                        de{" "}
                        {yourProductsProps.ponUserInfoPsiCouplePlan.nsesiones}{" "}
                        sesiones disponibles.
                      </p>
                    </>
                  )}
                </div>
                <span className="myprod-link">
                  <img
                    className="icon-rigth home-private-links prod-psych"
                    src={Icon}
                    alt="icon-rigth"
                  ></img>
                </span>
              </div>
              <div className="tags"></div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default YourProducts;
