import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  FormControl,
  FormControlLabel,
  Checkbox,
  FormGroup,
} from "@mui/material";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import Icon from "../../../../../assets/img/pesa.svg";
import "./TrainingRadioButton.css";
interface RadioGroupProps {
  options: {
    value: string;
    option_name: string;
    hideOnCheck: boolean;
    new_price_lonelly: number;
    new_price_reduced_lonelly: number;
    new_price_composed: number;
    new_price_reduced_composed: number;
  }[];
  onChange: (value: string) => void;
  defaultValue?: string;
  isChecked: boolean;
  isActiveRadioChecked: boolean;
  resetRadioSelection: boolean;
  setResetRadioSelection: (value: boolean) => void;
}

const TrainingRadioButton: React.FC<RadioGroupProps> = ({
  options,
  onChange,
  isActiveRadioChecked,
  isChecked,
  defaultValue,
  resetRadioSelection,
  setResetRadioSelection,
}) => {
  const { id } = useParams();
  const [selectedIndex, setSelectedIndex] = useState(-1);

  useEffect(() => {
    if (id === "psicologia") {
      setSelectedIndex(0);
      onChange(options[0]?.value);
    } else setSelectedIndex(-1);
  }, [id]);

  useEffect(() => {
    if (resetRadioSelection) {
      setSelectedIndex(0);
      onChange(options[0]?.value);
      setResetRadioSelection(false);
    }
  }, [resetRadioSelection]);

  useEffect(() => {
    !isActiveRadioChecked ? setSelectedIndex(-1) : setSelectedIndex(0);
    if (isActiveRadioChecked) {
      onChange(options[0]?.value);
    }
  }, [isActiveRadioChecked]);

  const handleChange =
    (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const newIndex = event.target.checked
        ? index
        : id === "psicologia"
        ? index
        : -1;

      setSelectedIndex(newIndex);
      onChange(options[newIndex]?.value);
    };

  return (
    <FormControl component="fieldset" className="training-group-button">
      <FormGroup>
        {options.map((option, index) => (
          <>
            {(index !== 2 || id === "psicologia" || !isChecked) && (
              <FormControlLabel
                className="training-option-label"
                key={index}
                value={option.value}
                control={
                  <Checkbox
                    disabled={!isActiveRadioChecked}
                    className="radio-color"
                    checked={selectedIndex === index}
                    onChange={handleChange(index)}
                    icon={<RadioButtonUncheckedIcon />}
                    checkedIcon={<RadioButtonCheckedIcon />}
                  />
                }
                
                label={
                  <>
                
                   <div className={["option5", "option6", "option7"].includes(option.value) ? "" : "psycho-bono-labels"}> {option.option_name}</div>
                  
                    {id === "psicologia" && isChecked && (
                      <>
                      <span
                          style={{
                            marginLeft: "16px",
                            color: "rgba(41, 41, 46, 0.7)",
                            fontFamily: "Montserrat SemiBold",
                          }}
                          className={["option5", "option6", "option7"].includes(option.value) ? "" : "psycho-bono-labels"}
                        >
                          {" "}
                          {option.new_price_reduced_composed}€
                        </span>
                        {(index !== 0 || isChecked) && (
                          <span
                            style={{
                              textDecoration: "line-through",
                              margin: "0 0.5rem",
                              color: "rgb(177, 178, 178)",
                              fontWeight: "600"
                            }}
                            className={["option5", "option6", "option7"].includes(option.value) ? "" : "psycho-bono-labels psycho-bono-label-reduced-prices"}
                          >
                            {" "}
                            {option.new_price_composed}€
                          </span>
                        )}
                        
                      </>
                    )}

                    {id === "psicologia" && !isChecked && (
                      <>
                        {option.value === "option5" || 
                        option.value === "option6" || 
                        option.value === "option7" ? (
                          <>
                            <span
                              style={{
                                marginLeft: "6px",
                                color: "rgba(41, 41, 46, 0.7)",
                                fontFamily: "Montserrat SemiBold",
                                fontWeight: "600"
                              }}
                              className={["option5", "option6", "option7"].includes(option.value) ? "" : "psycho-bono-labels"}
                            >
                              {option.new_price_reduced_lonelly.toString().replace(".", ",")}€
                            </span>
                            <span
                              style={{
                                margin: "0 0.5rem",
                                color: "rgba(41, 41, 46, 0.5)"
                              }}
                              className={["option5", "option6", "option7"].includes(option.value) ? "psycho-bono-label-reduced-price GYM" : "GYM"}
                            >
                              {option.new_price_lonelly}€ por sesión
                            </span>
                          </>
                        ) : (
                          <>
                          <span
                              style={{
                                marginLeft: "16px",
                                color: "rgba(41, 41, 46, 0.7)",
                                fontFamily: "Montserrat SemiBold",
                              }}
                              className={["option5", "option6", "option7"].includes(option.value) ? "" : "psycho-bono-labels"}

                            >
                              {option.new_price_reduced_lonelly}€
                            </span>
                            {index !== 0 && (
                              <span
                                style={{
                                  textDecoration: "line-through",
                                  margin: "0 0 0 0.7rem",
                                  color: "rgba(177, 178, 178, 1)"
                                }}
                                className={["option5", "option6", "option7"].includes(option.value) ? "psycho-bono-label-reduced-price GYM" : "GYM"}                                
                              >
                                {option.new_price_lonelly}€
                              </span>
                            )}
                             
                          </>
                        )}
                      </>
                    )}
                    {index === 2 && id !== "psicologia" && (
                      <img src={Icon} alt="icon" />
                    )}
                  </>
                }
              />
            )}
          </>
        ))}
      </FormGroup>
    </FormControl>
  );
};

export default TrainingRadioButton;
