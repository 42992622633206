import * as React from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { ProductDetailData } from '../../../../models/ProductDetailData';
import IconQAccordionDiet1 from '../../../../assets/img/iconAccordion/IconaccordionDiet1.svg';
import IconQAccordionDiet2 from '../../../../assets/img/iconAccordion/IconaccordionDiet2.svg';
import IconQAccordionDiet3 from '../../../../assets/img/iconAccordion/IconaccordionDiet3.svg';
import IconQAccordionDiet4 from '../../../../assets/img/iconAccordion/IconaccordionDiet4.svg';
import IconQAccordionDiet5 from '../../../../assets/img/iconAccordion/IconaccordionDiet5.svg';
import IconQAccordionDiet6 from '../../../../assets/img/iconAccordion/IconaccordionDiet6.svg';
import IconQAccordionPsycho1 from '../../../../assets/img/iconAccordion/IconAccordioPsycho.svg';
import IconQAccordionPsycho2 from '../../../../assets/img/iconAccordion/IconAccordionPsycho2.svg';
import IconQAccordionPsycho3 from '../../../../assets/img/iconAccordion/IconAccordionPsycho3.svg';
import IconQAccordionPsycho4 from '../../../../assets/img/iconAccordion/IconAccordioPsycho4.svg';
import IconQAccordionPsycho5 from '../../../../assets/img/iconAccordion/IconAccordionPsycho5.svg';
import IconQAccordionPsycho6 from '../../../../assets/img/iconAccordion/IconAccordionPsycho6.svg';
import './DescriptionAccordion.css';
import parse from 'html-react-parser';
import { useParams } from 'react-router-dom';

interface DescriptionAccordionProps {
  product: ProductDetailData;
  selectedPosition: number;
}

const DescriptionAccordion = (componentProps: DescriptionAccordionProps) => {
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const [noneExpanded, setNoneExpanded] = React.useState<boolean>(true);
  const accordionIdPrefix = 'accordion';
  const { id } = useParams();
  const iconArray = [
    IconQAccordionDiet1,
    IconQAccordionDiet2,
    IconQAccordionDiet3,
    IconQAccordionDiet4,
    IconQAccordionDiet5,
    IconQAccordionDiet6,
  ];
  const iconArrayPsychology = [
    IconQAccordionPsycho1,
    IconQAccordionPsycho3,
    IconQAccordionPsycho2,
    IconQAccordionPsycho4,
    IconQAccordionPsycho5,
    IconQAccordionPsycho6,
    IconQAccordionDiet6,
  ];

  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setNoneExpanded(!isExpanded);
    setExpanded(isExpanded ? panel : false);
  };

  React.useEffect(() => {
    if (componentProps.selectedPosition !== -1) {
      setExpanded(`${componentProps.selectedPosition}`);
      setNoneExpanded(false);
    }
  }, [componentProps.selectedPosition]);

  return (
    <div>
      {id === 'psicologia' ? (
        componentProps.product.detailed_explanation.map((option, index) => (
          <Accordion
            key={index}
            className="accordion-description-panel"
            expanded={expanded === `${index}`}
            id={`${accordionIdPrefix}${index}`}
            onChange={handleChange(`${index}`)}
          >
            <AccordionSummary
              className="title-accordion"
              expandIcon={expanded === `${index}` ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              {noneExpanded && (
                <>
                  <img src={iconArrayPsychology[index]} alt="IconAccordion" style={{ color: '#29292E', opacity: 1 }} />
                  <Typography style={{ color: '#29292E' }} sx={{ color: '#29292E' }} variant="h6">
                  <span dangerouslySetInnerHTML={{ __html: option.item_name }} />
                  </Typography>
                </>
              )}
              {!noneExpanded && (
                <>
                  <img src={iconArrayPsychology[index]} alt="IconAccordion" />
                  <Typography sx={{ color: 'text.secondary' }} variant="h6">
                  <span dangerouslySetInnerHTML={{ __html: option.item_name }} />
                  </Typography>
                </>
              )}
            </AccordionSummary>
            <AccordionDetails className="description-accordion">
              {parse(componentProps.product.detailed_explanation[index].item_description)}
            </AccordionDetails>
          </Accordion>
        ))
      ) : (
        componentProps.product.detailed_explanation.map((option, index) => (
          <Accordion
            key={index}
            className="accordion-description-panel"
            expanded={expanded === `${index}`}
            id={`${accordionIdPrefix}${index}`}
            onChange={handleChange(`${index}`)}
          >
            <AccordionSummary
              className="title-accordion"
              expandIcon={expanded === `${index}` ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              {noneExpanded && (
                <>
                  <img src={iconArray[index]} alt="IconAccordion" style={{ color: '#29292E', opacity: 1 }} />
                  <Typography style={{ color: '#29292E' }} sx={{ color: '#29292E' }} variant="h6">
                    {option.item_name}
                  </Typography>
                </>
              )}
              {!noneExpanded && (
                <>
                  <img src={iconArray[index]} alt="IconAccordion" />
                  <Typography sx={{ color: 'text.secondary' }} variant="h6">
                    {option.item_name}
                  </Typography>
                </>
              )}
            </AccordionSummary>
            <AccordionDetails className="description-accordion">
              {parse(componentProps.product.detailed_explanation[index].item_description)}
            </AccordionDetails>
          </Accordion>
        ))
      )}
    </div>
  );
};

export default DescriptionAccordion;
