import { useState, useEffect, RefObject } from "react";

import { Select, MenuItem, IconButton, SelectChangeEvent } from '@mui/material';
import { FormControlLabel, RadioGroup } from '@mui/material';
import {StyledRadio,StyledSelectRadioUncheked,StyledSelectRadioCheked} from "../formComponents/StyledRadio";


import { FeedingData } from '../../../../../models/formsModels/FeedingData';
import nextArrow from './../../../../../assets/img/arrow-right-form.svg';
import nextArrowGrey from './../../../../../assets/img/arrow-right-form-grey.svg';
import previousArrow from './../../../../../assets/img/arrow-left-form.svg';
import './FeedingDataBuySubscriptions.css';

interface FeedingDataProps {
    setNext: (value: any) => void;
    feedingData: FeedingData;
    setFeedingData: (value: any) => void;
    feeding:number;
    setFeeding: (value: any) => void;
    miComponenteRef:RefObject<HTMLDivElement>;
  }

const FeedingDataBuySubscriptions = (feedingDataProps:FeedingDataProps) => {

    const [validationPassOne, setValidationPassOne] = useState(false);

    const [feedingDataValidation, setFeedingDataValidation] = useState<FeedingData>({

      aresidencia:'',
      asuper:'',
      aenfermedades:'',
      aalergias:'',
      aaversion:'',
      galimentos:'',
      acocinar:'',
      tiempoc:'',
      momentodia:'',
      sencilloelaborado:'',
      asuplementacion:'',
      asuplementacion_actual:'',
      asuplementacioni:'',
      gcomidas:'',
      avegetariano:'',
      aveghuevos:'',
      aveglacteos:'',
      avegpescado:'',
      avegmarisco:'',
      apicar:'',
      qpicas:'',
      detalles_plan:'',
      cnewsletter:false,
      cprivacidad:true
    });

    useEffect(() => {
      feedingDataProps.miComponenteRef.current?.scrollIntoView({block: "start",behavior: 'smooth'});
    },[]);

    useEffect(() => {
      CheckIsValid();
    },[feedingDataValidation]);

    const CheckIsValid = () => {
      const isValid = (
        (feedingDataProps.feedingData.asuplementacion !== '')&&
        (feedingDataProps.feedingData.gcomidas !== '')&&
        (feedingDataProps.feedingData.avegetariano !== '')
      );
      setValidationPassOne(isValid);
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      const { value,name } = event.target;
      feedingDataProps.setFeedingData((prevFormData: FeedingData) => ({ ...prevFormData, [name]: value }));
      setFeedingDataValidation((prevFormData: FeedingData) => ({ ...prevFormData, [name]: value }));
    };

    const handleInputChangeSelect = (event: SelectChangeEvent<string>)=>{
      const { value,name } = event.target;
      feedingDataProps.setFeedingData((prevFormData: FeedingData) => ({ ...prevFormData, [name]: value }));
      setFeedingDataValidation((prevFormData: FeedingData) => ({ ...prevFormData, [name]: value }));   
    }

    return(
     <>
        {feedingDataProps.feeding===1 && (
        <>
        <div className="name-fields feeding-data-residence">
          <div className="fam-groups">
             <label htmlFor="residenciaCliente">Lugar de residencia</label>
          <input 
          type="text" 
          name="aresidencia" 
          id="residenciaCliente" 
          className="feeding-data-residence-input"
          defaultValue={feedingDataProps.feedingData.aresidencia}
          onChange={handleInputChange}
          maxLength={120}
          style={{marginBottom:"10px"}}
          />
          </div>
          <div className="fam-groups">
            <label htmlFor="supermercadoCliente">Supermercado que frecuentas</label>
          <input 
          type="text" 
          name="asuper" 
          id="supermercadoCliente" 
          className="feeding-data-residence-input"
          defaultValue={feedingDataProps.feedingData.asuper}
          onChange={handleInputChange}
          maxLength={120}
          style={{marginBottom:"10px"}}
          />
          </div>
        </div>
         
          
          <label className="feeding-data-label" htmlFor="enfermedadesCliente">Enfermedades y/o lesiones</label>
          <input 
          type="text" 
          name="aenfermedades" 
          id="enfermedadesCliente" 
          className="feeding-data-residence-input"
          placeholder="Max 150 palabras" 
          defaultValue={feedingDataProps.feedingData.aenfermedades}
          onChange={handleInputChange}
          />
          <label className="feeding-data-label" htmlFor="alergiasCliente">Alergias o intolerancias alimentarias</label>
          <input 
          type="text" 
          name="aalergias" 
          id="alergiasCliente" 
          className="feeding-data-residence-input"
          placeholder="Max 150 palabras" 
          defaultValue={feedingDataProps.feedingData.aalergias}
          onChange={handleInputChange}
          />
          <label className="feeding-data-label" htmlFor="aversionCliente">¿Tiene aversión a algún alimento?</label>
          <input 
          type="text" 
          name="aaversion" 
          id="aversionCliente" 
          className="feeding-data-residence-input"
          defaultValue={feedingDataProps.feedingData.aaversion}
          onChange={handleInputChange}
          />
          <label className="feeding-data-label" htmlFor="alimentosCliente">¿Qué alimentos te gustan y quisieras incluir en tu plan en caso de que fuera posible?</label>
          <input 
          type="text" 
          name="galimentos" 
          id="alimentosCliente" 
          className="feeding-data-residence-input"
          defaultValue={feedingDataProps.feedingData.galimentos}
          onChange={handleInputChange}
          maxLength={250}
          />
          <p className="cook-p">¿Te gusta cocinar?</p>
          <div className="name-fields cooking-fields">
            <div className="cook-option">
              <RadioGroup name="acocinar" className="materialui-fields-check cocinar-check">
                <FormControlLabel className="materialui-fields-label" control={
                  <StyledRadio 
                    id="cocinarSi" 
                    value="1"
                    checked={feedingDataProps.feedingData.acocinar==="1"}
                    onChange={handleInputChange}
                  />} label="Si" />
                <FormControlLabel className="materialui-fields-label" control={
                  <StyledRadio 
                    id="cocinarNo" 
                    value="0"
                    checked={feedingDataProps.feedingData.acocinar==="0"}
                    onChange={handleInputChange}
                  />} label="No" />
              </RadioGroup>
            </div>
          </div>
          
         

          <label className="feeding-data-label" htmlFor="tiempoCocinar">¿Cuanto tiempo dispones para preparar tus comidas cada día?</label>
          <Select 
          name="tiempoc" 
          id="tiempoCocinar"
          className="buySubscriptionsSelect materialui-fields-select"
          value={feedingDataProps.feedingData.tiempoc}
          onChange={handleInputChangeSelect} 
          >
            <MenuItem value="" className="materialui-fields-option" hidden></MenuItem>
            <MenuItem value="30m" className="materialui-fields-option">
              <IconButton className='materialui-fields-select-button'>
                  {feedingDataProps.feedingData.tiempoc === '30m' ? <StyledSelectRadioCheked /> : <StyledSelectRadioUncheked />}
              </IconButton>
              30 minutos
            </MenuItem>
            <MenuItem value="1h" className="materialui-fields-option">
              <IconButton className='materialui-fields-select-button'>
                  {feedingDataProps.feedingData.tiempoc === '1h' ? <StyledSelectRadioCheked /> : <StyledSelectRadioUncheked />}
              </IconButton>
              1 hora
            </MenuItem>
            <MenuItem value="1:30" className="materialui-fields-option">
              <IconButton className='materialui-fields-select-button'>
                  {feedingDataProps.feedingData.tiempoc === '1:30' ? <StyledSelectRadioCheked /> : <StyledSelectRadioUncheked />}
              </IconButton>
              1 hora y media
            </MenuItem>
            <MenuItem value="Preparado anteriormente" className="materialui-fields-option">
              <IconButton className='materialui-fields-select-button'>
                  {feedingDataProps.feedingData.tiempoc === 'Preparado anteriormente' ? <StyledSelectRadioCheked /> : <StyledSelectRadioUncheked />}
              </IconButton>
              Lo dejo preparado con anterioridad
            </MenuItem>
          </Select>

          <label className="feeding-data-label" htmlFor="apetitoCliente">En qué momento del día te sientes con más apetito</label>
          <input 
          type="text" 
          name="momentodia" 
          id="apetitoCliente" 
          className="feeding-data-residence-input"
          defaultValue={feedingDataProps.feedingData.momentodia}
          onChange={handleInputChange}
          maxLength={250}
          />

          <label className="feeding-data-label" htmlFor="preparacionesCliente">¿Prefieres preparaciones sencillas o más complejas?</label>
          <input 
          type="text" 
          name="sencilloelaborado" 
          id="preparacionesCliente" 
          className="feeding-data-residence-input"
          defaultValue={feedingDataProps.feedingData.sencilloelaborado}
          onChange={handleInputChange}
          maxLength={250}
          />
          <div className="page-manager">
            <span className="current">1</span><span className="not-current"></span>
            <button onClick={()=>{
              feedingDataProps.setFeeding(2);
              feedingDataProps.miComponenteRef.current?.scrollIntoView({block: "start",behavior: 'smooth'});
            }}><img src={nextArrow} alt={'next'} /></button>
          </div> 
                   <hr />

        </>
        )}
        {feedingDataProps.feeding===2 && (
        <>
          <p className="feeding-data-p"> *¿Tomas suplementación?</p>
          <div>
            <RadioGroup name="asuplementacion" className="materialui-fields-check">
                <FormControlLabel className="materialui-fields-label" control={
                  <StyledRadio 
                    id="suplementacionSi" 
                    value="1"
                    checked={feedingDataProps.feedingData.asuplementacion==="1"} 
                    onChange={handleInputChange}
                    required
                  />} label="Si" />
                <FormControlLabel className="materialui-fields-label" control={
                  <StyledRadio 
                    id="suplementacionNo" 
                    value="0"
                    checked={feedingDataProps.feedingData.asuplementacion==="0"} 
                    onChange={handleInputChange}
                    required
                  />} label="No" />
            </RadioGroup>
          </div>
          { feedingDataProps.feedingData.asuplementacion==="1" && (
            <div className="optional-sp">
              <label htmlFor="asuplementacion-actual">Indícanos que suplementación tomas actualmente</label>
              <br></br><input 
              placeholder="Max 150 palabras."
              type="text" 
              name="asuplementacion_actual" 
              id="asuplementacion-actual" 
              defaultValue={feedingDataProps.feedingData.asuplementacion_actual}
              onChange={handleInputChange}
              style={{margin:"10px 5px", width:"98%", color: feedingDataProps.feedingData.asuplementacion_actual!==''?"#29292E":"#B1B2B2"}}
              />
            </div>
          )}
          <p className="feeding-data-p">Si no tomas ¿Tienes inconveniente en tomar proteina?</p>
          <div>
            <RadioGroup name="asuplementacioni" className="materialui-fields-check">
                <FormControlLabel className="materialui-fields-label" control={
                  <StyledRadio 
                    id="asuplementacioniSi" 
                    value="1"
                    checked={feedingDataProps.feedingData.asuplementacioni==="1"}
                    onChange={handleInputChange}
                  />} label="Si" />
                <FormControlLabel className="materialui-fields-label" control={
                  <StyledRadio 
                    id="asuplementacioniNo" 
                    value="0"
                    checked={feedingDataProps.feedingData.asuplementacioni==="0"}
                    onChange={handleInputChange}
                  />} label="No" />
              </RadioGroup>
          </div>
          <p className="feeding-data-p">*¿Cuantas comidas te gustaría tener al día en tu plan? (recomendamos 5 al día)</p>
          <div>
            <RadioGroup name="gcomidas" className="materialui-fields-check">
                <FormControlLabel className="materialui-fields-label" control={
                  <StyledRadio 
                    id="gcomidas3" 
                    value="3"
                    checked={feedingDataProps.feedingData.gcomidas==="3"}
                    onChange={handleInputChange}
                    required
                  />} label="3" />
                <FormControlLabel className="materialui-fields-label" control={
                  <StyledRadio 
                    id="gcomidas4" 
                    value="4"
                    checked={feedingDataProps.feedingData.gcomidas==="4"}
                    onChange={handleInputChange}
                    required
                  />} label="4" />  
                <FormControlLabel className="materialui-fields-label" control={
                  <StyledRadio 
                    id="gcomidas5" 
                    value="5"
                    checked={feedingDataProps.feedingData.gcomidas==="5"}
                    onChange={handleInputChange}
                    required
                  />} label="5" />
            </RadioGroup>
          </div>
          <p className="feeding-data-p">*¿Eres vegetariano?</p>
          <div>
            <RadioGroup name="avegetariano" className="materialui-fields-check">
                <FormControlLabel className="materialui-fields-label" control={
                  <StyledRadio 
                    id="vegetarianoSi" 
                    value="1"
                    checked={feedingDataProps.feedingData.avegetariano==="1"}
                    onChange={handleInputChange}
                    required
                  />} label="Si" />
                <FormControlLabel className="materialui-fields-label" control={
                  <StyledRadio 
                    id="vegetarianoNo" 
                    value="0"
                    checked={feedingDataProps.feedingData.avegetariano==="0"}
                    onChange={handleInputChange}
                    required
                  />} label="No" />
            </RadioGroup>
          </div>
          { feedingDataProps.feedingData.avegetariano==="1" && (
          <div className="optional-sp">
            <p className="feeding-data-p">¿Qué alimentos puedes tomar?:</p>
            <div className="foods-optional">

              <div>
              <p className="feeding-data-p-optional">Huevos y derivados</p>
              <RadioGroup name="aveghuevos" className="materialui-fields-check">
                <FormControlLabel className="materialui-fields-label" control={
                  <StyledRadio 
                    id="aveghuevosSi" 
                    value="1"
                    checked={feedingDataProps.feedingData.aveghuevos==="1"}
                    onChange={handleInputChange}
                  />} label="Si" />
                <FormControlLabel className="materialui-fields-label" control={
                  <StyledRadio 
                    id="aveghuevosNo" 
                    value="0"
                    checked={feedingDataProps.feedingData.aveghuevos==="0"}
                    onChange={handleInputChange}
                  />} label="No" />
              </RadioGroup>
            </div>
            <div>
              <p className="feeding-data-p-optional">Lacteos y derivados</p>
              <RadioGroup name="aveglacteos" className="materialui-fields-check">
                <FormControlLabel className="materialui-fields-label" control={
                  <StyledRadio 
                    id="aveglacteosSi" 
                    value="1"
                    checked={feedingDataProps.feedingData.aveglacteos==="1"}
                    onChange={handleInputChange}
                  />} label="Si" />
                <FormControlLabel className="materialui-fields-label" control={
                  <StyledRadio 
                    id="aveglacteosNo" 
                    value="0"
                    checked={feedingDataProps.feedingData.aveglacteos==="0"}
                    onChange={handleInputChange}
                  />} label="No" />
              </RadioGroup>
            </div>
            <div>
              <p className="feeding-data-p-optional">Pescado</p>
              <RadioGroup name="avegpescado" className="materialui-fields-check">
                <FormControlLabel className="materialui-fields-label" control={
                  <StyledRadio 
                    id="avegpescadoSi" 
                    value="1"
                    checked={feedingDataProps.feedingData.avegpescado==="1"}
                    onChange={handleInputChange}
                  />} label="Si" />
                <FormControlLabel className="materialui-fields-label" control={
                  <StyledRadio 
                    id="avegpescadoNo" 
                    value="0"
                    checked={feedingDataProps.feedingData.avegpescado==="0"}
                    onChange={handleInputChange}
                  />} label="No" />
              </RadioGroup>
            </div>
            <div>
              <p className="feeding-data-p-optional">Marisco</p>
              <RadioGroup name="avegmarisco" className="materialui-fields-check">
                <FormControlLabel className="materialui-fields-label" control={
                  <StyledRadio 
                    id="avegmariscoSi" 
                    value="1"
                    checked={feedingDataProps.feedingData.avegmarisco==="1"}
                    onChange={handleInputChange}
                  />} label="Si" />
                <FormControlLabel className="materialui-fields-label" control={
                  <StyledRadio 
                    id="avegmariscoNo" 
                    value="0"
                    checked={feedingDataProps.feedingData.avegmarisco==="0"}
                    onChange={handleInputChange}
                  />} label="No" />
              </RadioGroup>
            </div>
          </div>
            
          </div>
          )}
          <p className="feeding-data-p">¿Picas entre horas?</p>
          <div>
            <RadioGroup name="apicar" className="materialui-fields-check">
              <FormControlLabel className="materialui-fields-label" control={
                <StyledRadio 
                  id="picarSi" 
                  value="1"
                  checked={feedingDataProps.feedingData.apicar==="1"}
                  onChange={handleInputChange}
              />} label="Si" />
              <FormControlLabel className="materialui-fields-label" control={
                <StyledRadio 
                  id="picarNo" 
                  value="0"
                  checked={feedingDataProps.feedingData.apicar==="0"}
                  onChange={handleInputChange}
              />} label="No" />
            </RadioGroup>
          </div>
          {feedingDataProps.feedingData.apicar==="1" && (
            <div className="optional-sp">
              <label htmlFor="picas">¿Qué sueles comer cuando picas?</label>
              <input 
              type="text" 
              name="qpicas" 
              id="picas" 
              defaultValue={feedingDataProps.feedingData.qpicas}
              onChange={handleInputChange}
              style={{margin:"10px 5px", width:"98%", color:"#29292E"}}
              maxLength={250}
              />
            </div>
          )}
          <label htmlFor="detalles_plan" className="feeding-data-p">Cuéntanos algún detalle más que necesitemos saber para hacer tu plan (Ej: Horarios no comunes)</label>
          <input 
          type="text" 
          name="detalles_plan" 
          id="detalles_plan" 
          defaultValue={feedingDataProps.feedingData.detalles_plan}
          onChange={handleInputChange}
          />

          <div className="page-manager">
          <button onClick={()=>{
            feedingDataProps.setFeeding(1);
            feedingDataProps.setNext(3);
            feedingDataProps.miComponenteRef.current?.scrollIntoView({block: "start",behavior: 'smooth'});
          }}><img src={previousArrow} alt={'previous'} /></button>
              <span className="current-done">1</span><span className="current">2</span>
          <button disabled={!validationPassOne} onClick={()=>{
            feedingDataProps.setFeeding(3);
            feedingDataProps.miComponenteRef.current?.scrollIntoView({block: "start",behavior: 'smooth'});
          }}>{!validationPassOne?<img src={nextArrowGrey} alt={'next'} />:<img src={nextArrow} alt={'next'} />}</button>
            </div>
            <hr />
        </>
        )}
     </>
    );
}

export default FeedingDataBuySubscriptions;