import React,{ useState } from "react";
import Avatar from "../../../../../assets/img/chat/Avatar.png";
import is_couple from '../../../../../assets/img/is_couple2.png';
import Info from "../../../../../assets/img/info-icon-form.svg";
import IconCancel from "../../../../../assets/img/iconoir_cancel.png";
import ArrowText from "../../../../../assets/img/more_text.png";
import "./calendar.css";
import { Dayjs } from "dayjs";
import { IAppointmentEmployee } from "../../../../../services/getDietCalendarService";

interface ComponentProps {
    start_dayjs: Dayjs;
    end_dayjs: Dayjs;
    instruc:string,
    plan:string,
    type_calendar:string,
    specialist: string|null;
    fullText:boolean;
    couple:boolean;
  }

const Cites = ({
    start_dayjs,
    end_dayjs,
    instruc,
    plan,
    type_calendar,
    specialist,
    fullText,
    couple,
}: ComponentProps) => {

    const [showFullText, setShowFullText] = useState(fullText);
    const [duration, setDuration] = useState(0);

    React.useEffect(() => {
      const startMinutes = convertirAMinutos(start_dayjs.format('HH:mm'));
      const endMinutes = convertirAMinutos(end_dayjs.format('HH:mm'));
      setDuration(endMinutes - startMinutes)
    }, []);  

    const createMarkup = (instruc:string) => {
      return { __html: instruc };
    };

    const convertirAMinutos = (time:string) => {
      const [hours, minutes] = time.split(':').map(Number);
      return hours * 60 + minutes;
    };
  
    

    return (
        <div className="dates_container">
        <div
          className={"card_calendar card-mi-cita"}
        >
        {couple?
        <div className="text_dates_calendar">
          <div>
            <img className="avatar_calendar" src={Avatar} alt="" />
          </div>
          <div className="info_dates">
            <span>
              <b>Fecha:</b> {start_dayjs.format('DD/MM/YYYY')} (
              {start_dayjs.format('HH:mm')} a {end_dayjs.format('HH:mm')} hora peninsular española)
            </span>
            <br />
            <span>
              <b>Especialistas:</b> {specialist}
            </span>
            <br />
            <span>
              <b>Producto:</b> {plan}
            </span>
            <br />
            <span>
              <b>Enlace:</b> <span className="mi-cita-instruc-link" dangerouslySetInnerHTML={createMarkup(instruc)} />
              
            </span>
            <br />
          </div>
          <div className="couple-icon-container">
            <img src={is_couple} alt="plan_en_pareja" className="couple-icon"/>
          </div>
        </div>        
        :
        <div className="text_dates_calendar">
          <div>
            <img className="avatar_calendar" src={Avatar} alt="" />
          </div>
          <div className="info_dates">
            <span>
              <b>Fecha:</b> {start_dayjs.format('DD/MM/YYYY')} (
              {start_dayjs.format('HH:mm')} a {end_dayjs.format('HH:mm')} hora peninsular española)
            </span>
            <br />
            <span>
              <b>Especialista:</b> {specialist}
            </span>
            <br />
            <span>
              <b>Producto:</b> {plan}
            </span>
            <br />
            <span>
              <b>Enlace:</b> <span className="mi-cita-instruc-link" dangerouslySetInnerHTML={createMarkup(instruc)} />
              
            </span>
            <br />
          </div>
        </div>
          
        }

        </div>
        <div className="text_dates">
          <div className="text_dates_div">
            <img
              src={Info}
              alt=""
              style={{ height: "12px", width: "12px", marginRight: "8px" }}
            />
            <div>
              <div>
                {!showFullText && (
                  <>
                    <p>
                      El especialista te estará esperando a la hora de la cita. Te aconsejamos revisar previamente que puedes acceder y que tu micrófono y cámara funcionan...
                    </p>
                    <div className="more_text">
                      <p onClick={() => setShowFullText(true)}>
                        {" "}
                        <u>Leer más </u>
                        <img
                          onClick={() => setShowFullText(true)}
                          className="arrow_text"
                          src={ArrowText}
                          alt="arrow"
                        />
                      </p>
                    </div>
                  </>
                )}
              </div>
              {showFullText ? (
                <>
                  {type_calendar ==="diet" &&
                  <>
                    <p>
                      El especialista te estará esperando a la hora de la cita. Te aconsejamos revisar previamente que puedes acceder y que tu micrófono y cámara funcionan. Recuerda que son {duration} minutos y que deberás ser puntual, de lo contrario perderás tu videollamada.
                    </p>
                    <p>
                      Recuerda que dispones de <b>5 días</b> a partir de la fecha de compra para gestionar tu videollamada.
                    </p>
                  </>
                  }
                  {type_calendar !=="diet" &&
                    <p>
                      El especialista te estará esperando a la hora de la cita. Te aconsejamos revisar previamente que puedes acceder y que tu micrófono y cámara funcionan.
                    </p>
                  }
                  <p>
                    Si te surge algún problema y no puedes realizar o acceder a la videollamada, ponte en contacto rápidamente con el quipo de atención al cliente{" "}
                    <a
                      href="mailto:attcliente@vikika.es"
                      style={{ color: "blue" }}
                    >
                      attcliente@vikika.es
                    </a>
                  </p>
                  <p>
                    Recuerda que sólo podrás cambiar de fecha o hora tu videollamada si avisas 24h antes.
                  </p>
                  <div className={"text_dates_img_container"}>
                    <img
                      alt="cancel"
                      src={IconCancel}
                      style={{ cursor: "pointer" }}
                      className="cancel_calendar"
                      onClick={() => setShowFullText(false)}
                    />
                  </div>
                </>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    );
};

export default Cites;