import Slider, { Settings } from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './TestimonialsTypeSlider.css';
import parse from "html-react-parser";
import { TestimonialType } from "../../../../models/TestimonialType";
import { CATEGORY_DIETA } from "../../../../utils/constants";
import { DietNextArrow, DietPrevArrow, NextArrow, PrevArrow } from "../../../shared/arrows/Arrows";

interface TestimonialsProps {
    testimonials: TestimonialType[];
    selectedCategory: string;
}

const TestimonialsTypeSlider = (props: TestimonialsProps) => {
    const { testimonials } = props;
    const settings: Settings = {
        dots: true,
        dotsClass: "slidertestimonials-dots",
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        arrows: true,
        nextArrow: props.selectedCategory === CATEGORY_DIETA ? (
                        <DietNextArrow />
                    ) : (
                        <NextArrow />
                    ),
        prevArrow: props.selectedCategory === CATEGORY_DIETA ? (
                      <DietPrevArrow />
                    ) : (
                      <PrevArrow />
                    ),
        customPaging: (i) => <div className="custom-dot-testimonios"></div>,

    };

    const getTestimonialContainerClassName = (testimonialCategory: string) => {
        if (testimonialCategory === CATEGORY_DIETA) {
            return "testimonial-container-diet";
        }
        return "testimonial-container-psico";
    };

    const getTestimonialContainerTitle = (testimonialCategory: string) => {
        if (testimonialCategory === CATEGORY_DIETA) {
            return "Evolución Plan de Dieta";
        }
        return "Sesiones de Psicología";
    };

    const getSubtitle = (testimonial: TestimonialType) => {
        if (testimonial.category === CATEGORY_DIETA) {
            return (
                <span className="subtitle">
                    <b style={{ fontFamily: 'Montserrat SemiBold' }}> Tiempo: </b>
                    <span> {testimonial.duration_in_months} MESES </span>
                    <span className='subtitle-change'>
                        <b style={{ fontFamily: 'Montserrat SemiBold' }}> Antes: </b>
                        <span> {testimonial.previous_weight} | </span>
                        <b style={{ fontFamily: 'Montserrat SemiBold' }}> Ahora: </b>
                        <span> {testimonial.current_weight} </span>
                    </span>
                </span>
            );
        }
        return (
            <span className="subtitle">
                <b style={{ fontFamily: 'Montserrat SemiBold' }}> Psicóloga: </b>
                <span> {testimonial.psychologist} </span>
                <span className='subtitle-change'>
                    <b style={{ fontFamily: 'Montserrat SemiBold' }}> Número de sesiones: </b>
                    <span> {testimonial.number_of_sessions} </span>
                </span>
                <span className='subtitle-change subtitle-change-goDown'>
                    <br className={'br-subtitile-change'} />
                    <b style={{ fontFamily: 'Montserrat SemiBold' }}> Tipo de sesión: </b>
                    <span> {testimonial.type_of_sessions} </span>
                </span>
            </span>
        );
    }

    return (
        <Slider {...settings} >
            {
                testimonials.map((testimonial, index) =>
                    (
                        <div className={getTestimonialContainerClassName(testimonial.category)} key={index}>
                            <div className="testimonial-img">
                                <img src={testimonial.image} className="image-post" alt='1' />
                            </div>
                            <div className="description-container">
                                <h1>{getTestimonialContainerTitle(testimonial.category)}</h1>
                                <h3><b style={{ fontFamily: 'Montserrat SemiBold' }}>{testimonial.title}</b></h3>
                                <h3>
                                    {getSubtitle(testimonial)}
                                </h3>
                                {parse(testimonial.description.substring(0, 200) + ('...'))}
                            </div>
                        </div>
                    )
                )
            }
        </Slider>
    )
}

export default TestimonialsTypeSlider

