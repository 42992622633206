import { useState, useEffect, RefObject } from "react";

import { ProgressData } from "../../../../../models/formsModels/RenewProgressData";

import nextArrow from '../../../../../assets/img/arrow-right-form.svg';
import nextArrowGrey from '../../../../../assets/img/arrow-right-form-grey.svg';
import './ProgressDataRenewSubscriptions.css';

interface ProgressDataProps {
  progressData: ProgressData;
  setProgressData: (value: any) => void;
  setNext: (value: any) => void;
  typePlan: string;
  miComponenteRef:RefObject<HTMLDivElement>;
}

const ProgressDataRenewSubscriptions = (progressDataProps: ProgressDataProps) => {

    const [validationPassOne, setValidationPassOne] = useState(false);

    const trainingPlans=["mes","mes_dev","mes_cas","par_mes_cas","ent_cas","ent_mes","ent_mes_r","mes_r"];

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { value,name } = event.target;
        progressDataProps.setProgressData((prevFormData: ProgressData) => ({ ...prevFormData, [name]: value }));
        setProgressDataValidation((prevFormData: ProgressData) => ({ ...prevFormData, [name]: value }));
    };

    const [progressDataValidation, setProgressDataValidation] = useState<ProgressData>({
      dppeso: '',
      cambiosvolumen: '',
      dietacumplido: '',
      dietaporcentaje:'',
      dietahambre:'',
      entrenamientocumplido:'',
      entrenamientoporcentaje:'',
      entrenamientomejora:'',
      entrenamientonotas:'',
    });

   const CheckIsValid = () => {

        var trainingIsValid=true;

        if(trainingPlans.includes(progressDataProps.typePlan)){
            trainingIsValid=(
                (progressDataProps.progressData.entrenamientocumplido !== '') &&
                (progressDataProps.progressData.entrenamientonotas !== '') 
            )
        }
        const isValid = (          
          (progressDataProps.progressData.dppeso !== '') &&
          (progressDataProps.progressData.cambiosvolumen !== '') &&
          (progressDataProps.progressData.dietacumplido !== '') &&
          trainingIsValid
        )
        setValidationPassOne(isValid);
      }

      useEffect(() => {
        progressDataProps.miComponenteRef.current?.scrollIntoView({block: "start",behavior: 'smooth'});
      },[]);
  
      useEffect(() => {
        CheckIsValid();
      },[progressDataValidation]);

    return (
      <>
        <div className="progress-data-activity">
            <h4>¡Hola! Necesitamos que completes este cuestionario para conocer tus progresos</h4>
            <label htmlFor="personaldatapeso" className="data-label">*Peso</label><br></br>
            <input 
            type="text" 
            name="dppeso" 
            id="personaldatapeso" 
            placeholder="En kg" 
            className=''
            value={progressDataProps.progressData.dppeso}
            onChange={handleInputChange}
            required
            /><br></br>

            <label htmlFor="cambiosvolumenCliente" className="data-label">*¿Qué cambios has notado de volumen?</label><br></br>
            <input 
            type="text" 
            name="cambiosvolumen" 
            id="cambiosvolumenCliente" 
            placeholder="Max 150 palabras" 
            className=''
            value={progressDataProps.progressData.cambiosvolumen}
            onChange={handleInputChange}
            required
            maxLength={150}
            /><br></br>

            <label htmlFor="cambiosdietaCliente" className="data-label">*¿Has cumplido la dieta?</label><br></br>
            <input 
            type="text" 
            name="dietacumplido" 
            id="cambiosvolumenCliente" 
            placeholder="Max 150 palabras" 
            className=''
            value={progressDataProps.progressData.dietacumplido}
            onChange={handleInputChange}
            required
            maxLength={150}
            /><br></br>

            <label htmlFor="dietaporcentajeCliente" className="data-label">Indícanos en porcentaje cuánto has cumplido la dieta</label><br></br>
            <input 
            type="text" 
            name="dietaporcentaje" 
            id="dietaporcentajeCliente" 
            placeholder="Max 150 palabras" 
            className=''
            value={progressDataProps.progressData.dietaporcentaje}
            onChange={handleInputChange}
            /><br></br>

            <label htmlFor="dietahambreCliente" className="data-label">¿Cómo te has sentido en relación a tu hambre / saciedad?</label><br></br>
            <input 
            type="text" 
            name="dietahambre" 
            id="dietahambreCliente" 
            className=''
            value={progressDataProps.progressData.dietahambre}
            onChange={handleInputChange}
            /><br></br>

            { trainingPlans.includes(progressDataProps.typePlan) &&
                <>
                    <label htmlFor="entrenamientocumplidoCliente" className="data-label">*¿Has cumplido el entrenamiento?</label><br></br>
                    <input 
                    type="text" 
                    name="entrenamientocumplido" 
                    id="entrenamientocumplidoCliente" 
                    className=''
                    value={progressDataProps.progressData.entrenamientocumplido}
                    onChange={handleInputChange}
                    required
                    /><br></br>
                    <label htmlFor="entrenamientoporcentajeCliente" className="data-label">Indícanos en porcentaje cuánto has cumplido el entrenamiento</label><br></br>
                    <input 
                    type="text" 
                    name="entrenamientoporcentaje" 
                    id="entrenamientoporcentajeCliente" 
                    className=''
                    value={progressDataProps.progressData.entrenamientoporcentaje}
                    onChange={handleInputChange}
                    /><br></br>
                    <label htmlFor="entrenamientomejoraCliente" className="data-label">¿Sientes mejora en tu fuerza, técnica, etc. en el entrenamiento?</label><br></br>
                    <input 
                    type="text" 
                    name="entrenamientomejora" 
                    id="entrenamientomejoraCliente"
                    placeholder="Max 150 palabras"  
                    className=''
                    value={progressDataProps.progressData.entrenamientomejora}
                    onChange={handleInputChange}
                    /><br></br>
                    
                    <label htmlFor="entrenamientonotasCliente" className="data-label">*¿Algo que contar sobre el entrenamiento?</label><br></br>
                    <input 
                    type="text" 
                    name="entrenamientonotas" 
                    id="entrenamientonotasCliente"
                    placeholder="Max 150 palabras"  
                    className=''
                    value={progressDataProps.progressData.entrenamientonotas}
                    onChange={handleInputChange}
                    required
                    /><br></br>
                </>
            }
        </div>
        <hr />
        <button className="next-field next-field-personal-data" disabled={!validationPassOne} onClick={()=>progressDataProps.setNext(2)}>Siguiente {!validationPassOne?<img src={nextArrowGrey} alt={'next'} />:<img src={nextArrow} alt={'next'} />}</button>
      </>
    );
}

export default ProgressDataRenewSubscriptions;