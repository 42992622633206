import {FaqData} from "../models/FaqData";
import axios from "../api/axios";
import {FAQ_URL} from "../utils/constants";

export async function faqsService(selectedCategory?: string): Promise<FaqData[]> {
    let response;
    if (selectedCategory) {
      response = await axios.get(`${FAQ_URL}?category=${selectedCategory}`);
    } else {
        response = await axios.get(FAQ_URL);
    }
    return response.data;
}
