import Icon from '../../../assets/img/icon-collapse.svg';
import parse from "html-react-parser";
import { VER_MENOS, VER_TESTIMONIO_COMPLETO } from "../../../utils/constants";
import { TestimonialProps } from "../../../models/componentProps/TestimonialProps";
import { CATEGORY_DIETA } from '../../../utils/constants';


const Testimonial = (testimonialProps: TestimonialProps) => {
    const getTestimonialContainerClassName = (testimonialCategory: string) => {
        if (testimonialCategory === CATEGORY_DIETA) {
            return "testimonial-container-complete-diet";
        }
        return "testimonial-container-complete-psico";
    };

    const testimonialContainerClassName = testimonialProps.selectedCategory
        ? getTestimonialContainerClassName(testimonialProps.selectedCategory)
        : testimonialProps.showMoreTestimonial
            ? "testimonial-container"
            : "testimonial-complete-container";

    const getTestimonialContainerTitle = (testimonialCategory: string) => {
        if (testimonialCategory === CATEGORY_DIETA) {
            return "Evolución Plan de Dieta";
        }
        else {
            return "Sesiones de Psicología";
        }
        
    };

    const getSubtitle = (testimonial: TestimonialProps) => {
        if (testimonial.category === CATEGORY_DIETA) {
            return (
                <span className="subtitle">
                    <b style={{ fontFamily: 'Montserrat SemiBold' }}> Tiempo: </b>

                    {testimonial.duration_in_months === ''
                        ? (
                            <span>1 MES</span>
                        )
                        : (
                            <span> {testimonial.duration_in_months} MESES </span>
                        )}
                    
                    <span className='subtitle-change'>
                        <b style={{ fontFamily: 'Montserrat SemiBold' }}> Antes: </b>
                        <span> {testimonial.previous_weight} | </span>
                        <b style={{ fontFamily: 'Montserrat SemiBold' }}> Ahora: </b>
                        <span> {testimonial.current_weight} </span>
                    </span>
                </span>
            );
        }
        return (
            <span className="subtitle">
                <b style={{ fontFamily: 'Montserrat SemiBold' }}> Psicóloga: </b>
                <span> {testimonial.psychologist} </span>
                <span className='subtitle-change'>
                    <b style={{ fontFamily: 'Montserrat SemiBold' }}> Número de sesiones: </b>
                    <span> {testimonial.number_of_sessions} </span>
                </span>
                <span className='subtitle-change subtitle-change-goDown'>
                    <br className={'br-subtitile-change'} />
                    <b style={{ fontFamily: 'Montserrat SemiBold' }}> Tipo de sesión: </b>
                    <span> {testimonial.type_of_sessions} </span>
                </span>
            </span>
        );
    }

    return (
        <div className={testimonialContainerClassName}>
            <div className={testimonialProps.showMoreTestimonial ? 'testimonial-img padding-image-home' : "testimonial-complete-img"}>
                <img src={testimonialProps.image}
                     className={`${testimonialProps.showMoreTestimonial ? 'image-post' : 'image-complete-post' } 
                        ${testimonialProps.category === 'diet' ? 'image-slider-diet' : 'image-slider-psychology'}`} alt='1' />
            </div>
            <div className={testimonialProps.showMoreTestimonial ? "description-container" : "description-complete-container"}>
                <div className="flex-container-title-button">
                    <h2>{getTestimonialContainerTitle(testimonialProps.category)}</h2>                    
                    {
                        testimonialProps.showMoreTestimonialTop &&
                        <>
                            <button className="read-more-product" onClick={testimonialProps.moreTestimonial}> <p>VER MÁS TESTIMONIOS</p> </button>
                        </>
                    }
                </div>
                <div className="flex-container-title-button1">
                    <h2 className={'testimonial-name'}><b>{testimonialProps.title}</b></h2>
                </div>
                <h3 style={{marginTop: '4px'}}>
                    {getSubtitle(testimonialProps)}
                </h3>
                {
                    !testimonialProps.showMoreTestimonial &&
                    <>
                        <p>{testimonialProps.expanded ? parse(testimonialProps.description) : parse(testimonialProps.description.substring(0, 170) + ('...'))}</p>
                        <button className="complete-testimonial-button" onClick={testimonialProps.handleClick}>
                            <p style={{textTransform: "none"}}> {testimonialProps.expanded ? VER_MENOS : VER_TESTIMONIO_COMPLETO} <img alt='icon' className="icon-collapse" src={Icon} style={{ transform: testimonialProps.expanded ? 'rotate(180deg)' : undefined }} /></p>
                        </button>
                    </>
                }
                {
                    testimonialProps.showMoreTestimonial &&
                    <>
                        <p style={{ height: '100px' }}> {parse(testimonialProps.description.substring(0, 200) + ('...'))} </p>
                        <div style={{width: '100%'}}>
                            <button className="read-more" onClick={testimonialProps.moreTestimonial}> <p style={{marginTop: 0}}>VER MÁS TESTIMONIOS</p> </button>
                        </div>
                    </>
                }
                 {
                        testimonialProps.showMoreTestimonialTop &&
                        <div className='read-more-product-end-flex'>
                            <button className="read-more-product-end" style={{fontFamily: 'Montserrat SemiBold', marginTop: '1rem'}} onClick={testimonialProps.moreTestimonial}> <span>VER MÁS TESTIMONIOS</span> </button>
                        </div>
                    }
            </div>
        </div>
    );
}

export default Testimonial;
