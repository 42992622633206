import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import Banner from './banner/Banner';
import HowWorks from './how-works/HowWorks';
import Questions from '../../shared/questions/Questions';
import Testimonials from './testimonials/Testimonials';
import Products from './products/Products';
import WhyVikika from './why_vikika/WhyVikika';
import './Home.css';
import Brands from "./brands/Brands";

const Home = () => {

  return (
    <>
    <Helmet>
      <link rel="canonical" href={`${process.env.REACT_APP_FRONT_URL}`} />
      <meta name="description" content="¿Buscas un entrenador personal y nutricionista para lograr tus metas? Entrenamientos y planes de alimentación por expertos."/>
      <title>Vikika | Entrenador Personal y Nutricionista: un estilo de vida saludable</title>
    </Helmet>
    <main className="main-content">
      <div className='home-container'>
        <Banner />
        <WhyVikika />
        <Products />
        <HowWorks />
        <div className='title-home'>
          <p>LO QUE OPINAN DE NOSOTROS</p>
        </div>
        <div className='testimonials-home-content'>
          <Testimonials />
        </div>
        <Brands />
        <Questions />
      </div>
    </main>
    </>
  )
}

export default Home

