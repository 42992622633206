import "./ScheduleSession.css";
import { useState, useEffect, useRef } from "react";
import { updateVideoCallPsiService } from "../../../../../services/videoCallService";
import * as React from "react";
import Drawer from "@mui/material/Drawer";
import 'dayjs/locale/es';
import dayjs from "dayjs";
import isBetweenPlugin from "dayjs/plugin/isBetween";
import ModalCites from "../../diet/calendar/modalCites";
import Cites from "../../diet/calendar/cites";
import { usePsiCalendarQuery } from "../../../../../hooks/usePsiCalendarQuery";
import CalendarHoursArea from "../../diet/calendar/calendar_hours_area";
import { IAppointmentSlot } from "../../../../../services/getDietCalendarService";
import CalendarVideocallArea from "../../diet/calendar/calendar_videocall_area";
import CalendarCalendarArea from "../../diet/calendar/calendar_calendar_area";
import PrivateLoading from "../../../../shared/loading/PrivateLoading";
import { specialistAssignedSubscriptionService } from "../../../../../services/getPsiSpecialistService";

interface DrawerContentProps {
  drawerView: string;
  noShowButtons: boolean;
}

interface ScheduleSessionProps {
  tab: string;
}

function touchWeekDays() {
  const elements = document.querySelectorAll(".MuiDayCalendar-weekDayLabel");
  if (elements.length > 0) {
    // OJO: en mi navegador la columna 0 es LUNES, no DOMINGO
    elements[0].textContent = "DO";
    elements[1].textContent = "LU";
    elements[2].textContent = "MA";
    elements[3].textContent = "MI";
    elements[4].textContent = "JU";
    elements[5].textContent = "VI";
    elements[6].textContent = "SA";
  }
}

dayjs.extend(isBetweenPlugin);


const ScheduleSession = (scheduleSessionProps: ScheduleSessionProps) => {
  const [specialistAssigned, setSpecialistAssigned] = useState(true);

  useEffect(() => {
    async function isSpecialistAssigned() {
      const response = await specialistAssignedSubscriptionService();
      setSpecialistAssigned(response.data["employee"]);
    }
    isSpecialistAssigned();
  }, []);
  const formatDate = (inputDate:string) => {
    const months = [
      'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
      'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
    ];
  
    const [year, month, day] = inputDate.split('-');
    const monthName = months[parseInt(month, 10) - 1];
  
    return `${parseInt(day, 10)}  ${monthName} ${year}`;
  };

  const [typePlan, setTypePlan] = useState<string>("psi_subscription_ind");
  const [showModalRedirect, setShowModalRedirect] = useState(false);
  // Aquí almacenamos la fecha elegida del calendario, aunque aún no sepamos la hora, formato Y-m-d
  const [selectedDate, setSelectedDate] = useState<string | null>(null);
  // Aquí almacenamos fecha y hora elegidas, con inicio y fin, etc.
  const [selectedSlot, setSelectedSlot] = useState<IAppointmentSlot | null>(null);
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [selectedTab, setSelectedTab] = useState<'schedule' | 'dates'>("schedule");
  const [drawerView, setDrawerView] = useState<'schedule' | 'details'>("schedule");
  const [dateConfirmed, setDateConfirmed] = useState(false);
  const [changeDate, setChangeDate] = useState(false);

  const { data, error, isLoading, refetch, isRefetching } = usePsiCalendarQuery(typePlan);

  const noShowButtons = false;

  const miComponenteRef = useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    miComponenteRef.current?.scrollIntoView({ block: "start", behavior: 'smooth' });
    const timer = setTimeout(() => {
      window.scrollTo(0, 0);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  React.useEffect(() => {
    if (changeDate === true) {
      touchWeekDays();
    }
  }, [selectedTab]);

  React.useEffect(() => {
    // Verificar si 'tab' es igual a 'videocall' y establecer 'schedule' si es así
    if (scheduleSessionProps.tab === 'videocall') {
      console.log(scheduleSessionProps.tab);
      setSelectedTab("dates");
      console.log(selectedTab);
    }
  }, [scheduleSessionProps.tab]); // Ejecutar el efecto cuando 'tab' cambie

  if (isLoading) {
    return (
      <>
        <h1 className="title_calendar">CALENDARIO Y GESTIÓN DE VIDEOLLAMADA</h1>
        <p className="subtitle_calendar">
        Selecciona la fecha y hora de tu próxima sesión con tu psicóloga. Posteriormente podrás ver la información de tus sesiones en el apartado de “tus citas”.
        </p>
        <PrivateLoading />
      </>
    );
  } else if (error) {
    return <i>{error.message}</i>;
  } else if (!data) {
    return <i>Error de comunicaciones</i>;
  }

  // Filter last_reply.citas a fecha. data.citas.start has a string representation, and we want unique Y-m-d dates
  const highlightedDays = data.citas.map((cita) => {
    return cita.start.format("YYYY-MM-DD");
  }).filter((value, index, self) => {
    return self.indexOf(value) === index;
  });

  const onVideoCallNoAgendar = () => {
    setDrawerView('schedule');
    setSelectedDate(null);
    setSelectedSlot(null);
    setDrawerOpen(false);
    setShowModalRedirect(true);
  }

  const after_save = () => {
    setDateConfirmed(false);
    setDrawerView('schedule');
    setDrawerOpen(false);
    setSelectedTab("dates");
    setChangeDate(true);
  }

  // El usuario quiere agendar una cita de videoconsulta
  const onVideoCallAgendarClicked = async () => {
    if (!selectedDate || !selectedSlot) {
      console.warn("Error de lógica");
      return null;
    }

    try {
      const fecha_inicio = selectedDate + " " + selectedSlot.start.format('HH:mm:00');
      const cita2 = data.citas.find(item => item.start.format("YYYY-MM-DD HH:mm:00") === fecha_inicio);
      if (cita2) {
        const response = await updateVideoCallPsiService({
          IdVideoCallUpdated: cita2.id, TypePlan: typePlan, IdPlan: cita2.idPlan,
        });
        if (response.data['inscripcion_realizada']) {
          refetch();
          after_save();
        }
      }

    } catch (e) {
      window.alert("Error registrando la cita");
    }
  }

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }
      setDrawerOpen(open);
    };

  // El calendario nos proporciona una fecha, pero no una hora aún.
  const onSelectDate = (ymd: string | null) => {
    if (ymd !== null && highlightedDays.includes(ymd)) {
      setSelectedDate(ymd);
      setSelectedSlot(null);
      setDrawerView("schedule");
      setDrawerOpen(true);
    } else {
      setSelectedDate(null);
      setSelectedSlot(null);
      setDrawerOpen(false);
    }
  }

  // La barra derecha nos proporciona un slot, con su fecha/hora de inicio y de fin
  const onSelectSlot = (slot: IAppointmentSlot) => {
    console.log("onSelectSlot", slot);
    setSelectedSlot(slot);
    setDrawerView("details");
  }

  // El usuario quiere esconder la zona de la derecha, sea cual sea
  const onHideDrawer = () => {
    toggleDrawer(false);
    setSelectedDate(null);
    setSelectedSlot(null);
    setDrawerOpen(false);
  }

  const drawerContent = ({ drawerView, noShowButtons }: DrawerContentProps) => {
    if (drawerView === "schedule" && selectedDate) {

      return <CalendarHoursArea
        onHideDrawer={onHideDrawer}
        selectedDate={selectedDate}
        onSelectAppointmentSlot={onSelectSlot}
        options={data.citas.filter((cita) => {
          return cita.start.format("YYYY-MM-DD") === selectedDate;
        })}
      />

    } else if (drawerView === "details" && data.empleado && selectedSlot) {

      return <CalendarVideocallArea
        type="psi"
        onHideDrawer={onHideDrawer}
        specialist={data.empleado}
        selected_slot={selectedSlot}
        booked_slot={null}
        onVideoCallNoAgendar={onVideoCallNoAgendar}
        onVideoCallAgendarClicked={onVideoCallAgendarClicked}
        noShowButtons={noShowButtons}
      />
    }

    return null;
  };

  return (
    <div className="container_calendar_general" ref={miComponenteRef}>

      <React.Fragment key={"right"}>
        <Drawer
          anchor={"right"}
          open={drawerOpen}
          onClose={toggleDrawer(false)}
        >
          {drawerContent({ drawerView, noShowButtons })}
        </Drawer>
      </React.Fragment>

      <h1 className="title_calendar">CALENDARIO Y GESTIÓN DE VIDEOLLAMADA</h1>
      {!specialistAssigned &&
        <div>
          <p className="subtitle_calendar">
            El calendario aún no está disponible. Una vez te asignemos un especialista,
            tendrás acceso al calendario para agendar tu cita.
          </p>
        </div>
      }
      {specialistAssigned &&
        <div >
          <p className="subtitle_calendar">
            Selecciona e inscríbete en una de las citas disponibles para realizar la
            videollamada con tu especialista. Posteriormente, recibirás tu plan
            personalizado.
          </p>
          <div className={"order-history calendar-content"}>
            <div className={"order-history-options calendar-options"}>
              <p
                className={selectedTab === "schedule" ? "text-monserrat active-option" : "text-monserrat "}
                onClick={() => setSelectedTab("schedule")}
              >
                Agendar
              </p>
              <p
                className={selectedTab === "dates" ? "text-monserrat active-option" : "text-monserrat "}
                onClick={() => {
                  setSelectedTab("dates");
                }}
              >
                Tus citas
              </p>
            </div>
            {selectedTab === "schedule" && (
              <>
                <p className="sessions-avaliable">Te quedan <span>{data.datosPlan?.ndisponibles} de {data.datosPlan?.nsesiones} citas disponibles</span></p>
                <p className="sessions-avaliable">Renovación {formatDate(data.datosPlan?.fProxRenovacion?.toString() || '')}</p>
              </>
            )}
            {selectedTab === "schedule" && (
              <>
                {data.datosPlan?.activo == 1 ?
                  <CalendarCalendarArea
                    calendarType="psychology"
                    highlightedDays={highlightedDays}
                    onSelectDate={onSelectDate}
                    selectedDate={selectedDate}
                  />
                  :
                  <ModalCites />
                }
              </>
            )}

            {(selectedTab === "dates" && selectedSlot && dateConfirmed) && (!data.citasPendientes) && (data.empleado) && (
              <>
                {isRefetching && <div className="calendar_dates_loading"><PrivateLoading /></div>}
                <Cites
                  start_dayjs={selectedSlot.start}
                  end_dayjs={selectedSlot.end}
                  instruc=""
                  plan="Plan de psicología"
                  type_calendar = {typePlan}
                  specialist={data.empleado.first_name}
                  fullText={true}
                  couple={false}
                />
              </>

            )}

            {(selectedTab === "dates") && (data.citasPendientes) && (
              <>
                {isRefetching && <div className="calendar_dates_loading"><PrivateLoading /></div>}
                {data.citasPendientes.map((item, index) => (
                  <Cites key={index}
                    start_dayjs={item.start_date}
                    end_dayjs={item.end_date}
                    instruc={item.instruc}
                    plan="Plan de psicología"
                    type_calendar = {typePlan}
                    specialist={item.first_name}
                    fullText={index === 0 ? true : false}
                    couple={false}
                  />
                ))}
              </>
            )}
          </div>
        </div>
      }
    </div>
  );
};

export default ScheduleSession;