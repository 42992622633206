import { createContext } from 'react';

interface MenuContextProps {
  showMenuPrincipal: boolean;
  setShowMenuPrincipal: (showMenuPrincipal: boolean) => void;
}

const MenuContext = createContext<MenuContextProps>({
  showMenuPrincipal: false,
  setShowMenuPrincipal: () => {},
});

export default MenuContext;