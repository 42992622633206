import { useMediaQuery, useTheme } from '@mui/material';
import Divider from '@mui/material/Divider';
import Icon from '../../../../assets/img/carrera-profesional.png';
import improve_habits from '../../../../assets/img/saludable.png';
import more_than_a_diet from '../../../../assets/img/bienestar.png';
import personal_panel from '../../../../assets/img/cuenta.png';
import client_attention from '../../../../assets/img/servicio-al-cliente.png';
import learn_take_care from '../../../../assets/img/conocimiento.png';
import "./WhyVikika.css";
import WhyContainer from "./why-container/WhyContainer";

const WhyVikika = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const data = [
        [
            {
                title: 'Tu mejor versión',
                subtitle: 'Abordarás tu cambio desde distintas perspectivas y disfrutarás de un camino lleno de aprendizaje.',
                icon: Icon
            },
            {
                title: 'Mejora tus hábitos',
                subtitle: 'Lograrás una mejora en tus hábitos que aumentará tu bienestar y motivación diaria.',
                icon: improve_habits
            }
        ],
        [
            {
                title: 'Más que una dieta ',
                subtitle: 'La nutrición es clave, no solo para lograr un cambio físico, sino para potenciar el bienestar general.',
                icon: more_than_a_diet
            },
            {
                title: 'Panel personal',
                subtitle: 'Accederás a tu panel personal donde podrás llevar el seguimiento y control de tus productos.',
                icon: personal_panel
            }
        ],
        [
            {
                title: 'Atención al cliente',
                subtitle: 'Nuestro servicio de atención al cliente resolverá todas tus dudas en menos de 48 horas. ',
                icon: client_attention
            },
            {
                title: 'Aprende a cuidarte',
                subtitle: 'Tomarás decisiones desde el conocimiento, una valiosa herramienta para la superación personal.',
                icon: learn_take_care
            }
        ],
    ];

    return (
        <>
            <div className='title-home title-home-seo'>
                <p>¿POR QUÉ ELEGIR VIKIKA TEAM? {isMobile}</p>
            </div>
            <WhyContainer data={data[0]} />
            <div className={!isMobile ? 'vertical-spacer-desktop' : 'vertical-spacer-hide'}>
                <Divider />
            </div>
            <WhyContainer data={data[1]} />
            <div className={!isMobile ? 'vertical-spacer-desktop' : 'vertical-spacer-hide'}>
                <Divider />
            </div>
            <WhyContainer data={data[2]} />
            <div className={!isMobile ? 'vertical-spacer-desktop' : 'vertical-spacer-hide'}>
                <Divider />
            </div>
        </>
    )
};

export default WhyVikika
