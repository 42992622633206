import React from "react";
// import "./Message.css";
import { Avatar } from "@material-ui/core";
import '../../diet/contact-specialist/Message.css'

interface ComponentProps {
    sender: string,
    avatar: string,
    message: string,
    time: string
}
const firstName = localStorage.getItem('firstName');
const lastName = localStorage.getItem('lastName');
console.log(firstName,lastName);
function Message(props: ComponentProps) {
    return (
        <div className="message" style={{display: "flex", justifyContent: props.sender === 'user' ? "end" : 'start'}}>
            <div style={{display: "flex", flexDirection: props.sender === 'user' ? "row-reverse" : "row"}}>
                {props.sender ===''
                ? 
                <img src={props.avatar} alt="img" style={{}}/>
            : 
            <div className='user-name-logo'>
                <div className='header-background-letters-chat'>
                <p>{firstName || lastName ? `${firstName?.substring(0, 1).toUpperCase()}${lastName?.substring(0, 1).toUpperCase()}` : 'U'}</p>
                </div>
                <img src={props.avatar} className="" alt="img" />
          </div>
            }
                {/* <img src={props.avatar} alt="img" style={{}}/> */}
                <p className={props.sender === 'user' ? 'message-green-chat' : 'message-white-chat'} dangerouslySetInnerHTML={{ __html: props.message }}></p>
            </div>
        </div>
    );
}

export default Message;
