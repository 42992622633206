import axios from "../api/axios";
import {GET_ORDERS_URL} from "../utils/constants";

export interface WoocomerceOrder {
    categories: Array<string>;
    order_id: number;
    total: number;
    status: string;
    paid_on?: string;
    product_id?: string;
    number_of_sessions: number;
    variation_desc: string;
    variation_id: number;
}

export async function getOrdersService(): Promise<WoocomerceOrder[]> {
    const token = localStorage.getItem('token');
    const headers =  {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }
    const response = await axios.get(GET_ORDERS_URL, headers);
    return response.data;
}