import React from "react";

interface ImagesContainerProps {
  frontImage: string;
  sideImage: string;
  backImage: string;
  frontText: string;
  sideText: string;
  backText: string;
  title: string;
}

const ImagesContainer: React.FC<ImagesContainerProps> = ({
  frontImage,
  sideImage,
  backImage,
  frontText,
  sideText,
  backText,
  title,
}) => {
  return (
    <div className="images-container">
      <h3 className="image_title">{title}</h3>
      <div className="images">
        <div>
          <img src={frontImage} alt="front" className="front" />
          <h3>{frontText}</h3>
        </div>
        <div>
          <img src={sideImage} alt="side" className="side" />
          <h3>{sideText}</h3>
        </div>
        <div>
          <img src={backImage} alt="back" className="back" />
          <h3>{backText}</h3>
        </div>
      </div>
    </div>
  );
};

export default ImagesContainer;
