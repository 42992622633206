import { ICompleteResponse, getPsiCalendarService,getPsiCalendarCoupleService } from "../services/getPsiCalendarService";
import { useQuery } from "react-query";

export function usePsiCalendarQuery(typePlan:string) {
    return useQuery<ICompleteResponse, Error>('psiCalendar', () => getPsiCalendarService(typePlan), {
        refetchInterval: 1000 * 60 * 5
    });
}

export function usePsiCalendarCoupleQuery() {
    return useQuery<ICompleteResponse, Error>('psiCoupleCalendar', () => getPsiCalendarCoupleService(), {
        refetchInterval: 1000 * 60 * 5
    });
}