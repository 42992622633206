import CancelModal from "../../../../../assets/img/iconoir_cancel.svg";
import { Typography } from "@mui/material";
import "./calendar.css";
import 'dayjs/locale/es';

type MyProps = {
    onHide: () => void;
    onCancelSubscription: () => void;

}

export default function ModalRedirect({
    onHide,
    onCancelSubscription,
}: MyProps) {
    return (
    <div className={"modal-success-container modal-redirect-container "}>
        <div className={"modal-success modal-redirect-success videocall-cancelation"}>
        <div>
            <img
            alt='Cancel'
            src={CancelModal}
            style={{
                position: "absolute",
                right: "1.5rem",
                top: "1.5rem",
                cursor: "pointer",
            }}
            onClick={onHide}
            />{" "}
        </div>
        <h1 className={"custom-modal-title-unified"}>
            ¿NO QUIERES AGENDAR TU VIDEOLLAMADA?
        </h1>
        <Typography className={"modal-success-text"}>
            Recuerda que si decides cancelar tu videollamada, el especialista solo tendrá en cuenta tu cuestionario para realizar la dieta personalizada.
        </Typography>
        <button
            className={"modal-success-button modal-redirect-success-button"}
            onClick={onCancelSubscription}
        >
            no agendar
        </button>
        </div>
    </div>);

}