import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from "react-router-dom";
import {trackView} from "../../../utils/FacebookPixelEvents";
import Sidebar from './sideBar/SideBar';
import Questions from '../../shared/questions/Questions';
import './Private.css'
import MediaQuery from "react-responsive";


const Private: React.FC = () => {

    const navigate = useNavigate();
    
    useEffect(() => {
        trackView();
        const status = localStorage.getItem('status');
        if (!status) {
            navigate('/iniciar-sesion');
        }else{
            if (status !== "isLoggedIn"){
                navigate('/iniciar-sesion');
            } 
        }
    }, []);

    return (
        <>
        <Helmet>
            <link rel="canonical" href={`${process.env.REACT_APP_FRONT_URL}`+"/private"} />
        </Helmet>
        <main className="main-content">
            <div className="private-container">
                <Sidebar />
                <MediaQuery maxWidth={768}>
                    <Questions showAlgunaDuda={false}/>
                </MediaQuery>
                <MediaQuery minWidth={768}>
                    <Questions />
                </MediaQuery>
            </div>
        </main>
        </>
    );
};

export default Private;
