/* eslint-disable jsx-a11y/alt-text */
import "./calendar.css";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import 'dayjs/locale/es';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import Hint from "../contact-specialist/Hint";
import dayjs, { Dayjs } from "dayjs";
import ServerDay from "./serverday";

/**
 * Este componente recibe, resalta y devuelve fechas en formato Y-m-d
 */

type MyProps = {
  calendarType: string | null;
    selectedDate: string|null;
    onSelectDate: (date: string|null) => void;
    highlightedDays: string[];
}

export default function CalendarCalendarArea({
    calendarType,
    selectedDate,
    onSelectDate,
    highlightedDays
}: MyProps ) {
    return (
        <>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
          <DemoContainer components={["StaticDatePicker"]}>
            <DemoItem >
              <StaticDatePicker showDaysOutsideCurrentMonth
                                onChange={(newValue) => onSelectDate(newValue?.format('YYYY-MM-DD')||null)}
                                views={['day']}
                                value = {dayjs(selectedDate)}
                                slots={{
                                  day: ServerDay,
                                }}
                                dayOfWeekFormatter={(day) => day.charAt(0).toUpperCase()+day.charAt(1).toUpperCase()}
                                slotProps={{
                                  day: {
                                    highlightedDays,
                                  } as any,
                                }}
              />
            </DemoItem>
          </DemoContainer>
        </LocalizationProvider>
        <div className="info_text_calendar">
          {calendarType == "psychology"? (
            
            <div>
              <Hint
                text={
                  'Si te surge algún problema y no puedes realizar o acceder a la videollamada, ponte en contacto con el equipo de atención al cliente en <a className="mailVikika" href="mailto:attcliente@vikika.es" style="text-decoration:none"> attcliente@vikika.es </a>'
                }
                width={"100%"}
              />
              <Hint
                text={
                  'Recuerda que podrás modificar o cancelar tu sesión avisando a tu psicóloga por el chat o en <a className="mailVikika" href="mailto:attcliente@vikika.es" style="text-decoration:none"> attcliente@vikika.es </a> con 24h de antelación.'
                }
                width={"100%"}
              />
            </div>

          ):(
            <div>
              <Hint
            text={
              "Recuerda que dispones de <strong style=\"font-family: 'Montserrat SemiBold';\">5 días</strong> a partir de la asignación de tu especialista para gestionar la videollamada."
            }
            width={"100%"}
          />
          <Hint
            text={
              'Si te surge algún problema y no puedes realizar o acceder a la videollamada, ponte en contacto con el equipo de atención al cliente en <a className="mailVikika" href="mailto:attcliente@vikika.es"> attcliente@vikika.es </a>'
            }
            width={"100%"}
          />
          <Hint
            text={
              "Recuerda que solo podrás cambiar la fecha y hora de tu videollamada si avisas 24h antes de la cita."
            }
            width={"100%"}
          />
            </div>
          )
}
        </div>
      </>);

}