import "./Contact-Specialist.css";
import React, { useState, useEffect } from "react";
import Chat from "./Chat";
import Hint from "./Hint";
import PrivateLoading from "../../../../shared/loading/PrivateLoading";
import useClientMessagesQuery from "../../../../../hooks/useClientMessagesQuery";
import { useQueryClient } from "react-query";
import { verifyPlanAvaliableService } from "../../../../../services/getDietPlanService";

interface Mensaje {
  mensaje: string;
  es_cliente: string;
}

interface ClientChat {
  canWrite: boolean;
  avaliableMessages: number;
  max: number;
  mensajes: Mensaje[];
}

const ContactSpecialist = () => {
  const [planAvaliable, setPlanAvaliable] = useState(true);
  const [canSend, setCanSend] = useState(true);
  const queryClient = useQueryClient();
  const userInfoString = localStorage.getItem('pon_user_info_diet');
  let planName = ''; 
  
 
  if (userInfoString) {
    const userInfo = JSON.parse(userInfoString);
    if (userInfo.length > 0) {
        const lastUserInfo = userInfo[userInfo.length - 1]; // Corrected from userInfoString to userInfo
        planName = lastUserInfo.typePlan; 
    } else {
        console.log("userInfo array is empty.");
    }
}

  const {
    data,
    isLoading: loading,
    error,
    refetch,
    //    setData
  } = useClientMessagesQuery();

  const onNewClientChat = (newClientChat: ClientChat) => {
    queryClient.setQueryData("clientMessages", newClientChat);
  }

  useEffect(() => {
    async function verifyVideocall() {
      const response = await verifyPlanAvaliableService();
      setPlanAvaliable(response.data.planAvaliable);
    }
    verifyVideocall();
  }, []);

  return (
    <div className="container_specialist">
      <h1 className="title_specialist">CONTACTA CON TU ESPECIALISTA</h1>
      {loading && <PrivateLoading />}
      {!loading && !planAvaliable &&
        <div className="not_chat_container">
          <p className="not_chat_text">El chat aún no está disponible. En cuanto recibas tu plan, podrás
            comunicarte con tu especialista para resolver tus dudas.</p>
        </div>
      }
      {!loading && planAvaliable &&
        <>
          {planName  !='die_mes_psi' ? 
          <>
          <p className="text_specialist">Te quedan
            <span style={{ fontFamily: "Montserrat SemiBold" }}>
              <strong>  {data && data.avaliableMessages} de {data && data.max} mensajes</strong>
            </span>
          </p>
          <div className={'hints-only-mobile'}>
            <Hint text={'Recuerda que tienes un número de mensajes limitado'} width={'700px'} />
            <Hint text={'Envía como máximo 2 dudas por mensaje'} width={'700px'} />
          </div>
          </> : <></>}
          {data ?
            <Chat messages={data} onNewClientChat={onNewClientChat} canSend={canSend} />
            : <PrivateLoading />
          }
          {planName  !='die_mes_psi' ? 
          <div className={'hints-only-desktop'}>
            <Hint text={'Recuerda que tienes un número de mensajes limitado'} width={'700px'} />
            <Hint text={'Envía como máximo 2 dudas por mensaje'} width={'700px'} />
          </div> : <></>}
        </>
      }
    </div>
  );
};

export default ContactSpecialist;