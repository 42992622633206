import axiosCodeigniter from "../api/axiosCodeigniter";
import {GET_DIET_URL,GET_DIET_GIFT_CALENDAR_URL,GET_DIET_VIDEOCALL_URL,CHECK_DIET_URL} from "../utils/constants";
import {AxiosResponse} from "axios";

export async function getDietPlanService(): Promise<Blob[]> {
    const token = localStorage.getItem('token');
  
    try{
        const responseDietPdf = await axiosCodeigniter.get(`${GET_DIET_URL}`,{
            responseType: 'blob',
            headers:{
                Authorization: `Bearer ${token}`,
            }
          });
      
          const responseCalendarPdf = await axiosCodeigniter.get(`${GET_DIET_GIFT_CALENDAR_URL}`,{
              responseType: 'blob',
              headers:{
                  Authorization: `Bearer ${token}`,
              }
          });
      
          const pdfDietData = responseDietPdf.data;
          var pdfCalendarData= null;
          if(responseCalendarPdf.data.size>1) pdfCalendarData= responseCalendarPdf.data;
          if(responseDietPdf.data.size>1 && responseCalendarPdf.data.size<1){
              const blobPdfDietData = new Blob([pdfDietData], { type: 'application/pdf' });
              return [blobPdfDietData];
          }
          if(responseDietPdf.data.size>1 && responseCalendarPdf.data.size>1){
              const blobPdfDietData = new Blob([pdfDietData], { type: 'application/pdf' });
              const blobPdfCalendarData = new Blob([pdfCalendarData], { type: 'application/pdf' });
              return [blobPdfDietData,blobPdfCalendarData];
          }
          else{
             return [];
          } 
    }catch (err) {
        throw new Error ('Se ha producido un error.Por favor vuelva a intentarlo');
    }

  }

  export async function verifyVideocallDietService(): Promise<AxiosResponse> {
    const token = localStorage.getItem('token');
    const headers =  {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }
    const response = await axiosCodeigniter.get(`${GET_DIET_VIDEOCALL_URL}`, headers);
    return response;
  }


  export async function verifyPlanAvaliableService(): Promise<AxiosResponse> {
    const token = localStorage.getItem('token');
    const headers =  {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }
    const response = await axiosCodeigniter.get(`${CHECK_DIET_URL}`, headers);
    return response;
  }
