import { useEffect, useState } from "react";
import { Helmet } from 'react-helmet-async';
import {trackView} from "../../../utils/FacebookPixelEvents";
import Questions from "../../shared/questions/Questions";
import "./Testimonials.css";
import TestimonialsTypeSlider from "./testimonials-type-slider/TestimonialsTypeSlider";
import { testimonialsService } from "../../../services/testimonialsService";
import { CATEGORY_DIETA, TESTIMONIALS_SLIDER_URL } from "../../../utils/constants";
import TestimonialButtons from "./testimonialButtons/TestimonialButtons";
import Testimonial from "./../../shared/testimonial/Testimonial";
import { TestimonialType } from "../../../models/TestimonialType";
import Loading from '../../shared/loading/Loading';


const Testimonials = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingToo, setIsLoadingToo] = useState(false);
  const [testimonials, setTestimonials] = useState<TestimonialType[]>([]);
  const [testimonialsSlider, setTestimonialsSlider] = useState<TestimonialType[]>([]);
  const [selectedCategory, setSelectedCategory] = useState(CATEGORY_DIETA);

  useEffect(() => {
    trackView();
  }, []);

  const handleButtonClick = (category: string) => {
    setSelectedCategory(category);
  };

  async function fetchTestimonialsSlider(category: string) {
    try {
      setIsLoading(true);
      const testimonialsSlider = await testimonialsService(`${TESTIMONIALS_SLIDER_URL}?category=${category}`);
      setIsLoading(false);
      setTestimonialsSlider(testimonialsSlider);
    } catch (error) {
      setIsLoading(false);
    }
  }

  async function fetchTestimonials(category: string) {
    try {
      setIsLoadingToo(true);
      const testimonials = await testimonialsService();
      setIsLoadingToo(false);
      const filteredTestimonials = testimonials.filter((testimonial) => testimonial.category === category);
      setTestimonials(filteredTestimonials);
    } catch (error) {
        setIsLoadingToo(false);
    }
  }

  useEffect(() => {    
    fetchTestimonialsSlider(selectedCategory);
    fetchTestimonials(selectedCategory);
  }, [selectedCategory]);

  const [expanded, setExpanded] = useState(-1);

  const handleClick = (index: number) => {
    setExpanded(index === expanded ? -1 : index);
  };

  const testimonialsContent = (
    testimonials.map((testimonial, index) => (
      <Testimonial
        key={index}
        title={testimonial.title}
        image={testimonial.image}
        subtitle={testimonial.subtitle}
        description={testimonial.description}
        expanded={index === expanded}
        handleClick={() => handleClick(index)}
        showMoreTestimonial={false}
        showMoreTestimonialTop={false}
        category={testimonial.category}
        type_of_sessions={testimonial.type_of_sessions || ''}
        number_of_sessions={testimonial.number_of_sessions || ''}
        current_weight={testimonial.current_weight || ''}
        psychologist={testimonial.psychologist || ''}
        duration_in_months={testimonial.duration_in_months || ''}
        previous_weight={testimonial.previous_weight || ''}
        selectedCategory={selectedCategory}
      />
    ))
  );

  return (
    <>
    <Helmet>
      <link rel="canonical" href={`${process.env.REACT_APP_FRONT_URL}`+"/testimonios"} />
    </Helmet>
    <main className="main-content">
       {(isLoading || isLoadingToo) && (<Loading/>)}      
      <div className='testimonials-container'>
        <div className="title-section-testimonials">
          <h1>LO QUE OPINAN DE NOSOTROS</h1>
          <p>Los testimonios de nuestros clientes nos emocionan y dan sentido a nuestro trabajo. </p>
          <p>¡Tú también puedes ser uno de ellos!</p>
        </div>
        <TestimonialButtons selectedCategory={selectedCategory} handleButtonClick={handleButtonClick} />
        <TestimonialsTypeSlider selectedCategory={selectedCategory} testimonials={testimonialsSlider} />
        <div className='testimoniasl-list'>
          {testimonialsContent}
        </div>
        <Questions />
      </div>
    </main>
    </>
  )

}

export default Testimonials;
