import axios from "../api/axios";
import { CHANGE_BILLING_DETAILS } from "../utils/constants";

export async function changeBillingDetails(address1:string,address2:any,cpo:string,city:string,region:string,country:string) {
    
    const token = localStorage.getItem('token');
    const config = {
        headers:{
            Authorization: `Bearer ${token}`,
            'Content-Type': "application/json"
        }
    };
    const requestData = {
        address_1: address1,
        address_2: address2,
        city: city,
        postcode: cpo,
        country: country,
        region: region,
        state:region
      };
    
    // formData.append();
    try{
        const response = await axios.put(CHANGE_BILLING_DETAILS,requestData,config);
        localStorage.setItem('address_1', address1);
        localStorage.setItem('address_2', address2);
        localStorage.setItem('city', city);
        localStorage.setItem('country', country);
        localStorage.setItem('region', region);
        localStorage.setItem('state', region);
        localStorage.setItem('postcode', cpo);
        return response.data;
    }catch(error){
        console.log('Error al resetear el mensaje');
        throw error;
    }
}