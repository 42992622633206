import { useMediaQuery, useTheme } from '@mui/material';
import Divider from '@mui/material/Divider';
import Icon from '../../../../../assets/img/carrera-profesional 1.svg';
import improve_habits from '../../../../../assets/img/saludable 1.svg';
import "./../WhyVikika.css";
import WhyCard from "./why-card/WhyCard";

interface WhyContainerProps {
    data: Array<any>;
}

const WhyContainer = (whyContainerProps: WhyContainerProps) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <>
            <div className='why-container'>
                <WhyCard data={whyContainerProps.data[0]} />

                <div className={isMobile ? 'vertical-spacer-mobile' : 'vertical-spacer-hide'}>
                    <Divider />
                </div>
                <WhyCard data={whyContainerProps.data[1]} />
                <div className={isMobile ? 'vertical-spacer-mobile' : 'vertical-spacer-hide'}>
                    <Divider />
                </div>
            </div>
        </>
    )
};

export default WhyContainer
