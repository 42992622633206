import { useState,useEffect} from "react";
import Image from "../../../../../assets/img/img-training-big.jpg";
import Icon from "../../../../../assets/img/icon_training.png";
import Info from "../../../../../assets/img/info_icon.png";
import { getTrainingPlanService,verifyVideocallTrainingService } from "../../../../../services/getTrainingPlanService";
import PrivateLoading from "../../../../shared/loading/PrivateLoading";
import { useNavigate } from "react-router-dom";
import "./YourTraining.css";

const downloadInNewTab = ( downloadUrl: string ) => {
  const link = document.createElement("a");
  link.href = downloadUrl;
  link.target = "_blank";
  link.download = 'Vikika_training';
  link.click();
  window.open(downloadUrl, '_blank');
};

const YourTraining : React.FC<{ handleOptionClick: (option: string) => void }> = ({ handleOptionClick })=> {
  const [loading, setLoading] = useState(false);
  const [citaPendiente, setCitaPendiente] = useState(false);
  const [citaRealizada, setCitaRealizada] = useState(false);
  const [lastError, setLastError] = useState<string | null>(null);
  const [trainingPlan, setTrainingPlan] = useState<Blob|null>();
  const navigate = useNavigate();
  const onClick = () => {
    if(trainingPlan!==undefined && trainingPlan!==null){
      const downloadUrl = URL.createObjectURL(trainingPlan);
      downloadInNewTab(downloadUrl);
    }
  
  };
  useEffect(() => {
    async function fetchDocumentName() {
      try {
        const documentName = await getTrainingPlanService(); 
        if(documentName && documentName!.size>0){
          setTrainingPlan(documentName);
          setLastError( null );
        }else{
          const cites = await verifyVideocallTrainingService();
          if(cites.data.identificacion==="plan dieta"){
            setLastError("no entrenamiento");
          }
          if(cites.data.identificacion==="plan entrenamiento"){
            setCitaPendiente(cites.data.citaPendiente);
            setCitaRealizada(cites.data.citaRealizada);
            setLastError( null );
          }

        }
        setLoading(true);
      }catch (e) {
        setLoading(true);
        setLastError( (e as Error).message );
      }        
    }
    fetchDocumentName(); 
  }, []);

  return (
    <> 
          
    <div className="your_training_container">
      <h1> TU ENTRENAMIENTO</h1> 

      {!loading && <PrivateLoading />}
      
      {(loading && lastError!==null) &&
      <p>
En este momento, no cuentas con el plan que incluye entrenamiento. Si deseas adquirir tu plan con entrenamiento, no dudes en ponerte en <a onClick={()=>{navigate('/contacto')}}>contacto</a> con nosotros.
              </p>
      }

      {(loading && lastError===null && (citaPendiente===false && citaRealizada===false) && !trainingPlan) &&
        <p>Para poder obtener tu plan de entrenamiento personalizado tendrás primero que <a onClick={()=>handleOptionClick("Calendar")}>agendar tu videollamada</a>.
            Una vez la realices o decidas no agendarla podrás visualizarlo en tu navegador o, si lo prefieres,
            descargarlo, imprimirlo y colgarlo en tu nevera.<br/> ¡Vamos a por ello!
        </p>
      }

      {(loading && lastError===null && (citaPendiente===true && citaRealizada===false) && !trainingPlan) &&
        <p>Para poder obtener tu plan de entrenamiento personalizado tendrás primero que finalizar tu videollamada.
            Una vez realizada, el especialista enviará tu plan y podrás visualizarlo en tu navegador o, si lo prefieres,
            descargarlo, imprimirlo y colgarlo en tu nevera.<br/> ¡Vamos a por ello!
        </p>
      } 

      {(loading && lastError===null && citaRealizada===true && !trainingPlan) &&
      <p>
        Estamos personalizando tu plan de entrenamiento, una vez lo tengas podras descargarlo desde aquí, 
        visualizarlo en tu navegador o, si lo prefieres, descargarlo, imprimirlo y colgarlo en tu nevera. 
        <br/> ¡Vamos a por ello!
      </p>
      }

      {(loading && lastError===null && trainingPlan) &&
      <p>
        Descarga desde aquí tu entrenamiento personalizado. Podrás visualizarlo
        en tu navegador o, si lo prefieres, descargarlo, imprimirlo y colgarlo
        en tu nevera.<br/> ¡Vamos a por ello!
      </p>
      }

      {trainingPlan &&
      <>
      <div className="your_training_download" onClick={onClick}>
        <img className="img_your_training" alt="img" src={Image} />
        <span> DESCARGA TU ENTRENAMIENTO </span>
        <img className="icon_your_training" alt="icon" src={Icon} />
      </div>
      <div className="hint-training">
        <img src={Info} alt="training-info" />
        <p>
          Recuerda que dispones del <a onClick={()=>handleOptionClick("Contact Specialist")}>chat</a> con tu especialista para consultar
          cualquier duda sobre el plan que te pueda surgir.
        </p>
      </div>
      </>
      
      }
      
    </div>
    </>
  );
};

export default YourTraining;