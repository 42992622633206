import {useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import {trackView} from "../../../utils/FacebookPixelEvents";
import "./Cookies.css";
function Cookies() {

  useEffect(() => {
    trackView();
  }, []);

  return (
    <>
    <Helmet>
      <link rel="canonical" href={`${process.env.REACT_APP_FRONT_URL}`+"/cookies"} />
    </Helmet>
    <div className="cookies-container">
      <h1>Política de cookies (UE)</h1>
      <p>
        Esta política de cookies ha sido actualizada por última vez el Feb 23 y
        se aplica a los ciudadanos del Espacio Económico Europeo.
      </p>

      <h2>1. Introducción</h2>
      <p>
        Nuestra web, https://www.vikika.es (en adelante: «la web») utiliza
        cookies y otras tecnologías relacionadas (para mayor comodidad, todas
        las tecnologías se denominan «cookies»). Las cookies también son
        colocadas por terceros a los que hemos contratado. En el siguiente
        documento te informamos sobre el uso de cookies en nuestra web.
      </p>


      <h2>2. ¿Qué son las cookies?</h2>
      <p>
        Una cookie es un pequeño archivo simple que se envía junto con las
        páginas de esta web y que tu navegador almacena en el disco duro de tu
        ordenador o de otro dispositivo. La información almacenada puede ser
        devuelta a nuestros servidores o a los servidores de terceros relevantes
        durante una visita posterior.
      </p>


      <h2>3. ¿Qué son los scripts?</h2>
      <p>
        Un script es un fragmento de código de programa que se utiliza para
        hacer que nuestra web funcione correctamente y de forma interactiva.
        Este código se ejecuta en nuestro servidor o en tu dispositivo.
      </p>


      <h2>4. ¿Qué es una baliza web?</h2>
      <p>
        Una baliza web (o una etiqueta de píxel) es una pequeña e invisible
        pieza de texto o imagen en una web que se utiliza para hacer seguimiento
        del tráfico en una web. Para ello, se almacenan varios datos sobre ti
        mediante balizas web.
      </p>


      <h2>5. Cookies</h2>
      <p>COSTA LIFE FIT, SL, Con N.I.F. número B87767257, inscrita en el Registro Mercantil de Madrid a 7 de Marzo de 2017, en el tomo 35694, folio 185, inscripción 1 con hoja M-641486, email de contacto: direccion@vikika.es de ahora en adelante EMPRESA.</p>
      <p className={'strong'}><strong>5.1 Cookies técnicas o funcionales</strong></p>
      <p>
        Algunas cookies aseguran que ciertas partes de la web funcionen
        correctamente y que tus preferencias de usuario sigan recordándose. Al
        colocar cookies funcionales, te facilitamos la visita a nuestra web. De
        esta manera, no necesitas introducir repetidamente la misma información
        cuando visitas nuestra web y, por ejemplo, los artículos permanecen en
        tu cesta de la compra hasta que hayas pagado. Podemos colocar estas
        cookies sin tu consentimiento.
      </p>
      <p className={'strong'}><strong>5.2 Cookies analíticas</strong></p>
      <p>
        Usamos cookies analíticas para optimizar la experiencia en el sitio web
        para nuestros usuarios. Con estas cookies analíticas obtenemos
        conocimientos del uso de nuestro sitio web. Te pedimos tu permiso para
        insertar cookies analíticas.
      </p>
        <p className={'strong'}><strong>5.3 Botones de medios sociales</strong></p>
      <p>
        En nuestra web hemos incluidos botones para Facebook y Instagram
        promocionar páginas (p. ej. «me gusta», «pin») o compartirlas (p. ej.
        «tuitear») en redes sociales como Facebook y Instagram. Estos botones
        funcionan usando código de Facebook y Instagram ellos mismos. Este
        código incrusta cookies. Estos botones de redes sociales pueden
        almacenar y procesar cierta información, de esta manera puede ser
        mostrado un anuncio personalizado. Por favor lee la política de
        privacidad de estas redes sociales (que puede cambiar frecuentemente)
        para saber que hacen con tus datos (personales) que procesan usando
        estas cookies. Los datos que reciben son anonimizados el máximo posible.
        Facebook y Instagram están ubicados en los Estados Unidos.
      </p>


      <h2>6. Cookies usadas</h2>
      <div className={'accordeon-container'}>
        <p className={'strong'} style={{marginBottom: '1rem'}}><strong>6.1 Google Ads</strong></p>
        <table border={2} className="table_cookies">
          <tr className={'row-title'}>
            <th colSpan={3}>Marketing/Seguimiento</th>
          </tr>
          <tr className={'row-headings'}>
            <th>Nombre</th>
            <th>Retención</th>
            <th>Función</th>
          </tr>
          <tr className={'row-normal'}>
            <td>goog_pem_mod</td>
            <td>persistente</td>
            <td>Habilitar la publicación de anuncios o retargeting</td>
          </tr>
          <tr className={'row-normal'}>
            <td>google_adsense_settings</td>
            <td>1 año</td>
            <td>Habilitar la publicación de anuncios o la reorientación</td>
          </tr>
          <tr className={'row-normal'}>
            <td>__gads</td>
            <td>1 año</td>
            <td>Google DoubleClick</td>
          </tr>
        </table>
      </div>

      <div className={'accordeon-container'}>
        <p className={'strong'} style={{marginBottom: '1rem'}}><strong>6.2 Smartsupp</strong></p>
        <table border={2} className="table_cookies">
          <tr className={'row-title'}>
            <th colSpan={3}>Estadísticas (anónimas)</th>
          </tr>
          <tr className={'row-headings'}>
            <th>Nombre</th>
            <th>Retención</th>
            <th>Función</th>
          </tr>
          <tr className={'row-normal'}>
            <td>ssupp_*</td>
            <td>6 meses</td>
            <td>Contar y rastrear vistas de página</td>
          </tr>
        </table>
        <table border={2} className="table_cookies">
          <tr className={'row-title'}>
            <th colSpan={3}>0</th>
          </tr>
          <tr className={'row-headings'}>
            <th>Nombre</th>
            <th>Retención</th>
            <th>Función</th>
          </tr>
          <tr className={'row-normal'}>
            <td>ssupp.animbnr</td>
            <td></td>
            <td>Contar y rastrear vistas de página</td>
          </tr>
        </table>
        <table border={2} className="table_cookies">
          <tr className={'row-title'}>
            <th colSpan={3}>Funcional</th>
          </tr>
          <tr className={'row-headings'}>
            <th>Nombre</th>
            <th>Retención</th>
            <th>Función</th>
          </tr>
          <tr className={'row-normal'}>
            <td>ssupp.vid</td>
            <td>6 meses</td>
            <td>Contar y rastrear vistas de página</td>
          </tr>
          <tr className={'row-normal'}>
            <td>ssupp.visits</td>
            <td>1 año</td>
            <td>Almacenar número de visitas</td>
          </tr>
          <tr className={'row-normal'}>
            <td>ssupp_vid</td>
            <td>6 meses</td>
            <td>Contar y rastrear vistas de página</td>
          </tr>
        </table>
        <table border={2} className="table_cookies">
          <tr className={'row-title'}>
            <th colSpan={3}>Estadísticas</th>
          </tr>
          <tr className={'row-headings'}>
            <th>Nombre</th>
            <th>Retención</th>
            <th>Función</th>
          </tr>
          <tr className={'row-normal'}>
            <td>ssupp.chatid</td>
            <td>sesión</td>
            <td>Almacenar ID único usuario chat</td>
          </tr>
          <tr className={'row-normal'}>
            <td>ssupp_chatid</td>
            <td>sesión</td>
            <td>Almacenar ID único usuario chat</td>
          </tr>
        </table>
      </div>

      <div className={'accordeon-container'}>
        <p className={'strong'} style={{marginBottom: '1rem'}}><strong>6.3 Google Ads Optimization</strong></p>
        <table border={2} className="table_cookies">
          <tr className={'row-title'}>
            <th colSpan={3}>Marketing/Seguimiento</th>
          </tr>
          <tr className={'row-headings'}>
            <th>Nombre</th>
            <th>Retención</th>
            <th>Función</th>
          </tr>
          <tr className={'row-normal'}>
            <td>google_experiment_mod*</td>
            <td>3 meses</td>
            <td>
              Almacenar y realizar un seguimiento de las visitas a través de
              múltiples sitios web
            </td>
          </tr>
        </table>
        <table border={2} className="table_cookies">
          <tr className={'row-title'}>
            <th colSpan={3}>Funcional</th>
          </tr>
          <tr className={'row-headings'}>
            <th>Nombre</th>
            <th>Retención</th>
            <th>Función</th>
          </tr>
          <tr className={'row-normal'}>
            <td>id</td>
            <td>Sesión</td>
            <td></td>
          </tr>
        </table>
      </div>

      <div className={'accordeon-container'}>
        <p className={'strong'} style={{marginBottom: '1rem'}}><strong>6.4 Elementor</strong></p>
        <table border={2} className="table_cookies">
          <tr className={'row-title'}>
            <th colSpan={3}>Estadísticas (anónimas)</th>
          </tr>
          <tr className={'row-headings'}>
            <th>Nombre</th>
            <th>Retención</th>
            <th>Función</th>
          </tr>
          <tr className={'row-normal'}>
            <td>elementor</td>
            <td>persistente</td>
            <td>Guardar acciones hechas por usuarios en el sitio web</td>
          </tr>
        </table>
      </div>

      <div className={'accordeon-container'}>
        <p className={'strong'} style={{marginBottom: '1rem'}}><strong>6.5 WooCommerce</strong></p>
        <table border={2} className="table_cookies">
          <tr className={'row-title'}>
            <th colSpan={3}>Funcional</th>
          </tr>
          <tr className={'row-headings'}>
            <th>Nombre</th>
            <th>Retención</th>
            <th>Función</th>
          </tr>
          <tr className={'row-normal'}>
            <td>wc_cart_hash_*</td>
            <td>sesión</td>
            <td>Guardar artículos en el carrito de compras</td>
          </tr>
          <tr className={'row-normal'}>
            <td>wc_fragments_*</td>
            <td>persistente</td>
            <td></td>
          </tr>
        </table>
        <table border={2} className="table_cookies">
          <tr className={'row-title'}>
            <th colSpan={3}>Estadísticas</th>
          </tr>
          <tr className={'row-headings'}>
            <th>Nombre</th>
            <th>Retención</th>
            <th>Función</th>
          </tr>
          <tr className={'row-normal'}>
            <td>History.store</td>
            <td>Sesión</td>
            <td>Almacenar la última visita</td>
          </tr>
        </table>
      </div>

      <div className={'accordeon-container'}>
        <p className={'strong'} style={{marginBottom: '1rem'}}><strong>6.6 Google Analytics</strong></p>
        <table border={2} className="table_cookies">
          <tr className={'row-title'}>
            <th colSpan={3}>Estadísticas</th>
          </tr>
          <tr className={'row-headings'}>
            <th>Nombre</th>
            <th>Retención</th>
            <th>Función</th>
          </tr>
          <tr className={'row-normal'}>
            <td>__utmc</td>
            <td>30 minutos</td>
            <td>Guardar hora de visita</td>
          </tr>
          <tr className={'row-normal'}>
            <td>__utmz</td>
            <td>6 meses</td>
            <td>Guardar la palabra clave usada y el motor de búsqueda</td>
          </tr>
          <tr className={'row-normal'}>
            <td>__utma</td>
            <td>persistente</td>
            <td>Calcular días y tiempo para comprar</td>
          </tr>
          <tr className={'row-normal'}>
            <td>_gat_gtag_UA_*</td>
            <td>1 minuto</td>
            <td>Almacenar un ID de usuario único</td>
          </tr>
        </table>
        <table border={2} className="table_cookies">
          <tr className={'row-title'}>
            <th colSpan={3}>Marketing/Seguimiento</th>
          </tr>
          <tr className={'row-headings'}>
            <th>Nombre</th>
            <th>Retención</th>
            <th>Función</th>
          </tr>
          <tr className={'row-normal'}>
            <td>__utmv</td>
            <td>2 años</td>
            <td></td>
          </tr>
        </table>
        <table border={2} className="table_cookies">
          <tr className={'row-title'}>
            <th colSpan={3}>Estadísticas (anónimas)</th>
          </tr>
          <tr className={'row-headings'}>
            <th>Nombre</th>
            <th>Retención</th>
            <th>Función</th>
          </tr>
          <tr className={'row-normal'}>
            <td>_ga</td>
            <td>2 años</td>
            <td>Contar y rastrear páginas vistas</td>
          </tr>
          <tr className={'row-normal'}>
            <td>_gid</td>
            <td>1 día</td>
            <td>Contar y rastrear páginas vistas</td>
          </tr>
          <tr className={'row-normal'}>
            <td>_gat</td>
            <td>1 minuto</td>
            <td>Filtrar solicitudes de bots</td>
          </tr>
        </table>
      </div>

      <div className={'accordeon-container'}>
        <p className={'strong'} style={{marginBottom: '1rem'}}><strong>6.7 Smartlook</strong></p>
        <table border={2} className="table_cookies">
          <tr className={'row-title'}>
            <th colSpan={3}>Propósito pendiente de investigación</th>
          </tr>
          <tr className={'row-headings'}>
            <th>Nombre</th>
            <th>Retención</th>
            <th>Función</th>
          </tr>
          <tr className={'row-normal'}>
            <td>SL_C_*_KEY</td>
            <td></td>
            <td></td>
          </tr>
          <tr className={'row-normal'}>
            <td>SL_C_*_VID</td>
            <td></td>
            <td></td>
          </tr>
        </table>
      </div>

      <div className={'accordeon-container'}>
        <p className={'strong'} style={{marginBottom: '1rem'}}><strong>6.8 Facebook</strong></p>
        <table border={2} className="table_cookies">
          <tr className={'row-title'}>
            <th colSpan={3}>Marketing/Seguimiento</th>
          </tr>
          <tr className={'row-headings'}>
            <th>Nombre</th>
            <th>Retención</th>
            <th>Función</th>
          </tr>
          <tr className={'row-normal'}>
            <td>_fbp</td>
            <td>3 meses</td>
            <td>Almacenar y rastrear visitas en sitios web</td>
          </tr>
        </table>
      </div>


      <div className={'accordeon-container'}>
        <p className={'strong'} style={{marginBottom: '1rem'}}><strong>6.9 Stripe</strong></p>
        <table border={2} className="table_cookies">
          <tr className={'row-title'}>
            <th colSpan={3}>Funcional</th>
          </tr>
          <tr className={'row-headings'}>
            <th>Nombre</th>
            <th>Retención</th>
            <th>Función</th>
          </tr>
          <tr className={'row-normal'}>
            <td>__stripe_mid</td>
            <td>1 año</td>
            <td>Proporcionar prevención de fraude</td>
          </tr>
          <tr className={'row-normal'}>
            <td>__stripe_sid</td>
            <td>30 minutos</td>
            <td>Proporcionar prevención de fraude</td>
          </tr>
        </table>
      </div>


      <div className={'accordeon-container'}>
        <p className={'strong'} style={{marginBottom: '1rem'}}><strong>6.10 Complianz</strong></p>
        <table border={2} className="table_cookies">
          <tr className={'row-title'}>
            <th colSpan={3}>Funcional</th>
          </tr>
          <tr className={'row-headings'}>
            <th>Nombre</th>
            <th>Retención</th>
            <th>Función</th>
          </tr>
          <tr className={'row-normal'}>
            <td>complianz_policy_id</td>
            <td>365 días</td>
            <td>Almacenar la ID de la política de cookies aceptada</td>
          </tr>
        </table>
      </div>


      <div className={'accordeon-container'}>
        <p className={'strong'} style={{marginBottom: '1rem'}}><strong>6.11 Varios</strong></p>
        <table border={2} className="table_cookies">
          <tr className={'row-title'}>
            <th colSpan={3}>Propósito pendiente de investigación</th>
          </tr>
          <tr className={'row-headings'}>
            <th>Nombre</th>
            <th>Retención</th>
            <th>Función</th>
          </tr>
          <tr className={'row-normal'}>
            <td>SL_L_*_KEY</td>
            <td></td>
            <td></td>
          </tr>
          <tr className={'row-normal'}>
            <td>SL_L_*_VID</td>
            <td></td>
            <td></td>
          </tr>
          <tr className={'row-normal'}>
            <td>SL_L_*_SID</td>
            <td></td>
            <td></td>
          </tr>
          <tr className={'row-normal'}>
            <td>SL_C_*_SID</td>
            <td></td>
            <td></td>
          </tr>
          <tr className={'row-normal'}>
            <td>ssupp_animbnr</td>
            <td></td>
            <td></td>
          </tr>
          <tr className={'row-normal'}>
            <td>intercom-state-pny81bhe</td>
            <td></td>
            <td></td>
          </tr>
          <tr className={'row-normal'}>
            <td>et_pb_templates_et_pb_social_media_follow</td>
            <td></td>
            <td></td>
          </tr>
          <tr className={'row-normal'}>
            <td>et_pb_templates_et_pb_sidebar</td>
            <td></td>
            <td></td>
          </tr>
          <tr className={'row-normal'}>
            <td>et_pb_templates_et_pb_fullwidth_post_slider</td>
            <td></td>
            <td></td>
          </tr>
          <tr className={'row-normal'}>
            <td>et_pb_templates_et_pb_counter</td>
            <td></td>
            <td></td>
          </tr>
          <tr className={'row-normal'}>
            <td>et_pb_templates_et_pb_blurb</td>
            <td></td>
            <td></td>
          </tr>
          <tr className={'row-normal'}>
            <td>et_pb_templates_et_pb_image</td>
            <td></td>
            <td></td>
          </tr>
          <tr className={'row-normal'}>
            <td>et_pb_templates_et_pb_tab</td>
            <td></td>
            <td></td>
          </tr>
          <tr className={'row-normal'}>
            <td>et_pb_templates_et_pb_signup</td>
            <td></td>
            <td></td>
          </tr>
          <tr className={'row-normal'}>
            <td>et_pb_templates_et_pb_search</td>
            <td></td>
            <td></td>
          </tr>
          <tr className={'row-normal'}>
            <td>et_pb_templates_et_pb_fullwidth_image</td>
            <td></td>
            <td></td>
          </tr>
          <tr className={'row-normal'}>
            <td>et_pb_templates_et_pb_row</td>
            <td></td>
            <td></td>
          </tr>
          <tr className={'row-normal'}>
            <td>et_pb_templates_et_pb_accordion_item</td>
            <td></td>
            <td></td>
          </tr>
          <tr className={'row-normal'}>
            <td>et_pb_templates_et_pb_tabs</td>
            <td></td>
            <td></td>
          </tr>
          <tr className={'row-normal'}>
            <td>et_pb_templates_et_pb_shop</td>
            <td></td>
            <td></td>
          </tr>
          <tr className={'row-normal'}>
            <td>intercom-state</td>
            <td></td>
            <td></td>
          </tr>
          <tr className={'row-normal'}>
            <td>et_pb_templates_et_pb_map_pin</td>
            <td></td>
            <td></td>
          </tr>
          <tr className={'row-normal'}>
            <td>et_pb_templates_et_pb_comments</td>
            <td></td>
            <td></td>
          </tr>
          <tr className={'row-normal'}>
            <td>et_pb_templates_et_pb_fullwidth_menu</td>
            <td></td>
            <td></td>
          </tr>
          <tr className={'row-normal'}>
            <td>et_pb_templates_et_pb_row_inner</td>
            <td></td>
            <td></td>
          </tr>
          <tr className={'row-normal'}>
            <td>et_pb_templates_et_pb_login</td>
            <td></td>
            <td></td>
          </tr>
          <tr className={'row-normal'}>
            <td>et_pb_templates_et_pb_text</td>
            <td></td>
            <td></td>
          </tr>
          <tr className={'row-normal'}>
            <td>et_pb_templates_et_pb_fullwidth_code</td>
            <td></td>
            <td></td>
          </tr>
          <tr className={'row-normal'}>
            <td>et_pb_templates_et_pb_signup_custom_field</td>
            <td></td>
            <td></td>
          </tr>
          <tr className={'row-normal'}>
            <td>et_pb_templates_et_pb_number_counter</td>
            <td></td>
            <td></td>
          </tr>
          <tr className={'row-normal'}>
            <td>et_pb_templates_et_pb_accordion</td>
            <td></td>
            <td></td>
          </tr>
          <tr className={'row-normal'}>
            <td>et_pb_templates_et_pb_toggle</td>
            <td></td>
            <td></td>
          </tr>
          <tr className={'row-normal'}>
            <td>et_pb_templates_et_pb_circle_counter</td>
            <td></td>
            <td></td>
          </tr>
          <tr className={'row-normal'}>
            <td>et_pb_templates_et_pb_button</td>
            <td></td>
            <td></td>
          </tr>
          <tr className={'row-normal'}>
            <td>et_pb_templates_et_pb_blog</td>
            <td></td>
            <td></td>
          </tr>
          <tr className={'row-normal'}>
            <td>google_pem_mod</td>
            <td></td>
            <td></td>
          </tr>
          <tr className={'row-normal'}>
            <td>et_pb_templates_et_pb_fullwidth_post_title</td>
            <td></td>
            <td></td>
          </tr>
          <tr className={'row-normal'}>
            <td>et_pb_clipboard_type</td>
            <td></td>
            <td></td>
          </tr>
          <tr className={'row-normal'}>
            <td>et_pb_templates_et_pb_fullwidth_slider</td>
            <td></td>
            <td></td>
          </tr>
          <tr className={'row-normal'}>
            <td>et_pb_templates_et_pb_team_member</td>
            <td></td>
            <td></td>
          </tr>
          <tr className={'row-normal'}>
            <td>et_pb_templates_et_pb_post_title</td>
            <td></td>
            <td></td>
          </tr>
          <tr className={'row-normal'}>
            <td>et_pb_templates_et_pb_map</td>
            <td></td>
            <td></td>
          </tr>
          <tr className={'row-normal'}>
            <td>et_pb_templates_et_pb_slide</td>
            <td></td>
            <td></td>
          </tr>
          <tr className={'row-normal'}>
            <td>et_pb_templates_et_pb_fullwidth_header</td>
            <td></td>
            <td></td>
          </tr>
          <tr className={'row-normal'}>
            <td>et_pb_templates_et_pb_countdown_timer</td>
            <td></td>
            <td></td>
          </tr>
          <tr className={'row-normal'}>
            <td>et_pb_templates_et_pb_pricing_table</td>
            <td></td>
            <td></td>
          </tr>
          <tr className={'row-normal'}>
            <td>et_pb_templates_et_pb_pricing_tables</td>
            <td></td>
            <td></td>
          </tr>
          <tr className={'row-normal'}>
            <td>et_pb_templates_settings_product_version</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>admin_notice_remote_dismissed</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>et_pb_templates_et_pb_counters</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>et_pb_templates_et_pb_post_nav</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>et_pb_templates_et_pb_post_slider</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>et_pb_templates_et_pb_gallery</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>et_pb_templates_et_pb_testimonial</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>google_ama_settings</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>et_pb_templates_et_pb_video_slider</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>admin_notice_installed_dismissed</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>et_pb_templates_et_pb_fullwidth_portfolio</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>et_pb_templates_et_pb_column</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>et_pb_templates_settings_date</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>et_pb_templates_et_pb_divider</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>et_pb_templates_et_pb_audio</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>et_pb_templates_et_pb_slider</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>et_pb_templates_et_pb_section</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>et_pb_templates_et_pb_portfolio</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>et_pb_templates_et_pb_social_media_follow_network</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>et_pb_clipboard_content</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>et_pb_templates_et_pb_contact_field</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>et_pb_templates_et_pb_video</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>et_pb_templates_settings_active_plugins</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>et_pb_templates_et_pb_code</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>et_pb_templates_et_pb_filterable_portfolio</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>qm-container-height</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>et_pb_templates_et_pb_cta</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>et_pb_templates_et_pb_contact_form</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>et_pb_templates_et_pb_fullwidth_map</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>et_pb_templates_et_pb_video_slider_item</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>woocommerce_cart_hash</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>woocommerce_items_in_cart</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>wp_woocommerce_session_f332e13cb62a1df5b76e1bfc6b656553</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>et-editor-available-post-225809-bb</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>cmplz_marketing</td>
            <td>365 días</td>
            <td></td>
          </tr>
          <tr>
            <td>cmplz_stats</td>
            <td>365 días</td>
            <td></td>
          </tr>
          <tr>
            <td>euCookie</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>wp-api-schema-modelhttps://www.vikika.es/wp-json/wp/v2/</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>et-editing-post-210999-bb</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>et-editor-available-post-210999-bb</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>complianz_consent_status</td>
            <td>365 días</td>
            <td></td>
          </tr>
          <tr>
            <td>wp-autosave-1</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>et-recommend-sync-post-210999-bb</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>et-saved-post-255993-bb</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>wp-saving-post</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>activetab</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>et-pb-recent-items-colors</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>wc_cart_created</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>et-editor-available-post-240784-bb</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>et-editor-available-post-250306-bb</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>et-editor-available-post-247613-bb</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>et-editor-available-post-260309-bb</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>et-editor-available-post-260405-bb</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>google_auto_fc_cmp_setting</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>intercom-session-n2aw56gn</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>f0189a22486d1619013808852</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>f0189a22486d1617187912917</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>et-editor-available-post-265892-bb</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>et-editor-available-post-275137-bb</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>smartlook_ban_expire</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>smartlook_ban_reason</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>_gcl_au</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>f0189a22486d1629216244226</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>f0189a22486d1629216243757</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>__utmb</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>__utmt</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>et-editor-available-post-290639-bb</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>et-editing-post-290639-bb</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>et-recommend-sync-post-290639-bb</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>et-saved-post-292372-bb</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>et-editor-available-post-268243-bb</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>et-editor-available-post-292396-bb</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>et-editor-available-post-294952-bb</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>et-editor-available-post-240939-bb</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>et-editor-available-post-294879-bb</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>et-editor-available-post-297279-bb</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>et-editor-available-post-297632-bb</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>et-editor-available-post-299892-bb</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>et-editor-available-post-297587-bb</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>et-editor-available-post-299801-bb</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>et-editor-available-post-285144-bb</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>et-editor-available-post-285181-bb</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>et-editor-available-post-291163-bb</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>et-editor-available-post-299605-bb</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>et-editor-available-post-299797-bb</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>et-editor-available-post-279584-bb</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>et-editor-available-post-300564-bb</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>pys_fb_event_id</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>store_notice</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>_ga_DQPJQH654E</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>resolution</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>__gpi</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>plyr</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>wistia-video-progress-95z0cb0yxb</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>active_cx_tab</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>wistia</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>cache-sprite-plyr</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>wistia-video-progress-frwm2xrksl</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>psn_result_origscores</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>tablesorter-savesort</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>wistia-video-progress-09kolaz9o0</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>pagespeed_usability</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>psn_cache_timestamp</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>loglevel</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>wpr-show-sidebar</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>wistia-video-progress-7seqacq2ol</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>
              persist:hs-beacon-message-44cc73fb-7636-4206-b115-c7b33823551b
            </td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>pagespeed_mobile_orig</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>pagespeed_mobile</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>wistia-video-progress-803tlui8oi</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>psn_result_time</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>wistia-video-progress-fj42vucf99</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>pagespeed_desktop_orig</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>pagespeed_desktop</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>wpr-hash</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>psn_result_psnscores</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>pagespeed_usability_orig</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>wistia-video-progress-j042jylrre</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>persist:hs-beacon-44cc73fb-7636-4206-b115-c7b33823551b</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>wistia-video-progress-q9hba9mu96</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>wistia-video-progress-z1qxl7s2zn</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>wistia-video-progress-9m1zg8p5wc</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>undefined</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>mp_a36067b00a263cce0299cfd960e26ecf_mixpanel</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>et-editor-available-post-320921-bb</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>et-editor-available-post-267583-bb</td>
            <td></td>
            <td></td>
          </tr>
        </table>
        <table border={2} className="table_cookies">
          <tr className={'row-title'}>
            <th colSpan={3}>Funcional</th>
          </tr>
          <tr className={'row-headings'}>
            <th>Nombre</th>
            <th>Retención</th>
            <th>Función</th>
          </tr>
          <tr>
            <td>*_mf</td>
            <td></td>
            <td></td>
          </tr>
        </table>
      </div>


      <h2>7. Consentimiento</h2>
      <p>
        Cuando visites nuestra web por primera vez, te mostraremos una ventana
        emergente con una explicación sobre las cookies. Tan pronto como hagas
        clic en «Guardar preferencias», aceptas que usemos las categorías de
        cookies y plugins que has seleccionado en la ventana emergente, tal y
        como se describe en esta política de cookies. Puedes desactivar el uso
        de cookies a través de tu navegador, pero, por favor, ten en cuenta que
        nuestra web puede dejar de funcionar correctamente.
      </p>
      <p className={'strong'} style={{marginBottom: '1rem'}}><strong>7.1 Gestiona tus ajustes de consentimiento</strong></p>
      <ul>
        <li>Funcionales</li>
        <li>Estadísticas</li>
        <li>Estadísticas</li>
      </ul>
      <h2>8. Tus derechos con respecto a los datos personales</h2>
      <p>Tienes los siguientes derechos con respecto a tus datos personales:</p>
      <ul>
        <li>
          Tiene derecho a saber por qué se necesitan tus datos personales, qué
          sucederá con ellos y durante cuánto tiempo se conservarán.
        </li>
        <li>
          Derecho de acceso: tienes derecho a acceder a tus datos personales que
          conocemos.
        </li>
        <li>
          Derecho de rectificación: tienes derecho a completar, rectificar,
          borrar o bloquear tus datos personales cuando lo desees.
        </li>
        <li>
          Si nos das tu consentimiento para procesar tus datos, tienes derecho a
          revocar dicho consentimiento y a que se eliminen tus datos personales.
        </li>
        <li>
          Derecho de cesión de tus datos: tienes derecho a solicitar todos tus
          datos personales al responsable del tratamiento y a transferirlos
          íntegramente a otro responsable del tratamiento.
        </li>
        <li>
          Derecho de oposición: puedes oponerte al tratamiento de tus datos.
          Nosotros cumplimos con esto, a menos que existan motivos justificados
          para el procesamiento.
        </li>
      </ul>
      <p>
        Para ejercer estos derechos, por favor, contacta con nosotros. Por
        favor, consulta los detalles de contacto en la parte inferior de esta
        política de cookies. Si tienes alguna queja sobre cómo gestionamos tus
        datos, nos gustaría que nos la hicieras saber, pero también tienes
        derecho a enviar una queja a la autoridad supervisora (la autoridad de
        protección de datos).
      </p>
      <h2>9. Activación, desactivación y eliminación de cookies</h2>
      <p>
        Puedes utilizar tu navegador de Internet para eliminar las cookies de
        forma automática o manual. También puedes especificar que ciertas
        cookies no pueden ser colocadas. Otra opción es cambiar los ajustes de
        tu navegador de Internet para que recibas un mensaje cada vez que se
        coloca una cookie. Para obtener más información sobre estas opciones,
        consulta las instrucciones de la sección «Ayuda» de tu navegador. Ten en
        cuenta que nuestra web puede no funcionar correctamente si todas las
        cookies están desactivadas. Si borras las cookies de tu navegador, se
        volverán a colocar después de tu consentimiento cuando vuelvas a visitar
        nuestras webs.
      </p>
      <h2>10. Detalles de contacto</h2>
      <p>
      Para preguntas y/o comentarios sobre nuestra política de cookies y esta declaración, por favor, contacta con nosotros usando los siguientes datos de contacto: 
      <br></br>España Website: https://www.vikika.es<br></br> Email: direccion@vikika.es<br></br> Telefono: +34 689 98 51 44 .<br></br>Esta política de cookies ha sido sincronizada con cookiedatabase.org el Nov 16
      </p>
    </div>
    </>
  );
}

export default Cookies;
