import "./../../WhyVikika.css";

interface WhyCardProps {
    data: any;
}

const WhyCard = (whyCardProps: WhyCardProps) => {

    return (
        <>
            <div className='why-card'>
                    <div className='circular-bg'>
                        <img src={whyCardProps.data.icon} className="" alt="Slider" />
                    </div>
                    <div className='why-text'>
                        <p className='why-text-tittle'>{whyCardProps.data.title}</p>
                        <p className='why-text-subtittle'>{whyCardProps.data.subtitle}</p>
                    </div>
                </div>
        </>
    )
};

export default WhyCard
