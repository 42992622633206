import React from 'react';
import IconPrev from '../../../assets/img/arrow-prev.svg';
import IconNext from '../../../assets/img/arrow-next.svg';

const PrevArrow = (props: any) => {
    const { className, onClick, location } = props;
    return (
        <img src={IconPrev} className={className} onClick={onClick}
            style={{
                height: '32px',
                width: '32px',
                display: 'block',
                background: '#CFDEBE',
                borderRadius: '50%',
                boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                padding: '0.5rem',
                position: 'absolute',
                top: '50%',                
                transform: 'translateY(-50%)',
                left: location && location === 'product' ? '-1rem' : '2rem',
                zIndex: '1',               
             }}
alt = 'arrow' ></img >

    );
};

const NextArrow = (props: any) => {
    const { className, onClick, location } = props;
    return (
        <img src={IconNext} className={className} onClick={onClick}
            style={{
                height: '32px',
                width: '32px',
                display: 'block',
                background: '#CFDEBE',
                borderRadius: '50%',
                boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                padding: '0.5rem',
                position: 'absolute',
                top: '50%',                
                transform: 'translateY(-50%)',                
                zIndex: '1',
                right: location && location === 'product' ? '-1rem' : '2rem'
            }}
            alt='arrow'></img>

    );
};

const DietPrevArrow = (props: any) => {
    const { className, onClick, location } = props;
    return (
        <img src={IconPrev} className={className} onClick={onClick}
            style={{
                height: '32px',
                width: '32px',
                display: 'block',
                background: '#ECC4B4',
                borderRadius: '50%',
                boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                padding: '0.5rem',
                position: 'absolute',
                top: '50%',                
                transform: 'translateY(-50%)',
                left: location && location === 'product' ? '-1rem' : '2rem',
                zIndex: '0',               
             }}
alt = 'arrow' ></img >

    );
};

const DietNextArrow = (props: any) => {
    const { className, onClick, location } = props;
    return (
        <img src={IconNext} className={className} onClick={onClick}
            style={{
                height: '32px',
                width: '32px',
                display: 'block',
                background: '#ECC4B4',
                borderRadius: '50%',
                boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                padding: '0.5rem',
                position: 'absolute',
                top: '50%',                
                transform: 'translateY(-50%)',                
                zIndex: '0',
                right: location && location === 'product' ? '-1rem' : '2rem'
            }}
            alt='arrow'></img>

    );
};

export { PrevArrow, NextArrow, DietPrevArrow, DietNextArrow };
