import { useState, useEffect } from 'react';
import MediaQuery from 'react-responsive';
import downloadIcon from '../../../../assets/img/downloadIcon.svg';
import { getPlansRecordService,getPlanPdfService,PlanRecorded, msgsDiet } from "../../../../services/getPlansRecordService";
import PrivateLoading from "../../../shared/loading/PrivateLoading";
import './PlansRecord.css'
import ModalShowMessages from "./Modal_messages";

const downloadInNewTab = ( downloadUrl: string) => {
    const link = document.createElement("a");
    link.href = downloadUrl;
    link.target = "_blank";
    link.download = 'Vikika_plan';
    link.click();
};

const PlansRecord = () => {
    const [listDietRealData, setListDietRealData] = useState<Array<PlanRecorded>>([]);
    const [listMsgs, setListMsgs] = useState<Array<msgsDiet>>([]);
    const [showModalMessages, setShowModalMessages] = useState(false);
    const [loaded, setLoaded] = useState(false);
    //const [error, setError] = useState(false);
    const [error, setError] = useState<string | null>(null);

    const handleDownloadPdfDiet=async(fasePdf:string)=>{
        //setError(false);
        try {
            const documentName = await getPlanPdfService(fasePdf); 
            if(documentName.size>0){
                const downloadUrl = URL.createObjectURL(documentName);
                downloadInNewTab(downloadUrl);
            }
        } catch (e) {
            //setError(true);
            console.log("Se ha producido un error al descargar el pdf");
        }

    }

    const handleShowMsgs= (msgs:msgsDiet[]|undefined)=>{
        if(msgs){
            setListMsgs(msgs);
            setShowModalMessages(true);
        }
    }

    const categpriesDictionary: Record<string, string> = {
        die_mes: 'Plan de dieta',
        par_die_mes: 'Plan de dieta para pareja',
        mes: 'Plan de dieta + entrenamiento en Gym',
        mes_dev: 'Plan de dieta + entrenamiento en Entrena virtual',
        mes_cas: 'Plan de dieta + entrenamiento en Casa',
        par_mes: 'Plan de dieta para pareja + entrenamiento en Gym',
        par_mes_cas: 'Plan de dieta para pareja + entrenamiento en Casa',
        die_mes_psi: 'Vital Wellness',
        ent_mes: 'Plan entrenamiento básico en gym',
        die_seg: 'Programa intensivo de dieta',
        grow: 'Programa clientes Grow',
        seg: 'Programa intensivo 360º',
        seg_cas: 'Programa intensivo 360º en casa',
        ent_cas: 'Plan entrenamiento básico en casa',
        die_pre: 'Programa presencial',
        die_prem: 'Dieta específico',
        seg_prem: 'Dieta + entrenamiento específico Gym',
        seg_prem_cas: 'Dieta + entrenamiento específico Casa',
        par_die_prem: 'Parejas - Dieta específico',
        par_seg_prem: 'Parejas - Dieta + entrenamiento específico Gym',
        par_seg_prem_cas: 'Parejas - Dieta + entrenamiento específico Casa',
        par_die_seg: 'Parejas - Programa intensivo dieta',
        par_ent_mes: 'Parejas - Plan entrenamiento básico en gym',
        par_ent_cas:'Parejas - Plan entrenamiento básico en casa',
        par_seg: 'Parejas - Programa intensivo 360º',
        par_seg_cas: 'Parejas - Programa intensivo 360º en casa',
        verano: 'PLAN EsteVeranoSÍ',
        onshape: 'ON SHAPE',
        wel_com: 'Wellcome pack',
    }; 

    useEffect(() => {
        const fetchOrders = async () => {
            try{
                const responseData = await getPlansRecordService();
                if (responseData) {
                    setListDietRealData(responseData);
                    setLoaded(true);
                } else {
                    setLoaded(true);
                }
            }catch (err) {
                setError('Parece que se ha producido un error al cargar los datos.');
                setLoaded(true);
            }
        }
        fetchOrders();
    }, [])

    return (
        <main className="main-content">
            
            <div className='plans-record-container'>
                <div>
                    <div className='profile-body-title-plansRecord'>
                        <p>HISTORIAL DE PLANES </p>
                    </div>

                    {!loaded && (<PrivateLoading />)}

                    {loaded &&  (
                        <>
                            <div className={'order-history'}>
                                <div className={'order-history-options'}>
                                    <p className={'active-option'}>Dieta</p>
                                </div> 

                                    <>
                                    <MediaQuery minWidth={768}>
                                        <table className={'table-diet plans-subscription-table-only-desktop'}>
                                        <tbody>
                                            <tr>
                                                <th>Plan</th>
                                                <th>Compra</th>
                                                <th>Dieta</th>
                                                <th>Entrenamiento</th>
                                                <th>Chat</th>
                                            </tr>
                                            {listDietRealData.map((item, index2) => (
                                                    item.pdfs && item.pdfs.map((fase,index)=>(
                                                        <tr key={index+Math.random()}>
                                                        <td>{categpriesDictionary[item.plan]+" ID "+item.planID}</td>
                                                        <td>{fase.fecha}</td>
                                                        {fase.pdf_dieta? (
                                                        <td><button className='plans-record-downloader' onClick={()=>handleDownloadPdfDiet(fase.pdf_dieta)}><img src={downloadIcon} alt={'download'} />Descargar</button></td>  
                                                        ):
                                                        (<td></td>)
                                                        }
                                                        {fase.pdf_entrenamiento? (
                                                        <td><button className='plans-record-downloader' onClick={()=>handleDownloadPdfDiet(fase.pdf_entrenamiento)}><img src={downloadIcon} alt={'download'} />Descargar</button></td>  
                                                        ):
                                                        (<td></td>)
                                                        }
                                                        {item.msgs? (
                                                        <td><button className='plans-record-downloader'onClick={()=>handleShowMsgs(item.msgs)}>Ver</button></td>  
                                                        ):
                                                        (<td></td>)
                                                        }
                                                        </tr>
                                                    ))
                                                ))
                                            }   
                                        </tbody>
                                        </table>
                                        {error &&(
                                            <div className="not-plan-text-container">
                                                <span className="not-plan-text">{error}</span>
                                            </div> 
                                        )}
                                        {(!error && (listDietRealData.length === 0 || (listDietRealData.length === 1 && !listDietRealData[0].pdfs))) &&  (
                                            <div className="not-plan-text-container">
                                                <span className="not-plan-text">Actualmente no tenemos planes para mostrar.</span>
                                            </div>    
                                        )}
                                            

                                    </MediaQuery>
                                    <MediaQuery maxWidth={768}>
                                        {error &&(
                                            <div className="not-plan-text-container">
                                                <span className="not-plan-text">{error}</span>
                                            </div> 
                                        )}
                                        {(!error && (listDietRealData.length === 0 || (listDietRealData.length === 1 && !listDietRealData[0].pdfs))) &&  (
                                            <div className="not-plan-text-container">
                                             <span className="not-plan-text">Actualmente no tenemos planes para mostrar.</span>
                                            </div> 
                                        )}
                                        <table className={'table-diet plans-record-table-card-mobile'}>
                                        {listDietRealData.map((item, index2) => (
                                                item.pdfs && item.pdfs.map((fase,index)=>(
                                                    <>
                                                    <tr key={index+Math.random()}>
                                                        <td>Plan</td>
                                                        <td>{categpriesDictionary[item.plan]+" ID "+item.planID}</td>
                                                    </tr>
                                                    <tr key={index+Math.random()}>
                                                        <td>Compra</td>
                                                        <td>{fase.fecha}</td>
                                                    </tr>
                                                    <tr key={index+Math.random()}>
                                                        <td>Dieta</td>
                                                        {fase.pdf_dieta? (
                                                        <td><button className='plans-record-downloader' onClick={()=>handleDownloadPdfDiet(fase.pdf_dieta)}><img src={downloadIcon} alt={'download'} />Descargar</button></td>  
                                                        ):
                                                        (<td></td>)
                                                        }
                                                    </tr>
                                                    <tr key={index+Math.random()}>
                                                        <td>Entrenamiento</td>
                                                        {fase.pdf_entrenamiento? (
                                                        <td><button className='plans-record-downloader' onClick={()=>handleDownloadPdfDiet(fase.pdf_entrenamiento)}><img src={downloadIcon} alt={'download'} />Descargar</button></td>  
                                                        ):
                                                        (<td></td>)
                                                        }
                                                    </tr>
                                                    <tr key={index+Math.random()}>
                                                        <td className='tdBottom'>Chat</td>
                                                        {item.msgs? (
                                                        <td className='tdBottom'><button className='plans-record-downloader'onClick={()=>handleShowMsgs(item.msgs)}>Ver</button></td>  
                                                        ):
                                                        (<td className='tdBottom'></td>)
                                                        }
                                                    </tr>
                                                    </>
                                                ))
                                            )
                                        )}
                                        </table>
                                    </MediaQuery>
                                    </>
                            </div>
                            {showModalMessages && (
                                <ModalShowMessages
                                onHide={() => setShowModalMessages(false)}
                                msgs={listMsgs}
                                />
                            )}
                        </>
                    )
                    }

                </div>
            </div>
        </main>
    )
}

export default PlansRecord;
