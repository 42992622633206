import { useState } from 'react';
import IconObjetive from '../../../../assets/img/target_type.svg'
import IconFoodSecurity from '../../../../assets/img/food_type.svg'
import IconDieta from '../../../../assets/img/type_allergy.svg'
import IconWomen from '../../../../assets/img/type_health.svg'
import Circle from '../../../../assets/img/circle_green.svg'
import Icon from "../../../../assets/img/arrow-1.svg";


interface ProductVariants {     //already defined
    name: string,
    options: string[],
    onSelectionChange: (selected: string[]) => void;
}
interface Option {
    value: string;
    label: string;
    icon: string;
}

const ProductVariants = () => {
    const [isOpenTipoObjetivo, setIsOpenTipoObjetivo] = useState(false);
    const [isOpenTipoAlimentacion, setIsOpenTipoAlimentacion] = useState(false);
    const [isOpenTipoAlergiasIntolerancias, setIsOpenTipoAlergiasIntolerancias] = useState(false);
    const [isOpenSaludMujer, setIsOpenSaludMujer] = useState(false);
    const tipo_objetivo_options: Option[] = [
        { value: 'option1', label: 'Adelgazar y quemar grasa', icon: `${Circle}` },
        { value: 'option2', label: 'Aumentar masa muscular', icon: `${Circle}` },
        { value: 'option3', label: 'Adquirir hábitos saludables ', icon: `${Circle}` },
        { value: 'option4', label: 'Mejorar mi rendimiento deportivo ', icon: `${Circle}` },
    ];
    const tipo_alimentacion_options: Option[] = [
        { value: 'option1', label: 'Veganos', icon: `${Circle}` },
        { value: 'option2', label: 'Vegetarianos', icon: `${Circle}` },
        { value: 'option3', label: 'Flexitarianos', icon: `${Circle}` },
        { value: 'option4', label: 'Sin restricciones', icon: `${Circle}` },
    ];
    const tipo_alergias_intolerancias_options: Option[] = [
        { value: 'option1', label: 'Celiaquía', icon: `${Circle}` },
        { value: 'option2', label: 'Alergia al trigo u otros cereales', icon: `${Circle}` },
        { value: 'option3', label: 'Intolerancia a la lactosa', icon: `${Circle}` },
        { value: 'option4', label: 'Intolerancia a la fructosa/sorbitol', icon: `${Circle}` },
        { value: 'option5', label: 'Síndrome intestino irritable', icon: `${Circle}` },
        { value: 'option6', label: 'SIBO', icon: `${Circle}` },
        { value: 'option7', label: 'Helicobacter', icon: `${Circle}` },
        { value: 'option8', label: 'Reflujo Gastroesofágico', icon: `${Circle}` },
        { value: 'option9', label: 'Autoinmunes', icon: `${Circle}` },
        { value: 'option10', label: 'Hipotiroidismo Hashimoto', icon: `${Circle}` },
        { value: 'option11', label: 'Diabetes (DM1)', icon: `${Circle}` },
        { value: 'option12', label: 'Lipedema', icon: `${Circle}` },
        { value: 'option13', label: 'Patologías Metabólicas', icon: `${Circle}` },
        { value: 'option14', label: 'Síndrome metabólico', icon: `${Circle}` },
        { value: 'option15', label: 'Hipotiroidismo/hipertiroidismo', icon: `${Circle}` },
        { value: 'option16', label: 'Diabetes (DM2) ', icon: `${Circle}` },
        { value: 'option17', label: 'Sobrepeso/obesidad', icon: `${Circle}` },
        { value: 'option18', label: 'Otros', icon: `${Circle}` },
    ];
    const salud_mujer_options: Option[] = [
        { value: 'option1', label: 'Menopausia', icon: `${Circle}` },
        { value: 'option2', label: 'Planes para embarazadas', icon: `${Circle}` },
        { value: 'option3', label: 'Planes postparto/lactancia materna', icon: `${Circle}` },
        { value: 'option4', label: 'Premenopausia', icon: `${Circle}` },
        { value: 'option6', label: 'Planes preparación del embarazo', icon: `${Circle}` },
        { value: 'option5', label: 'SOP', icon: `${Circle}` },
        { value: 'option7', label: 'Otros', icon: `${Circle}` },

    ];
    function handleToggleSelectTipoObjetivo() {
        setIsOpenTipoObjetivo(!isOpenTipoObjetivo);
        setIsOpenTipoAlimentacion(false);
        setIsOpenTipoAlergiasIntolerancias(false);
        setIsOpenSaludMujer(false);
    }
    function handleToggleSelectTipoAlimentacion() {
        setIsOpenTipoAlimentacion(!isOpenTipoAlimentacion);
        setIsOpenTipoObjetivo(false);
        setIsOpenTipoAlergiasIntolerancias(false);
        setIsOpenSaludMujer(false);
    }
    function handleToggleSelectTipoAlergiasIntolerancias() {
        setIsOpenTipoAlergiasIntolerancias(!isOpenTipoAlergiasIntolerancias);
        setIsOpenTipoObjetivo(false);
        setIsOpenTipoAlimentacion(false);
        setIsOpenSaludMujer(false);
    }
    function handleToggleSelectSaludMujer() {
        setIsOpenSaludMujer(!isOpenSaludMujer);
        setIsOpenTipoObjetivo(false);
        setIsOpenTipoAlimentacion(false);
        setIsOpenTipoAlergiasIntolerancias(false);
    }


    return (
        <>
            <div className='custom-flex-container'>
                <div className='custom-flex-item'>
                    <span style={{ paddingRight: '10px' }}><img src={IconObjetive} alt='info' /></span>
                    <a style={{cursor: 'pointer'}}  onClick={handleToggleSelectTipoObjetivo}>Tipo de Objetivo</a>
                    <span style={{ paddingRight: '10px', cursor: 'pointer' }}><img alt='icon' className="icon-collapse" src={Icon} onClick={handleToggleSelectTipoObjetivo} /></span>
                    {isOpenTipoObjetivo && (
                        <div>
                            {tipo_objetivo_options.map((option) => (
                                <div className='variants-options' key={option.value} onClick={handleToggleSelectTipoObjetivo}>
                                    <span className='circular-variants-icon' style={{ paddingRight: '10px' }}><img src={option.icon} alt='info' /></span>
                                    <a className='no-underline'>{option.label}</a>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
                <div className='custom-flex-item'>
                    <span style={{ paddingRight: '10px' }}><img src={IconFoodSecurity} alt='info' /></span>
                    <a style={{cursor: 'pointer'}}  onClick={handleToggleSelectTipoAlimentacion}>Tipo de alimentación</a>
                    <span style={{ paddingRight: '10px', cursor: 'pointer' }}><img alt='icon' className="icon-collapse" src={Icon} onClick={handleToggleSelectTipoAlimentacion} /></span>
                    {isOpenTipoAlimentacion && (
                        <div>
                            {tipo_alimentacion_options.map((option) => (
                                <div className='variants-options' key={option.value} onClick={handleToggleSelectTipoAlimentacion}>
                                    <span className='circular-variants-icon' style={{ paddingRight: '10px' }}><img src={option.icon} alt='info' /></span>
                                    <a >{option.label}</a>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
                <div className='custom-flex-item'>
                    <span style={{ paddingRight: '10px' }}><img src={IconDieta} alt='info' /></span>
                    <a style={{cursor: 'pointer'}}  onClick={handleToggleSelectTipoAlergiasIntolerancias}>T. alergias e intolerancias</a>
                    <span style={{ paddingRight: '10px', cursor: 'pointer' }}><img alt='icon' className="icon-collapse" src={Icon} onClick={handleToggleSelectTipoAlergiasIntolerancias} /></span>
                    {isOpenTipoAlergiasIntolerancias && (
                        <div>
                            {tipo_alergias_intolerancias_options.map((option) => (
                                <div className='variants-options' key={option.value} onClick={handleToggleSelectTipoAlergiasIntolerancias}>
                                    <span className='circular-variants-icon' style={{ paddingRight: '10px' }}><img src={option.icon} alt='info' /></span>
                                    <a >{option.label}</a>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
                <div className='custom-flex-item' style={{marginLeft: '5px'}}>
                    <span style={{ paddingRight: '10px' }}><img src={IconWomen} alt='info' /></span>
                    <a style={{cursor: 'pointer'}}  onClick={handleToggleSelectSaludMujer}>Salud en la mujer</a>
                    <span style={{ paddingRight: '10px', cursor: 'pointer' }}><img alt='icon' className="icon-collapse" src={Icon} onClick={handleToggleSelectSaludMujer} /></span>
                    {isOpenSaludMujer && (
                        <div>
                            {salud_mujer_options.map((option) => (
                                <div className='variants-options' key={option.value} onClick={handleToggleSelectSaludMujer}>
                                    <span className='circular-variants-icon' style={{ paddingRight: '10px' }}><img src={option.icon} alt='info' /></span>
                                    <a >{option.label}</a>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </div>

        </>
    );
}
export default ProductVariants
