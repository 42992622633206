import {
    CATEGORY_DIETA,
    CATEGORY_PSICOLOGIA,
    CATEGORY_TALLER,
} from "../../../../utils/constants";
import FaqButton from "./faqButton/FaqButton";
import {ButtonData} from "../../../../models/ButtonData";
import {FaqButtonsProps} from "../../../../models/componentProps/FaqButtonsProps";

const FaqButtons = (faqButtonsProps: FaqButtonsProps) => {

    const buttonsData: Array<ButtonData> = [
        {
            title: 'Plan de Dieta',
            asociatedCategory: CATEGORY_DIETA
        },
        {
            title: 'Plan de Psicología',
            asociatedCategory: CATEGORY_PSICOLOGIA
        },
        {
            title: 'Talleres',
            asociatedCategory: CATEGORY_TALLER
        }
    ]

    const buttonsContent = (
        buttonsData.map((button, index) => (
            <FaqButton
                key={index}
                selectedCategory={faqButtonsProps.selectedCategory}
                handleButtonClick={faqButtonsProps.handleButtonClick}
                title={button.title}
                categoryToCompare={button.asociatedCategory}/>
        ))
    )

    return (
        <div className="plans-type-select" style={{marginBottom: '2rem'}}>
            <>
                <div className="scrollmenu">
                    {buttonsContent}
                </div>
            </>
        </div>
    )
}

export default FaqButtons
