import React from "react";
import Info from "../../../../../assets/img/info_icon.png";
import "./Hint.css";
import parse from "html-react-parser";

interface ComponentProps {
    text: string;
    width?: string;
}

function Hint(props: ComponentProps) {

    return (
        <div className="hint-diet" style={{width: props.width ? props.width : '400px'}}>
            <img src={Info} alt="" />
            <p>
                {parse(props.text)}
            </p>
        </div>
    );
}

export default Hint;
