/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import Arrow from "../../../../../assets/img/arrow-right.png";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import 'dayjs/locale/es';
import Hours from "./hours";
import dayjs from "dayjs";
import { IAppointmentSlot } from "../../../../../services/getPsiCalendarService";

type MyProps = {
    onHideDrawer: () => void;
    onSelectAppointmentSlot: (slot: IAppointmentSlot) => void;
    selectedDate: string;
    options: IAppointmentSlot[];
}

export default function CalendarHoursArea({
    onHideDrawer,
    onSelectAppointmentSlot,
    selectedDate,
    options
}: MyProps) {
    return (
        <Box style={{ top: "60px" }} role="presentation">
          <Divider />
          <List>
            <img
              className="arrow_calendar"
              src={Arrow}
              alt="arrow"
              onClick={onHideDrawer}
            />
            <Hours
              title="Gestión de videollamada"
              subtitle={`Seleccionar horario para el ${(dayjs(selectedDate)).locale('es').format(
                "DD [de] MMMM [del] YYYY"
              )}`}
              options={options}
              setSelectedSlot={onSelectAppointmentSlot}
            />
          </List>
        </Box>
      );

}