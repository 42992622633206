import {useQuery} from 'react-query';
import axiosCodeigniter from '../api/axiosCodeigniter';
import { GET_COUPLE_PSI_CHAT_URL } from '../utils/constants';
interface Mensaje {
    mensaje: string;
    es_cliente: string;
}
interface ClientChat {
    // canWrite: boolean;
    // avaliableMessages: number;
    // max:number;
    mensajes: Mensaje[];
}
  
export default function useCouplePsiMessageQuery() {
    return useQuery<ClientChat>('clientMessages', async () => {
        const token = localStorage.getItem("token");
        const config = {
            headers: {
                Authorization: `Bearer ${token}`
            },
        };
        // return {
        //     canWrite: false,
        //     avaliableMessages: 0,
        //     max: 0,
        //     mensajes: [
        //         {
        //             es_cliente: "0",
        //             mensaje: "Hola," + Math.random(),
        //         }
        //     ],
        // }
        console.log(config);
        const ret = await axiosCodeigniter.get<ClientChat>(GET_COUPLE_PSI_CHAT_URL, config);
        console.log(ret);
        return ret.data;
    },{
        refetchInterval: 2000,
    });
}