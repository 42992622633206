import axiosCodeigniter from "../api/axiosCodeigniter";
import {AxiosResponse} from "axios";
import {SUBSCRIPTION_REVIEW_PLAN_URL} from "../utils/constants";

import { ProgressData } from '../models/formsModels/ReviewProgressData';
import { FeedingData } from "../models/formsModels/ReviewFeedingData";
import { InterestData } from "../models/formsModels/ReviewInterestData";

interface ReviewPlanFormData {
    progressDataToSend:ProgressData;
    feedingDataToSend:FeedingData;
    interestDataToSend:InterestData;
}

export const PlanReviewSubscriptionService = async (reviewPlanFormData:ReviewPlanFormData):Promise<AxiosResponse> => {
    const token = localStorage.getItem('token');
    const formData= new FormData();
    formData.append("dataSend",JSON.stringify(reviewPlanFormData));
    return await axiosCodeigniter.post(SUBSCRIPTION_REVIEW_PLAN_URL,
        formData,
        {
            headers: { 'Content-Type':'multipart/form-data', Authorization: `Bearer ${token}` }
        }
    );


}