import {useRef, useState, useEffect, useContext, SyntheticEvent} from 'react';
import {Link, Path, useNavigate} from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import AuthContext from "../../../providers/AuthProvider";

import {trackView} from "../../../utils/FacebookPixelEvents";
import Logo from '../../../assets/img/logo_mejorado.png';
import CancelModal from '../../../assets/img/iconoir_cancel.svg';
import "./Login.css";
import {loginService} from "../../../services/loginService";
import {productsService} from "../../../services/productsService";
import {isAxiosError} from "axios";
import {EMAIL_REGEX} from "../../../utils/constants";
import LoginImage from '../../../assets/img/imagen_equipo_login_50_1_50.jpg';
import Loading from '../../shared/loading/Loading';
import Tooltip, {TooltipProps, tooltipClasses} from '@mui/material/Tooltip';
import IconInfo from '../../../assets/img/info-black.svg'
import {Button, styled, Typography} from "@mui/material";
import React from 'react';
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import {IconButton} from "@material-ui/core";
import ToastMsgComponent from "../../shared/toast/ToastMsg";
import {forgotPasswordService} from "../../../services/forgotPasswordService";
import iconCheck from "../../../assets/img/mapas-y-banderas_1.png";

const Login = () => {
    const navigate = useNavigate();
    const {setAuth} = useContext(AuthContext);
    const errRef = useRef<HTMLInputElement>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [username, setUser] = useState('');
    const [password, setPwd] = useState('');
    const [errMsg, setErrMsg] = useState('');
    const [showFailureToast, setShowFailureToast] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [emailForgotPassword, setEmailForgotPassword] = useState('');
    const [showModalForgot, setShowModalForgot] = useState(false);
    const [validEmail, setValidEmail] = useState(false);
    const [disableButtonOnForgot, setDisableButtonOnForgot] = useState(false);
    const [disableButtonOnLogin, setDisableButtonOnLogin] = useState(false);

    const [openTooltip, setOpenTooltip] = useState(false);

    const handleShowTooltip = () => {
        setOpenTooltip(!openTooltip);
    };

    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const HtmlTooltip = styled(({className, ...props}: TooltipProps) => (
        <Tooltip {...props} classes={{popper: className}}/>
    ))(({theme}) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: '#fff',
            color: '#29292E',
            maxWidth: 220,
            fontFamily: 'Montserrat',
            fontSize: theme.typography.pxToRem(12),
            border: '1px solid #dadde9',
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        },
    }));

    const handleClickForgot = async () => {
        try {
            setDisableButtonOnForgot(true);
            setShowModalForgot(false);
            setIsLoading(true);
            const forgotPasswordResponse = await forgotPasswordService({
                email: emailForgotPassword,
            });
            setIsLoading(false);
            setDisableButtonOnForgot(false);
            if (forgotPasswordResponse) {
                setShowSuccessModal(true);
            } else {
                setShowFailureToast(true);
                setTimeout(() => {
                    setShowFailureToast(false);
                }, 10000);
            }
        } catch (e) {
            setIsLoading(false);
            setDisableButtonOnForgot(false);
            setShowFailureToast(true);
            setTimeout(() => {
                setShowFailureToast(false);
            }, 10000);
        }
    }

    const handleFinishForgot = () => {
        setShowSuccessModal(false);
        window.location.reload();
    }

    const redirectTo = (savedRoute:any)=>{
        savedRoute === null
        ? navigate(`/private`)
        //? window.location.href = '/private'
        : navigate(savedRoute)
    }

    useEffect(() => {
      trackView();
    }, []);

    useEffect(() => {
        setErrMsg('');
    }, [username, password])

    const handleSubmit = async (e: SyntheticEvent) => {
        e.preventDefault();
        setDisableButtonOnLogin(true);

        try {
            setIsLoading(true);
            const response = await loginService({username, password});
            console.log(response.data);
            setDisableButtonOnLogin(false);
            localStorage.setItem('status', 'isLoggedIn');
            localStorage.setItem('id', response.data.id);
            localStorage.setItem('registered_date', response.data.registered_date);
            localStorage.setItem('email', response.data.email);
            localStorage.setItem('password', password);
            localStorage.setItem('nicename', response.data.nicename);
            localStorage.setItem('firstName', response.data.billing_info.first_name);
            localStorage.setItem('lastName', response.data.billing_info.last_name);
            localStorage.setItem('displayName', response.data.displayName);
            localStorage.setItem('token', response.data.token);
            localStorage.setItem('address_1', response.data.billing_info.address_1);
            localStorage.setItem('address_2', response.data.billing_info.address_2);
            localStorage.setItem('city', response.data.billing_info.city);
            localStorage.setItem('state', response.data.billing_info.state);
            localStorage.setItem('postcode', response.data.billing_info.postcode);
            localStorage.setItem('country', response.data.billing_info.country);
            localStorage.setItem('tokenExpiration', response.data.tokenExpiration);
            localStorage.setItem('phone', response.data.billing_info.phone);
            localStorage.setItem('pon_user_info', JSON.stringify(response.data.pon_user_info));
            const products= await productsService();
            localStorage.setItem('products', JSON.stringify(products));
            setIsLoading(false);
            setUser('');
            setPwd('');
            const savedRoute = localStorage.getItem('nextViewAfterLogin');
            console.log(savedRoute);
            const encodedEmail = btoa(username);
            const encodedPassword = btoa(password);
            localStorage.setItem('encodedEmail', encodedEmail);
            localStorage.setItem('encodedPassword', encodedPassword);
            redirectTo(savedRoute); 

        } catch (err) {
            setIsLoading(false);
            setDisableButtonOnLogin(false);
            if (isAxiosError(err)) {
                if (!err.response) {
                    setErrMsg('No Server Response');
                } else if (err.response?.status === 400) {
                    setErrMsg('Missing Username or Password');
                } else if (err.response?.status === 401) {
                    setErrMsg('Unauthorized');
                } else {
                    setErrMsg('Login Failed');
                }
            }

            errRef.current?.focus();
        }
    }

    useEffect(() => {
        setValidEmail(EMAIL_REGEX.test(emailForgotPassword));
    }, [emailForgotPassword]);

    return (
      <>
      <Helmet>
        <link rel="canonical" href={`${process.env.REACT_APP_FRONT_URL}`+"/iniciar-sesion"} />
      </Helmet>
      <main className="">
          {showSuccessModal && (
              <div className={'modal-success-container'}>
                  <div className={'modal-success'}>
                      <img src={iconCheck} alt={'checked'} className={'modal-success-image'}/>
                      <h1 className={'custom-modal-title-unified'}>Éxito</h1>
                      <Typography className={'modal-success-text'}>
                          Has recibido un correo electrónico con instrucciones para restablecer tu contraseña.
                      </Typography>
                      <button className={'modal-success-button'} onClick={handleFinishForgot}>
                          Entendido
                      </button>
                  </div>
              </div>
          )}
          {showFailureToast && (
              <ToastMsgComponent
                  type='danger'
                  titleHeader='Error'
                  info='Ha habido un error al procesar tus datos'
                  position='top-end' />
          )}
          {showModalForgot && (
              <div className={'modal-success-container'}>
                  <div className={'modal-success'}>
                      <div><img src={CancelModal} style={{position: 'absolute', right: '1.5rem', top: '1.5rem', cursor: 'pointer'}} onClick={() => setShowModalForgot(false)}/> </div>
                      <h1 className={'custom-modal-title-unified'}>¿OLVIDASTE TU CONTRASEÑA?</h1>
                      <Typography className={'modal-success-text'}>
                          Introduce el correo electrónico con el que accedes a tu Área de Cliente. Recibiras un mail para restablecer tu contraseña.
                      </Typography>
                      <div className={'container-email-forgot-password'}>
                          <label htmlFor='emailForm'>Escribe tu correo electrónico <span className="red">*</span> </label>
                          <input
                              className={'email-forgot-password'}
                              type="text"
                              id='emailForgotPassword'
                              onInput={(event: React.ChangeEvent<HTMLInputElement>) => {
                                  setEmailForgotPassword(event.target.value)
                              }}
                              required
                              aria-invalid={validEmail ? "false" : "true"}
                              aria-describedby="emailnote"
                          />
                          <p id="emailnote" className={"offscreen"}>
                              Debe introducir un correo válido.<br />
                          </p>
                      </div>
                      <button className={'modal-success-button'} disabled={disableButtonOnForgot} onClick={handleClickForgot}>
                          Recuperar Contraseña
                      </button>
                  </div>
              </div>
          )}
        {isLoading && <Loading />}
        <div className="login-general-container">
          <div className="login-container">
            <div className="side-1">
              <div className="mask"></div>
              <img src={LoginImage} alt="Login" />
              <div className="policy-container">
                <p>
                  (c) 2023 VIKIKA · Politica de privacidad y protección de datos
                  · Aviso legal · Política de Cookies
                </p>
              </div>
            </div>
            <div className="side-2">
              <div className="login-area">
                <p
                  ref={errRef}
                  className={errMsg ? "errmsg" : "offscreen"}
                  aria-live="assertive"
                >
                  {errMsg}
                </p>
                <div className="centered">
                  <div className="mask"></div>
                  <img src={Logo} alt="logo" className="login-logo" />
                </div>
                <div className="login-title">Acceso área de cliente</div>
                <div className="login-form">
                  <form onSubmit={handleSubmit}>
                    <label htmlFor="username">
                      Usuario{" "}
                      <span className="info">
                        <HtmlTooltip
                          style={{ marginLeft: "-20px" }}
                          open={openTooltip}
                          title={
                            <React.Fragment>
                              <Typography color="inherit">
                                Para acceder al área privada, debes haber
                                comprado un producto previamente en <Link style={{color: '#0B49EB'}} to={'/'} target={'_blank'}>vikika.es</Link>
                              </Typography>
                            </React.Fragment>
                          }
                        >
                          <Button
                            onClick={handleShowTooltip}
                          >
                            <img src={IconInfo} alt="info" />
                          </Button>
                        </HtmlTooltip>
                      </span>{" "}
                    </label>
                    <input
                      type="text"
                      id="username"
                      autoComplete="off"
                      onChange={(e) => setUser(e.target.value)}
                      defaultValue={username}
                      required
                    />


                    <label htmlFor="password">Contraseña </label>
                    <input
                        type={showPassword ? 'text' : 'password'}
                      id="password"
                      onChange={(e) => setPwd(e.target.value)}
                      defaultValue={password}
                      required
                    />
                          <IconButton
                              style={{ position: 'relative', bottom: '34px', left: '83%', width: '40px', height: '40px' }}
                              onClick={handleClickShowPassword}
                          >
                              {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                      
                    <div className="forgot-pass">
                        <a style={{color: '#0B49EB', fontFamily: 'Montserrat SemiBold', fontSize: '11px', cursor: "pointer"}} onClick={() => setShowModalForgot(true)}>¿Olvidaste tu contraseña?</a>
                    </div>
                    <div className="centered1">
                      <button className="btn-login" disabled={disableButtonOnLogin}>ACCEDER</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      </>
    );
}

export default Login
