import axios from "../api/axios";
import {JSON_CONTENT_TYPE, LOGIN_URL} from "../utils/constants";
import {AxiosResponse} from "axios";
import {LoginData} from "../models/LoginData";

export const loginService = async (loginData: LoginData):Promise<AxiosResponse> => {
    return axios.post(LOGIN_URL,
        JSON.stringify(loginData),
        {
            headers: { 'Content-Type': JSON_CONTENT_TYPE },
            withCredentials: true
        }
    );
}
