import axios from "../api/axios";
import {TESTIMONIALS_URL} from "../utils/constants";
import {TestimonialType} from "../models/TestimonialType";


interface TestimonialResponse {
    id: number;
    author: string;
    message: string;
    image_url: string;
    category: string;
    duration_in_months?: number;
    previous_weight?: string;
    current_weight?: string;
    number_of_sessions?: number;
    type_of_session?: string;
    psychologist?: string;
}


export async function testimonialsService(filter?: string): Promise<TestimonialType[]> {
    const url = filter ? `${filter}` : TESTIMONIALS_URL;
    const response = await axios.get<TestimonialResponse[]>(url);
    const testimonials = response.data.map((testimonial) => {
        return ({
            id: testimonial.id,
            title: testimonial.author,
            image: testimonial.image_url,
            alt: '',
            description: testimonial.message,
            type_of_sessions: testimonial.type_of_session || '',
            number_of_sessions: testimonial.number_of_sessions || '',
            psychologist: testimonial.psychologist || '',
            current_weight: testimonial.current_weight || '',
            duration_in_months: testimonial.duration_in_months || '',
            previous_weight: testimonial.previous_weight || '',
            subtitle: testimonial.category === 'psychology' ? `Psicóloga: ${testimonial.psychologist} Número de sesiones: ${testimonial.number_of_sessions}  Tipo de sesión: ${testimonial.type_of_session}` : `Tiempo: ${testimonial.duration_in_months}  Antes: ${testimonial.previous_weight}  Ahora: ${testimonial.current_weight}`,
            category: testimonial.category,
        });
    }) as TestimonialType[];
    return testimonials;
}
