import React, { useEffect, useState, createRef} from "react";
import { useNavigate } from "react-router-dom";
import MediaQuery from "react-responsive";
import Questions from '../../../../shared/questions/Questions';
import { CircularProgress } from '@mui/material';

import './RenewSubscriptions.css';

import ProgressDataRenewSubscriptions from './ProgressDataRenewSubscriptions';
import FeedingDataRenewSubscriptions from './FeedingDataRenewSubscriptions';
import InterestDataRenewSubscriptions from './InterestDataRenewSubscriptions';

import { ProgressData } from '../../../../../models/formsModels/RenewProgressData';
import { FeedingData } from "../../../../../models/formsModels/RenewFeedingData";
import { InterestData } from "../../../../../models/formsModels/RenewInterestData";

import {PlanRenewSubscriptionService, checkTypePlanService} from '../../../../../services/planRenewSubscriptionService';
import {accessControlService} from '../../../../../services/planNewSubscriptionService';

import nextArrow from '../../../../../assets/img/arrow-right-form.svg';
import previousArrow from '../../../../../assets/img/arrow-left-form.svg';

import partyIcon from '../../../../../assets/img/fin-suscripcion-icon.png';
import infoIcon from '../../../../../assets/img/info-icon-form.svg';
import screenShotExample from '../../../../../assets/img/Screenshot-example-form.svg';
import def_img from '../../../../../assets/img/default_form_image.png';

import PrivateLoading from "../../../../shared/loading/PrivateLoading";

const RenewSubscriptions = () => {

    const [next, setNext] = useState(1);
    const [dataInterestStep, setDataInterestStep] = useState(1);
    const [validationSendData, setValidationSendData] = useState(false);
    const [access, setAccess] = useState(false);
    const [loading, setLoading] = useState(false);
    const [sending, setSending] = useState(false);
    const [typePlan, setTypePlan] = useState('');

    const [error, setError] = useState([]);

    const [progressData, setProgressData] = useState<ProgressData>({
      dppeso: '',
      cambiosvolumen: '',
      dietacumplido: '',
      dietaporcentaje:'',
      dietahambre:'',
      entrenamientocumplido:'',
      entrenamientoporcentaje:'',
      entrenamientomejora:'',
      entrenamientonotas:'',
    });

    const [feedingData, setFeedingData] = useState<FeedingData>({
      alimento1: '',
      motivo1: '',
      alimento2: '',
      motivo2: '',
      alimento3: '',
      motivo3: '',
      malimento1: '',
      malimento2: '',
      malimento3: '',
    });

    const [interestData, setInterestData] = useState<InterestData>({
      otrosdatos: '',
      obstaculobloqueo: '',
      mayorcapacidad: '',
      mejorahabitos: '',
      ayudaavanzar: '',
      cnewsletter: false,
      cprivacidad: false,
    });

    const [frontImage, setFrontImage] = useState<File | null>(null);
    const [sideImage, setSideImage] = useState<File | null>(null);
    const [backImage, setBackImage] = useState<File | null>(null);
    const [frontImageUrl, setFrontImageUrl] = useState<string | ArrayBuffer | null>(null);
    const [sideImageUrl, setSideImageUrl] = useState<string | ArrayBuffer | null>(null);
    const [backImageUrl, setBackImageUrl] = useState<string | ArrayBuffer | null>(null);

    const miComponenteRef = createRef<HTMLDivElement>();

    const navigate = useNavigate();

    useEffect(() => {

      async function AccessControl(){
        try{
          const accessControl= await accessControlService("renewPlan");
          setAccess(accessControl.data);
          if(!accessControl.data){
            const status = localStorage.getItem('status');
            if (status && (status === "isLoggedIn")) {
              navigate('/private');
            }else{
              navigate('/iniciar-sesion');
            }
          }
        }catch{
          navigate('/private');
        }
      }
      AccessControl();

      async function CheckTypePlan() {
        try {
          const response= await checkTypePlanService();
          console.log(response.data);
          setTypePlan(response.data);
          setLoading(true);
        }catch (e) {
          setLoading(true);
        } 
      }
      CheckTypePlan();
      miComponenteRef.current?.scrollIntoView({block: "start",behavior: 'smooth'});
    },[]);

    useEffect(() => {
      CheckIsValid();
    },[frontImage,sideImage,backImage,interestData['cprivacidad']]);


    const CheckIsValid = () => {
      const isValid = (
        (interestData.cprivacidad)&&
        (frontImage!=null)&&
        (sideImage!=null)&&
        (backImage!=null)
      );
      setValidationSendData(isValid);
    };
  
    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setError([]);
        if(validationSendData){
          setSending(true);
          const renewPlanResponse = await PlanRenewSubscriptionService({
            progressDataToSend:progressData,
            feedingDataToSend:feedingData,
            interestDataToSend:interestData
          },frontImage,sideImage,backImage);
          setSending(false);    
          if(renewPlanResponse.data["planRenovado"]==="exito") navigate('/private');            
          if(renewPlanResponse.data["planRenovado"]!=="exito") setError(renewPlanResponse.data["planRenovado"]);
        }
      }

    
    const handleFileChange = (event:React.ChangeEvent<HTMLInputElement>, imageType:String) => {
      if(event.target.files){
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onloadend = () => {
            switch (imageType) {
                    case 'front':
                        setFrontImage(file);
                        setFrontImageUrl(reader.result);
                        break;
                    case 'side':
                        setSideImage(file);
                        setSideImageUrl(reader.result);
                        break;
                    case 'back':
                        setBackImage(file);
                        setBackImageUrl(reader.result);
                        break;
                    default:
                        break;
            }
        }
          reader.readAsDataURL(file);
      }
    };
    

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { checked,name  } = event.target as HTMLInputElement;
        setInterestData((prevFormData: InterestData) => ({ ...prevFormData, [name]: checked }));
    };

    return (
      <>
       {!access && (<></>)}

       {access && (
        <div className="vikika-suscription-forms" ref={miComponenteRef}>  
            <div className="begin-form">
                <h2>CUESTIONARIO DE RENOVACIÓN</h2>
                <div className="data-form-titles">
                  <div className="data-form-titles-checks">
                    <span className='success-step sucess-step-icon'>{next > 1 ? '✔' : 1}</span>
                    <p className='success-step-p1'>Tu progreso</p>
                  </div>
                  {next===1 &&
                    <span className='line-progress line-progress-active'></span>
                  }
                  {next>1 &&
                    <span className='line-progress line-progress-active-full'></span>
                  }
                  <div className="data-form-titles-checks">
                    <span className={next>1 ? 'success-step sucess-step-icon' : 'sucess-step-icon'}>{next>2  ? '✔' : 2}</span>
                    <p className={next>1 ? 'success-step-p2' : ''}>Alimentación</p>
                  </div>
                  {next===1 && (
                    <span className='line-progress'></span>
                  )}
                  {next=== 2 &&(
                    <span className='line-progress line-progress-active'></span>
                  )}
                  {next=== 3 && dataInterestStep===1 && (
                    <span className='line-progress line-progress-active'></span>
                  )}
                  {next=== 3 && dataInterestStep===2 && (
                    <span className='line-progress line-progress-active-full'></span>
                  )}
                  <div className="data-form-titles-checks">
                    <span className={next>2 ? 'success-step sucess-step-icon' : 'sucess-step-icon'}>{dataInterestStep===2 ? '✔' : 3}</span>
                    <p className={next>2 ? 'success-step-p3' : ''}>Datos de interés</p>
                  </div>
                </div>
                <form onSubmit={handleSubmit}>
                    {next===1 && (
                        !loading?<PrivateLoading />:
                        <ProgressDataRenewSubscriptions miComponenteRef={miComponenteRef} setNext={setNext} progressData={progressData} setProgressData={setProgressData} typePlan={typePlan}/>
                    )}
                    {next===2 && (
                        <FeedingDataRenewSubscriptions miComponenteRef={miComponenteRef} setNext={setNext} feedingData={feedingData} setFeedingData={setFeedingData}/>  
                    )}
                    {next===3 && (
                      <>
                        {dataInterestStep===1 && (
                          <InterestDataRenewSubscriptions miComponenteRef={miComponenteRef} setNext={setNext} interestData={interestData} setInterestData={setInterestData} setDataInterestStep={setDataInterestStep} />  
                        )}
                        {dataInterestStep===2 && (
                        <>
                          <h3><img src={partyIcon} alt={'party icon'} />¡YA CASI TERMINAMOS !<img src={partyIcon} alt={'party icon'} /></h3>
                          <p className="photos-description">Para darte un mejor servicio necesitamos <span className="photos-description-bold"><u>tres fotos tuyas</u></span>, una <span className="photos-description-bold">de frente</span>, 
                          otra <span className="photos-description-bold">perfil</span> y otra de <span className="photos-description-bold">espalda</span>.
                          Las fotos deben ser en traje de baño o ropa ajustada, en lugar con mucha luz y <span className="photos-description-bold">sobre 
                          un fondo liso y blanco</span> para poder ver lo mejor posible tu físico. Recomendamos 
                          que alguien te tome las fotos. Si no es posible, coloca el movil en un lugar fijo y utiliza el temporizador.
                          </p>
                          <a href="https://www.vikika.es/blog/las-claves-para-hacerte-bien-las-fotos-del-antes-y-despues/" rel="noreferrer" target="_blank">+ Información para realizar tus fotos adecuadamente aquí.</a>
                          <h4>Ejemplo de fotos correctas y incorrectas</h4>
                          <img src={screenShotExample} alt=" fotos correctas y incorrectas" className="photo-change"/>
                          <h3 id="your-photos-title">¡TUS FOTOS!</h3>
                          <div className='page3-form-container'>

                          <div className="image-file-container">
                              <div className='img-section-1 img-file-section'>
                                <label htmlFor='img-person-1' className='custom-file-upload' style={{ backgroundImage: `url(${frontImageUrl})`, backgroundSize:'cover', backgroundRepeat:'no-repeat', backgroundPosition:'center'}}>
                                {frontImage ? null : <img src={def_img} alt="default img" style={{width:'80%',marginLeft:'10px',marginTop:'20px'}}/>}  
                                </label>
                                <input
                                  type='file'
                                  id='img-person-1'
                                  accept='image/gif, image/jpeg,image/png'
                                  onChange={(e) => handleFileChange(e, 'front')}
                                />
                                <p>*FRONT</p>
                              </div>

                              <div className='img-section-2 img-file-section'>
                                <label htmlFor='img-person-2' className='custom-file-upload' style={{ backgroundImage: `url(${sideImageUrl})`, backgroundSize:'cover', backgroundRepeat:'no-repeat', backgroundPosition:'center'}}>
                                {sideImage ? null : <img src={def_img} alt="default img" style={{width:'80%',marginLeft:'10px',marginTop:'20px'}}/>}
                                </label>
                                <input
                                  type='file'
                                  id='img-person-2'
                                  accept='image/gif, image/jpeg,image/png'
                                  onChange={(e) => handleFileChange(e, 'side')}
                                />
                                <p>*SIDE</p>
                              </div>

                              <div className='img-section-3 img-file-section'>
                                <label htmlFor='img-person-3' className='custom-file-upload' style={{ backgroundImage: `url(${backImageUrl})`, backgroundSize:'cover', backgroundRepeat:'no-repeat', backgroundPosition:'center'}}>
                                {backImage ? null : <img src={def_img} alt="default img" style={{width:'80%',marginLeft:'10px',marginTop:'20px'}}/>}
                                </label>
                                <input
                                  type='file'
                                  id='img-person-3'
                                  accept='image/gif, image/jpeg,image/png'
                                  onChange={(e) => handleFileChange(e, 'back')}
                                />
                                <p>*BACK</p>
                              </div>
                            </div>  
                            <span className="instructions-images" style={{fontSize:'10px'}}> <img src={infoIcon} alt="icono de informacion" /> Cada foto debe ser estar en formato GIF, JPG, JPEG o PNG para asegurarnos que se envían correctamente. </span>
                          </div>

                          <div className="accept-box">
                            <input 
                            type="checkbox"
                            name="cnewsletter"
                            id="comunicaciones" 
                            checked={interestData['cnewsletter']} 
                            onChange={handleInputChange}
                            style={{backgroundColor: "#0B49EB"}} 
                            /> 
                            <label htmlFor="comunicaciones"> Acepto recibir comunicaciones informativas</label>
                          </div>

                          <div className="accept-box">
                            <input 
                            type="checkbox"
                            name="cprivacidad"
                            id="privacidad"                         
                            checked={interestData['cprivacidad']} 
                            onChange={handleInputChange}
                            style={{backgroundColor: "#0B49EB"}} 
                            required
                            /> 
                            <label htmlFor="privacidad"> *He leido y acepto la <a href="/condiciones-generales" rel="noreferrer" target="_blank">política de uso y conformidad legal</a></label>
                          </div>
                          <p id="support-form-contact"> <img src={infoIcon} alt="icono de informacion" style={{width:"10px"}}/> ¿Tienes algún problema con el formulario?. Contacta con soporte técnico</p>
                          <p id="support-form-contact-text"> Le informamos que los datos personales facilitados 
                            serán responsabilidad de COSTA LIFE FIT S.L con la finalidad de gestión de las compraventas. Encargos, presupuestos o prestación 
                            de servicios bajo la legitimimación otorga por su consentimiento expreso o bien del propio interesado. No se cederán datos 
                            a terceros salvo obligaciones legales. En cuanto a sus derechos podrá acceder, retificar y suprimir los datos, limitarlos o 
                            incluso oponerse a su tratamiento. Más información sobre protección de datos en <a href="https://vikika.es/privacidad" rel="noreferrer" target="_blank">www.vikika.es</a>
                          </p> 

                          {error.length !== 0 &&
                          error.map((message,index)=><p key={index} className="instructions-send-form">{message}</p>)
                          }
                          
                          {sending?
                          <>
                          <div className={'form-data-processing'}>
                          <CircularProgress color="inherit" size={20} thickness={3}/>
                          <p className="instructions-send-form">Procesando, por favor espere.</p>
                          </div> 
                                                  </>                            
                           :<></>}
                          <div className="page-manager">
                            <button onClick={()=>setDataInterestStep(1)}><img src={nextArrow} alt={'previous'} className="rotate-arrow"/></button>
                            <span className="current-done">1</span><span className="current">2</span>
                          </div>    
                        </>
                        )}
                        <hr />
                        <div className="button-navigation-form">
                        {dataInterestStep===2?
                        <button onClick={()=>{setNext(2);setDataInterestStep(1)}} className="questionnaire-button-left"><img src={previousArrow} alt={'previous'} /> Anterior</button>:
                        <button onClick={()=>setNext(2)} className="questionnaire-button-left"><img src={previousArrow} alt={'previous'} /> Anterior</button>
                        }
                        
                        {dataInterestStep===2 &&
                          <button disabled={!validationSendData} className="questionnaire-button-right send-button" type="submit" value="Enviar cuestionario"  >ENVIAR CUESTIONARIO</button> 
                        }
                        </div>  
                       </>
                      )}   
                </form>  
            </div>
        </div>)}

        <MediaQuery maxWidth={768}>
            <Questions showAlgunaDuda={false}/>
        </MediaQuery>
        <MediaQuery minWidth={768}>
            <Questions />
        </MediaQuery>
      </>
    )
} 

export default RenewSubscriptions;