import axiosCodeigniter from "../api/axiosCodeigniter";
import { SEND_DIET_MESSAGE } from "../utils/constants";

interface Mensaje {
  mensaje: string;
  es_cliente: string;
}

interface ClientChat {
  canWrite: boolean;
  avaliableMessages: number;
  max: number;
  mensajes: Mensaje[];
}

export async function sendClientMessage(message: string): Promise<ClientChat> {
  const token = localStorage.getItem("token");
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'multipart/form-data'
    },
  };
  const formData = new FormData();
  formData.append('txt_msg', message);
  

  try {
    const response = await axiosCodeigniter.post(SEND_DIET_MESSAGE, formData ,config);
    console.log(response);
    return response.data;
  } catch (error) {
    console.log("Error al enviar el mensaje", error);
    throw error;
  }
}