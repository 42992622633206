import {
    ACTIVE_BUTTON,
    INACTIVE_BUTTON
} from "../../../../../utils/constants";
import {FaqButtonProps} from "../../../../../models/componentProps/FaqButtonProps";

const FaqButton = (faqButtonProps: FaqButtonProps) => {
    const displayStyle = faqButtonProps.title === "Talleres" ? "none" : "inline-block";
    return (
        <button
            style={{ display: displayStyle }}
            onClick={() => faqButtonProps.handleButtonClick(faqButtonProps.categoryToCompare)}
            className={
                faqButtonProps.selectedCategory === faqButtonProps.categoryToCompare ? ACTIVE_BUTTON : INACTIVE_BUTTON
            }
        >
            {faqButtonProps.title}
        </button>
    )
}

export default FaqButton
