import { createContext } from 'react';

interface ReloadContextProps {
  reload: boolean;
  setReload: (reload: boolean) => void;
}

const ReloadContext = createContext<ReloadContextProps>({
  reload: false,
  setReload: () => {},
});

export default ReloadContext;
