import { useState, useEffect } from 'react';
import Divider from '@mui/material/Divider';
import './MyData.css';



interface FormData {
    name: string;
    email: string;
    phone: string;
    address: string;
    postalCode: string;
    city: string;
    province: string;
    country: string;
    birthday: string;
    gender: string;
}

const fakeData = {
    name: 'Claudia garcía lópez ',
    email: 'Claudia.garcía@gmail.com',
    phone: '+34 354676456',
    address: '',
    postalCode: '',
    city: '',
    province: '',
    country: '',
    birthday: '',
    gender: 'female'
}


const MyData = () => {    

    const [formData, setFormData] = useState<FormData>({
        name: '',
        email: '',
        phone: '',
        address: '',
        postalCode: '',
        city: '',
        province: '',
        country: '',
        birthday: '',
        gender: "female" 
    });

    useEffect(() => {
        async function fetchPersonalData() {
            //setIsLoading(true);
            //const personalData = await userDataService(id);
            //setIsLoading(false);
            //setFormData(personalData);
            setFormData(fakeData)
        }
        fetchPersonalData();
        
    }, []);
    

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>, name: string) => {
        const { value } = event.target;
        setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    };

    return (
        <main className="main-content">
            <div className='mydata-content'>
                <div className='mydata-title'>MIS DATOS </div>
                <div className='mydata-form'>
                    <div className='mydata-row'>
                        <div className='mydata-input-complete' >
                            <form >
                                <div className='mydata-input' >
                                    <label htmlFor="name">Nombre </label>
                                    <input
                                        type="text"
                                        id="name"
                                        defaultValue={formData.name}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => handleInputChange(event, 'name')}
                                        required
                                        onFocus={() => {

                                        }}
                                        onBlur={() => {

                                        }}
                                    />
                                </div>
                            </form>
                        </div>
                        <div className='mydata-buttons'>
                            <button className='mydata-cancel'>Cancelar</button>
                            <button className='mydata-save'>Guardar</button>
                        </div>
                    </div>
                </div>
                <Divider />
                <div className='mydata-form'>
                    <div className='mydata-row'>
                        <div className='mydata-input-complete' >
                            <form >
                                <div className='mydata-input-simple' >
                                    <label htmlFor="email">Correo Electrónico</label>
                                    <input
                                        type="text"
                                        id="email"
                                        defaultValue={formData.email}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => handleInputChange(event, 'email')}
                                        required
                                        onFocus={() => {

                                        }}
                                        onBlur={() => {

                                        }}
                                    />
                                </div>
                            </form>
                        </div>
                        <div className='mydata-buttons'>
                            <button className='mydata-edit'>Editar</button>
                        </div>
                    </div>
                </div>
                <Divider />
                <div className='mydata-form'>
                    <div className='mydata-row'>
                        <div className='mydata-input-complete' >
                            <form >
                                <div className='mydata-input-simple' >
                                    <label htmlFor="phone">Teléfono</label>
                                    <input
                                        type="text"
                                        id="phone"
                                        defaultValue={formData.phone}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => handleInputChange(event, 'phone')}
                                        required
                                        onFocus={() => {

                                        }}
                                        onBlur={() => {

                                        }}
                                    />
                                </div>
                            </form>
                        </div>
                        <div className='mydata-buttons'>
                            <button className='mydata-edit'>Editar</button>
                        </div>
                    </div>
                </div>
                <Divider />
                <div className='mydata-form'>
                    <div className='mydata-row'>
                        <div className='mydata-input-complete' >
                            <form >
                                <div className='mydata-input-simple' >
                                    <label htmlFor="address">Dirección</label>
                                    <input
                                        type="text"
                                        id="address"
                                        defaultValue={formData.address}
                                        placeholder='Calle - numero- piso- letra'
                                        onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => handleInputChange(event, 'address')}
                                        required
                                        onFocus={() => {

                                        }}
                                        onBlur={() => {

                                        }}
                                    />
                                </div>
                            </form>
                        </div>
                        <div className='mydata-buttons'>
                            <button className='mydata-edit'>Editar</button>
                        </div>
                    </div>
                </div>
                <Divider />
                <div className='mydata-address-details-row'>
                    <div className='mydata-address-details'>
                        <div className='mydata-form'>
                            <div className='mydata-row'>
                                <form >
                                    <div className='mydata-input-simple' >
                                        <label htmlFor="postalCode">Codigo postal</label>
                                        <input
                                            type="text"
                                            id="postalCode"
                                            defaultValue={formData.postalCode}
                                            placeholder='00000'
                                            onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => handleInputChange(event, 'postalCode')}
                                            required
                                            onFocus={() => {

                                            }}
                                            onBlur={() => {

                                            }}
                                        />
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className='mydata-form'>
                            <div className='mydata-row'>
                                <form >
                                    <div className='mydata-input-simple' >
                                        <label htmlFor="city">Población</label>
                                        <input
                                            type="text"
                                            id="city"
                                            defaultValue={formData.city}
                                            placeholder='Población'
                                            onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => handleInputChange(event, 'city')}
                                            required
                                            onFocus={() => {

                                            }}
                                            onBlur={() => {

                                            }}
                                        />
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className='mydata-form'>
                            <div className='mydata-row'>
                                <form >
                                    <div className='mydata-input-simple' >
                                        <label htmlFor="province">Provincia</label>
                                        <input
                                            type="text"
                                            id="province"
                                            defaultValue={formData.province}
                                            placeholder='Provincia'
                                            onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => handleInputChange(event, 'province')}
                                            required
                                            onFocus={() => {

                                            }}
                                            onBlur={() => {

                                            }}
                                        />
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className='mydata-form'>
                            <div className='mydata-row'>
                                <form >
                                    <div className='mydata-input-simple' >
                                        <label htmlFor="country">País</label>
                                        <input
                                            type="text"
                                            id="country"
                                            defaultValue={formData.country}
                                            placeholder='País'
                                            onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => handleInputChange(event, 'country')}
                                            required
                                            onFocus={() => {

                                            }}
                                            onBlur={() => {

                                            }}
                                        />
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className='mydata-buttons-address'>
                        <button className='mydata-edit'>Editar</button>
                    </div>
                </div>
                <div className='mydata-form'>
                    <div className='mydata-row'>
                        <div className='mydata-input-complete' >
                            <form >
                                <div className='mydata-input-simple' >
                                    <label htmlFor="birthday">Fecha De Nacimiento</label>
                                    <input
                                        type="text"
                                        id="birthday"
                                        defaultValue={formData.birthday}
                                        placeholder='dd/mm/aaaa'
                                        onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => handleInputChange(event, 'birthday')}
                                        required
                                        onFocus={() => {

                                        }}
                                        onBlur={() => {

                                        }}
                                    />
                                </div>
                            </form>
                        </div>
                        <div className='mydata-buttons'>
                            <button className='mydata-edit'>Editar</button>
                        </div>
                    </div>
                </div>
                <Divider />
                <div className='mydata-form'>
                    <div className='mydata-row'>
                        <form className='mydata-spacer' >
                            <div className='mydata-input-complete' >
                                <label htmlFor="gender">Sexo</label>
                                <div className='mydata-input-radio'>
                                    <label className='radio-button'>
                                        <input
                                            type="radio"
                                            name="gender"
                                            value="male"
                                            checked={formData.gender === "male"}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => handleInputChange(event, 'gender')}
                                        />
                                        <span className="checkmark"></span>                                        
                                        Hombre
                                    </label>
                                    <label className='radio-button '>
                                        <input 
                                            type="radio"
                                            name="gender"
                                            value="female"
                                            checked={formData.gender === "female"}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => handleInputChange(event, 'gender')}
                                        />
                                        <span className="checkmark"></span>              
                                        Mujer
                                    </label>
                                </div>
                            </div>
                        </form>
                        <div className='mydata-buttons'>
                            <button className='mydata-edit'>Editar</button>
                        </div>
                    </div>
                </div>
                <Divider />
            </div>
        </main>
    )
}

export default MyData
