import axiosCodeigniter from "../api/axiosCodeigniter";
import {AxiosResponse} from "axios";
import {ACTIVE_PLANS_URL} from "../utils/constants";

export async function PlansActiveService(): Promise<AxiosResponse> {
    const token = localStorage.getItem('token');
    const headers =  {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }
    const response = await axiosCodeigniter.get(`${ACTIVE_PLANS_URL}`, headers);
    return response;
};