import { Card, CardContent, CardMedia, Divider, Typography } from '@mui/material';
import './Interesting.css';
import { useState } from 'react';
import { useNavigate } from "react-router-dom";
import Novedad from '../../home/products/productCard/New';
interface CardProps {
    id: number
    name: string;
    image: string;
    alt: string;
    description: string;
    price: number;
    subscription: number;
    button_text: string;
    onClick: () => void;
    category?: string;
}

const Interesting = ({ id, name, image, alt, description, price, subscription, button_text, onClick,category }: CardProps) => {
    const [routeCategory, setRouteCategory] = useState<string>('dieta');
    const navigate = useNavigate();
    // eslint-disable-next-line  @typescript-eslint/no-unused-vars
    const handleClick = () => {
        onClick();
    };

    const formattedPrice = price % 1 === 0 ? price: price.toLocaleString('es-ES', { minimumFractionDigits: 2 });

    const handle = () => {
      const productCategories: { [key: string]: string } = {
        psychology: 'psicologia',
        workshop: 'talleres',
      };
    
      const newRouteCategory = category ? productCategories[category] || 'dieta' : 'dieta';
    
      console.log(category);
      console.log(newRouteCategory);
    
      setRouteCategory(newRouteCategory);
      setTimeout(() => {
        navigate(`/productos/${newRouteCategory}`);
      }, 1);
    };

    return (
      <Card sx={{ maxWidth: 520 }} className="card-content-product-container interesting-card-only">
        <CardMedia
        className='interesting-home'
          component="img"
          alt={alt}
          height="40"
          src={image}
          // className={category === "workshop" ? "opacity" : ""}
        />
        <div className="card-content-product card-content-interesting">
          <CardContent className='card-content-card'>
            <Typography
              className="letter-type-interesting-center"
              variant="h5"
              component="div"
            >
              <span
                className={`letter-type-interesting`}
              >
                {name}
              </span>
            </Typography>
            {/* <Divider className="divider-color" /> */}
            <Typography
              gutterBottom={true}
              variant="body1"
              color="text.secondary"
              component="div"
            >
              <div className="description-product-interesting">
                <p>{description}</p>
              </div>
            </Typography>
            <Divider className="divider-color" />
            {/* {category !== "workshop" && ( */}
              <div className="normal-plan">Desde</div>
            {/* )} */}
            <div className="flex-container-title-button-price">
              <div className="card-price-container">
                {/* {category !== "workshop" && ( */}
                  <div className="card-price-quantity">{formattedPrice}</div>
                {/* )} */}
                <div>
                  <p className="card-price-frecuency">
                    {category === "diet" ? "€/mes" : ""}
                    {category === "psychology" ? "€/mes" : ""}
                    {category === "workshop" ? "€/mes" : ""}
                  </p>
                </div>
              </div>
              <button
                className={`card-button`}
                style={{ cursor: "pointer"}}
                onClick={handle}
                /* disabled={category === "workshop"} */

              >
                {category === "workshop" ? "Próximamente" : "COMPRAR"}
              </button>
            </div>
          </CardContent>
        </div>
      </Card>
    );
}

export default Interesting
