import './HowWorks.css';
import Imgworks from '../../../../assets/img/equipo_home.png';
import SlideHowWork from './slide-how-work/SlideHowWork';
import { useMediaQuery, useTheme } from '@mui/material';
import {useNavigate} from "react-router-dom";

const HowWorks = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const navigate = useNavigate();

    const handleSendMessage = () => {
        navigate('/contacto');
    }

    return (
        <div>
            <div className='title-home'>
                <p>¿CÓMO FUNCIONA?</p>
            </div>
            {isMobile ? (
                <SlideHowWork />
            ) : (<div className='how-row'>
                <div className='how-column'>
                    <h3>Únete al Team</h3>
                </div>
                <div className='how-column-span'>
                    <span> - </span>
                </div>
                <div className='how-column'>
                    <h3> Rellenas tus datos </h3>
                </div>
                <div className='how-column-span'>
                    <span> - </span>
                </div>
                <div className='how-column'>
                    <h3>Accede a tu panel <br />personal </h3>
                </div>
                <div className='how-column-span'>
                    <span> - </span>
                </div>
                <div className='how-column'>
                    <h3>¡Disfruta la experiencia!</h3>
                </div>
            </div>)}

            <div className="works-area-container">
                <img src={Imgworks} className="side-img" alt="works" />
                <div className="side-text">
                    <div className="text-works-container">
                        <h2> ¿Tienes dudas y no sabes cual es el plan
                            que mejor se adapta a ti?</h2>
                        <h1>¿buscas un entrenador personal o nutricionista?</h1>
                        <h2 className='we-help-you'> ¡Te ayudamos!</h2>
                        <button style={{marginBottom: 0}} className="button-message" onClick={handleSendMessage}> <p>MÁNDANOS UN MENSAJE</p> </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HowWorks;
