import React, { useState, useEffect} from "react";
import "./YourTransformation.css";
import PrivateLoading from "../../../../shared/loading/PrivateLoading";
//import Loading from '../../shared/loading/Loading';
import ImagesContainer from "./ImagesContainer";
import { getTransformationsService } from "../../../../../services/getTransformationsService";
import { BACK_IMAGE_TEXT, SIDE_IMAGE_TEXT, FRONT_IMAGE_TEXT } from "../../../../../utils/constants";
import { TransformationData } from "../../../../../models/TransformationData";


const YourTransformation = () => {
  const [imagesData, setImagesData] = useState<TransformationData[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    async function fetchTransformation() {
      const transformations = await getTransformationsService();      
      setImagesData(transformations!) 
      setIsLoading(true);       
    }
    fetchTransformation();
  }, []);  

  return (
    <div className="transform_container">
      <h1>TRANSFORMACIÓN</h1>
      <p>
        Echa un vistazo a tu evolución desde que empezaste. La constancia y la paciencia son las claves para conseguir tus objetivos. <br></br> ¡Vamos a por ello!
      </p>
      {!isLoading && <PrivateLoading />}
      {imagesData.map((data, index) => (
        <ImagesContainer
          key={index}
          frontImage={data.frontImage}
          sideImage={data.sideImage}
          backImage={data.backImage}
          frontText={`${FRONT_IMAGE_TEXT}`}
          sideText={`${SIDE_IMAGE_TEXT}`}
          backText={`${BACK_IMAGE_TEXT}`}
          title={data.title}
        />
      ))}
    </div>
  );
};

export default YourTransformation;

