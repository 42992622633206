import React, { useEffect, useState } from "react";
import Slider, { Settings } from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import A3 from '../../../../assets/img/logo-a3-1.png';
import Abc from '../../../../assets/img/logo-abc-1.png';
import As from '../../../../assets/img/logo-as-1.png';
import Cosmopolitan from '../../../../assets/img/logo-cosmopolitan-1.png';
import Divinity from '../../../../assets/img/logo-divinity-1.png';
import Elmundo from '../../../../assets/img/logo-el-mundo-1.png';
import Elle from '../../../../assets/img/logo-elle-1.png';
import Glamours from '../../../../assets/img/logo-glamour-1.png';
import Larazon from '../../../../assets/img/logo-la-razon-1.png';
import Lasexta from '../../../../assets/img/logo-la-sexta-1.png';
import Lavanguardia from '../../../../assets/img/logo-la-vanguardia-1.png';
import Loscuarenta from '../../../../assets/img/logo-los-40-1.png';
import Marieclaire from '../../../../assets/img/logo-marie-claire-1.png';
import Md from '../../../../assets/img/logo-md-1.png';
import Sportlife from '../../../../assets/img/logo-sportlife-1.png';
import Womenshealth from '../../../../assets/img/logo-womenshealth-1-1.png';
import "./Brands.css"
import Brand from "./Brand";

const Brands = () => {
    const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

    const settings: Settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: width < 768 ? 3 : 5,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        arrows: false,
    };

    const brandsData = [
        A3, Abc, As, Cosmopolitan, Divinity, Elmundo, Elle, Glamours, Larazon, Lasexta, Lavanguardia, Loscuarenta, Marieclaire, Md, Sportlife, Womenshealth
    ]

    const brandsContent = (
        brandsData.map((brand, index) => (
            <Brand key={index} image={brand}/>
        ))
    );

    return ( <div className="brands-container">
            <Slider className="slick-brands" {...settings}>
                {
                    brandsContent
                }
            </Slider>  </div>
    )
}

export default Brands
