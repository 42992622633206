import { Member } from "../../../../models/TeamMember";
import Instagram_logo from "../../../../assets/img/Instagram logo.png";
import Slider, { Settings } from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { NextArrow, PrevArrow } from "../../../shared/arrows/Arrows";
import Default from '../../../../assets/img/TeamMemberDefault.jpg';
import { useNavigate } from "react-router-dom";


interface TrainerTeamProps {
  trainers: Member[];
}

const TrainerTeam = ({ trainers }: TrainerTeamProps) => {
  const settings: Settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    autoplay: true,
    initialSlide: 0,
    autoplaySpeed: 3000,
    arrows: true,
    nextArrow: <NextArrow location={"product"} />,
    prevArrow: <PrevArrow location={"product"} />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
          infinite: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          className: "teamcard-container",
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  };

  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate("/equipo");
  };

  const trainersContent = trainers.map((trainer, index) => (
    <div key={index} className="ourtemacard" onClick={handleNavigate}>
      <div className="row-trainer">
        <div className="image-trainer">
          <img src={trainer.image !== '' ? trainer.image : Default} alt={trainer.name} />
        </div>
        <div className="name-position-trainer">
          <h3>{trainer.name}</h3>
          <h5>{trainer.position}</h5>
        </div>
        <div className="instagram-trainer">
          <a href={trainer.instagram_url} target="_blank" rel="external,noreferrer,noopener" onClick={(event) => event.stopPropagation()}>
            <img className={'trainer-instagram-logo'} src={Instagram_logo} alt="Instagram_logo" />
          </a>
        </div>
      </div>
    </div>
  ));

  return (
    <>
      <Slider {...settings}>{trainersContent}</Slider>
    </>
  );
};

export default TrainerTeam;
