import { useState, useEffect, RefObject } from "react";

import { ProgressData } from "../../../../../models/formsModels/ReviewProgressData";

import nextArrow from '../../../../../assets/img/arrow-right-form.svg';
import nextArrowGrey from '../../../../../assets/img/arrow-right-form-grey.svg';
import './ProgressDataReviewSubscriptions.css';

interface ProgressDataProps {
  progressData: ProgressData;
  setProgressData: (value: any) => void;
  setNext: (value: any) => void;
  miComponenteRef:RefObject<HTMLDivElement>;
}

const ProgressDataReviewSubscriptions = (progressDataProps: ProgressDataProps) => {

    const [validationPassOne, setValidationPassOne] = useState(false);
    
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { value,name } = event.target;
        progressDataProps.setProgressData((prevFormData: ProgressData) => ({ ...prevFormData, [name]: value }));
        setProgressDataValidation((prevFormData: ProgressData) => ({ ...prevFormData, [name]: value }));
    };

    const [progressDataValidation, setProgressDataValidation] = useState<ProgressData>({
      dppeso: '',
      cambiosvolumen: '',
      dietaporcentaje:'',
      dietahambre:'',
      entrenamientocumplido:'',
      entrenamientoporcentaje:'',
      entrenamientonotas:'',
    });

    const CheckIsValid = () => {
        const isValid = (          
          (progressDataProps.progressData.dppeso !== '') &&
          (progressDataProps.progressData.cambiosvolumen !== '') &&
          (progressDataProps.progressData.dietaporcentaje !== '') &&
          (progressDataProps.progressData.dietahambre !== '') &&
          (progressDataProps.progressData.entrenamientocumplido !== '') &&
          (progressDataProps.progressData.entrenamientoporcentaje !== '') &&
          (progressDataProps.progressData.entrenamientonotas !== '') 
        )
        setValidationPassOne(isValid);
    }

    useEffect(() => {
        progressDataProps.miComponenteRef.current?.scrollIntoView({block: "start",behavior: 'smooth'});
    },[]);
  
    useEffect(() => {
        CheckIsValid();
    },[progressDataValidation]);

    return (
      <>
        <div className="progress-data-activity">
            <h4>¡Hola! ¿como estás llevando estas primeras semanas? Cuéntanos aquí para seguir avanzando.</h4>
            <label htmlFor="personaldatapeso" className="data-label">*Peso</label><br></br>
            <input 
            type="text" 
            name="dppeso" 
            id="personaldatapeso" 
            placeholder="En kg" 
            className=''
            value={progressDataProps.progressData.dppeso}
            onChange={handleInputChange}
            required
            /><br></br>

            <label htmlFor="cambiosvolumenCliente" className="data-label">*¿Estas notando cambio de volumen?</label><br></br>
            <input 
            type="text" 
            name="cambiosvolumen" 
            id="cambiosvolumenCliente" 
            placeholder="Max 150 palabras" 
            className=''
            value={progressDataProps.progressData.cambiosvolumen}
            onChange={handleInputChange}
            required
            maxLength={150}
            /><br></br>

            <label htmlFor="dietaporcentajeCliente" className="data-label">*Indícanos en porcentaje cuanto la estás cumpliendo</label><br></br>
            <input 
            type="text" 
            name="dietaporcentaje" 
            id="dietaporcentajeCliente" 
            placeholder="Max 150 palabras" 
            className=''
            value={progressDataProps.progressData.dietaporcentaje}
            onChange={handleInputChange}
            /><br></br>

            <label htmlFor="dietahambreCliente" className="data-label">*¿Cómo te estás sintiendo con respecto al hambre/saciedad con el menú?</label><br></br>
            <input 
            type="text" 
            name="dietahambre" 
            id="dietahambreCliente" 
            className=''
            value={progressDataProps.progressData.dietahambre}
            onChange={handleInputChange}
            /><br></br>

            <label htmlFor="entrenamientocumplidoCliente" className="data-label">*¿Estás cumplido el entrenamiento?</label><br></br>
            <input 
            type="text" 
            name="entrenamientocumplido" 
            id="entrenamientocumplidoCliente" 
            className=''
            value={progressDataProps.progressData.entrenamientocumplido}
            onChange={handleInputChange}
            required
            /><br></br>

            <label htmlFor="entrenamientoporcentajeCliente" className="data-label">*Indícanos en porcentaje cuanto lo estás cumpliendo</label><br></br>  
            <input 
            type="text" 
            name="entrenamientoporcentaje" 
            id="entrenamientoporcentajeCliente" 
            className=''
            value={progressDataProps.progressData.entrenamientoporcentaje}
            onChange={handleInputChange}
            /><br></br> 

            <label htmlFor="entrenamientonotasCliente" className="data-label">*¿Algún detalle o duda que quieras comentar del entreno?</label><br></br>
            <input 
            type="text" 
            name="entrenamientonotas" 
            id="entrenamientonotasCliente"
            placeholder="Max 150 palabras"  
            className=''
            value={progressDataProps.progressData.entrenamientonotas}
            onChange={handleInputChange}
            required
            /><br></br>   

        </div>
        <hr />
        <button className="next-field next-field-personal-data" disabled={!validationPassOne} onClick={()=>progressDataProps.setNext(2)}>Siguiente {!validationPassOne?<img src={nextArrowGrey} alt={'next'} />:<img src={nextArrow} alt={'next'} />}</button>
      </>
    );
}

export default ProgressDataReviewSubscriptions;