import { Helmet } from 'react-helmet-async';
import "./Reset.css";

import {trackView} from "../../../utils/FacebookPixelEvents";
import logo from "./../../../assets/img/logovikika.svg";
import { PWD_REGEX } from "../../../utils/constants";
import React, {useEffect, useState} from "react";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import {IconButton} from "@material-ui/core";
import {useLocation, useNavigate} from "react-router-dom";
import ToastMsgComponent from "../../shared/toast/ToastMsg";
import {resetPasswordService} from "../../../services/resetPasswordService";

function Reset() {
  const [token, setToken] = useState("");
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [errorPassword, setErrorPassword] = useState("");
  const [errorRepeatPassword, setErrorRepeatPassword] = useState("");
  const [isValid, setIsValid] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showFailureToast, setShowFailureToast] = useState(false);
  const location = useLocation();
  const [disableButtonOnReset, setDisableButtonOnReset] = useState(false);

  useEffect(() => {
    trackView();
  }, []);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get('token');
    if (token) {
      setToken(token);
    }
  }, [location]);


  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const validateForm = () => {
    setIsValid(
        !errorPassword && !errorRepeatPassword && password !== '' && repeatPassword !== ''
    );
  }

  const handleConfirmPasswordBlur = () => {
    if (password !== repeatPassword) {
      setErrorRepeatPassword("Las contraseñas deben coincidir");
      setIsValid(false);
    } else {
      setErrorRepeatPassword("");
      validateForm();
    }
  };

  const validatePassword = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    setPassword(event.target.value);
    if (!PWD_REGEX.test(event.target.value)) {
      setErrorPassword(
          "La contraseña debe tener al menos 8 caracteres, una letra mayúscula, una letra minúscula, un número y un carácter especial. Carácteres permitidos: !@#$%*-"
      );
    } else {
      setErrorPassword("");
    }
    validateForm();
  }

  const validateConfirmPassword = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    setRepeatPassword(event.target.value);
    if (event.target.value !== password) {
      setErrorRepeatPassword("Las contraseñas deben coincidir");
    } else {
      setErrorRepeatPassword("");
    }
    validateForm();
  }

  useEffect(() => {
    handleConfirmPasswordBlur();
  }, [password, repeatPassword])

  const handleClickSubmit = async () => {
    setDisableButtonOnReset(true);
    try {
      const resetPasswordResponse = await resetPasswordService({
        token,
        new_password: password,
      });
      if (resetPasswordResponse) {
        navigate("/iniciar-sesion");
      } else {
        setShowFailureToast(true);
        setDisableButtonOnReset(false);
        setTimeout(() => {
          setShowFailureToast(false);
        }, 10000);
      }
    } catch (error) {
      setDisableButtonOnReset(false);
      setShowFailureToast(true);
      setTimeout(() => {
        setShowFailureToast(false);
      }, 10000);
    }
  }

  return (
    <>
    <Helmet>
      <link rel="canonical" href={`${process.env.REACT_APP_FRONT_URL}`+"/recover-password"} />
    </Helmet>
    <div className="container-reset">
      {showFailureToast && (
          <ToastMsgComponent
              type='danger'
              titleHeader='Error'
              info='Ha habido un error al resetear tu contraseña'
              position='top-end' />
      )}
      <div className={'form'}>
        <img src={logo} alt="Vikika Team" />
        <p className="title">Recupera tu contraseña</p>
          <div style={{width: '90%'}}>
            <label htmlFor="password">
              Contraseña{" "}
              <span style={{ color: "red", fontWeight: "bold" }}> *</span>{" "}
            </label>
            <input
                type={showPassword ? 'text' : 'password'}
              id="password"
              name="password"
              required
              onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => validatePassword(event)}
              onInput={(event: React.ChangeEvent<HTMLInputElement>) => validatePassword(event)}
              onBlur={(event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => validatePassword(event)}
            ></input>
            <IconButton
                style={{ position: 'relative', bottom: '45px', left: '83%', width: '40px', height: '40px' }}
                onClick={handleClickShowPassword}
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
            {errorPassword && password && (
              <span className="error">{errorPassword}</span>
            )}
          </div>

          <div style={{width: '90%'}}>
            <label htmlFor="confirm-password">
              Repite contraseña{" "}
              <span style={{ color: "red", fontWeight: "bold" }}> *</span>{" "}
            </label>
            <input
                type={showPassword ? 'text' : 'password'}
              id="confirm-password"
              name="confirm-password"
              required
              onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => validateConfirmPassword(event)}
              onInput={(event: React.ChangeEvent<HTMLInputElement>) => validateConfirmPassword(event)}
              onBlur={(event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => validateConfirmPassword(event)}
            />
            <IconButton
                style={{ position: 'relative', bottom: '45px', left: '83%', width: '40px', height: '40px' }}
                onClick={handleClickShowPassword}
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
            {errorRepeatPassword && repeatPassword && (
              <span className="error">{errorRepeatPassword}</span>
            )}
          </div>

          <button className={'custom-button'} disabled={disableButtonOnReset ||  !isValid} onClick={handleClickSubmit}>
            Recuperar Contraseña
          </button>
      </div>
    </div>
    </>
  );
}

export default Reset;
