import { AxiosResponse } from "axios";
import axiosCodeigniter from "../api/axiosCodeigniter";
import { GET_PSI_SPECIALIST_URL,GET_PSI_SPECIALIST_COUPLE_URL } from "../utils/constants"

export async function specialistAssignedService(): Promise<AxiosResponse> {
  const token = localStorage.getItem('token');
  const config = {
    headers: {
        Authorization: `Bearer ${token}`
    },
    params: {
        typePlan: 'psi_bono_ind' 
    }
  };
  const response = await axiosCodeigniter.get(`${GET_PSI_SPECIALIST_URL}`, config);
  return response;
}

export async function specialistAssignedSubscriptionService(): Promise<AxiosResponse> {
  const token = localStorage.getItem('token');
  const config = {
    headers: {
        Authorization: `Bearer ${token}`
    },
    params: {
        typePlan: 'psi_subscription_ind' 
    }
  };
  const response = await axiosCodeigniter.get(`${GET_PSI_SPECIALIST_URL}`, config);
  return response;
}


export async function specialistAssignedDieMesPsiService(): Promise<AxiosResponse> {
  const token = localStorage.getItem('token');
  const config = {
    headers: {
        Authorization: `Bearer ${token}`
    },
    params: {
        typePlan: 'psi_die_mes_psi' 
    }
  };
  const response = await axiosCodeigniter.get(`${GET_PSI_SPECIALIST_URL}`, config);
  return response;
}

export async function specialistAssignedCoupleService (): Promise<AxiosResponse> {
  const token = localStorage.getItem('token');
  const headers = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }
  const response = await axiosCodeigniter.get(`${GET_PSI_SPECIALIST_COUPLE_URL}`, headers);
  return response;
}