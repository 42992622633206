import { Select, MenuItem, IconButton, SelectChangeEvent } from '@mui/material';
import { FormControlLabel, RadioGroup } from '@mui/material';
import {StyledRadio,StyledSelectRadioUncheked,StyledSelectRadioCheked} from "../formComponents/StyledRadio";


import { useState, useEffect, RefObject} from "react";
import { SportActivityData } from "../../../../../models/formsModels/SportActivityData";
import nextArrow from '../../../../../assets/img/arrow-right-form.svg';
import nextArrowGrey from '../../../../../assets/img/arrow-right-form-grey.svg';
import previousArrow from '../../../../../assets/img/arrow-left-form.svg';
import IconChevron from '../../../../../assets/img/chevron-down.png';
import './SportActivityBuySuscription.css'
import doubtimg from '../../../../../assets/img/doubtformimg.png'
interface SportActivityProps {
    sportActivityData:SportActivityData,
    setSportActivityData:(value: any) => void;
    selectedTypeForm:string;
    setNext: (value: any) => void;
    miComponenteRef:RefObject<HTMLDivElement>;
    /* onValidation: (isValid: boolean) => void; */
}


const SportActivityBuySubscriptions = (sportActivityProps: SportActivityProps) => {

    const trainingPlans=["mes","mes_dev","mes_cas","par_mes_cas","ent_cas","ent_mes","ent_mes_r","mes_r","seg_prem","seg_prem_cas","par_mes","vital_wellness"];

    const [sportActivity, setSportActivity] = useState(1);

    const [validationPassOne, setValidationPassOne] = useState(false);
    const [validationPassTwo, setValidationPassTwo] = useState(false);

    const [sportActivityDataValidation, setSportActivityDataValidation] = useState<SportActivityData>({
      hactividad:'',
      henergia:'',
      hformafisica:'',
      hsueno:'',
      hobjetivo:'',
      nivelafisica:'',
      hdiadia:'',
      hdespertar:'',
      hacostar:'',
      hentrenar:'',
      nivelentreno:'',
      hfumador:'',
      hdlunes:false,
      hdlunesact:'',
      hdluneshora:'',
      hdmartes:false,
      hdmartesact:'',
      hdmarteshora:'',
      hdmiercoles:false,
      hdmiercolesact:'',
      hdmiercoleshora:'',
      hdjueves:false,
      hdjuevesact:'',
      hdjueveshora:'',
      hdviernes:false,
      hdviernesact:'',
      hdvierneshora:'',
      hdsabado:false,
      hdsabadoact:'',
      hdsabadohora:'',
      hddomingo:false,
      hddomingoact:'',
      hddomingohora:'',
      helunes:false,
      helunesact:'',
      heluneshora:'',
      hemartes:false,
      hemartesact:'',
      hemarteshora:'',
      hemiercoles:false,
      hemiercolesact:'',
      hemiercoleshora:'',
      hejueves:false,
      hejuevesact:'',
      hejueveshora:'',
      heviernes:false,
      heviernesact:'',
      hevierneshora:'',
      hesabado:false,
      hesabadoact:'',
      hesabadohora:'',
      hedomingo:false,
      hedomingoact:'',
      hedomingohora:''
    });

    const CheckPassOneIsValid = () => {
      const isValid = (
        (sportActivityProps.sportActivityData.hactividad !== '') &&
        (sportActivityProps.sportActivityData.henergia !== '') &&
        (sportActivityProps.sportActivityData.hformafisica !== '') &&
        (sportActivityProps.sportActivityData.hsueno !== '')
      );
      setValidationPassOne(isValid);
    }

    const CheckPassTwoIsValid = () => {
      const isValid = (
        (sportActivityProps.sportActivityData.hobjetivo !== '') &&
        (sportActivityProps.sportActivityData.nivelafisica !== '') &&
        (sportActivityProps.sportActivityData.hdiadia !== '') &&
        (sportActivityProps.sportActivityData.hdespertar !== '') &&
        (sportActivityProps.sportActivityData.hacostar !== '')&&
        (sportActivityProps.sportActivityData.hentrenar !== '')&&
        (sportActivityProps.sportActivityData.nivelentreno !== '')&&
        (sportActivityProps.sportActivityData.hfumador !== '')
      );
      setValidationPassTwo(isValid);
    }

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      const { value,name } = event.target;
      sportActivityProps.setSportActivityData((prevFormData: SportActivityData) => ({ ...prevFormData, [name]: value }));
      setSportActivityDataValidation((prevFormData: SportActivityData) => ({ ...prevFormData, [name]: value }));
    };

    const handleInputChangeSelect = (event: SelectChangeEvent<string>)=>{
      const { value,name } = event.target;
      sportActivityProps.setSportActivityData((prevFormData: SportActivityData) => ({ ...prevFormData, [name]: value }));
      setSportActivityDataValidation((prevFormData: SportActivityData) => ({ ...prevFormData, [name]: value }));
    }

    const handleInputChangeCheckBox = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      const { checked,name } = event.target as HTMLInputElement;
      sportActivityProps.setSportActivityData((prevFormData: SportActivityData) => ({ ...prevFormData, [name]: checked }));
    };

    const [doubtOn, isdoubtOn]=useState(false);

    const doubtUpdate = () =>{
      isdoubtOn(!doubtOn);
    }

    useEffect(() => {
      sportActivityProps.miComponenteRef.current?.scrollIntoView({block: "start",behavior: 'smooth'});
    },[]);

    useEffect(() => {
      CheckPassOneIsValid();
      CheckPassTwoIsValid();
    },[sportActivityDataValidation]);

    return (
        <div className="sport-activity">
        {sportActivity===1 && (
          <>
            <h4>En una escala del 1 al 10, en donde 1 es la puntuación más baja y 10 la más alta. Indica el nivel de actividad diaria y deportiva que realizas .</h4>
            <p>*Actividad diaria</p>
            <div className="sport-activity-first-form">
              <input 
              type="radio" 
              name="hactividad" 
              id="actividadDiaria1" 
              value="1"
              checked={sportActivityProps.sportActivityData.hactividad==="1"}
              onChange={handleInputChange} 
              required
              />
              <label htmlFor="actividadDiaria1">1</label>

              <input 
              type="radio" 
              name="hactividad" 
              id="actividadDiaria2" 
              value="2"
              checked={sportActivityProps.sportActivityData.hactividad==="2"}
              onChange={handleInputChange} 
              required
              />
              <label htmlFor="actividadDiaria2">2</label>

              <input 
              type="radio" 
              name="hactividad" 
              id="actividadDiaria3" 
              value="3"
              checked={sportActivityProps.sportActivityData.hactividad==="3"}
              onChange={handleInputChange} 
              required
              />
              <label htmlFor="actividadDiaria3">3</label>

              <input 
              type="radio" 
              name="hactividad" 
              id="actividadDiaria4" 
              value="4"
              checked={sportActivityProps.sportActivityData.hactividad==="4"}
              onChange={handleInputChange} 
              required
              />
              <label htmlFor="actividadDiaria4">4</label>

              <input 
              type="radio" 
              name="hactividad" 
              id="actividadDiaria5" 
              value="5"
              checked={sportActivityProps.sportActivityData.hactividad==="5"}
              onChange={handleInputChange} 
              required
              />
              <label htmlFor="actividadDiaria5">5</label>

              <input 
              type="radio" 
              name="hactividad" 
              id="actividadDiaria6" 
              value="6"
              checked={sportActivityProps.sportActivityData.hactividad==="6"}
              onChange={handleInputChange} 
              required
              />
              <label htmlFor="actividadDiaria6">6</label>

              <input 
              type="radio" 
              name="hactividad" 
              id="actividadDiaria7" 
              value="7"
              checked={sportActivityProps.sportActivityData.hactividad==="7"}
              onChange={handleInputChange} 
              required
              />
              <label htmlFor="actividadDiaria7">7</label>

              <input 
              type="radio" 
              name="hactividad" 
              id="actividadDiaria8" 
              value="8"
              checked={sportActivityProps.sportActivityData.hactividad==="8"}
              onChange={handleInputChange} 
              required
              />
              <label htmlFor="actividadDiaria8">8</label>

              <input 
              type="radio" 
              name="hactividad" 
              id="actividadDiaria9" 
              value="9"
              checked={sportActivityProps.sportActivityData.hactividad==="9"}
              onChange={handleInputChange} 
              required
              />
              <label htmlFor="actividadDiaria9">9</label>

              <input 
              type="radio" 
              name="hactividad" 
              id="actividadDiaria10" 
              value="10"
              checked={sportActivityProps.sportActivityData.hactividad==="10"}
              onChange={handleInputChange} 
              required
              />
              <label htmlFor="actividadDiaria10">10</label>

            </div>
            <p>*Nivel de energía</p>
            <div className="sport-activity-first-form">
              <input type="radio" 
              name="henergia" 
              id="nivelEnergia1" 
              value="1"
              checked={sportActivityProps.sportActivityData.henergia==="1"}
              onChange={handleInputChange} 
              required
              />
              <label htmlFor="nivelEnergia1">1</label>

              <input 
              type="radio" 
              name="henergia" 
              id="nivelEnergia2" 
              value="2"
              checked={sportActivityProps.sportActivityData.henergia==="2"}
              onChange={handleInputChange} 
              required
              />
              <label htmlFor="nivelEnergia2">2</label>

              <input 
              type="radio" 
              name="henergia" 
              id="nivelEnergia3" 
              value="3"
              checked={sportActivityProps.sportActivityData.henergia==="3"}
              onChange={handleInputChange} 
              required
              />
              <label htmlFor="nivelEnergia3">3</label>

              <input 
              type="radio" 
              name="henergia" 
              id="nivelEnergia4" 
              value="4"
              checked={sportActivityProps.sportActivityData.henergia==="4"}
              onChange={handleInputChange} 
              required
              />
              <label htmlFor="nivelEnergia4">4</label>

              <input 
              type="radio" 
              name="henergia" 
              id="nivelEnergia5" 
              value="5"
              checked={sportActivityProps.sportActivityData.henergia==="5"}
              onChange={handleInputChange} 
              required
              />
              <label htmlFor="nivelEnergia5">5</label>

              <input 
              type="radio" 
              name="henergia" 
              id="nivelEnergia6" 
              value="6"
              checked={sportActivityProps.sportActivityData.henergia==="6"}
              onChange={handleInputChange} 
              required
              />
              <label htmlFor="nivelEnergia6">6</label>

              <input 
              type="radio" 
              name="henergia" 
              id="nivelEnergia7" 
              value="7"
              checked={sportActivityProps.sportActivityData.henergia==="7"}
              onChange={handleInputChange} 
              required
              />
              <label htmlFor="nivelEnergia7">7</label>

              <input 
              type="radio" 
              name="henergia" 
              id="nivelEnergia8" 
              value="8"
              checked={sportActivityProps.sportActivityData.henergia==="8"}
              onChange={handleInputChange} 
              required
              />
              <label htmlFor="nivelEnergia8">8</label>

              <input 
              type="radio" 
              name="henergia" 
              id="nivelEnergia9" 
              value="9"
              checked={sportActivityProps.sportActivityData.henergia==="9"}
              onChange={handleInputChange} 
              required
              />
              <label htmlFor="nivelEnergia9">9</label>

              <input 
              type="radio" 
              name="henergia" 
              id="nivelEnergia10" 
              value="10"
              checked={sportActivityProps.sportActivityData.henergia==="10"}
              onChange={handleInputChange} 
              required
              />
              <label htmlFor="nivelEnergia10">10</label>

            </div>
            <p>*Nivel de forma física</p>
            <div className="sport-activity-first-form">
              <input 
              type="radio" 
              name="hformafisica" 
              id="nivelFormaFisica1" 
              value="1"
              checked={sportActivityProps.sportActivityData.hformafisica==="1"}
              onChange={handleInputChange} 
              required
              />
              <label htmlFor="nivelFormaFisica1">1</label>

              <input 
              type="radio" 
              name="hformafisica" 
              id="nivelFormaFisica2" 
              value="2"
              checked={sportActivityProps.sportActivityData.hformafisica==="2"}
              onChange={handleInputChange} 
              required
              />
              <label htmlFor="nivelFormaFisica2">2</label>

              <input 
              type="radio" 
              name="hformafisica" 
              id="nivelFormaFisica3" 
              value="3"
              checked={sportActivityProps.sportActivityData.hformafisica==="3"}
              onChange={handleInputChange} 
              required
              />
              <label htmlFor="nivelFormaFisica3">3</label>

              <input 
              type="radio" 
              name="hformafisica" 
              id="nivelFormaFisica4" 
              value="4"
              checked={sportActivityProps.sportActivityData.hformafisica==="4"}
              onChange={handleInputChange} 
              required
              />
              <label htmlFor="nivelFormaFisica4">4</label>

              <input 
              type="radio" 
              name="hformafisica" 
              id="nivelFormaFisica5" 
              value="5"
              checked={sportActivityProps.sportActivityData.hformafisica==="5"}
              onChange={handleInputChange} 
              required
              />
              <label htmlFor="nivelFormaFisica5">5</label>

              <input 
              type="radio" 
              name="hformafisica" 
              id="nivelFormaFisica6" 
              value="6"
              checked={sportActivityProps.sportActivityData.hformafisica==="6"}
              onChange={handleInputChange} 
              required
              />
              <label htmlFor="nivelFormaFisica6">6</label>

              <input 
              type="radio" 
              name="hformafisica" 
              id="nivelFormaFisica7" 
              value="7"
              checked={sportActivityProps.sportActivityData.hformafisica==="7"}
              onChange={handleInputChange} 
              required
              />
              <label htmlFor="nivelFormaFisica7">7</label>

              <input 
              type="radio" 
              name="hformafisica" 
              id="nivelFormaFisica8" 
              value="8"
              checked={sportActivityProps.sportActivityData.hformafisica==="8"}
              onChange={handleInputChange} 
              required
              />
              <label htmlFor="nivelFormaFisica8">8</label>

              <input 
              type="radio" 
              name="hformafisica" 
              id="nivelFormaFisica9" 
              value="9"
              checked={sportActivityProps.sportActivityData.hformafisica==="9"}
              onChange={handleInputChange} 
              required
              />
              <label htmlFor="nivelFormaFisica9">9</label>

              <input 
              type="radio" 
              name="hformafisica" 
              id="nivelFormaFisica10" 
              value="10"
              checked={sportActivityProps.sportActivityData.hformafisica==="10"}
              onChange={handleInputChange} 
              required
              />
              <label htmlFor="nivelFormaFisica10">10</label>

            </div>
            <p>*Calidad del sueño</p>
            <div className="sport-activity-first-form">
              <input 
              type="radio" 
              name="hsueno" 
              id="calidadSueno1" 
              value="1"
              checked={sportActivityProps.sportActivityData.hsueno==="1"}
              onChange={handleInputChange} 
              required
              />
              <label htmlFor="calidadSueno1">1</label>

              <input 
              type="radio" 
              name="hsueno" 
              id="calidadSueno2" 
              value="2"
              checked={sportActivityProps.sportActivityData.hsueno==="2"}
              onChange={handleInputChange} 
              required
              />
              <label htmlFor="calidadSueno2">2</label>

              <input 
              type="radio" 
              name="hsueno" 
              id="calidadSueno3" 
              value="3"
              checked={sportActivityProps.sportActivityData.hsueno==="3"}
              onChange={handleInputChange} 
              required
              />
              <label htmlFor="calidadSueno3">3</label>

              <input 
              type="radio" 
              name="hsueno" 
              id="calidadSueno4" 
              value="4"
              checked={sportActivityProps.sportActivityData.hsueno==="4"}
              onChange={handleInputChange} 
              required
              />
              <label htmlFor="calidadSueno4">4</label>

              <input 
              type="radio" 
              name="hsueno" 
              id="calidadSueno5" 
              value="5"
              checked={sportActivityProps.sportActivityData.hsueno==="5"}
              onChange={handleInputChange} 
              required
              />
              <label htmlFor="calidadSueno5">5</label>

              <input 
              type="radio" 
              name="hsueno" 
              id="calidadSueno6" 
              value="6"
              checked={sportActivityProps.sportActivityData.hsueno==="6"}
              onChange={handleInputChange} 
              required
              />
              <label htmlFor="calidadSueno6">6</label>

              <input 
              type="radio" 
              name="hsueno" 
              id="calidadSueno7" 
              value="7"
              checked={sportActivityProps.sportActivityData.hsueno==="7"}
              onChange={handleInputChange} 
              required
              />
              <label htmlFor="calidadSueno7">7</label>

              <input 
              type="radio" 
              name="hsueno" 
              id="calidadSueno8" 
              value="8"
              checked={sportActivityProps.sportActivityData.hsueno==="8"}
              onChange={handleInputChange} 
              required
              />
              <label htmlFor="calidadSueno8">8</label>

              <input 
              type="radio" 
              name="hsueno" 
              id="calidadSueno9" 
              value="9"
              checked={sportActivityProps.sportActivityData.hsueno==="9"}
              onChange={handleInputChange} 
              required
              />
              <label htmlFor="calidadSueno9">9</label>

              <input 
              type="radio" 
              name="hsueno" 
              id="calidadSueno10" 
              value="10"
              checked={sportActivityProps.sportActivityData.hsueno==="10"}
              onChange={handleInputChange} 
              required
              />
              <label htmlFor="calidadSueno10">10</label>

            </div>
            <div className="page-manager">
            <span className="current">1</span><span className="not-current"></span><span className="not-current"></span>
            <button disabled={!validationPassOne} onClick={()=>{
              setSportActivity(2);
              sportActivityProps.miComponenteRef.current?.scrollIntoView({block: "start",behavior: 'smooth'});
            }}>{!validationPassOne?<img src={nextArrowGrey} alt={'next'} />:<img src={nextArrow} alt={'next'} />}</button>
            </div>
          </>
        )}

        {sportActivity===2 && (
          <>
            <label htmlFor="objetivoCliente">*¿Qué objetivo buscas?</label>
            <Select  
            name="hobjetivo" 
            id="objetivoCliente"
            className='sport-activity-form-two materialui-fields-select'
            value={sportActivityProps.sportActivityData.hobjetivo}
            onChange={handleInputChangeSelect} 
            required
            >
              <MenuItem value="" className="materialui-fields-option" hidden ></MenuItem>
              <MenuItem value="Perdida de peso/Definición" className="materialui-fields-option">
                <IconButton className='materialui-fields-select-button'>
                  {sportActivityProps.sportActivityData.hobjetivo === 'Perdida de peso/Definición' ? <StyledSelectRadioCheked /> : <StyledSelectRadioUncheked />}
                </IconButton>
                Perdida de peso/Definición
              </MenuItem>
              <MenuItem value="Ganancia de peso/Masa muscular" className="materialui-fields-option">
                <IconButton className='materialui-fields-select-button'>
                  {sportActivityProps.sportActivityData.hobjetivo === 'Ganancia de peso/Masa muscular' ? <StyledSelectRadioCheked /> : <StyledSelectRadioUncheked />}
                </IconButton>
                Ganancia de peso/Masa muscular
              </MenuItem>
              <MenuItem value="Rendimiento deportivo" className="materialui-fields-option">
                <IconButton className='materialui-fields-select-button'>
                  {sportActivityProps.sportActivityData.hobjetivo === 'Rendimiento deportivo' ? <StyledSelectRadioCheked /> : <StyledSelectRadioUncheked />}
                </IconButton>
                Rendimiento deportivo
              </MenuItem>
              <MenuItem value="Crear un hábito de vida saludable" className="materialui-fields-option">
                <IconButton className='materialui-fields-select-button'>
                  {sportActivityProps.sportActivityData.hobjetivo === 'Crear un hábito de vida saludable' ? <StyledSelectRadioCheked /> : <StyledSelectRadioUncheked />}
                </IconButton>
                Crear un hábito de vida saludable
              </MenuItem>
            </Select>

            <label htmlFor="actividadFisica">*¿Cual es tu nivel de actividad física?</label>
            <Select 
            name="nivelafisica" 
            id="actividadFisica"
            className='sport-activity-form-two materialui-fields-select'
            value={sportActivityProps.sportActivityData.nivelafisica}
            onChange={handleInputChangeSelect} 
            required
            >
              <MenuItem value="" className="materialui-fields-option" hidden></MenuItem>
              <MenuItem value="activo" className="materialui-fields-option">
                <IconButton className='materialui-fields-select-button'>
                  {sportActivityProps.sportActivityData.nivelafisica === 'activo' ? <StyledSelectRadioCheked /> : <StyledSelectRadioUncheked />}
                </IconButton>
                Activo (4-6 entrenamientos semanales)
              </MenuItem>
              <MenuItem value="moderado" className="materialui-fields-option">
                <IconButton className='materialui-fields-select-button'>
                  {sportActivityProps.sportActivityData.nivelafisica === 'moderado' ? <StyledSelectRadioCheked /> : <StyledSelectRadioUncheked />}
                </IconButton>
                Moderado (1-3 entrenamientos semanales)
              </MenuItem>
              <MenuItem value="iniciando" className="materialui-fields-option">
                <IconButton className='materialui-fields-select-button'>
                  {sportActivityProps.sportActivityData.nivelafisica === 'iniciando' ? <StyledSelectRadioCheked /> : <StyledSelectRadioUncheked />}
                </IconButton>
                Estoy iniciándome
              </MenuItem>
              <MenuItem value="sin entreno" className="materialui-fields-option">
                <IconButton className='materialui-fields-select-button'>
                  {sportActivityProps.sportActivityData.nivelafisica === 'sin entreno' ? <StyledSelectRadioCheked /> : <StyledSelectRadioUncheked />}
                </IconButton>
                No entreno
              </MenuItem>
            </Select>

            <label htmlFor="definirDia">*¿Como definirías tu día a día?</label>
            <Select 
            name="hdiadia" 
            id="definirDia"
            className='sport-activity-form-two materialui-fields-select'
            value={sportActivityProps.sportActivityData.hdiadia}
            onChange={handleInputChangeSelect} 
            required
            >
              <MenuItem value="" className="materialui-fields-option" hidden></MenuItem>
              <MenuItem value="Suelo comer fuera habitualmente" className="materialui-fields-option">
                <IconButton className='materialui-fields-select-button'>
                  {sportActivityProps.sportActivityData.hdiadia === 'Suelo comer fuera habitualmente' ? <StyledSelectRadioCheked /> : <StyledSelectRadioUncheked />}
                </IconButton>
                Suelo comer fuera habitualmente
              </MenuItem>
              <MenuItem value="Puntualmente como fuera o tengo algún evento" className="materialui-fields-option">
                <IconButton className='materialui-fields-select-button'>
                  {sportActivityProps.sportActivityData.hdiadia === 'Puntualmente como fuera o tengo algún evento' ? <StyledSelectRadioCheked /> : <StyledSelectRadioUncheked />}
                </IconButton>
                Puntualmente como fuera o tengo algún evento
              </MenuItem>
              <MenuItem value="Estable, sin muchos cambios" className="materialui-fields-option">
                <IconButton className='materialui-fields-select-button'>
                  {sportActivityProps.sportActivityData.hdiadia === 'Estable, sin muchos cambios' ? <StyledSelectRadioCheked /> : <StyledSelectRadioUncheked />}
                </IconButton>
                Estable, sin muchos cambios
              </MenuItem>
            </Select>

            <label htmlFor="horaDespertarse">*¿A qué hora te despiertas?</label><br></br>
            <input 
            type="text" 
            name="hdespertar" 
            id="horaDespertarse" 
            className='sport-activity-form-two'
            value={sportActivityProps.sportActivityData.hdespertar}
            onChange={handleInputChange}
            required
            /><br></br>
            <label htmlFor="horaAcostarse">*¿A qué hora te acuestas?</label>
            <br></br> <input 
            type="text" 
            name="hacostar" 
            id="horaAcostarse"
            className='sport-activity-form-two' 
            value={sportActivityProps.sportActivityData.hacostar}
            onChange={handleInputChange}
            required
            /><br></br>
            <label htmlFor="horaEntrenar">*¿A qué hora entrenas?</label>
            <br></br><input 
            type="text" 
            name="hentrenar" 
            id="horaEntrenar" 
            className='sport-activity-form-two'
            value={sportActivityProps.sportActivityData.hentrenar}
            onChange={handleInputChange}
            required
            />
            <br></br>
            <label htmlFor="nivelEntrenamiento">*Nivel de entrenamiento</label>
            <Select 
            name="nivelentreno" 
            id="nivelEntrenamiento"
            className='sport-activity-form-two materialui-fields-select'
            value={sportActivityProps.sportActivityData.nivelentreno}
            onChange={handleInputChangeSelect} 
            required
            >
              <MenuItem value="" className="materialui-fields-option" hidden></MenuItem>
              <MenuItem value="Nunca he entrenado" className="materialui-fields-option">
                <IconButton className='materialui-fields-select-button'>
                  {sportActivityProps.sportActivityData.nivelentreno === 'Nunca he entrenado' ? <StyledSelectRadioCheked /> : <StyledSelectRadioUncheked />}
                </IconButton>
                Nunca he entrenado
              </MenuItem>
              <MenuItem value="Principiante" className="materialui-fields-option">
                <IconButton className='materialui-fields-select-button'>
                  {sportActivityProps.sportActivityData.nivelentreno === 'Principiante' ? <StyledSelectRadioCheked /> : <StyledSelectRadioUncheked />}
                </IconButton>
                Principiante
              </MenuItem>
              <MenuItem value="Amateur" className="materialui-fields-option">
                <IconButton className='materialui-fields-select-button'>
                  {sportActivityProps.sportActivityData.nivelentreno === 'Amateur' ? <StyledSelectRadioCheked /> : <StyledSelectRadioUncheked />}
                </IconButton>
                Amateur
              </MenuItem>
              <MenuItem value="Avanzado" className="materialui-fields-option">
                <IconButton className='materialui-fields-select-button'>
                  {sportActivityProps.sportActivityData.nivelentreno === 'Avanzado' ? <StyledSelectRadioCheked /> : <StyledSelectRadioUncheked />}
                </IconButton>
                Avanzado
              </MenuItem>
            </Select>
          
            <div id="eres_fumador">
              <p>*¿Eres fumador?</p>
              <RadioGroup name="hfumador" className="materialui-fields-check">
                <FormControlLabel className="materialui-fields-label" control={
                  <StyledRadio 
                    id="fumadorSi" 
                    value="1"
                    checked={sportActivityProps.sportActivityData.hfumador==="1"}
                    onChange={handleInputChange}
                  />} label="Si" />
                <FormControlLabel className="materialui-fields-label" control={
                  <StyledRadio 
                    id="fumadorNo" 
                    value="0"
                    checked={sportActivityProps.sportActivityData.hfumador==="0"}
                    onChange={handleInputChange}
                  />} label="No" />
              </RadioGroup>
            </div>
            <div className="page-manager">
            <button onClick={()=>{
              setSportActivity(1);
              sportActivityProps.miComponenteRef.current?.scrollIntoView({block: "start",behavior: 'smooth'});
            }}><img src={nextArrow} alt={'previous'} className="rotate-arrow"/></button>
            <span className="current-done">1</span><span className="current">2</span><span className="not-current"></span>
            <button disabled={!validationPassTwo} onClick={()=>{
              setSportActivity(3);
              sportActivityProps.miComponenteRef.current?.scrollIntoView({block: "start",behavior: 'smooth'});
            }}>{!validationPassTwo?<img src={nextArrowGrey} alt={'next'} />:<img src={nextArrow} alt={'next'} />}</button>
            </div>
          </>
        )} 

        {sportActivity===3 && (
          <>
          {!trainingPlans.includes(sportActivityProps.selectedTypeForm) && (
            <>
              <h6 className="diet-train-h">Para planes que solo incluyen dieta</h6>
              <p className="diet-train-p">En este cuadrante <span className="diet-train-p-bold"><u>indicanos tu rutina actual de 
                entrenamiento y que vayas a mantener durante el plan</u></span>.Cuentanos
                qué día a que hora (<span className="diet-train-p-bold">formato 24h</span>) y qué tipo de actividad realizas. 
                <span className="diet-train-p-bold"> Si no realizas ninguna actualmente déjalo en blanco.</span>
              </p>

              <div id="img-fill-doubt"><strong onClick={doubtUpdate}>Ejemplo<img className="img-fill-doubt-arrow" src={IconChevron} alt="chevron down" /></strong>
              <br></br>
               {doubtOn && (
                  <img src={doubtimg} alt="Imagen base para rellenar cuestionario" id="img-doubton" onClick={doubtUpdate}/>
                )
                }
              
              </div>
               
              <p style={{fontWeight:'200', fontFamily:'Montserrat'}}>Cuéntanos tu rutina:</p>
              <div className={`day-activity  ${sportActivityProps.sportActivityData.hdlunes ? 'checked-day' : ''}`}>
                <input 
                type="checkbox" 
                name="hdlunes" 
                id="hdLunes"
                checked={sportActivityProps.sportActivityData.hdlunes}                
                onChange={handleInputChangeCheckBox}
                style={{backgroundColor: "#0B49EB"}} 
                />
                <label htmlFor="hdLunes">Lunes</label>
                <input 
                type="text" 
                name="hdlunesact" 
                placeholder="Actividad"
                defaultValue={sportActivityProps.sportActivityData.hdlunesact}
                onChange={handleInputChange}
                maxLength={200}
                />
                <input 
                type="time" 
                name="hdluneshora" 
                value={sportActivityProps.sportActivityData.hdluneshora}
                onChange={handleInputChange}
                />
              </div>

              <div  className={`day-activity  ${sportActivityProps.sportActivityData.hdmartes ? 'checked-day' : ''}`}>
                <input 
                type="checkbox" 
                name="hdmartes" 
                id="hdMartes" 
                checked={sportActivityProps.sportActivityData.hdmartes}               
                onChange={handleInputChangeCheckBox}
                style={{backgroundColor: "#0B49EB"}}
                />
                <label htmlFor="hdMartes">Martes</label>
                <input 
                type="text" 
                name="hdmartesact" 
                placeholder="Actividad"
                defaultValue={sportActivityProps.sportActivityData.hdmartesact}
                onChange={handleInputChange}
                maxLength={200}
                />
                <input 
                type="time" 
                name="hdmarteshora"
                value={sportActivityProps.sportActivityData.hdmarteshora}
                onChange={handleInputChange}
                />
              </div>
              <div className={`day-activity  ${sportActivityProps.sportActivityData.hdmiercoles ? 'checked-day' : ''}`}>
                <input 
                type="checkbox" 
                name="hdmiercoles" 
                id="hdMiercoles" 
                checked={sportActivityProps.sportActivityData.hdmiercoles}               
                onChange={handleInputChangeCheckBox} 
                style={{backgroundColor: "#0B49EB"}}
                />
                <label htmlFor="hdMiercoles">Miércoles</label>
                <input 
                type="text" 
                name="hdmiercolesact" 
                placeholder="Actividad"
                defaultValue={sportActivityProps.sportActivityData.hdmiercolesact}
                onChange={handleInputChange}
                maxLength={200}
                />
                <input 
                type="time" 
                name="hdmiercoleshora"
                value={sportActivityProps.sportActivityData.hdmiercoleshora}
                onChange={handleInputChange}
                />
              </div>
              <div className={`day-activity  ${sportActivityProps.sportActivityData.hdjueves ? 'checked-day' : ''}`}>
                <input 
                type="checkbox" 
                name="hdjueves" 
                id="hdJueves" 
                checked={sportActivityProps.sportActivityData.hdjueves} 
                onChange={handleInputChangeCheckBox} 
                style={{backgroundColor: "#0B49EB"}}
                />
                <label htmlFor="hdJueves">Jueves</label>
                <input 
                type="text" 
                name="hdjuevesact" 
                placeholder="Actividad"
                defaultValue={sportActivityProps.sportActivityData.hdjuevesact}
                onChange={handleInputChange}
                maxLength={200}
                />
                <input 
                type="time" 
                name="hdjueveshora"
                value={sportActivityProps.sportActivityData.hdjueveshora}
                onChange={handleInputChange}
                />
              </div>
              <div className={`day-activity  ${sportActivityProps.sportActivityData.hdviernes ? 'checked-day' : ''}`}>
                <input 
                type="checkbox" 
                name="hdviernes" 
                id="hdViernes" 
                checked={sportActivityProps.sportActivityData.hdviernes} 
                onChange={handleInputChangeCheckBox} 
                style={{backgroundColor: "#0B49EB"}}
                />
                <label htmlFor="hdViernes">Viernes</label>
                <input 
                type="text" 
                name="hdviernesact" 
                placeholder="Actividad"
                defaultValue={sportActivityProps.sportActivityData.hdviernesact}
                onChange={handleInputChange}
                maxLength={200}
                />
                <input 
                type="time" 
                name="hdvierneshora"
                value={sportActivityProps.sportActivityData.hdvierneshora}
                onChange={handleInputChange}
                />
              </div>
              <div className={`day-activity  ${sportActivityProps.sportActivityData.hdsabado ? 'checked-day' : ''}`}>
                <input 
                type="checkbox" 
                name="hdsabado" 
                id="hdSabado" 
                checked={sportActivityProps.sportActivityData.hdsabado} 
                onChange={handleInputChangeCheckBox} 
                style={{backgroundColor: "#0B49EB"}}
                />
                <label htmlFor="hdSabado">Sábado</label>
                <input 
                type="text" 
                name="hdsabadoact" 
                placeholder="Actividad"
                defaultValue={sportActivityProps.sportActivityData.hdsabadoact}
                onChange={handleInputChange}
                maxLength={200}
                />
                <input 
                type="time" 
                name="hdsabadohora"
                value={sportActivityProps.sportActivityData.hdsabadohora}
                onChange={handleInputChange}
                />
              </div>
              <div className={`day-activity  ${sportActivityProps.sportActivityData.hddomingo ? 'checked-day' : ''}`}>
                <input 
                type="checkbox" 
                name="hddomingo" 
                id="hdDomingo" 
                checked={sportActivityProps.sportActivityData.hddomingo} 
                onChange={handleInputChangeCheckBox} 
                style={{backgroundColor: "#0B49EB"}}
                />
                <label htmlFor="hdDomingo">Domingo</label>
                <input 
                type="text" 
                name="hddomingoact" 
                placeholder="Actividad"
                defaultValue={sportActivityProps.sportActivityData.hddomingoact}
                onChange={handleInputChange}
                maxLength={200}
                />
                <input 
                type="time" 
                name="hddomingohora"
                value={sportActivityProps.sportActivityData.hddomingohora}
                onChange={handleInputChange}
                />
              </div>
            </>
          )}
          {trainingPlans.includes(sportActivityProps.selectedTypeForm) && (
            <>
              <h6 className="diet-train-h">Para planes que incluyan dieta y entrenamiento</h6>
              <p className="diet-train-p">En este cuadrante indícanos con una X qué días quieres realizar nuestro entrenamiento. &#x0020;Si
                actualmente realizas actividades deportivas y quieres mantenerlas, indícanoslo tambien en el cuadrante.&#x0020; &#x0020;
                Cuéntanos qué día y a qué hora (formato 24 horas) y qué tipo de actividad.
              </p>

              <div  id="img-fill-doubt" ><strong>¿Tienes dudas de cómo rellenarlo? mira este ejemplo.</strong><img style={{width: "12px", marginLeft: "5px"}} src={IconChevron} alt="chevron down" onClick={doubtUpdate} /></div>
              <br></br>
               {doubtOn && (
                  <img src={doubtimg} alt="Imagen base para rellenar cuestionario" id="img-doubton" />
                )
                }          
              <p style={{fontWeight:'200', fontFamily:'Montserrat'}}>Cuéntanos la rutina de entrenamiento que quieres:</p>

              <div className={`day-activity  ${sportActivityProps.sportActivityData.helunes ? 'checked-day' : ''}`}>
                <input 
                type="checkbox" 
                name="helunes" 
                id="heLunes"
                checked={sportActivityProps.sportActivityData.helunes} 
                onChange={handleInputChangeCheckBox}
                style={{backgroundColor: "#0B49EB"}} 
                />
                <label htmlFor="heLunes">Lunes</label>
                <input 
                type="text" 
                name="helunesact" 
                placeholder="Actividad"
                defaultValue={sportActivityProps.sportActivityData.helunesact}
                onChange={handleInputChange}
                maxLength={200}
                />
                <input 
                type="time" 
                name="heluneshora" 
                value={sportActivityProps.sportActivityData.heluneshora}
                onChange={handleInputChange}
                />
              </div>
              <div className={`day-activity  ${sportActivityProps.sportActivityData.hemartes ? 'checked-day' : ''}`}>
                <input 
                type="checkbox" 
                name="hemartes"
                id= "hemartes"
                checked={sportActivityProps.sportActivityData.hemartes} 
                onChange={handleInputChangeCheckBox}
                style={{backgroundColor: "#0B49EB"}} 
                />
                <label htmlFor="heMartes">Martes</label>
                <input 
                type="text" 
                name="hemartesact" 
                placeholder="Actividad"
                defaultValue={sportActivityProps.sportActivityData.hemartesact}
                onChange={handleInputChange}
                maxLength={200}
                />
                <input 
                type="time" 
                name="hemarteshora"
                value={sportActivityProps.sportActivityData.hemarteshora}
                onChange={handleInputChange}
                />
              </div>
              <div className={`day-activity  ${sportActivityProps.sportActivityData.hemiercoles ? 'checked-day' : ''}`}>
                <input 
                type="checkbox" 
                name="hemiercoles" 
                id="heMiercoles" 
                checked={sportActivityProps.sportActivityData.hemiercoles} 
                onChange={handleInputChangeCheckBox}
                style={{backgroundColor: "#0B49EB"}} 
                />
                <label htmlFor="heMiercoles">Miércoles</label>
                <input 
                type="text" 
                name="hemiercolesact" 
                placeholder="Actividad"
                defaultValue={sportActivityProps.sportActivityData.hemiercolesact}
                onChange={handleInputChange}
                maxLength={200}
                />
                <input 
                type="time" 
                name="hemiercoleshora"
                value={sportActivityProps.sportActivityData.hemiercoleshora}
                onChange={handleInputChange}
                />
              </div>
              <div  className={`day-activity  ${sportActivityProps.sportActivityData.hejueves ? 'checked-day' : ''}`}>
                <input 
                type="checkbox" 
                name="hejueves" 
                id="heJueves" 
                checked={sportActivityProps.sportActivityData.hejueves} 
                onChange={handleInputChangeCheckBox}
                style={{backgroundColor: "#0B49EB"}} 
                />
                <label htmlFor="heJueves">Jueves</label>
                <input 
                type="text" 
                name="hejuevesact" 
                placeholder="Actividad"
                defaultValue={sportActivityProps.sportActivityData.hejuevesact}
                onChange={handleInputChange}
                maxLength={200}
                />
                <input 
                type="time" 
                name="hejueveshora"
                value={sportActivityProps.sportActivityData.hejueveshora}
                onChange={handleInputChange}
                />
              </div>
              <div className={`day-activity  ${sportActivityProps.sportActivityData.heviernes ? 'checked-day' : ''}`}>
                <input 
                type="checkbox" 
                name="heviernes" 
                id="heViernes" 
                checked={sportActivityProps.sportActivityData.heviernes}  
                onChange={handleInputChangeCheckBox}
                style={{backgroundColor: "#0B49EB"}} 
                />
                <label htmlFor="heViernes">Viernes</label>
                <input 
                type="text" 
                name="heviernesact" 
                placeholder="Actividad"
                defaultValue={sportActivityProps.sportActivityData.heviernesact}
                onChange={handleInputChange}
                maxLength={200}
                />
                <input 
                type="time" 
                name="hevierneshora"
                value={sportActivityProps.sportActivityData.hevierneshora}
                onChange={handleInputChange}
                />
              </div>
              <div  className={`day-activity  ${sportActivityProps.sportActivityData.hesabado ? 'checked-day' : ''}`}>
                <input 
                type="checkbox" 
                name="hesabado" 
                id="heSabado" 
                checked={sportActivityProps.sportActivityData.hesabado}  
                onChange={handleInputChangeCheckBox}
                style={{backgroundColor: "#0B49EB"}} 
                />
                <label htmlFor="heSabado">Sábado</label>
                <input 
                type="text" 
                name="hesabadoact" 
                placeholder="Actividad"
                defaultValue={sportActivityProps.sportActivityData.hesabadoact}
                onChange={handleInputChange}
                maxLength={200}
                />
                <input 
                type="time" 
                name="hesabadohora"
                value={sportActivityProps.sportActivityData.hesabadohora}
                onChange={handleInputChange}
                />
              </div>
              <div  className={`day-activity  ${sportActivityProps.sportActivityData.hedomingo ? 'checked-day' : ''}`}>
                <input 
                type="checkbox" 
                name="hedomingo" 
                id="heDomingo" 
                checked={sportActivityProps.sportActivityData.hedomingo}  
                onChange={handleInputChangeCheckBox}
                style={{backgroundColor: "#0B49EB"}} 
                />
                <label htmlFor="heDomingo">Domingo</label>
                <input 
                type="text" 
                name="hedomingoact" 
                placeholder="Actividad"
                defaultValue={sportActivityProps.sportActivityData.hedomingoact}
                onChange={handleInputChange}
                maxLength={200}
                />
                <input 
                type="time" 
                name="hedomingohora"
                value={sportActivityProps.sportActivityData.hedomingohora}
                onChange={handleInputChange}
                />
              </div>
            </>
          )}
          <div className="page-manager">
          <button className="rotate-arrow" onClick={()=>{
            setSportActivity(2);
            sportActivityProps.miComponenteRef.current?.scrollIntoView({block: "start",behavior: 'smooth'});
          }}><img src={nextArrow} alt={'previous'} /></button>      
          <span className="current-done">1</span><span className="current-done">2</span><span className="current-done">3</span>
            {sportActivity!==3 &&<button ><img src={nextArrow} alt={'next'} /></button>}
          </div>
          </>
        )}  
        <hr />
        <button className="prev-form-button" onClick={()=>{
          sportActivityProps.setNext(1);
          setSportActivity(1);
          sportActivityProps.miComponenteRef.current?.scrollIntoView({block: "start",behavior: 'smooth'});
        }}><img src={previousArrow} alt={'previous'} /> Anterior</button>
        {sportActivity===3? 
          <button onClick={()=>{
            sportActivityProps.setNext(3);
            sportActivityProps.miComponenteRef.current?.scrollIntoView({block: "start",behavior: 'smooth'});
        }} className="nxt-form-button">Siguiente <img src={nextArrow} alt={'next'} /></button>:
          <></>
        } 
                                 
        </div>

    );
}

export default SportActivityBuySubscriptions;