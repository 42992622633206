import Slider, { Settings } from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './Testimonials.css';
import { useNavigate } from "react-router-dom";
import Testimonial from "../../../shared/testimonial/Testimonial";
import {useEffect, useState} from "react";
import {testimonialsService} from "../../../../services/testimonialsService";
import {TESTIMONIALS_SLIDER_URL} from "../../../../utils/constants";
import {TestimonialType} from "../../../../models/TestimonialType";
import {DietNextArrow, DietPrevArrow} from "../../../shared/arrows/Arrows";

const Testimonials = () => {
    const navigate = useNavigate();
    const settings: Settings = {
        dots: true,
        dotsClass: "slidertestimonials-home-dots",
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        arrows: true,
        prevArrow: <DietPrevArrow />,
        nextArrow: <DietNextArrow />,
        customPaging: (i) => <div className="custom-dot-testimonios"></div>,
    };
    const [testimonials, setTestimonials] = useState<TestimonialType[]>([]);

    useEffect(() => {
        async function fetchTestimonials() {
            const testimonials = await testimonialsService(TESTIMONIALS_SLIDER_URL);
            setTestimonials(testimonials);
        }
        fetchTestimonials();
    }, []);

    const moreTestimonial = () => {
        navigate(`/testimonios`);
    };

    return (
        <Slider {...settings}>
            {
                testimonials.map((testimonial, index) => {
                   
                        return (
                            <Testimonial
                                key={index}
                                title={testimonial.title}
                                image={testimonial.image}
                                subtitle={testimonial.subtitle}
                                description={testimonial.description}
                                showMoreTestimonial={true}
                                moreTestimonial={moreTestimonial}
                                showMoreTestimonialTop={false}
                                category={testimonial.category}
                                type_of_sessions={testimonial.type_of_sessions || ''}
                                psychologist={testimonial.psychologist || ''}
                                number_of_sessions={testimonial.number_of_sessions || ''}
                                current_weight={testimonial.current_weight || ''}
                                duration_in_months={testimonial.duration_in_months || ''}
                                previous_weight={testimonial.previous_weight || ''}
                            />
                        );
                    
                })
            }
        </Slider>
    )
}

export default Testimonials

