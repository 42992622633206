import React,{useState,useEffect,useRef} from "react";
// import "./Chat.css";
import '../../diet/contact-specialist/Chat.css'
import SendIcon from "../../../../../assets/img/send.png";
import Message from "./Message";
import avatar_specialist from '../../../../../assets/img/chat/Avatar2.jpg'
import circulo from '../../../../../assets/img/circulo.jpg'
import { sendClientMessage } from "../../../../../services/sendPsiClientMessage";
interface Mensaje {
  mensaje: string;
  es_cliente: string;
}

interface ClientChat {
  mensajes: Mensaje[];
}

interface ChatProps {
  messages: ClientChat | null;
  onNewClientChat:(newClientChat:ClientChat)=>void;
}

const Chat: React.FC<ChatProps> = ({ messages,onNewClientChat}) => {
  const [messageToSend, setMessageToSend] = React.useState("");
  const [sending,setSending]=React.useState(false);

  const bottomRef = useRef<null | HTMLInputElement>(null); 

  const [typePlan, setTypePlan] = useState<string>("psi_subscription_ind");

  const handleSend = async () => {
    try {
      // await sendClientMessage(messageToSend);
      // console.log("Mensaje enviado correctamente");
      setMessageToSend("");
      setSending(true);
      setMessageToSend("");
      const screenHeight = window.innerHeight;
      const scrollToHeight = screenHeight * 0;
      
      window.scrollTo({
        top: scrollToHeight,
        behavior: 'smooth' // or 'auto' for instant scrolling
      });
      const new_message_history=await sendClientMessage({mensaje:messageToSend,TypePlan:typePlan});
      onNewClientChat(new_message_history);
      console.log('mensaje enviado correctamente');
    } catch (error) {
      console.log("Error al enviar el mensaje", error);
    }finally{
      setSending(false);
    }
  };
  useEffect(()=>{
    

    console.log(messages?.mensajes);
    bottomRef!.current!.scrollIntoView({block: 'end',behavior: 'smooth'
  });    
    }	, []);
  if (!messages) {
    return null;
  }

  

  return (
    <div className="chat"  ref={bottomRef} >
      <div className="chat-content" id="chat-containerr"        
>
        { messages.mensajes.map((message, index) => (
          <Message
            key={index}
            sender={message.es_cliente === "1" ? "user" : ""}
            avatar={message.es_cliente === "1" ? circulo : avatar_specialist}
            message={message.mensaje}
            time="12:00 AM"
          />
        ))}
      </div>
      <div className="input-content"         
>
        <input
          
        disabled={sending}
          placeholder="Enviar un mensaje a mi especialista"
          value={messageToSend}
          onChange={(event) => setMessageToSend(event.target.value)}
          className="input-message"
        />
        <img
          src={SendIcon}
          alt="send"
          className="send-icon"
          onClick={handleSend}
        />
      </div>
    </div>
  );
};

export default Chat;