import TeamCard from "./teamCard/TeamCard";
import {TeamSectionProps} from "../../../../models/componentProps/TeamSectionProps";

const TeamSection = (teamSectionProps: TeamSectionProps) => {

    return (
        <div className="section">
            <div className="section-title"><p>{teamSectionProps.title}</p></div>
            <div className='grid'>
                {teamSectionProps.items.map((item, index) => (
                    <TeamCard key={index} {...item} />
                ))}
            </div>
        </div>
    );
}

export default TeamSection
