import logo from './../../../assets/img/logo_footer.png';
import VectorImage from './../../../assets/img/arrow-right.svg';
import { useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import './Footer.css';

const Footer = () => {
  const [hidden, setHidden] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const year = new Date().getFullYear();

  useEffect(() => {
    switch (true) {
      case location.pathname === "/testimonios":
        setHidden(false);
        break;
      case location.pathname === "/faq":
        setHidden(false);
        break;
      case location.pathname === "/equipo":
        setHidden(false);
        break;
      case location.pathname === "/blog":
        setHidden(false);
        break;
      case location.pathname === "/contacto":
        setHidden(false);
        break;
      case location.pathname === "/":
        setHidden(false);
        break;
      case location.pathname === "/iniciar-sesion":
        setHidden(true);
        break;
      case location.pathname === "/register":
        setHidden(false);
        break;
      case /^\/profile\/\w+$/.test(location.pathname):
        setHidden(false);
        break;
      case location.pathname === '/private':
        setHidden(false);
        break;
      case location.pathname === "/plans_subscriptions":
        setHidden(false);
        break;
    }
  }, [location]);

  return (
    <>
      {hidden ?
        <div className='footer-hidden'></div>
        :
        (
          <footer className="footer">
            <div className="footer-col-container">
              <div className="footer-col col-1-spacer">
                <img src={logo} className="logo-footer" alt="Logo" />
                <div className='suscribe-desktop-view'>
                  {/* <p className="spacer-3">SUSCRÍBETE A LA NEWSLETTER</p> */}
                  <div className="footer-input">
                    <p>
                      Suscribirme
                    </p>
                    <a href={`${process.env.REACT_APP_FRONT_URL_NEWSLETTER}/blog/newsletter/`} target='_blank' className='btn-footer'>
                         <img src={VectorImage} alt="icon"/>
                      </a>
                    {/* <input
                      type="text"
                      placeholder="  Suscribirme"
                      id="emailFooter" disabled
                      autoComplete="off"
                      required
                    />
                      <a href={`${process.env.REACT_APP_FRONT_URL}/blog/newsletter/`} target='_blank' className='btn-footer'>
                         <img src={VectorImage} alt="icon"/>
                      </a> */}
                  </div>
                </div>


              </div>
              <div className="footer-col-1">
                <div className="footer-col-centered">
                  <div>
                    <p className="title-info">INFORMACIÓN</p>
                    <p className="spacer-2" style={{cursor: 'pointer'}} onClick={() => navigate('/testimonios')}>Testimonios</p>
                    <p className="spacer-2" style={{cursor: 'pointer'}} onClick={() => navigate('/faq')}>Preguntas frecuentes</p>
                    <a style={{textDecoration: "auto"}} href='https://www.vikika.es/blog/' target={"_blank"} id="blog"><p className="spacer-2">Blog</p></a>
                   <Link style={{textDecoration: "auto"}} to='/trabaja-con-nosotros'> <p className="spacer-2">Trabaja con nosotros</p></Link>
                  </div>
                  <div className="footer-col-2">
                    <p className="title-help">¿NECESITAS AYUDA?</p>
                    <p className="spacer-2" style={{cursor: 'pointer'}} onClick={() => navigate('/contacto')}>Contacta con nosotros</p>
                  </div>
                  <div className="footer-col-2">
                    <p className="title-info">NUESTROS PLANES</p>
                    <p className="spacer-2 footer-link-underlined" style={{cursor: 'pointer'}} onClick={() => navigate('/productos/dieta')}>Plan de Dieta</p>
                    <p className="spacer-2 footer-link-underlined" style={{cursor: 'pointer'}} onClick={() => navigate('/productos/psicologia')}>Plan de Psicología</p>
                    <p className="spacer-2 footer-link-underlined" style={{cursor: 'pointer'}} onClick={() => navigate('/productos/talleres')}>Talleres</p>
                  </div>

                </div>
              </div>



              <div className="footer-col">

              </div>
              <div className='suscribe-mobile-view'>
                {/* <p className="spacer-3">SUSCRÍBETE A LA NEWSLETTER</p> */}
                <div className="footer-input">
                    <p>
                      Suscribirme
                    </p>
                    <a href={`${process.env.REACT_APP_FRONT_URL}/blog/newsletter/`} target='_blank' className='btn-footer'>
                         <img src={VectorImage} alt="icon"/>
                      </a>
                {/* <input
                      type="text"
                      placeholder=" Suscribirme"
                      id="emailFooter" disabled
                      autoComplete="off"
                      required
                    />
                  <a href={`${process.env.REACT_APP_FRONT_URL}/blog/newsletter/`} target='_blank' className='btn-footer'>
                         <img src={VectorImage} alt="icon"/>
                      </a> */}
                </div>
              </div>
            </div>
            <div className="footer-copyright">
              <p>(c) {year} VIKIKA
                · <a href={'/privacidad'} className={"copyrigth"}> Politica de privacidad y protección de datos </a>
                · <a href={'/aviso-legal'} className={"copyrigth"}> Aviso legal </a>
                · <a href={'/cookies'} className={"copyrigth"}>Política de Cookies</a>
                · <a className={"copyrigth"}  href='/condiciones-generales'>Condiciones Generales</a></p>
            </div>
          </footer>
        )}
    </>
  );
}

export default Footer;

