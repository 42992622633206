import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import team_photo from "../../../assets/img/team_photo.jpg";
import Questions from '../../shared/questions/Questions';
import { getTeamMembersService } from '../../../services/teamService';
import { Member } from '../../../models/TeamMember'
import "./Team.css"
import TeamSection from "./teamSection/TeamSection";
import { ENTRENADORES_Y_NUTRICIONISTAS, PSICOLOGAS, ROLE_PSYCHOLOGIST, ROLE_TRAINER } from "../../../utils/constants";


const Team = () => {
  const [trainers, setTrainers] = useState<Member[]>([]);
  const [psychologists, setPsychologists] = useState<Member[]>([]);

  useEffect(() => {
    async function fetchTeamMembers() {
      const members = await getTeamMembersService();
      setTrainers(members.filter(member => (member.role === ROLE_TRAINER)));
      setPsychologists(members.filter(member => member.role === ROLE_PSYCHOLOGIST));
    }
    fetchTeamMembers();
 
  }, []);

  return (
    <>
      <Helmet>
        <link rel="canonical" href={`${process.env.REACT_APP_FRONT_URL}`+"/equipo"} />
        <meta name="description" content="Nuestro equipo de profesionales. Referentes en psicología, nutrición y entrenamiento, dan forma a Vikika Team."/>
        <title>Equipo | Referentes en cuidado físico, mental y nutrición</title>
      </Helmet>
      <main className="main-content">
        <div className='team-container'>
          <div className="team-banner">
            <div className="mask"></div>
            <img src={team_photo} alt="Imagen de encabezado" />
            <h1 className='header-team-section'>VIKIKA TEAM</h1>
            <h1 className='header-team-section-subtitle'>Nuestro equipo de profesionales referentes en psicología, nutrición y entrenamiento.</h1>
          </div>
          <div className="container" style={{maxWidth: '1600px', marginLeft: 'auto', marginRight: 'auto'}}>
            <TeamSection title={ENTRENADORES_Y_NUTRICIONISTAS} items={trainers} />
            <TeamSection title={PSICOLOGAS} items={psychologists} />
          </div>
        </div>
        <Questions />
      </main>
    </>
  )
}


export default Team;