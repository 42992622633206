import { useState, useEffect } from 'react';
import { UserProfileData } from '../../../../models/UserProfileData';
import { PROVINCES_NEW ,EMAIL_REGEX, PWD_REGEX } from '../../../../utils/constants';
import './Data.css'
import { UserBillingData } from '../../../../models/UserBillingData';
import { changePassword } from '../../../../services/changePassword';
import { changeBillingDetails } from '../../../../services/changeBillingDetails';
import Successfull from './Succesfull';
import {States} from "../../../../models/States";

const Data = () => {
    const options : States[] = PROVINCES_NEW;

    //Son en total --> 255 paises
    const countries = [
        { country: "Afganistán", code: "AF" },
        { country: "Islas Åland", code: "AX" },
        { country: "Albania", code: "AL" },
        { country: "Argelia", code: "DZ" },
        { country: "Samoa Americana", code: "AS" },
        { country: "Andorra", code: "AD" },
        { country: "Angola", code: "AO" },
        { country: "Anguila", code: "AI" },
        { country: "Antártida", code: "AQ" },
        { country: "Antigua y Barbuda", code: "AG" },
        { country: "Argentina", code: "AR" },
        { country: "Armenia", code: "AM" },
        { country: "Aruba", code: "AW" },
        { country: "Australia", code: "AU" },
        { country: "Austria", code: "AT" },
        { country: "Azerbaiyán", code: "AZ" },
        { country: "Bahamas", code: "BS" },
        { country: "Bahréin", code: "BH" },
        { country: "Bangladés", code: "BD" },
        { country: "Barbados", code: "BB" },
        { country: "Bielorrusia", code: "BY" },
        { country: "Bélgica", code: "BE" },
        { country: "Belice", code: "BZ" },
        { country: "Benín", code: "BJ" },
        { country: "Bermudas", code: "BM" },
        { country: "Bután", code: "BT" },
        { country: "Bolivia", code: "BO" },
        { country: "Bonaire, San Eustaquio y Saba", code: "BQ" },
        { country: "Bosnia y Herzegovina", code: "BA" },
        { country: "Botsuana", code: "BW" },
        { country: "Isla Bouvet", code: "BV" },
        { country: "Brasil", code: "BR" },
        { country: "Territorio Británico del Océano Índico", code: "IO" },
        { country: "Brunéi", code: "BN" },
        { country: "Bulgaria", code: "BG" },
        { country: "Burkina Faso", code: "BF" },
        { country: "Burundi", code: "BI" },
        { country: "Camboya", code: "KH" },
        { country: "Camerún", code: "CM" },
        { country: "Canadá", code: "CA" },
        { country: "Cabo Verde", code: "CV" },
        { country: "Islas Caimán", code: "KY" },
        { country: "República Centroafricana", code: "CF" },
        { country: "Chad", code: "TD" },
        { country: "Chile", code: "CL" },
        { country: "China", code: "CN" },
        { country: "Isla de Navidad", code: "CX" },
        { country: "Islas Cocos (Keeling)", code: "CC" },
        { country: "Colombia", code: "CO" },
        { country: "Comoras", code: "KM" },
        { country: "Congo - Brazzaville", code: "CG" },
        { country: "Congo - Kinshasa", code: "CD" },
        { country: "Islas Cook", code: "CK" },
        { country: "Costa Rica", code: "CR" },
        { country: "Costa de Marfil", code: "CI" },
        { country: "Croacia", code: "HR" },
        { country: "Cuba", code: "CU" },
        { country: "Curazao", code: "CW" },
        { country: "Chipre", code: "CY" },
        { country: "República Checa", code: "CZ" },
        { country: "Dinamarca", code: "DK" },
        { country: "Yibuti", code: "DJ" },
        { country: "Dominica", code: "DM" },
        { country: "República Dominicana", code: "DO" },
        { country: "Ecuador", code: "EC" },
        { country: "Egipto", code: "EG" },
        { country: "El Salvador", code: "SV" },
        { country: "Guinea Ecuatorial", code: "GQ" },
        { country: "Eritrea", code: "ER" },
        { country: "Estonia", code: "EE" },
        { country: "Etiopía", code: "ET" },
        { country: "Islas Malvinas (Falkland Islands)", code: "FK" },
        { country: "Islas Feroe", code: "FO" },
        { country: "Fiyi", code: "FJ" },
        { country: "Finlandia", code: "FI" },
        { country: "Francia", code: "FR" },
        { country: "Guayana Francesa", code: "GF" },
        { country: "Polinesia Francesa", code: "PF" },
        { country: "Territorios Franceses del Sur", code: "TF" },
        { country: "Gabón", code: "GA" },
        { country: "Gambia", code: "GM" },
        { country: "Georgia", code: "GE" },
        { country: "Alemania", code: "DE" },
        { country: "Ghana", code: "GH" },
        { country: "Gibraltar", code: "GI" },
        { country: "Grecia", code: "GR" },
        { country: "Groenlandia", code: "GL" },
        { country: "Granada", code: "GD" },
        { country: "Guadalupe", code: "GP" },
        { country: "Guam", code: "GU" },
        { country: "Guatemala", code: "GT" },
        { country: "Guernsey", code: "GG" },
        { country: "Guinea", code: "GN" },
        { country: "Guinea-Bissau", code: "GW" },
        { country: "Guyana", code: "GY" },
        { country: "Haití", code: "HT" },
        { country: "Islas Heard y McDonald", code: "HM" },
        { country: "Ciudad del Vaticano", code: "VA" },
        { country: "Honduras", code: "HN" },
        { country: "Hong Kong", code: "HK" },
        { country: "Hungría", code: "HU" },
        { country: "Islandia", code: "IS" },
        { country: "India", code: "IN" },
        { country: "Indonesia", code: "ID" },
        { country: "Irán", code: "IR" },
        { country: "Irak", code: "IQ" },
        { country: "Irlanda", code: "IE" },
        { country: "Isla de Man", code: "IM" },
        { country: "Israel", code: "IL" },
        { country: "Italia", code: "IT" },
        { country: "Jamaica", code: "JM" },
        { country: "Jersey", code: "JE" },
        { country: "Jordania", code: "JO" },
        { country: "Kazajistán", code: "KZ" },
        { country: "Kenia", code: "KE" },
        { country: "Kiribati", code: "KI" },
        { country: "Corea del Norte", code: "KP" },
        { country: "Corea del Sur", code: "KR" },
        { country: "Kosovo", code: "XK" },
        { country: "Kuwait", code: "KW" },
        { country: "Kirguistán", code: "KG" },
        { country: "Laos", code: "LA" },
        { country: "Letonia", code: "LV" },
        { country: "Líbano", code: "LB" },
        { country: "Lesoto", code: "LS" },
        { country: "Liberia", code: "LR" },
        { country: "Libia", code: "LY" },
        { country: "Liechtenstein", code: "LI" },
        { country: "Lituania", code: "LT" },
        { country: "Luxemburgo", code: "LU" },
        { country: "Macao", code: "MO" },
        { country: "Macedonia del Norte", code: "MK" },
        { country: "Madagascar", code: "MG" },
        { country: "Malaui", code: "MW" },
        { country: "Malasia", code: "MY" },
        { country: "Maldivas", code: "MV" },
        { country: "Malí", code: "ML" },
        { country: "Malta", code: "MT" },
        { country: "Islas Marshall", code: "MH" },
        { country: "Martinica", code: "MQ" },
        { country: "Mauritania", code: "MR" },
        { country: "Mauricio", code: "MU" },
        { country: "Mayotte", code: "YT" },
        { country: "México", code: "MX" },
        { country: "Micronesia", code: "FM" },
        { country: "Moldavia", code: "MD" },
        { country: "Mónaco", code: "MC" },
        { country: "Mongolia", code: "MN" },
        { country: "Montenegro", code: "ME" },
        { country: "Montserrat", code: "MS" },
        { country: "Marruecos", code: "MA" },
        { country: "Mozambique", code: "MZ" },
        { country: "Myanmar (Birmania)", code: "MM" },
        { country: "Namibia", code: "NA" },
        { country: "Nauru", code: "NR" },
        { country: "Nepal", code: "NP" },
        { country: "Países Bajos", code: "NL" },
        { country: "Curazao", code: "AN" },
        { country: "Nueva Caledonia", code: "NC" },
        { country: "Nueva Zelanda", code: "NZ" },
        { country: "Nicaragua", code: "NI" },
        { country: "Níger", code: "NE" },
        { country: "Nigeria", code: "NG" },
        { country: "Niue", code: "NU" },
        { country: "Isla Norfolk", code: "NF" },
        { country: "Islas Marianas del Norte", code: "MP" },
        { country: "Noruega", code: "NO" },
        { country: "Omán", code: "OM" },
        { country: "Pakistán", code: "PK" },
        { country: "Palaos", code: "PW" },
        { country: "Palestina", code: "PS" },
        { country: "Panamá", code: "PA" },
        { country: "Papúa Nueva Guinea", code: "PG" },
        { country: "Paraguay", code: "PY" },
        { country: "Perú", code: "PE" },
        { country: "Filipinas", code: "PH" },
        { country: "Islas Pitcairn", code: "PN" },
        { country: "Polonia", code: "PL" },
        { country: "Portugal", code: "PT" },
        { country: "Puerto Rico", code: "PR" },
        { country: "Catar", code: "QA" },
        { country: "Reunión", code: "RE" },
        { country: "Rumania", code: "RO" },
        { country: "Rusia", code: "RU" },
        { country: "Ruanda", code: "RW" },
        { country: "San Bartolomé", code: "BL" },
        { country: "Santa Elena", code: "SH" },
        { country: "San Cristóbal y Nieves", code: "KN" },
        { country: "Santa Lucía", code: "LC" },
        { country: "San Martín", code: "MF" },
        { country: "San Pedro y Miquelón", code: "PM" },
        { country: "San Vicente y las Granadinas", code: "VC" },
        { country: "Samoa", code: "WS" },
        { country: "San Marino", code: "SM" },
        { country: "Santo Tomé y Príncipe", code: "ST" },
        { country: "Arabia Saudita", code: "SA" },
        { country: "Senegal", code: "SN" },
        { country: "Serbia", code: "RS" },
        { country: "Seychelles", code: "SC" },
        { country: "Sierra Leona", code: "SL" },
        { country: "Singapur", code: "SG" },
        { country: "Sint Maarten", code: "SX" },
        { country: "Eslovaquia", code: "SK" },
        { country: "Eslovenia", code: "SI" },
        { country: "Islas Salomón", code: "SB" },
        { country: "Somalia", code: "SO" },
        { country: "Sudáfrica", code: "ZA" },
        { country: "Islas Georgias del Sur y Sandwich del Sur", code: "GS" },
        { country: "Sudán del Sur", code: "SS" },
        { country: "España", code: "ES" },
        { country: "Sri Lanka", code: "LK" },
        { country: "Sudán", code: "SD" },
        { country: "Surinam", code: "SR" },
        { country: "Svalbard y Jan Mayen", code: "SJ" },
        { country: "Esuatini", code: "SZ" },
        { country: "Suecia", code: "SE" },
        { country: "Suiza", code: "CH" },
        { country: "Siria", code: "SY" },
        { country: "Taiwán", code: "TW" },
        { country: "Tayikistán", code: "TJ" },
        { country: "Tanzania", code: "TZ" },
        { country: "Tailandia", code: "TH" },
        { country: "Timor Oriental", code: "TL" },
        { country: "Togo", code: "TG" },
        { country: "Tokelau", code: "TK" },
        { country: "Tonga", code: "TO" },
        { country: "Trinidad y Tobago", code: "TT" },
        { country: "Túnez", code: "TN" },
        { country: "Turquía", code: "TR" },
        { country: "Turkmenistán", code: "TM" },
        { country: "Islas Turcas y Caicos", code: "TC" },
        { country: "Tuvalu", code: "TV" },
        { country: "Uganda", code: "UG" },
        { country: "Ucrania", code: "UA" },
        { country: "Emiratos Árabes Unidos", code: "AE" },
        { country: "Reino Unido", code: "GB" },
        { country: "Estados Unidos", code: "US" },
        { country: "Islas Ultramarinas Menores de Estados Unidos", code: "UM" },
        { country: "Uruguay", code: "UY" },
        { country: "Uzbekistán", code: "UZ" },
        { country: "Vanuatu", code: "VU" },
        { country: "Venezuela", code: "VE" },
        { country: "Vietnam", code: "VN" },
        { country: "Islas Vírgenes Británicas", code: "VG" },
        { country: "Islas Vírgenes de los Estados Unidos", code: "VI" },
        { country: "Wallis y Futuna", code: "WF" },
        { country: "Sahara Occidental", code: "EH" },
        { country: "Yemen", code: "YE" },
        { country: "Zambia", code: "ZM" },
        { country: "Zimbabue", code: "ZW" }
    ];
      
    const [formData, setFormData] = useState<UserProfileData>({
        firstName: '',
        lastName: '',
        displayName: '',
        email: '',
        password: '',
        newPassword: '',
        confirmPassword: '',
      });

      const [formBill, setFormBill] = useState<UserBillingData>({
        country:'',
        address: '',
        address2: '',
        cpo: '',
        city: '',
        province: ''   
      });

    const [isVisible2,setIsVisible2]=useState(false);
      const [isVisible,setIsVisible]=useState(false);
      const [checkIsValid, setCheckIsValid] = useState(false); 
      const [checkValidBill, setCheckValidBill] = useState(false); 

    const [firstNameFocus, setFirstNameFocus] = useState(false);
    const [firstNameTouched, setFirstNameTouched] = useState(false);

    const [lastNameFocus, setLastNameFocus] = useState(false);
    const [lastNameTouched, setLastNameTouched] = useState(false);

    const [displayNameFocus, setDisplayNameFocus] = useState(false);
    const [displayNameTouched, setDisplayNameTouched] = useState(false);

    const [password, setPassword] = useState('');
    const [validPassword, setValidPassword] = useState(false);
    const [passwordFocus, setPasswordFocus] = useState(false);
    const [passwordTouched, setPasswordTouched] = useState(false);

    const [newPassword, setNewPassword] = useState('');
    const [validNewPassword, setValidNewPassword] = useState(false);
    const [newPasswordFocus, setNewPasswordFocus] = useState(false);
    const [newPasswordTouched, setNewPasswordTouched] = useState(false);
    const [newPasswordisValid, setNewPasswordisValid] = useState(false);

    const [matchPwd, setMatchPwd] = useState('');
    const [validMatch, setValidMatch] = useState(false);
    const [matchFocus, setMatchFocus] = useState(false);
    const [matchTouched, setMatchTouched] = useState(false);
    
    const [email, setEmail] = useState('');
    const [validEmail, setValidEmail] = useState(false);
    const [emailFocus, setEmailFocus] = useState(false);
    const [emailTouched, setEmailTouched] = useState(false);    
    
    const [countryTouched, setCountryTouched] = useState(false);
    const [addressTouched, setaddressTouched] = useState(false);
    const [cpoTouched, setcpoTouched] = useState(false);
    const [stateTouched, setStateTouched] = useState(false);
    const [cityTouched, setCityTouched] = useState(false);
    const [address2Touched, setAddress2Touched] = useState(false);


    useEffect(() => {
        setValidEmail(EMAIL_REGEX.test(email) );
    }, [email])     
    
    useEffect(() => {
        setValidNewPassword(PWD_REGEX.test(newPassword));
        setValidMatch(newPassword === matchPwd);
    }, [newPassword, matchPwd])
    

    useEffect(() => {
        setValidPassword(PWD_REGEX.test(password));
    }, [password])

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>, name: string) => {
        const { value } = event.target;
        setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
        if(password && newPassword){
            setCheckIsValid(true);
        }
    };    
    const handlePasswordChange = (newPassword :string) => {
        setNewPassword(newPassword);
        setNewPasswordisValid(PWD_REGEX.test(newPassword));
        console.log(newPassword);
        console.log(newPasswordisValid);
        console.log(PWD_REGEX.test(newPassword));
      };

       const handleSubmitForm = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {  
        console.log('Billing details changed successfully');
              event.preventDefault();

         try {
            setIsVisible2(false);
            await changePassword(formData.newPassword,formData.confirmPassword);
            console.log(formData.newPassword,formData.confirmPassword);
            console.log('password changed successfully');
            // updateLocaldata();            
            setIsVisible2(true);
          } catch (error) {
            console.error('Error changing  password:', error);
          }
       
    };
    const handleSubmitForm2 = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {  
        console.log('Billing details changed successfully');
              event.preventDefault();

         try {
            setIsVisible(false);
            await changeBillingDetails(formBill.address,formBill.address2,formBill.cpo,formBill.city,formBill.province,formBill.country);
            console.log(formBill.address,formBill.address2,formBill.city,formBill.province,'ES');
            console.log('Billing details changed successfully');
            updateLocaldata();  
                   setIsVisible(true);

          } catch (error) {
            console.error('Error changing billing details:', error);
          }
        
       
    };

    const updateLocaldata = () => {
  // Actualizar campos en localStorage
  localStorage.setItem('address_1', formBill.address);
  localStorage.setItem('address_2', formBill.address2 ? formBill.address2 : '');
  localStorage.setItem('city', formBill.city);
  localStorage.setItem('cpo', formBill.cpo.toString());
  localStorage.setItem('state', formBill.province);

  // Recargar la página
//   window.location.reload();
    };

    useEffect(() => {       
               
    // const [countryTouched, setCountryTouched] = useState(false);
    // const [addressTouched, setaddressTouched] = useState(false);
    // const [cpoTouched, setcpoTouched] = useState(false);
    // const [stateTouched, setStateTouched] = useState(false);
    // const [cityTouched, setCityTouched] = useState(false);
    // const [address2Touched, setAddress2Touched] = useState(false);
 
        setCheckIsValid(
        
            (formData.newPassword === formData.confirmPassword) &&
            (formData.confirmPassword === formData.newPassword) &&
            (formData.password !== '') &&
            (formData.newPassword !== '') &&
            (formData.confirmPassword !== '')
        );      
      }, [
         formData.confirmPassword,
         formData.newPassword]);

    useEffect(() => {
        setCheckValidBill(
            (formBill.address!== '') &&
            (formBill.cpo!== '') &&
            (formBill.city!== '') && (countryTouched || addressTouched || address2Touched || cpoTouched || stateTouched || cityTouched)
        );  
      }, [
         formBill.address,
         formBill.cpo,
         formBill.city,
         countryTouched,address2Touched,addressTouched,cpoTouched,stateTouched,cityTouched
        ]);
 useEffect(() => {
    const address = localStorage.getItem('address_1');
    const address2 = localStorage.getItem('address_2');
    const cpo = localStorage.getItem('postcode');
        const city = localStorage.getItem('city');
        const state = localStorage.getItem('state');
        const country = localStorage.getItem('country');
        if (address && cpo && city && country ) {
          setFormBill((prevData) => ({
            ...prevData,
            country:country,
            address: address,
            address2:address2,
            cpo: cpo,
            city: city,
            provice:state
        }));
        }
    
      }, []);

      useEffect(() => {        
        const storedFirstName = localStorage.getItem('firstName');
        const storedLastName = localStorage.getItem('lastName');
        const storedDisplayName = localStorage.getItem('displayName');
        const storedEmail = localStorage.getItem('email');
        const password = localStorage.getItem('password');
        if (storedFirstName && storedLastName && storedDisplayName && storedEmail){
            setFormData((prevData) => ({
                ...prevData,
                firstName: storedFirstName,
                lastName: storedLastName,
                displayName: storedDisplayName,
                email: storedEmail,
            })); 
        }

        if (storedFirstName && storedLastName && storedDisplayName && storedEmail && password) {
          setFormData((prevData) => ({
            ...prevData,
            password: password
          }));
        }
      }, []);
     
    
       

    return (
        <div>
            <div className='profile-body-title'>
                <p>INICIO DE SESIÓN  Y SEGURIDAD </p>
            </div>
            <div className='data-form'>
                {isVisible && (
                                    <Successfull message='La información se  ha cambiado con éxito ' />
                )
                }
                {isVisible2 && (    
                    <div style={{top:'140px', position:'sticky'}}>
                                                        <Successfull message='La contraseña se ha cambiado con éxito'/>
                    </div>
                )
                }
                <form  className='personal-data-forms-form'>
                    <div className='data-row-double-input' >
                        <div className={!firstNameFocus && firstNameTouched && formData.firstName === '' ? 'data-input-short1 colored invalidInput' : 'data-input-short1 colored'}   >
                            <label htmlFor="firstName">Nombre </label>
                            <input
                            disabled
                                type="text"
                                id="firstName"
                                defaultValue={formData.firstName}
                                onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => handleInputChange(event, 'firstName')}
                                required
                                onFocus={() => {
                                    setFirstNameFocus(true)
                                    setFirstNameTouched(true)
                                }}
                                onBlur={() => {
                                    setFirstNameFocus(false)
                                    //CheckIsValid()
                                }}
                            />
                        </div>

                        <div className={!lastNameFocus && lastNameTouched && formData.lastName === '' ? 'data-input-short2 colored invalidInput' : 'data-input-short2 colored'}>
                            <label htmlFor="lastName">Apellido </label>
                            <input
                            disabled
                                type="text"
                                id="lastName"
                                defaultValue={formData.lastName}
                                onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
                                    handleInputChange(event, 'lastName')
                                    //CheckIsValid()
                                }}
                                required
                                onFocus={() => {
                                    setLastNameFocus(true)
                                    setLastNameTouched(true)
                                }}
                                onBlur={() => {
                                    setLastNameFocus(false)
                                    //CheckIsValid()
                                }}
                            />
                        </div>
                    </div>

                    <div className='data-row-double-input' >
                        <div className={!displayNameFocus && displayNameTouched && formData.displayName === '' ? 'data-input-short1 colored invalidInput' : 'data-input-short1 colored'}  >
                            <label htmlFor="displayName">Nombre Publico </label>
                            <input
                            disabled
                                type="text"
                                id="displayName"
                                defaultValue={formData.displayName}
                                onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => handleInputChange(event, 'displayName')}
                                required
                                onFocus={() => {
                                    setDisplayNameFocus(true)
                                    
                                    setDisplayNameTouched(true)
                                }}
                                onBlur={() => {
                                    setDisplayNameFocus(false)
                                    //CheckIsValid()
                                }}
                            />
                        </div>

                        <div className={(!emailFocus && email && !validEmail) ||
                            (!emailFocus && emailTouched && formData.email === '') ? 'data-input-short1  colored invalidInput' : 'data-input-short2 colored'} >
                            <label htmlFor='email'>Correo electrónico </label>
                            <input
                            disabled
                                type="text"
                                id='email'
                                defaultValue={formData.email}
                                onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
                                    setEmail(event.target.value)
                                    handleInputChange(event, 'email')
                                }
                                }
                                required
                                aria-invalid={validEmail ? "false" : "true"}
                                aria-describedby="emailnote"
                                onFocus={() => {
                                    setEmailFocus(true)
                                    setEmailTouched(true)
                                }}
                                onBlur={() => {
                                    setEmailFocus(false)
                                    //CheckIsValid()
                                }}
                            />
                            <p id="emailnote" className={emailFocus && email && !validEmail ? "instructions" : "offscreen"}>
                                Debe introducir un correo válido.<br />
                            </p>
                        </div>
                    </div>
                    {/* <br/>
                    <br/> */}
                    <div className='data-row-double-input' >
                        <div className={(!passwordFocus && password && !validPassword) ||
                            (!passwordFocus && passwordTouched && formData.password === '') ? 'data-input-short1 invalidInput' : 'data-input-short1'}>
                            <label htmlFor="firstName">Contraseña </label>
                            <input readOnly
                            className='password-security'
                                type="password"
                                id="password"
                                defaultValue={formData.password}
                                onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
                                    setPassword(event.target.value)
                                    handleInputChange(event, 'password')
                                }}
                                required
                                onFocus={() => {
                                    setPasswordFocus(true)
                                    setPasswordTouched(true)
                                }}
                                onBlur={() => {
                                    setPasswordFocus(false)
                                    //CheckIsValid()
                                }}
                                aria-invalid={validPassword ? "false" : "true"}
                                aria-describedby="passwordnote"
                            />
                            <p id="passwordnote" className={passwordFocus && !validPassword ? "instructions" : "offscreen"}>
                                Mínimo 8 caracteres.<br />
                                Debe incluir mayúsculas, al menos un número y un caracter especial.<br />
                                Caracteres permitidos:
                                <span aria-label="exclamation mark">!</span>
                                <span aria-label="at symbol">@</span>
                                <span aria-label="hashtag">#</span>
                                <span aria-label="dollar sign">$</span>
                                <span aria-label="percent">%</span>
                                <span aria-label="start">*</span>
                                <span aria-label="start">-</span>
                            </p>
                        </div>
                    </div>
                    <div className='data-row-double-input'>
                        <div className={(!newPasswordFocus && newPassword && !validNewPassword && newPasswordisValid) ||
                            (!newPasswordFocus && newPasswordTouched && formData.newPassword === '' && newPasswordisValid) ? 'data-input-short1 invalidInput' : 'data-input-short1'}>
                            <label htmlFor='password'>Nueva contraseña  </label>
                            <input
                            className={formData.newPassword!=='' ? 'dots':'nodots'}
                                defaultValue={formData.newPassword}
                                type='password'
                                id='newPassword'
                                required
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    const newPassword = event.target.value;  // Obtener el valor seleccionado del <select>
                                    setFormData(prevFormData => ({
                                        ...prevFormData,
                                        newPassword: newPassword,  // Actualizar el valor de region en el estado
                                    }));
                                    handlePasswordChange(newPassword);
                                }}
                                placeholder='Nueva Contraseña'
                                aria-invalid={validNewPassword ? "false" : "true"}
                                aria-describedby="newpasswordnote"
                                onFocus={() => {
                                    setNewPasswordFocus(true)
                                    setNewPasswordTouched(true)
                                }}
                                onBlur={() => {
                                    setNewPasswordFocus(false)
                                    //CheckIsValid()
                                }}
                                
                            />
                        </div>

                        <div className={(!matchFocus && matchPwd && !validMatch) ||
                            (!matchFocus && matchTouched && formData.confirmPassword === '') ? 'data-input-short2 invalidInput' : 'data-input-short2'}>
                            <label htmlFor='confPassword'>Confirmar contraseña </label>
                            <input
                               className={formData.confirmPassword!=='' ? 'dots':'nodotos'}
                                type='password'
                                id='confPassword'
                                //onPaste={handlePaste}
                                placeholder='Confirmar nueva contraseña'

                                defaultValue={formData.confirmPassword}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    const confirmPassword = event.target.value;  // Obtener el valor seleccionado del <select>
                                    console.log(confirmPassword);
                                    setFormData(prevFormData => ({
                                        ...prevFormData,
                                        confirmPassword: confirmPassword,  // Actualizar el valor de region en el estado
                                    }));
                                }}
                                aria-invalid={validMatch ? "false" : "true"}
                                aria-describedby="matchPasswordNote"
                                onFocus={() => {
                                    setMatchFocus(true)
                                    setMatchTouched(true)
                                }}
                                onBlur={() => {
                                    setMatchFocus(false)
                                    //CheckIsValid()
                                }}
                                required
                            />
                            <p id="matchPasswordNote" className={matchFocus && !validMatch ? "instructions" : "offscreen"}>
                                Las contraseñas deben coincidir.
                            </p>
                        </div>
                    </div>
                    <div className="data-row-double-input">
                    <p id="newpasswordnote" className={newPasswordFocus && !validNewPassword  ? "instructions" : "offscreen"}>
                                Mínimo 8 caracteres.<br />
                                Debe incluir mayúsculas, al menos un número y un caracter especial.<br />
                                Caracteres permitidos:
                                <span aria-label="exclamation mark">!</span>
                                <span aria-label="at symbol">@</span>
                                <span aria-label="hashtag">#</span>
                                <span aria-label="dollar sign">$</span>
                                <span aria-label="percent">%</span>
                                <span aria-label="start">*</span>
                                <span aria-label="start">-</span>
                            </p>
                    </div>




                    <div className='data-button'>
                        <button disabled = {!checkIsValid || !newPasswordisValid} type="submit" onClick={handleSubmitForm} >GUARDAR CAMBIOS</button>
                    </div>
                </form>


                {/* formulario de los datos de facturación */}
                <div className='profile-body-title'>
                    <p>INFORMACIÓN DE FACTURACIÓN</p>
                </div>
                <form className='personal-data-forms-form'>
                    <div className="data-row-double-input">
                        <div className="data-input-long">
                        <label htmlFor="country">País </label>
                            <select
                                id="country"
                                onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                                    const country = event.target.value;  // Obtener el valor seleccionado del <select>
                                    console.log(country);
                                    setFormBill(prevFormBill => ({
                                        ...prevFormBill,
                                        country: country,  // Actualizar el valor de region en el estado
                                    }));
                                }}
                                onFocus={(event: React.ChangeEvent<HTMLSelectElement>)=>{
                                    setCountryTouched(true);
                                }
                                }
                            required>
                                <option value="" disabled selected hidden>País</option>
                                {countries.map(
                                    
                                    (countries) => (
                                        <option key={countries.code} value={countries.code} className='province-options' selected={countries.code === localStorage.getItem('country')}
                                        >{countries.country}</option>
                                    )
                                )

                                }
                            </select>      
                        </div>
                    </div>
                    <div className='data-row-double-input' >
                        <div className={!firstNameFocus && firstNameTouched && formData.firstName === '' ? 'data-input-short1 invalidInput' : 'data-input-short1'}   >
                            <label htmlFor="address">Dirección de Facturación </label>
                            <input
                            id='address'
                             defaultValue={formBill.address}
                             onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                const newValue = event.target.value;
                                setFormBill((prevFormBill) => ({
                                  ...prevFormBill,
                                  address: newValue,  // Actualizar el valor de city en el estado
                                }));
                              }}                                required
                                type="text"
                                onFocus={(event: React.ChangeEvent<HTMLInputElement>)=>{
                                    setaddressTouched(true);
                                }
                                }
                            />

                        </div>
                        <div className={!firstNameFocus && firstNameTouched && formData.firstName === '' ? 'data-input-short2 invalidInput' : 'data-input-short2'}   >
                        <label htmlFor="address2"></label>
                            <input
                            id='address2'
                            placeholder='Apartamento, habitación, etc. (opcional)'
                             defaultValue={formBill.address2 ?? ''} 
                             onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                const newValue = event.target.value;
                                setFormBill((prevFormBill) => ({
                                  ...prevFormBill,
                                  address2: newValue,  // Actualizar el valor de city en el estado
                                }));
                              }}                                required
                                type="text"
                                
                                onFocus={(event: React.ChangeEvent<HTMLInputElement>)=>{
                                    setAddress2Touched(true);
                                }
                                }

                            />
                        </div>
                    </div>

                    <div className='data-row-double-input' >
                        <div className={!displayNameFocus && displayNameTouched && formData.displayName === '' ? 'data-input-short1 invalidInput' : 'data-input-short1'}  >
                            <label htmlFor="codigo-postal">Código Postal </label>
                            <input
                                type="text"
                                defaultValue={formBill.cpo}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    const newValue = event.target.value;
                                    setFormBill((prevFormBill) => ({
                                      ...prevFormBill,
                                      cpo: newValue,  // Actualizar el valor de city en el estado
                                    }));
                                  }}             
                                                        required   
                                id="codigo-postal"
                                onFocus={(event: React.ChangeEvent<HTMLInputElement>)=>{
                                    setcpoTouched(true);
                                }
                                }

                                
                            />
                        </div>

                        <div className={(!emailFocus && email && !validEmail) ||
                            (!emailFocus && emailTouched && formData.email === '') ? 'data-input-short1  invalidInput' : 'data-input-short2 '} >
                            <label htmlFor='city'>Localidad / Ciudad </label>
                            <input
                                type="text"
                                defaultValue={formBill.city}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    const newValue = event.target.value;
                                    setFormBill((prevFormBill) => ({
                                      ...prevFormBill,
                                      city: newValue,  // Actualizar el valor de city en el estado
                                    }));
                                  }}                                required
                                id='city'
                                onFocus={(event: React.ChangeEvent<HTMLInputElement>)=>{
                                    setCityTouched(true);
                                }
                                }

                            />
                            <p id="city" className={emailFocus && email && !validEmail ? "instructions" : "offscreen"}>
                                Debe introducir un código postal válido.<br />
                            </p>
                        </div>
                    </div>
                    {/* <br/>
                    <br/> */}
                    {formBill.country === 'ES' && (

//  <div className='data-row-double-input' >
//                         <div className='data-input-long'>
//                             <label htmlFor="provincia">Provincia </label>
//                             <select
//                                 id="provincia"
//                                 onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
//                                     const newRegion = event.target.value;  // Obtener el valor seleccionado del <select>
//                                     console.log(newRegion);
//                                     setFormBill(prevFormBill => ({
//                                         ...prevFormBill,
//                                         province: newRegion,  // Actualizar el valor de region en el estado
//                                     }));
//                                 }}
//                             >
//                                 {options.map(
                                    
//                                     (provincia) => (
//                                         <option key={provincia.provincia} value={provincia.provincia} className='province-options' selected={provincia.provincia === localStorage.getItem('state')}
//                                         >{provincia.provincia}</option>
//                                     )
//                                 )

//                                 }
//                             </select>
//                         </div>
//                     </div>
                        <div className='data-row-double-input'>
                        <div className='data-input-long'>
                        <label htmlFor='provincia'>Provincia</label>
                        <select
                            id='provincia'
                            name='provincia'
                            onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                                                                    const newRegion = event.target.value;  // Obtener el valor seleccionado del <select>
                                                                    console.log(newRegion);
                                                                    setFormBill(prevFormBill => ({
                                                                        ...prevFormBill,
                                                                        province: newRegion,  // Actualizar el valor de region en el estado
                                                                    }));
                                                                }}
                            onFocus={(event: React.ChangeEvent<HTMLSelectElement>)=>{
                                setStateTouched(true);
                            }
                            }
                                >
                            <option value=''>Selecciona una provincia</option>
                            {PROVINCES_NEW[0].states.map((province) => (
                            <option
                                key={province.code}
                                value={province.code}
                                className='province-options'
                                selected={province.code === localStorage.getItem('state')}
                            >
                                {province.name}
                            </option>
                            ))}
                        </select>
                        </div>
                        </div>
                    ) 
                    }
                   
                    <div className='data-button'>
                        <button disabled = {!checkValidBill} type="submit" onClick={handleSubmitForm2}>GUARDAR CAMBIOS</button>
                    </div>
                </form>              
            </div>            
        </div>
    )
}
export default Data;
