const DOMAIN_STAGE = 'vikika.criterian.es';
const DOMAIN_LIVE = 'vikika.es';

const defaults = {
    BASE_URL: 'http://localhost:3000',
    SENTRY_DSN: 'https://a01a3e071e1680601725e444c7270519@o4506110035296256.ingest.sentry.io/4506110894866432',
};

const variables = {
    development: {
        ...defaults, SENTRY_ENVIRONMENT: 'local',
    }, stage: {
        ...defaults, SENTRY_ENVIRONMENT: 'stage',
    }, production: {
        ...defaults, SENTRY_ENVIRONMENT: 'production',
    },
};

const getVariables = () => {
    if (window.location.hostname === DOMAIN_STAGE) {
        return variables.stage;
    }

    if (window.location.hostname === DOMAIN_LIVE) {
        return variables.production;
    }

    return variables.development;
};

export const config = getVariables();

export const {
    BASE_URL, SENTRY_DSN, SENTRY_ENVIRONMENT,
} = config;

export default config;