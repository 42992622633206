import { Badge } from "@mui/material";
import "./calendar.css";
import 'dayjs/locale/es';
import { PickersDay, PickersDayProps } from "@mui/x-date-pickers";
import { Dayjs } from "dayjs";


export default function ServerDay(
  {
    highlightedDays = [],
    day,
    outsideCurrentMonth,
    ...restProps
  } : PickersDayProps<Dayjs> & { highlightedDays?: string[] }
  ) {

    const ymd = day.format('YYYY-MM-DD');
    const isSelected =
      !outsideCurrentMonth &&
      highlightedDays.indexOf(ymd) >= 0;

    return (
      <Badge
        key={day.toString()}
        overlap="circular"
        badgeContent={undefined}
        style={{ background: isSelected ? "#BAD0A2" : "white" }}
      >
        <PickersDay
          {...restProps}
          outsideCurrentMonth={outsideCurrentMonth}
          day={day}
        />
      </Badge>
    );
  }
