import axiosCodeigniter from "../api/axiosCodeigniter";
import {GET_PLAN_RECORDS_URL,GET_DIET_PLAN_PDF_URL} from "../utils/constants";

export interface pdfsDietTraining {
    fecha:string;
    pdf_dieta: string;
    pdf_entrenamiento: string;
    duda1:string;
    duda2:string;
    respuesta_duda1:string;
    respuesta_duda2:string;
}

export interface msgsDiet {
    mensaje: string;
    id_plan: string;
    id_fase: string;
    fecha: string;
    es_cliente: string;
}


export interface PlanRecorded {
    plan: string;
    planID: string;
    fechaPlan: string;
    idPlanNuevo: string;
    pdfs: pdfsDietTraining[];
    msgs?: msgsDiet[];

}

export async function getPlansRecordService(): Promise<PlanRecorded[]> {
    try{
        const token = localStorage.getItem('token');
        const headers =  {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }
        const response = await axiosCodeigniter.get(`${GET_PLAN_RECORDS_URL}`, headers);
        return response.data.planes_app;
    }catch (err) {
        throw new Error ();
    }
}


export async function getPlanPdfService(namePdf:string): Promise<Blob> {
    const token = localStorage.getItem('token');
    const formData= new FormData();
    formData.append("formNamePdf",namePdf);
    const response = await axiosCodeigniter.post(`${GET_DIET_PLAN_PDF_URL}`,formData,{
        responseType: 'blob',
        headers: { 'Content-Type':'multipart/form-data', Authorization: `Bearer ${token}` }
      });
    const responseDietPdf =response.data;
    if(response.data.size>1){
        const blob = new Blob([responseDietPdf], { type: 'application/pdf' });
        return blob;
    }else{
        throw new Error("Error");
    }
}

