import axiosCodeigniter from "../api/axiosCodeigniter";
// import { SEND_PSI_MESSAGE } from "../utils/constants";
import { SEND_COUPLE_PSI_MESSAGE } from "../utils/constants";
interface Mensaje {
  mensaje: string;
  es_cliente: string;
}

interface ClientChat {
  mensajes: Mensaje[];
}

export async function sendCouplePsiMessage(message: string): Promise<ClientChat> {
  const token = localStorage.getItem("token");
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'multipart/form-data'
    },
  };
  const formData = new FormData();
  formData.append('txt_msg', message);
  

  try {
    const response = await axiosCodeigniter.post(SEND_COUPLE_PSI_MESSAGE, formData ,config);
    console.log(response);
    return response.data;
  } catch (error) {
    console.log("Error al enviar el mensaje", error);
    throw error;
  }
}