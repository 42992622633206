import axios from "../api/axios";

interface RadioCheckBoxOption {
    value: string;
    option_name: string;
    new_price_lonelly: number;
    new_price_reduced_lonelly: number;
    new_price_composed: number;
    new_price_reduced_composed: number;
    feeLonely: number;
    feeComposed: number;
    hideOnCheck: boolean;
    idLonelly: string,
    idComposed: string
}
interface DetailedExplanationItem {
    item_name: string;
    item_description: string;
}
export interface ProductDetailData {
    radioLabel: string;
    radio_options: Array<RadioCheckBoxOption>;
    checkbox_option: RadioCheckBoxOption;
    base_price: number;
    idBasic: string;
    idTraining?: string;
    idTrainingCouple?: string;
    idCouple: string;
    name: string;
    realName: string;
    img: string;
    slogan: string;
    alt: string;
    description: string;
    product_subscription: boolean
    subscription_price: number;
    detailed_explanation: Array<DetailedExplanationItem>;
}

async function getDietProducts(): Promise<Array<ProductDetailData>> {
    const dietProducts = await axios.get('/products?category=diet');
    const dietBasic = dietProducts.data.find((x:any) => x.categories.includes('diet_basic'));
    const dietCouple = dietProducts.data.find((x:any) => x.categories.includes('diet_couple'));
    const dietTrainingVirtual = dietProducts.data.find((x:any) => x.categories.includes('diet_training_virtual'));
    const dietTrainingGym = dietProducts.data.find((x:any) => x.categories.includes('diet_training_gym'));
    const dietTrainingGymCouple = dietProducts.data.find((x:any) => x.categories.includes('diet_training_couple_gym'));
    const dietTrainingCasa = dietProducts.data.find((x:any) => x.categories.includes('diet_training_home'));
    const dietTrainingCasaCouple = dietProducts.data.find((x:any) => x.categories.includes('diet_training_couple_home'));

    const product_return: Array<ProductDetailData> = [{
        radioLabel: 'Añadir entrenamiento a tu plan',
        radio_options: [
            {
                value: 'option1',
                option_name: 'Entrenamiento en el GYM',
                new_price_lonelly: parseFloat(dietTrainingGym.price),
                new_price_reduced_lonelly: parseFloat(dietTrainingGym.price),
                new_price_composed: parseFloat(dietTrainingGymCouple.price),
                new_price_reduced_composed: parseFloat(dietTrainingGymCouple.price),
                feeLonely: parseFloat(dietTrainingGym.fee),
                feeComposed: parseFloat(dietTrainingGymCouple.fee),
                hideOnCheck: false,
                idLonelly: dietTrainingGym.id.toString(),
                idComposed: dietTrainingGymCouple.id.toString()
            },
            {
                value: 'option2',
                option_name: 'Entrenamiento en CASA',
                new_price_lonelly: parseFloat(dietTrainingCasa.price),
                new_price_reduced_lonelly: parseFloat(dietTrainingCasa.price),
                new_price_composed: parseFloat(dietTrainingCasaCouple.price),
                new_price_reduced_composed: parseFloat(dietTrainingCasaCouple.price),
                feeLonely: parseFloat(dietTrainingCasa.fee),
                feeComposed: parseFloat(dietTrainingCasaCouple.fee),
                hideOnCheck: false,
                idLonelly: dietTrainingCasa.id.toString(),
                idComposed: dietTrainingCasaCouple.id.toString()
            },
            {
                value: 'option3',
                option_name: 'ENTRENA VIRTUAL',
                new_price_lonelly: parseFloat(dietTrainingVirtual.price),
                new_price_reduced_lonelly: parseFloat(dietTrainingVirtual.price),
                new_price_composed: parseFloat(dietTrainingVirtual.price),
                new_price_reduced_composed: parseFloat(dietTrainingVirtual.price),
                hideOnCheck: false,
                feeLonely: parseFloat(dietTrainingVirtual.fee),
                feeComposed: parseFloat(dietTrainingVirtual.fee),
                idLonelly: dietTrainingVirtual.id.toString(),
                idComposed: dietTrainingVirtual.id.toString()
            }
        ],
        checkbox_option: {
            value: 'option4',
            option_name: 'Quiero hacer el plan en pareja',
            new_price_lonelly: parseFloat(dietCouple.price),
            new_price_reduced_lonelly: parseFloat(dietCouple.reduced_price || dietCouple.price),
            new_price_composed: parseFloat(dietCouple.price),
            new_price_reduced_composed: parseFloat(dietCouple.reduced_price || dietCouple.price),
            feeLonely: parseFloat(dietCouple.fee),
            feeComposed: parseFloat(dietCouple.fee),
            hideOnCheck: false,
            idLonelly: dietCouple.id.toString(),
            idComposed: dietTrainingCasaCouple.id.toString()
        },
        base_price: parseFloat(dietBasic.price),
        idBasic: dietBasic.id.toString(),
        idCouple: dietCouple.id.toString(),
        name: 'Plan de Dieta',
        realName: dietBasic.name,
        img: dietBasic.images,
        alt: 'dieta',
        slogan: 'Cuídate por dentro y por fuera con nuestros planes y di hola a tu mejor versión',
        description: dietBasic.short_description,
        product_subscription: dietBasic.subscription_product,
        subscription_price: parseFloat(dietBasic.fee),
        detailed_explanation: [
            {
                item_name: 'Qué incluye el plan',
                item_description: `
                    <p style="margin-bottom: 8px"><b style="font-family: 'Montserrat SemiBold'">El plan incluye un menú organizado en una semana completa</b>, en el que se indican todas las comidas que deberás realizar. En cada comida aparecen detallados los alimentos y las cantidades a tomar. Este será el menú que tengas durante las 4 semanas que dura tu plan. </p><br>
                    <p style="margin-bottom: 8px"><b style="font-family: 'Montserrat SemiBold'">Videollamada con tu especialista</b> (1 cada 4 semanas). Tras rellenar el cuestionario inicial, tendrás una videollamada de 20 minutos de duración con tu especialista. El especialista te hará las preguntas necesarias para poder elaborar tu plan junto con la información indicada en el cuestionario inicial. La videollamada se repetirá de forma mensual con la renovación de tu plan.</p><br>
                    <p style="margin-bottom: 8px"><b style="font-family: 'Montserrat SemiBold'">Chat con el especialista  </b> (15 mensajes cada 4 semanas). Dispondrás de un chat privado con tu especialista, donde podrás preguntarle cualquier duda que te surja a lo largo del mes sobre tu plan.</p><br>   
                    <p style="margin-bottom: 8px"><b style="font-family: 'Montserrat SemiBold'">Guía para implantar nuevos hábitos. </b> Con tu primer plan recibirás una guía con consejos diarios muy útiles y sencillos que te ayudarán a integrar tus nuevos hábitos y cumplir tus objetivos.</p>`
            },
            {
                item_name: 'Duración',
                item_description: `
                    <p style="margin-bottom: 8px"><b style="font-family: 'Montserrat SemiBold'">La duración del plan es de 4 semanas.</b> Transcurridas las cuatro semanas del primer plan, se recomienda continuar con las renovaciones para seguir evolucionando y poder lograr los objetivos deseados </p>`
            },
            {
                item_name: '¿Cómo empiezo el plan?',
                item_description:
                `<ol style="padding-left: 25px; font-size: 16px;">
                    <li style="line-height: 28px; margin-bottom: 8px">Selecciona el plan que quieres llevar a cabo y realiza la compra. </li>
                    <li style="line-height: 28px; margin-bottom: 8px">Una vez confirmada la compra, rellena el cuestionario inicial. </li>
                    <li style="line-height: 28px; margin-bottom: 8px">Confirmada la recepción de tu cuestionario y activado tu usuario, escoge la fecha y hora de la videollamada con tu especialista. </li>
                    <li style="line-height: 28px; margin-bottom: 2px">Tras la videollamada, en un plazo de 72 h laborales, recibirás tu plan y podrás empezar. </li>
                    </ol><br>
                    <p style="margin-bottom: 8px">La fecha de inicio de tu plan será en el momento en el que recibas tu planificación.</p>`
            },
            {
                item_name: '¿Cómo funcionan las renovaciones?',
                item_description:
                    `<p style="margin-bottom: 8px">Tras finalizar las 4 semanas de tu plan, <b style="font-family: 'Montserrat SemiBold'">se realizará la renovación de forma automática</b>. Las renovaciones se harán de forma periódica cada mes. Igual que en el primer plan, deberás rellenar el cuestionario de renovación en primer lugar. </p><br>
                    <p style="margin-bottom: 8px">Una vez realizado el pago de la renovación, podrás seleccionar la fecha y hora de la nueva videollamada con tu especialista.En la renovación se realizarán los cambios necesarios en el plan para conseguir tu objetivo en función de tus avances y sensaciones, que compartirás con el especialista. Tras finalizar la videollamada, en un plazo de 72 h laborales, recibirás tu nuevo plan. </p><br>
                    <p style="margin-bottom: 8px"><b style="font-family: 'Montserrat SemiBold'">Es importante que estés atento a tu email</b>, ya que las notificaciones con las instrucciones para realizar cada paso del proceso te llegarán al email que nos indicas en la compra. Recuerda revisar tu bandeja de correo no deseado o spam.  </p><br>
                    <p style="margin-bottom: 8px"><b style="font-family: 'Montserrat SemiBold'">Si deseas cancelar la renovación del plan, podrás hacerlo desde tu perfil. </b><br>Para ello, deberás gestionar la baja durante el periodo transcurrido entre el inicio del plan y los 5 días antes de la fecha de renovación. </p>`
            },
            {
                item_name: '¿Quieres añadir entrenamiento a tu plan de dieta?',
                item_description:
                    `<p style="margin-bottom: 8px">Tienes la posibilidad de añadir a tu plan de dieta un plan de entrenamiento. <b style="font-family: 'Montserrat SemiBold'">Tu plan de entrenamiento</b> puede estar <b style="font-family: 'Montserrat SemiBold'">enfocado para casa, para el gym o para la plataforma Entrena Virtual</b>. </p><br>
                    <p style="margin-bottom: 8px">El plan de entrenamiento, así como tu plan de dieta, <b style="font-family: 'Montserrat SemiBold'">se organizará en una semana completa;</b> se detallarán todos los ejercicios a ejecutar en cada día, así como las series y repeticiones. El plan estaráelaborado en función de tu objetivo personal, nivel, actividad diaria y disponibilidad semanal. Nuestra recomendación es realizar, como mínimo, 3 días de entrenamiento de fuerza y complementarlo con ejercicio cardiovascular. </p><br>
                    <p style="margin-bottom: 8px">Si escoges la opción <b style="font-family: 'Montserrat SemiBold'">para gym</b>, todo el entrenamiento se organizará con el material propio disponible en un gimnasio. </p>
                    <p style="margin-bottom: 8px">Si prefieres la opción <b style="font-family: 'Montserrat SemiBold'">para casa</b>, organizaremos tu entrenamiento con un material mínimo que puedes almacenar en casa. Si dispones de más material, puedes contárnoslo en el cuestionario o en la videollamada. El material mínimo que necesitas para esta opción es el siguiente: </p><br>
                    <ul style="padding-left: 25px; font-size: 16px; line-height: 28px; margin-bottom: 8px">
                        <li><a href="https://shop.entrenavirtual.es/producto/mancuernas-studio-par-vitalidad-y-funcionalidad/?attribute_pa_peso=4kg" target="_blank"><b style="font-family: 'Montserrat SemiBold'"><u>Un par de mancuernas (de 4 kg si eres principiante).</u></b></a></li>
                        <li><a href="https://shop.entrenavirtual.es/producto/elastico-evergy-la-resistencia-mas-comoda/" target="_blank"><b style="font-family: 'Montserrat SemiBold'"><u>Gomas elásticas largas con agarre.</u></b></a></li>
                        <li><a href="https://shop.entrenavirtual.es/producto/everstep-evergy-limited-versatilidad-en-estado-puro/" target="_blank"><b style="font-family: 'Montserrat SemiBold'"><u>Step o banco.</u></b></a> <b style="font-family: 'Montserrat SemiBold'">También puedes usar un sofá o una silla.</b></li>
                        <li><a href="https://shop.entrenavirtual.es/producto/set-bandas-de-resistencia-trabaja-tu-gluteo/" target="_blank"><b style="font-family: 'Montserrat SemiBold'"><u>Minibands.</u></b></a></li> 
                    </ul><br>
                    <p style="margin-bottom: 8px">Si has probado la plataforma Entrena Virtual y te resulta más cómodo entrenar con las clases dirigidas, la opción de entrenamiento con <b style="font-family: 'Montserrat SemiBold'">Entrena Virtual </b>será perfecta para ti. Con la opción de entrenamiento con Entrena Virtual, elaboraremos tu rutina semanal de entrenamiento con las clases de la plataforma. Es posible que necesites material mínimo para realizar las clases. Nuestra recomendación es que tengas disponible:</p><br>
                    <ul style="padding-left: 25px; font-size: 16px; line-height: 28px; margin-bottom: 8px">
                        <li><a href="https://shop.entrenavirtual.es/producto/mancuernas-studio-par-vitalidad-y-funcionalidad/?attribute_pa_peso=4kg" target="_blank"><b style="font-family: 'Montserrat SemiBold'"><u>Un par de mancuernas (de 4 kg si eres principiante).</u></b></a></li>
                        <li><a href="https://shop.entrenavirtual.es/producto/set-bandas-de-resistencia-trabaja-tu-gluteo/" target="_blank"><b style="font-family: 'Montserrat SemiBold'"><u>Barra, si la prefieres en lugar de mancuernas. </u></b></a></li>
                        <li><a href="https://shop.entrenavirtual.es/producto/kettlebell-studio-la-fuerza-rusa/?attribute_pa_peso=6kg" target="_blank"><b style="font-family: 'Montserrat SemiBold'"><u>Kettlebell (de 6 kg si eres principiante).</u></b></a></li>
                        <li><a href="https://shop.entrenavirtual.es/producto/set-bandas-de-resistencia-trabaja-tu-gluteo/" target="_blank"><b style="font-family: 'Montserrat SemiBold'"><u>Minibands.</u></b></a></li> 
                    </ul>`
            },
            {
                item_name: '¿Quieres hacer el plan en pareja?',
                item_description:
                    `<p style="margin-bottom: 8px">Este plan es perfecto para aquellos que quieran seguir un plan en pareja. Puedes escoger tanto el plan de dieta, como el plan de dieta con entrenamiento. Cada persona de la pareja tendrá su propio plan, respetando las necesidades individuales de cada uno, pero los planes serán lo más parecidos posible para facilitar el día a día. Para poder realizar el plan en pareja es imprescindible que ambas personas de la pareja tengáis el mismo objetivo para poder seguir el plan. Los objetivos pueden ser: </p><br>
                    <ol style="padding-left: 25px; font-size: 16px; line-height: 28px"><li>Bajar peso (pérdida de grasa).</li><li>Aumento de peso (ganancia muscular).</li><li>Mantener un estilo de vida saludable.</li><li>Rendimiento deportivo</li></ol>`
            }
        ]
    }]
    return product_return;
}

async function getPsychologyProducts(): Promise<Array<ProductDetailData>> {
    const psychologyProducts = await axios.get('/products?category=psychology');
    const psychologyBasic = psychologyProducts.data.find((x:any) => x.categories.includes('psychology_basic'));
    const psychologyCouple = psychologyProducts.data.find((x:any) => x.categories.includes('psychology_couple'));
    const psychologyIndividual = psychologyProducts.data.find((x:any) => x.categories.includes('psychology'));
    const product_return: Array<ProductDetailData> = [{
        radioLabel: 'Sesiones por bonos',
        radio_options: [
            {
                value: 'option1',
                option_name: psychologyBasic.variants[0].description,
                new_price_lonelly: parseFloat(psychologyBasic.variants[0].price),
                new_price_reduced_lonelly: parseFloat(psychologyBasic.variants[0].reduced_price) || parseFloat(psychologyBasic.variants[0].price),
                new_price_composed: parseFloat(psychologyCouple.variants[0].introductory_price),
                new_price_reduced_composed: parseFloat(psychologyCouple.variants[0].reduced_price) || parseFloat(psychologyCouple.variants[0].price),
                hideOnCheck: false,
                feeLonely: 0,
                feeComposed: 0,
                idLonelly: psychologyBasic.variants[0].id.toString(),
                idComposed: psychologyCouple.variants[0].id.toString()
            },
            {
                value: 'option2',
                option_name: psychologyBasic.variants[1].description,
                new_price_lonelly: parseFloat(psychologyBasic.variants[1].introductory_price),
                new_price_reduced_lonelly: parseFloat(psychologyBasic.variants[1].reduced_price) || parseFloat(psychologyBasic.variants[1].price),
                new_price_composed: parseFloat(psychologyCouple.variants[1].introductory_price),
                new_price_reduced_composed: parseFloat(psychologyCouple.variants[1].reduced_price) || parseFloat(psychologyCouple.variants[1].price),
                feeLonely: 0,
                feeComposed: 0,
                hideOnCheck: false,
                idLonelly: psychologyBasic.variants[1].id.toString(),
                idComposed: psychologyCouple.variants[1].id.toString()
            },
            {
                value: 'option3',
                option_name: psychologyBasic.variants[2].description,
                new_price_lonelly: parseFloat(psychologyBasic.variants[2].introductory_price),
                new_price_reduced_lonelly: parseFloat(psychologyBasic.variants[2].reduced_price) || parseFloat(psychologyBasic.variants[2].price),
                new_price_composed: parseFloat(psychologyCouple.variants[2].introductory_price),
                new_price_reduced_composed: parseFloat(psychologyCouple.variants[2].reduced_price) || parseFloat(psychologyCouple.variants[2].price),
                feeLonely: 0,
                feeComposed: 0,
                hideOnCheck: false,
                idLonelly: psychologyBasic.variants[2].id.toString(),
                idComposed: psychologyCouple.variants[2].id.toString()
            }
        ],
        checkbox_option: {
            value: 'option4',
            option_name: 'Quiero hacer las sesiones en pareja',
            new_price_lonelly: parseFloat(psychologyBasic.variants[1].price),
            new_price_reduced_lonelly: parseFloat(psychologyBasic.variants[1].reduced_price),
            new_price_composed: parseFloat(psychologyCouple.variants[1].price),
            new_price_reduced_composed: parseFloat(psychologyCouple.variants[1].reduced_price),
            feeLonely: 0,
            feeComposed: 0,
            hideOnCheck: false,
            idLonelly: psychologyBasic.variants[1].id.toString(),
            idComposed: psychologyCouple.variants[1].id.toString()
        },
        base_price: 0,
        idBasic: psychologyBasic.id.toString(),
        idCouple: psychologyCouple.id.toString(),
        name: 'Plan Psicología',
        realName: psychologyBasic.name,
        img: psychologyBasic.images,
        slogan: 'Salud física y mental van de la mano con nuestras sesiones de psicología online con expertos en psicología de pareja y emocional.',
        alt: 'psicología',
        description: psychologyBasic.short_description,
        product_subscription: psychologyBasic.subscription_product,
        subscription_price: 0,
        detailed_explanation: [
            {
                item_name: '¿En qué consiste el servicio de psicología?',
                item_description:
                    `<p style="margin-bottom: 8px">El servicio de psicología es un servicio online por sesiones, individual o en pareja y privado, que te permite comentar y compartir aquello que necesites con nuestras  psicólogas  del  equipo  Vikika  Team, profesionales con años de experiencia.</p>
                    <p style="margin-bottom: 8px">En Vikika Team trabajamos para ayudarte a alcanzar un estilo de vida saludable que engloba tanto el bienestar físico como el mental. Por ello, nuestras  psicólogas te brindarán  todas las herramientas necesarias para mejorar día a día y encontrar el equilibrio.  Algunos ejemplos de los temas que puedes tratar son: </p><br>
                    <ul style="padding-left: 25px; font-size: 16px;">
                        <li style="line-height: 28px">Autoconocimiento, cuidado y mejora de hábitos.</li>
                        <li style="line-height: 28px">Cómo la alimentación y el entrenamiento puede afectar a las emociones. </li>
                        <li style="line-height: 28px">Cómo la motivación afecta al día a día; encontrarla y aprender a mantenerla.</li>
                        <li style="line-height: 28px">Recompensas y castigos autoimpuestos.</li>
                        <li style="line-height: 28px">La importancia de reconocer, identificar y gestionar las emociones... </li>
                    </ul><br>
                    <p style="margin-bottom: 8px">¡Y mucho más!</p>
                    <p style="margin-bottom: 8px">Sean cuales sean tus preocupaciones, nuestras psicólogas estarán para atenderte en todo lo que necesites.</p>`
            },
            {
                item_name: '¿Cómo puedo realizar las sesiones de psicología?',
                item_description:
                    `<p style="margin-bottom: 8px">Puedes escoger entre dos opciones:</p><br>
                    <ol style="padding-left: 25px; font-size: 16px;">
                        <li style="line-height: 28px">Suscripción de psicología.</li>
                        <li style="line-height: 28px">Sesiones o bonos</li>
                   </ol><br>
                   <p style="margin-bottom: 8px">
                   En primer lugar, con la <strong>suscripción de psicología</strong> dispondrás de 30 días para realizar las sesiones que se incluyan en tu plan. Tenemos la posibilidad de realizar 2, 3 o 4 sesiones al mes, por lo que puedes elegir la opción que mejor se adapte a tus necesidades. Cada 30 días se realizará la renovación de tu suscripción de forma automática y tendrás de nuevo   disponibles el número de sesiones que hayas elegido para realizar en los siguientes 30 días.</p>
                   <p style="margin-bottom: 8px">
                   Por otro lado, con las <strong> sesiones o bonos </strong>tendrás la opción de adquirir una sesión suelta o bonos de 5 o 10 sesiones, las cuales podrás agendar cuando quieras sin límite de tiempo. Si prefieres no realizar las sesiones de forma recurrente, esta sería la mejor opción. Además, si quieres hacer sesiones en pareja, esta será también la opción que debas escoger.</p>
`

           },
            {
                item_name: '¿Qué diferencia hay entre la suscripción y las sesiones/bonos?',
                item_description:
                    `<p style="margin-bottom: 8px">Con la <strong>suscripción de psicología </strong>tú eliges el número de sesiones que quieres llevar a cabo de forma periódica cada mes (2, 3 o 4 sesiones). Tendrás un tiempo limitado de 30 días para realizarlas, por lo que perderás aquellas que no hayas utilizado al finalizar el periodo de suscripción. Cada 30 días se renovarán de forma automática. Con esta opción podrás adquirir las sesiones por un precio inferior en comparación con las sesiones sueltas o bonos.<p>
                    <p style="margin-bottom: 8px">
                    Con las  <strong>sesiones o bonos</strong>  podrás comprar el número de sesiones que desees realizar y utilizarlas cuando quieras, sin límite de tiempo. Esta opción te permite programar las sesiones cuando consideres oportuno en función de tus necesidades. Una vez finalices la sesión, o bono de sesiones, podrás comprar más para agendar nuevas citas con tu psicóloga. </p>
                    <p style="margin-bottom: 8px">En este caso la sesión tendrá un precio mayor, pero podrás realizarlas sin límite de tiempo. </p>
                    <p style="margin-bottom: 8px">En el caso de que quieras realizar sesiones en pareja, tendrás que elegir esta última opción, ya que no disponemos de suscripción de psicología en pareja..</p>
                     `
                    
            },
            {
                item_name: '¿Cómo empiezo y cómo es el proceso de la <strong>suscripción de psicología</strong>?',
                item_description:
                    `<p style="margin-bottom: 8px">Si escoges la opción de suscripción de psicología, este será el proceso a seguir: </p><br>
                    <ol style="padding-left: 25px; font-size: 16px;">
                        <li style="line-height: 28px">Adquiere la suscripción que vayas a querer realizar.</li>
                        <li style="line-height: 28px">Envíanos por correo el consentimiento informado que recibirás con la confirmación de compra. Recuerda enviarlo lo antes posible.</li>
                        <li style="line-height: 28px">Una vez hayas enviado el consentimiento, se te asignará una profesional del equipo de psicólogas de Vikika Team.</li>
                    </ol><br>
                    <p style="margin-bottom: 8px">A partir de este momento, empezarán a contar los 30 días de tu suscripción y podrás acceder a tu perfil para elegir la fecha y hora de tus sesiones. Puedes programar todas tus sesiones a la vez o agendar solo una y más tarde el resto. Ahora bien, en esta segunda opción es importante recordar que, cada día que pase, tendrás menos tiempo antes de que finalicen los 30 días de suscripción. Si necesitas cancelar o cambiar la sesión deberás avisar con un mínimo de 24 h de antelación y no olvides que, si cancelas o quieres modificarla y posteriormente no hay disponibilidad para agendarla en el plazo de tus 30 días de suscripción, perderás la cita y deberás esperar a tu renovación para agendar las siguientes sesiones.</p>`
            },
            {
                item_name: '<p>¿Cómo empiezo y cómo es el proceso de las <strong>sesiones por bonos</strong>?</p>',
                item_description:
                    `<p style="margin-bottom: 8px">Para poder realizar una o varias sesiones con nuestras psicólogas del equipo Vikika Team, solo tienes que seguir estos sencillos pasos:</p>
                    <ol style="padding-left: 25px; font-size: 16px;">
                        <li style="line-height: 28px">Adquiere la suscripción que vayas a querer realizar.</li>
                        <li style="line-height: 28px">Envíanos por correo el consentimiento informado que recibirás con la confirmación de compra de tus sesiones.</li>
                        <li style="line-height: 28px">Una vez hayas enviado el consentimiento, se te asignará una profesional del equipo de psicólogas de Vikika Team.</li>
                    </ol><br>
                    <p style="margin-bottom: 8px">A partir de este momento, podrás acceder a tu perfil y elegir la fecha y hora de tu primera sesión. En el caso de que escojas varias sesiones, podrás agendar las siguientes en el mismo momento o directamente con tu psicóloga en función de tu disponibilidad, necesidades y la evolución que vayas teniendo.</p>`
            },
            {
                item_name: '¿Duración?',
                item_description: '<p style="margin-bottom: 8px">La duración de cada sesión es de 55 minutos.</p>'
            },
            {
                item_name: '¿Quieres hacer sesiones en pareja?',
                item_description:
                    `<p style="margin-bottom: 8px">También disponemos de sesiones de psicología en pareja. El servicio de sesiones en pareja está diseñado para parejas que quieran tratar de conocer, trabajar y resolver conflictos y mejorar dentro de la relación.  </p><br>
                    <p style="margin-bottom: 8px">El funcionamiento de las sesiones de pareja es el mismo con respecto a las sesiones individuales, en cuanto a gestión y tiempo de la sesión. Cada uno tendrá su propio perfil privado, con el fin de agendar sesiones individuales en el caso de que así lo deseen o la psicóloga lo considere oportuno.</p>`
            }
        ]
    },{
        radioLabel: 'Suscripción',
        radio_options: [
            {
                value: 'option5',
                option_name: psychologyIndividual.variants[0].description,
                new_price_lonelly: parseFloat(psychologyIndividual.variants[0].reduced_price),
                new_price_reduced_lonelly: parseFloat(psychologyIndividual.variants[0].price) || parseFloat(psychologyIndividual.variants[0].price),
                new_price_composed: parseFloat(psychologyIndividual.variants[0].price),
                new_price_reduced_composed: parseFloat(psychologyIndividual.variants[0].reduced_price) || parseFloat(psychologyIndividual.variants[0].price),
                hideOnCheck: false,
                feeLonely: 0,
                feeComposed: 0,
                idLonelly: psychologyIndividual.variants[0].id.toString(),
                idComposed: psychologyIndividual.variants[0].id.toString()
            },
            {
                value: 'option6',
                option_name: psychologyIndividual.variants[1].description,
                new_price_lonelly: parseFloat(psychologyIndividual.variants[1].reduced_price),
                new_price_reduced_lonelly: parseFloat(psychologyIndividual.variants[1].price) || parseFloat(psychologyIndividual.variants[1].price),
                new_price_composed: parseFloat(psychologyIndividual.variants[1].reduced_price),
                new_price_reduced_composed: parseFloat(psychologyIndividual.variants[1].reduced_price) || parseFloat(psychologyIndividual.variants[1].price),
                feeLonely: 0,
                feeComposed: 0,
                hideOnCheck: false,
                idLonelly: psychologyIndividual.variants[1].id.toString(),
                idComposed: psychologyIndividual.variants[1].id.toString()
            },
            {
                value: 'option7',
                option_name: psychologyIndividual.variants[2].description,
                new_price_lonelly: parseFloat(psychologyIndividual.variants[2].reduced_price),
                new_price_reduced_lonelly: parseFloat(psychologyIndividual.variants[2].price) || parseFloat(psychologyIndividual.variants[2].price),
                new_price_composed: parseFloat(psychologyIndividual.variants[2].reduced_price),
                new_price_reduced_composed: parseFloat(psychologyIndividual.variants[2].reduced_price) || parseFloat(psychologyIndividual.variants[2].price),
                feeLonely: 0,
                feeComposed: 0,
                hideOnCheck: false,
                idLonelly: psychologyIndividual.variants[2].id.toString(),
                idComposed: psychologyIndividual.variants[2].id.toString()
            }
        ],
        checkbox_option: {
            value: 'option8',
            option_name: 'Plan de psicología en pareja',
            new_price_lonelly: parseFloat(psychologyIndividual.variants[1].price),
            new_price_reduced_lonelly: parseFloat(psychologyIndividual.variants[1].reduced_price),
            new_price_composed: parseFloat(psychologyIndividual.variants[1].price),
            new_price_reduced_composed: parseFloat(psychologyIndividual.variants[1].reduced_price),
            feeLonely: 0,
            feeComposed: 0,
            hideOnCheck: false,
            idLonelly: psychologyIndividual.variants[1].id.toString(),
            idComposed: psychologyIndividual.variants[1].id.toString()
        },
        base_price: 0,
        idBasic: psychologyIndividual.id.toString(),
        idCouple: psychologyIndividual.id.toString(),
        name: 'Plan de Psicología Suscripción',
        realName: psychologyIndividual.name,
        img: psychologyIndividual.images,
        slogan: 'Salud física y mental van de la mano con nuestras sesiones de psicología online con expertos en psicología de pareja y emocional.',
        alt: 'psicología suscripción',
        description: psychologyIndividual.short_description,
        product_subscription: psychologyIndividual.subscription_product,
        subscription_price: 0,
        detailed_explanation: [
            {
                item_name: '¿En qué consiste el servicio de psicología?',
                item_description:
                    `<p style="margin-bottom: 8px">El servicio de psicología es un servicio online por sesiones, individual o en pareja y privado, que te permite comentar y compartir aquello que necesites con nuestras  psicólogas  del  equipo  Vikika  Team, profesionales con años de experiencia.</p><br>
                    <p style="margin-bottom: 8px">En Vikika Team trabajamos para ayudarte a alcanzar un estilo de vida saludable que engloba tanto el bienestar físico como el mental. Por ello, nuestras  psicólogas te brindarán  todas las herramientas necesarias para mejorar día a día y encontrar el equilibrio.  Algunos ejemplos de los temas que puedes tratar son: </p><br>
                    <ul style="padding-left: 25px; font-size: 16px;">
                        <li style="line-height: 28px">Autoconocimiento, cuidado y mejora de hábitos.</li>
                        <li style="line-height: 28px">Cómo la alimentación y el entrenamiento puede afectar a las emociones. </li>
                        <li style="line-height: 28px">Cómo la motivación afecta al día a día; encontrarla y aprender a mantenerla.</li>
                        <li style="line-height: 28px">Recompensas y castigos autoimpuestos.</li>
                        <li style="line-height: 28px">La importancia de reconocer, identificar y gestionar las emociones... </li>
                    </ul><br>
                    <p style="margin-bottom: 8px">¡Y mucho más!</p>
                    <p style="margin-bottom: 8px">Sean cuales sean tus preocupaciones, nuestras psicólogas estarán para atenderte en todo lo que necesites.</p>`
            },
            {
                item_name: '¿Cómo puedo realizar las sesiones de psicología?',
                item_description:
                    `<p style="margin-bottom: 8px">Puedes escoger entre dos opciones:</p><br>
                    <ol style="padding-left: 25px; font-size: 16px;">
                        <li style="line-height: 28px">Suscripción de psicología.</li>
                        <li style="line-height: 28px">Sesiones o bonos</li>
                   </ol><br>
                    <p style="margin-bottom: 8px">
                    En primer lugar, con la <strong>suscripción de psicología</strong>dispondrás de 30 días para realizar las sesiones que se incluyan en tu plan. Tenemos la posibilidad de realizar 2, 3 o 4 sesiones al mes, por lo que puedes elegir la opción que mejor se adapte a tus necesidades. Cada 30 días se realizará la renovación de tu suscripción de forma automática y tendrás de nuevo   disponibles el número de sesiones que hayas elegido para realizar en los siguientes 30 días.</p>`
            },
            {
                item_name: '¿Duración?',
                item_description: '<p style="margin-bottom: 8px">La duración de cada sesión es de 55 minutos.</p>'
            },
            {
                item_name: '¿Cómo es el proceso?',
                item_description:
                    `<p style="margin-bottom: 8px">Para poder realizar una o varias sesiones con nuestras psicólogas del equipo Vikika Team, solo tienes que seguir estos sencillos pasos: </p><br>
                    <ol style="padding-left: 25px; font-size: 16px;">
                        <li style="line-height: 28px">Adquiere las sesiones que vayas a querer realizar.</li>
                        <li style="line-height: 28px">Envíanos por correo el consentimiento informado que recibirás con la confirmación de compra de tus sesiones.</li>
                        <li style="line-height: 28px">Una vez hayas enviado el consentimiento, se te asignará una profesional del equipo de psicólogas de Vikika Team.</li>
                    </ol><br>
                    <p style="margin-bottom: 8px">A partir de este momento, podrás acceder a tu perfil y elegir la fecha y hora de tu primera sesión. En el caso de que escojas varias sesiones, agendarás con la psicólogalas futuras sesiones en función de tu disponibilidad, necesidades y la evolución que vayas teniendo.</p>`
            },
            {
                item_name: '¿Quieres hacer sesiones de psicología en pareja?',
                item_description:
                    `<p style="margin-bottom: 8px">También disponemos de sesiones de psicología en pareja. El servicio de sesiones en pareja está diseñado para parejas que quieran tratar de conocer, trabajar y resolver conflictos y mejorar dentro de la relación.  </p><br>
                    <p style="margin-bottom: 8px">El funcionamiento de las sesiones de pareja es el mismo con respecto a las sesiones individuales, en cuanto a gestión y tiempo de la sesión. Cada uno tendrá su propio perfil privado, con el fin de agendar sesiones individuales en el caso de que así lo deseen o la psicóloga lo considere oportuno.</p>`
            }
        ]
    }]
    return product_return;
}

export async function productDetailService(category?: string): Promise<Array<ProductDetailData>> {
    if (category === 'diet') {
        const product_return: ProductDetailData[] = await getDietProducts();
        return product_return;
    } else if (category === 'psychology') {
        const product_return: ProductDetailData[] = await getPsychologyProducts();
        return product_return;
    } else {
        const product_return: ProductDetailData[] = await getDietProducts();
        return product_return;
    }
}