import { createContext, useState } from "react";

interface AuthContextData {
    auth: any;
    setAuth: (auth: any) => void;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);


interface AuthProviderProps {
    children: React.ReactNode;
}

export const AuthProvider = ({ children }: AuthProviderProps) => {
    const [auth, setAuth] = useState({});

    return (
        <AuthContext.Provider value={{ auth, setAuth }}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext;


