import axiosCodeigniter from "../api/axiosCodeigniter";
import {RENOUNCE_VIDEOCALL_URL,UPDATE_VIDEOCALL_URL,UPDATE_VIDEOCALL_PSI_URL,UPDATE_VIDEOCALL_PSI_COUPLE_URL} from "../utils/constants";
import {AxiosResponse} from "axios";

interface VideoCallUpdateData {
    IdVideoCallUpdated:string;   
}

interface VideoCallUpdatePsiData {
    IdVideoCallUpdated:string; 
    TypePlan:string;  
    IdPlan:string;

}

export const renounceVideoCallService = async ():Promise<AxiosResponse> => {
    const token = localStorage.getItem('token');
    const tipo_llamada="0";
    const formData= new FormData();
    formData.append("tipo_llamada",tipo_llamada);
    return await axiosCodeigniter.post(RENOUNCE_VIDEOCALL_URL,
        formData,
        {
            headers: { 'Content-Type':'multipart/form-data', Authorization: `Bearer ${token}` }
        }
    );
}

export const updateVideoCallService = async (videoCallUpdateData: VideoCallUpdateData):Promise<AxiosResponse> => {
    const token = localStorage.getItem('token');
    const formData= new FormData();
    formData.append("dataSend",JSON.stringify(videoCallUpdateData))
    return await axiosCodeigniter.post(UPDATE_VIDEOCALL_URL,
        formData,
        {
            headers: { 'Content-Type':'multipart/form-data', Authorization: `Bearer ${token}` }
        }
    );
}

export const updateVideoCallPsiService = async (videoCallUpdatePsiData: VideoCallUpdatePsiData):Promise<AxiosResponse> => {
    const token = localStorage.getItem('token');
    const formData= new FormData();
    formData.append("dataSend",JSON.stringify(videoCallUpdatePsiData))
    return await axiosCodeigniter.post(UPDATE_VIDEOCALL_PSI_URL,
        formData,
        {
            headers: { 'Content-Type':'multipart/form-data', Authorization: `Bearer ${token}` }
        }
    );
}


export const updateVideoCallPsiCoupleService = async (videoCallUpdateData: VideoCallUpdateData):Promise<AxiosResponse> => {
    const token = localStorage.getItem('token');
    const formData= new FormData();
    formData.append("dataSend",JSON.stringify(videoCallUpdateData))
    return await axiosCodeigniter.post(UPDATE_VIDEOCALL_PSI_COUPLE_URL,
        formData,
        {
            headers: { 'Content-Type':'multipart/form-data', Authorization: `Bearer ${token}` }
        }
    );
}
