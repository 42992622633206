import { useState, useEffect } from "react";
import {PROVINCES_NEW,PROVINCES, EMAIL_REGEX, PWD_REGEX, PHONE_REGEX, PHONE_REGEX2} from "../../../../utils/constants";
import { FacturationData } from "../../../../models/FacturationData";
import {PartnerData} from "../../../../models/PartnerData";
import "./FacturationForm.css"
import {useNavigate} from "react-router-dom";
import {
    IconButton,
} from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import {States} from "../../../../models/States";

interface FacturationFormProps {
    setFacturationData: (value: any) => void;
    onValidation: (isValid: boolean) => void;
    partnerFormData: PartnerData;
}

const FacturationForm = (facturationFormProps: FacturationFormProps) => {
    const { onValidation } = facturationFormProps
    const isLoggedIn = localStorage.getItem('status');
    const navigate = useNavigate();
const countries = [
    { country: "Afganistán", code: "AF" },
    { country: "Islas Åland", code: "AX" },
    { country: "Albania", code: "AL" },
    { country: "Argelia", code: "DZ" },
    { country: "Samoa Americana", code: "AS" },
    { country: "Andorra", code: "AD" },
    { country: "Angola", code: "AO" },
    { country: "Anguila", code: "AI" },
    { country: "Antártida", code: "AQ" },
    { country: "Antigua y Barbuda", code: "AG" },
    { country: "Argentina", code: "AR" },
    { country: "Armenia", code: "AM" },
    { country: "Aruba", code: "AW" },
    { country: "Australia", code: "AU" },
    { country: "Austria", code: "AT" },
    { country: "Azerbaiyán", code: "AZ" },
    { country: "Bahamas", code: "BS" },
    { country: "Bahréin", code: "BH" },
    { country: "Bangladés", code: "BD" },
    { country: "Barbados", code: "BB" },
    { country: "Bielorrusia", code: "BY" },
    { country: "Bélgica", code: "BE" },
    { country: "Belice", code: "BZ" },
    { country: "Benín", code: "BJ" },
    { country: "Bermudas", code: "BM" },
    { country: "Bután", code: "BT" },
    { country: "Bolivia", code: "BO" },
    { country: "Bonaire, San Eustaquio y Saba", code: "BQ" },
    { country: "Bosnia y Herzegovina", code: "BA" },
    { country: "Botsuana", code: "BW" },
    { country: "Isla Bouvet", code: "BV" },
    { country: "Brasil", code: "BR" },
    { country: "Territorio Británico del Océano Índico", code: "IO" },
    { country: "Brunéi", code: "BN" },
    { country: "Bulgaria", code: "BG" },
    { country: "Burkina Faso", code: "BF" },
    { country: "Burundi", code: "BI" },
    { country: "Camboya", code: "KH" },
    { country: "Camerún", code: "CM" },
    { country: "Canadá", code: "CA" },
    { country: "Cabo Verde", code: "CV" },
    { country: "Islas Caimán", code: "KY" },
    { country: "República Centroafricana", code: "CF" },
    { country: "Chad", code: "TD" },
    { country: "Chile", code: "CL" },
    { country: "China", code: "CN" },
    { country: "Isla de Navidad", code: "CX" },
    { country: "Islas Cocos (Keeling)", code: "CC" },
    { country: "Colombia", code: "CO" },
    { country: "Comoras", code: "KM" },
    { country: "Congo - Brazzaville", code: "CG" },
    { country: "Congo - Kinshasa", code: "CD" },
    { country: "Islas Cook", code: "CK" },
    { country: "Costa Rica", code: "CR" },
    { country: "Costa de Marfil", code: "CI" },
    { country: "Croacia", code: "HR" },
    { country: "Cuba", code: "CU" },
    { country: "Curazao", code: "CW" },
    { country: "Chipre", code: "CY" },
    { country: "República Checa", code: "CZ" },
    { country: "Dinamarca", code: "DK" },
    { country: "Yibuti", code: "DJ" },
    { country: "Dominica", code: "DM" },
    { country: "República Dominicana", code: "DO" },
    { country: "Ecuador", code: "EC" },
    { country: "Egipto", code: "EG" },
    { country: "El Salvador", code: "SV" },
    { country: "Guinea Ecuatorial", code: "GQ" },
    { country: "Eritrea", code: "ER" },
    { country: "Estonia", code: "EE" },
    { country: "Etiopía", code: "ET" },
    { country: "Islas Malvinas (Falkland Islands)", code: "FK" },
    { country: "Islas Feroe", code: "FO" },
    { country: "Fiyi", code: "FJ" },
    { country: "Finlandia", code: "FI" },
    { country: "Francia", code: "FR" },
    { country: "Guayana Francesa", code: "GF" },
    { country: "Polinesia Francesa", code: "PF" },
    { country: "Territorios Franceses del Sur", code: "TF" },
    { country: "Gabón", code: "GA" },
    { country: "Gambia", code: "GM" },
    { country: "Georgia", code: "GE" },
    { country: "Alemania", code: "DE" },
    { country: "Ghana", code: "GH" },
    { country: "Gibraltar", code: "GI" },
    { country: "Grecia", code: "GR" },
    { country: "Groenlandia", code: "GL" },
    { country: "Granada", code: "GD" },
    { country: "Guadalupe", code: "GP" },
    { country: "Guam", code: "GU" },
    { country: "Guatemala", code: "GT" },
    { country: "Guernsey", code: "GG" },
    { country: "Guinea", code: "GN" },
    { country: "Guinea-Bissau", code: "GW" },
    { country: "Guyana", code: "GY" },
    { country: "Haití", code: "HT" },
    { country: "Islas Heard y McDonald", code: "HM" },
    { country: "Ciudad del Vaticano", code: "VA" },
    { country: "Honduras", code: "HN" },
    { country: "Hong Kong", code: "HK" },
    { country: "Hungría", code: "HU" },
    { country: "Islandia", code: "IS" },
    { country: "India", code: "IN" },
    { country: "Indonesia", code: "ID" },
    { country: "Irán", code: "IR" },
    { country: "Irak", code: "IQ" },
    { country: "Irlanda", code: "IE" },
    { country: "Isla de Man", code: "IM" },
    { country: "Israel", code: "IL" },
    { country: "Italia", code: "IT" },
    { country: "Jamaica", code: "JM" },
    { country: "Jersey", code: "JE" },
    { country: "Jordania", code: "JO" },
    { country: "Kazajistán", code: "KZ" },
    { country: "Kenia", code: "KE" },
    { country: "Kiribati", code: "KI" },
    { country: "Corea del Norte", code: "KP" },
    { country: "Corea del Sur", code: "KR" },
    { country: "Kosovo", code: "XK" },
    { country: "Kuwait", code: "KW" },
    { country: "Kirguistán", code: "KG" },
    { country: "Laos", code: "LA" },
    { country: "Letonia", code: "LV" },
    { country: "Líbano", code: "LB" },
    { country: "Lesoto", code: "LS" },
    { country: "Liberia", code: "LR" },
    { country: "Libia", code: "LY" },
    { country: "Liechtenstein", code: "LI" },
    { country: "Lituania", code: "LT" },
    { country: "Luxemburgo", code: "LU" },
    { country: "Macao", code: "MO" },
    { country: "Macedonia del Norte", code: "MK" },
    { country: "Madagascar", code: "MG" },
    { country: "Malaui", code: "MW" },
    { country: "Malasia", code: "MY" },
    { country: "Maldivas", code: "MV" },
    { country: "Malí", code: "ML" },
    { country: "Malta", code: "MT" },
    { country: "Islas Marshall", code: "MH" },
    { country: "Martinica", code: "MQ" },
    { country: "Mauritania", code: "MR" },
    { country: "Mauricio", code: "MU" },
    { country: "Mayotte", code: "YT" },
    { country: "México", code: "MX" },
    { country: "Micronesia", code: "FM" },
    { country: "Moldavia", code: "MD" },
    { country: "Mónaco", code: "MC" },
    { country: "Mongolia", code: "MN" },
    { country: "Montenegro", code: "ME" },
    { country: "Montserrat", code: "MS" },
    { country: "Marruecos", code: "MA" },
    { country: "Mozambique", code: "MZ" },
    { country: "Myanmar (Birmania)", code: "MM" },
    { country: "Namibia", code: "NA" },
    { country: "Nauru", code: "NR" },
    { country: "Nepal", code: "NP" },
    { country: "Países Bajos", code: "NL" },
    { country: "Curazao", code: "AN" },
    { country: "Nueva Caledonia", code: "NC" },
    { country: "Nueva Zelanda", code: "NZ" },
    { country: "Nicaragua", code: "NI" },
    { country: "Níger", code: "NE" },
    { country: "Nigeria", code: "NG" },
    { country: "Niue", code: "NU" },
    { country: "Isla Norfolk", code: "NF" },
    { country: "Islas Marianas del Norte", code: "MP" },
    { country: "Noruega", code: "NO" },
    { country: "Omán", code: "OM" },
    { country: "Pakistán", code: "PK" },
    { country: "Palaos", code: "PW" },
    { country: "Palestina", code: "PS" },
    { country: "Panamá", code: "PA" },
    { country: "Papúa Nueva Guinea", code: "PG" },
    { country: "Paraguay", code: "PY" },
    { country: "Perú", code: "PE" },
    { country: "Filipinas", code: "PH" },
    { country: "Islas Pitcairn", code: "PN" },
    { country: "Polonia", code: "PL" },
    { country: "Portugal", code: "PT" },
    { country: "Puerto Rico", code: "PR" },
    { country: "Catar", code: "QA" },
    { country: "Reunión", code: "RE" },
    { country: "Rumania", code: "RO" },
    { country: "Rusia", code: "RU" },
    { country: "Ruanda", code: "RW" },
    { country: "San Bartolomé", code: "BL" },
    { country: "Santa Elena", code: "SH" },
    { country: "San Cristóbal y Nieves", code: "KN" },
    { country: "Santa Lucía", code: "LC" },
    { country: "San Martín", code: "MF" },
    { country: "San Pedro y Miquelón", code: "PM" },
    { country: "San Vicente y las Granadinas", code: "VC" },
    { country: "Samoa", code: "WS" },
    { country: "San Marino", code: "SM" },
    { country: "Santo Tomé y Príncipe", code: "ST" },
    { country: "Arabia Saudita", code: "SA" },
    { country: "Senegal", code: "SN" },
    { country: "Serbia", code: "RS" },
    { country: "Seychelles", code: "SC" },
    { country: "Sierra Leona", code: "SL" },
    { country: "Singapur", code: "SG" },
    { country: "Sint Maarten", code: "SX" },
    { country: "Eslovaquia", code: "SK" },
    { country: "Eslovenia", code: "SI" },
    { country: "Islas Salomón", code: "SB" },
    { country: "Somalia", code: "SO" },
    { country: "Sudáfrica", code: "ZA" },
    { country: "Islas Georgias del Sur y Sandwich del Sur", code: "GS" },
    { country: "Sudán del Sur", code: "SS" },
    { country: "España", code: "ES" },
    { country: "Sri Lanka", code: "LK" },
    { country: "Sudán", code: "SD" },
    { country: "Surinam", code: "SR" },
    { country: "Svalbard y Jan Mayen", code: "SJ" },
    { country: "Esuatini", code: "SZ" },
    { country: "Suecia", code: "SE" },
    { country: "Suiza", code: "CH" },
    { country: "Siria", code: "SY" },
    { country: "Taiwán", code: "TW" },
    { country: "Tayikistán", code: "TJ" },
    { country: "Tanzania", code: "TZ" },
    { country: "Tailandia", code: "TH" },
    { country: "Timor Oriental", code: "TL" },
    { country: "Togo", code: "TG" },
    { country: "Tokelau", code: "TK" },
    { country: "Tonga", code: "TO" },
    { country: "Trinidad y Tobago", code: "TT" },
    { country: "Túnez", code: "TN" },
    { country: "Turquía", code: "TR" },
    { country: "Turkmenistán", code: "TM" },
    { country: "Islas Turcas y Caicos", code: "TC" },
    { country: "Tuvalu", code: "TV" },
    { country: "Uganda", code: "UG" },
    { country: "Ucrania", code: "UA" },
    { country: "Emiratos Árabes Unidos", code: "AE" },
    { country: "Reino Unido", code: "GB" },
    { country: "Estados Unidos", code: "US" },
    { country: "Islas Ultramarinas Menores de Estados Unidos", code: "UM" },
    { country: "Uruguay", code: "UY" },
    { country: "Uzbekistán", code: "UZ" },
    { country: "Vanuatu", code: "VU" },
    { country: "Venezuela", code: "VE" },
    { country: "Vietnam", code: "VN" },
    { country: "Islas Vírgenes Británicas", code: "VG" },
    { country: "Islas Vírgenes de los Estados Unidos", code: "VI" },
    { country: "Wallis y Futuna", code: "WF" },
    { country: "Sahara Occidental", code: "EH" },
    { country: "Yemen", code: "YE" },
    { country: "Zambia", code: "ZM" },
    { country: "Zimbabue", code: "ZW" }
];
    const [formData, setFormData] = useState<FacturationData>({
        firstName: '',
        lastName: '',
        country: isLoggedIn ? localStorage.getItem('country')! : 'ES',
        address_1: '',
        address_2: '',
        postalCode: '',
        state:'',
        city: '',
        province: '',
        phone: '',
        email: isLoggedIn ? localStorage.getItem('email')! : '',
        confirmEmail: isLoggedIn ? localStorage.getItem('email')! : '',
        password: '',
        confirmPassword: '',
    });

    const provinces:States[]= PROVINCES_NEW;

    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const [email, setEmail] = useState('');
    const [validEmail, setValidEmail] = useState(false);
    const [emailFocus, setEmailFocus] = useState(false);
    const [emailTouched, setEmailTouched] = useState(false);

    const [matchEmail, setMatchEmail] = useState('');
    const [validMatchEmail, setValidMatchEmail] = useState(false);
    const [matchEmailFocus, setMatchEmailFocus] = useState(false);
    const [matchEmailTouched, setMatchEmailTouched] = useState(false);

    const [password, setPassword] = useState('');
    const [validPassword, setValidPassword] = useState(false);
    const [passwordFocus, setPasswordFocus] = useState(false);
    const [passwordTouched, setPasswordTouched] = useState(false);

    const [matchPwd, setMatchPwd] = useState('');
    const [validMatch, setValidMatch] = useState(false);
    const [matchFocus, setMatchFocus] = useState(false);
    const [matchTouched, setMatchTouched] = useState(false);

    const [firstNameFocus, setFirstNameFocus] = useState(false);
    const [firstNameTouched, setFirstNameTouched] = useState(false);

    const [lastNameFocus, setLastNameFocus] = useState(false);
    const [lastNameTouched, setLastNameTouched] = useState(false);

    const [countryFocus, setCountryFocus] = useState(false);
    const [countryTouched, setCountryTouched] = useState(false);

    const [address1Focus, setAddress1Focus] = useState(false);
    const [address1Touched, setAddress1Touched] = useState(false);

    const [postalCodeFocus, setPostalCodeFocus] = useState(false);
    const [postalCodeTouched, setPostalCodeTouched] = useState(false);

    const [cityFocus, setCityFocus] = useState(false);
    const [cityTouched, setCityTouched] = useState(false);

    const [phone, setPhone] = useState('');
    const [validPhone, setValidPhone] = useState(false);
    const [phoneFocus, setPhoneFocus] = useState(false);
    const [phoneTouched, setPhoneTouched] = useState(false);


    useEffect(() => {
        setValidEmail(EMAIL_REGEX.test(email));
        setValidMatchEmail(email === matchEmail);
    }, [email, matchEmail])

    useEffect(() => {
        setValidPassword(PWD_REGEX.test(password));
        setValidMatch(password === matchPwd);
    }, [password, matchPwd])

    useEffect(() => {
        setValidPhone(PHONE_REGEX.test(phone) || PHONE_REGEX2.test(phone));
    }, [phone])

    const CheckIsValid = () => {
        const resultCheckPassword = (formData.password !== '') && (formData.confirmPassword !== '') &&
                                    validPassword && validMatch
        const resultCheckEmail = (formData.email !== '') && (formData.confirmEmail !== '') &&
                                    validEmail &&  validMatchEmail
        const passwordValidation = isLoggedIn ? true : resultCheckPassword;
        const emailValidation = isLoggedIn ? true : resultCheckEmail
        const isValid = (                     
            (formData.firstName !== '') &&
            (formData.lastName !== '') &&
            (formData.country !== '') &&
            (formData.address_1 !== '') &&
            (formData.postalCode !== '') &&
            (formData.city !== '') &&
            (formData.phone !== '') &&
            (formData.email !== facturationFormProps.partnerFormData.partner_email) &&
            validPhone &&
            emailValidation &&
            passwordValidation
        )
        onValidation(isValid)
    }

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>, name: string) => {
        const { value } = event.target;
        setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    };
    

    useEffect(() => {
        facturationFormProps.setFacturationData(formData);
        setValidEmail(EMAIL_REGEX.test(email));
        setValidMatchEmail(formData.email === formData.confirmEmail);
        setValidPassword(PWD_REGEX.test(password));
        setValidMatch(formData.password === formData.confirmPassword);
        setValidPhone(PHONE_REGEX.test(formData.phone) || PHONE_REGEX2.test(formData.phone));
        CheckIsValid();
    }, [formData,facturationFormProps.partnerFormData.partner_email])

    useEffect(() => {
        CheckIsValid();
    }, [validEmail, validMatchEmail, validPassword, validMatch, validPhone])

    useEffect(() => {
        const savedFormdata = localStorage.getItem('facturationData');
        if (savedFormdata) {
            const parsedFormData = JSON.parse(savedFormdata);
            setFormData({
                firstName: localStorage.getItem('firstName') && localStorage.getItem('firstName') != 'undefined' ? localStorage.getItem('firstName')! : parsedFormData.firstName,
                lastName: localStorage.getItem('lastName') && localStorage.getItem('lastName') != 'undefined' ? localStorage.getItem('lastName')! : parsedFormData.lastName,
                country: localStorage.getItem('country') && localStorage.getItem('country') != 'undefined' ? localStorage.getItem('country')! : 'ES',
                address_1: localStorage.getItem('address_1') && localStorage.getItem('address_1') != 'undefined' ? localStorage.getItem('address_1')! : parsedFormData.address_1,
                address_2: localStorage.getItem('address_2') && localStorage.getItem('address_2') != 'undefined' ? localStorage.getItem('address_2')! : parsedFormData.address_2,
                postalCode: localStorage.getItem('postcode') && localStorage.getItem('postcode') != 'undefined' ? localStorage.getItem('postcode')!  : parsedFormData.postalCode,
                city: localStorage.getItem('city') && localStorage.getItem('city') != 'undefined' ? localStorage.getItem('city')! : parsedFormData.city,
                province: localStorage.getItem('state') && localStorage.getItem('state') != 'undefined' ? localStorage.getItem('state')! : '',
                state: localStorage.getItem('state') && localStorage.getItem('state') != 'undefined' ? localStorage.getItem('state')! : '',
                phone: localStorage.getItem('phone') && localStorage.getItem('phone') != 'undefined' ? localStorage.getItem('phone')! : parsedFormData.phone,
                email: localStorage.getItem('email') && localStorage.getItem('email') != 'undefined' ? localStorage.getItem('email')! : parsedFormData.email,
                confirmEmail: localStorage.getItem('email') && localStorage.getItem('email') != 'undefined' ? localStorage.getItem('email')! : parsedFormData.confirmEmail,
                password: localStorage.getItem('password') && localStorage.getItem('password') != 'undefined' ? localStorage.getItem('password')! : parsedFormData.password,
                confirmPassword: localStorage.getItem('password') && localStorage.getItem('password') != 'undefined' ? localStorage.getItem('password')! : parsedFormData.confirmPassword,
            });
            facturationFormProps.setFacturationData(formData);
        } else {
            setFormData({
                firstName: localStorage.getItem('firstName') && localStorage.getItem('firstName') != 'undefined' ? localStorage.getItem('firstName')! : '',
                lastName: localStorage.getItem('lastName') && localStorage.getItem('lastName') != 'undefined' ? localStorage.getItem('lastName')! : '',
                country: localStorage.getItem('country') && localStorage.getItem('country') != 'undefined' ? localStorage.getItem('country')! : 'ES',
                address_1: localStorage.getItem('address_1') && localStorage.getItem('address_1') != 'undefined' ? localStorage.getItem('address_1')! : '',
                address_2: localStorage.getItem('address_2') && localStorage.getItem('address_2') != 'undefined' ? localStorage.getItem('address_2')! : '',
                postalCode: localStorage.getItem('postcode') && localStorage.getItem('postcode') != 'undefined' ? localStorage.getItem('postcode')!  : '',
                city: localStorage.getItem('city') && localStorage.getItem('city') != 'undefined' ? localStorage.getItem('city')! : '',
                state: localStorage.getItem('state') && localStorage.getItem('state') != 'undefined' ? localStorage.getItem('state')! : '',
                province: localStorage.getItem('state') && localStorage.getItem('state') != 'undefined' ? localStorage.getItem('state')! : '',
                phone: localStorage.getItem('phone') && localStorage.getItem('phone') != 'undefined' ? localStorage.getItem('phone')! : '',
                email: localStorage.getItem('email') && localStorage.getItem('email') != 'undefined' ? localStorage.getItem('email')! : '',
                confirmEmail: localStorage.getItem('email') && localStorage.getItem('email') != 'undefined' ? localStorage.getItem('email')! : '',
                password: localStorage.getItem('password') && localStorage.getItem('password') != 'undefined' ? localStorage.getItem('password')! : '',
                confirmPassword: localStorage.getItem('password') && localStorage.getItem('password') != 'undefined' ? localStorage.getItem('password')! : '',
            });
        }
    }, []);  // eslint-disable-line react-hooks/exhaustive-deps

    const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
        event.preventDefault();
    };

    const redirectToLogin = () => {
        localStorage.setItem('facturationData', JSON.stringify(formData));
        localStorage.setItem('nextViewAfterLogin', '/pago');
        navigate('/iniciar-sesion');
    }

    return (
        <div >
            <div className='facturation-form-title'>
                <p>INFORMACIÓN DE LA FACTURACIÓN</p>
            </div>

            <div className="facturation-form-container" >
                <div className='facturation-form-container-header'></div>
                <div className='form-container'>
                    <div className='facturation-form-subtitle'>
                        <p>Utilizaremos esta información para emitir tu primer  recibo. En tu perfil podrás actualizar estos datos para el siguiente recibo.</p>
                    </div>

                    <form >
                        <div className='row-double-input' >
                            <div className={!firstNameFocus && firstNameTouched && formData.firstName === '' ? 'input-short1 invalidInput' : 'input-short1'} >
                                <label htmlFor="firstName">Nombre <span className="red">*</span></label>
                                <input
                                    type="text"
                                    id="firstName"
                                    defaultValue={formData.firstName}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => handleInputChange(event, 'firstName')}
                                    onInput={(event: React.ChangeEvent<HTMLInputElement>) => handleInputChange(event, 'firstName')}
                                    required
                                    onFocus={() => {
                                        setFirstNameFocus(true)
                                        setFirstNameTouched(true)
                                    }}
                                    onBlur={() => {
                                        setFirstNameFocus(false)
                                        CheckIsValid()
                                    }}
                                />
                            </div>

                            <div className={!lastNameFocus && lastNameTouched && formData.lastName === '' ? 'input-short2 invalidInput' : 'input-short2'}>
                                <label htmlFor="lastName">Apellido <span className="red">*</span></label>
                                <input
                                    type="text"
                                    id="lastName"
                                    defaultValue={formData.lastName}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
                                        handleInputChange(event, 'lastName')
                                        CheckIsValid()
                                    }}
                                    onInput={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        handleInputChange(event, 'lastName')
                                        CheckIsValid()
                                    }}
                                    required
                                    onFocus={() => {
                                        setLastNameFocus(true)
                                        setLastNameTouched(true)
                                    }}
                                    onBlur={() => {
                                        setLastNameFocus(false)
                                        CheckIsValid()
                                    }}
                                />
                            </div>
                        </div>
                        <div className={!countryFocus && countryTouched && formData.country === '' ? 'input-large invalidInput' : 'input-large'}>
                            <label htmlFor="country">País <span className="red">*</span></label>
                            {/* <input
                                type="text"
                                id="country"
                                defaultValue={formData.country}
                                onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => handleInputChange(event, 'country')}
                                onInput={(event: React.ChangeEvent<HTMLInputElement>) => handleInputChange(event, 'country')}
                                required
                                onFocus={() => {
                                    setCountryFocus(true)
                                    setCountryTouched(true)
                                }}
                                onBlur={() => {
                                    setCountryFocus(false)
                                    CheckIsValid()
                                }}
                            /> */}
                            <select
                                id="country"
                                onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => handleInputChange(event, 'country')}
                                onFocus={() => {
                                    setCountryFocus(true)
                                    setCountryTouched(true)
                                }}
                                onBlur={() => {
                                    setCountryFocus(false)
                                    CheckIsValid()
                                }}
                                // onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                                //     const country = event.target.value;  // Obtener el valor seleccionado del <select>
                                //     console.log(country);
                                //     setFormBill(prevFormBill => ({
                                //         ...prevFormBill,
                                //         country: country,  // Actualizar el valor de region en el estado
                                //     }));
                                // }}
                            required
                            
                            >
                                {/* <option value="" disabled selected hidden>País</option> */}
                                {countries.map(
                                    
                                    (countries) => (
                                        <option key={countries.code} value={countries.code} className='province-options' selected={countries.code === formData.country}
                                        >{countries.country}</option>
                                    )
                                )

                                }
                            </select>     
                        </div>

                        <div className='row-double-input'>
                            <div className={!address1Focus && address1Touched && formData.address_1 === '' ? 'input-short1 invalidInput' : 'input-short1'}>
                                <label htmlFor="address1">Dirección de la calle <span className="red">*</span> </label>
                                <input
                                    type='text'
                                    placeholder='Número de la casa y nombre de la calle'
                                    id="address1"
                                    defaultValue={formData.address_1}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => handleInputChange(event, 'address_1')}
                                    onInput={(event: React.ChangeEvent<HTMLInputElement>) => handleInputChange(event, 'address_1')}
                                    required
                                    onFocus={() => {
                                        setAddress1Focus(true)
                                        setAddress1Touched(true)
                                    }}
                                    onBlur={() => {
                                        setAddress1Focus(false)
                                        CheckIsValid()
                                    }}
                                />
                            </div>

                            <div className='input-short2 address-2-hide'>
                                <label htmlFor='address2'><span style={{color: '#fff'}}>Direccion</span></label>
                                <div></div>
                                <input className="without-label"
                                    type="text"
                                    placeholder='Apartamento, habitación, etc. (opcional)'
                                    id='address2'
                                    defaultValue={formData.address_2}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => handleInputChange(event, 'address_2')}
                                    onInput={(event: React.ChangeEvent<HTMLInputElement>) => handleInputChange(event, 'address_2')}
                                    required
                                />
                            </div>

                        </div >
                        <div className='row-double-input'>
                            <div className={!postalCodeFocus && postalCodeTouched && formData.postalCode === '' ? 'input-short1 invalidInput' : 'input-short1'}>
                                <label htmlFor='postalCode'>Código Postal <span className="red">*</span> </label>
                                <input
                                    type="text"
                                    id='postalCode'
                                    defaultValue={formData.postalCode}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => handleInputChange(event, 'postalCode')}
                                    onInput={(event: React.ChangeEvent<HTMLInputElement>) => handleInputChange(event, 'postalCode')}
                                    required
                                    onFocus={() => {
                                        setPostalCodeFocus(true)
                                        setPostalCodeTouched(true)
                                    }}
                                    onBlur={() => {
                                        setPostalCodeFocus(false)
                                        CheckIsValid()
                                    }}
                                />
                            </div>

                            <div className={!cityFocus && cityTouched && formData.city === '' ? 'input-short2 invalidInput' : 'input-short2'}>
                                <label htmlFor='city'>Localidad/Ciudad <span className="red">*</span> </label>
                                <input
                                    type="text"
                                    id='city'
                                    defaultValue={formData.city}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => handleInputChange(event, 'city')}
                                    onInput={(event: React.ChangeEvent<HTMLInputElement>) => handleInputChange(event, 'city')}
                                    required
                                    onFocus={() => {
                                        setCityFocus(true)
                                        setCityTouched(true)
                                    }}
                                    onBlur={() => {
                                        setCityFocus(false)
                                        CheckIsValid()
                                    }}
                                />
                            </div>

                        </div>
                        { formData.country == 'ES' && (
                  <div className='input-large'>
                  <label htmlFor='province'>Provincia</label>
                  <select
                      id="province"
                      name="province"
                      defaultValue={formData.province}
                      onChange={(event: React.ChangeEvent<HTMLSelectElement>) => handleInputChange(event, 'state')}
                  >
                      <option value=''>Selecciona una provincia</option>
                      {provinces[0].states.map((province) => ( // Access the first object in the array
                          <option
                              key={province.code}
                              value={province.code}
                              selected={province.code === formData.province}
                          >
                              {province.name}
                          </option>
                      ))}
                  </select>
              </div>
                 
                            

                        )
                        }
                   
                        <div className={(!phoneFocus && phone && !validPhone) ||
                        (!phoneFocus && phoneTouched && formData.phone === '') ? 'input-large invalidInput' : 'input-large'}>
                            <label htmlFor='phone'>Teléfono <span className="red">*</span></label>
                            <input
                                type="text"
                                id='phone'
                                defaultValue={formData.phone}
                                onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
                                    setPhone(event.target.value);
                                    handleInputChange(event, 'phone')
                                }}
                                onInput={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    setPhone(event.target.value);
                                    handleInputChange(event, 'phone')
                                }}
                                required
                                onFocus={() => {
                                    setPhoneFocus(true)
                                    setPhoneTouched(true)
                                }}
                                onBlur={() => {
                                    setPhoneFocus(false)
                                    CheckIsValid()
                                }}
                            />
                            <p id="phonenote" className={phoneFocus && phone && !validPhone ? "instructions" : "offscreen"} style={{marginBottom: '0.5rem'}}>
                                Debe introducir un teléfono válido.<br />
                            </p>
                        </div>
                        {(!isLoggedIn || isLoggedIn !== 'isLoggedIn') && (
                            <p style={{fontFamily: 'Montserrat', fontSize: '12.8px', lineHeight: '16px', color: '#A4A4A4', marginTop: '12px'}}>
                                Si ya tienes cuenta en VIKIKA TEAM <a onClick={redirectToLogin} style={{color: '#0B49EB', cursor: 'pointer'}}> Inicia sesión</a>
                            </p>
                        )}
                        <div className='row-double-input'>
                            <div className={(!emailFocus && email && !validEmail) ||
                            (!emailFocus && emailTouched && formData.email === '') ? 'input-short1 invalidInput' : (emailFocus && !validEmail) ? 'input-short1 height-recalculada-email' : 'input-short1'}>
                                <label htmlFor='emailForm'>Correo electrónico <span className="red">*</span> </label>
                                <input
                                    type="text"
                                    id='emailForm'
                                    defaultValue={formData.email}
                                    disabled={isLoggedIn === 'isLoggedIn'}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
                                        setEmail(event.target.value)
                                        handleInputChange(event, 'email')
                                    }}
                                    onInput={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        setEmail(event.target.value)
                                        handleInputChange(event, 'email')
                                    }}
                                    required
                                    aria-invalid={validEmail ? "false" : "true"}
                                    aria-describedby="emailnote"
                                    onFocus={() => {
                                        setEmailFocus(true)
                                        setEmailTouched(true)
                                    }}
                                    onBlur={() => {
                                        setEmailFocus(false)
                                        CheckIsValid()
                                    }}
                                />
                                <p id="emailnote" className={emailFocus && email && !validEmail ? "instructions" : "offscreen"} style={{marginBottom: '0.5rem'}}>
                                    Debe introducir un correo válido.<br />
                                </p>
                            </div>

                            <div className={(!matchEmailFocus && matchEmail && !validMatchEmail) ||
                            (!matchEmailFocus && matchEmailTouched && formData.confirmEmail === '') ? 'input-short1 invalidInput' : (matchEmailFocus && !validMatchEmail) ? 'input-short1 height-recalculada-email' : 'input-short1'}>
                                <label htmlFor='confirmEmail'>Confirmar correo electrónico <span className="red">*</span> </label>
                                <input
                                    type="text"
                                    id='confirmEmail'
                                    onPaste={handlePaste}
                                    disabled={isLoggedIn === 'isLoggedIn'}
                                    defaultValue={formData.confirmEmail}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
                                        setMatchEmail(event.target.value)
                                        handleInputChange(event, 'confirmEmail')
                                    }}
                                    onInput={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        setMatchEmail(event.target.value)
                                        handleInputChange(event, 'confirmEmail')
                                    }}
                                    required
                                    aria-invalid={validMatchEmail ? "false" : "true"}
                                    aria-describedby="matchEmailNote"
                                    onFocus={() => {
                                        setMatchEmailFocus(true)
                                        setMatchEmailTouched(true)
                                    }}
                                    onBlur={() => {
                                        setMatchEmailFocus(false)
                                        CheckIsValid()
                                    }}
                                />
                                <p id="matchEmailNote" className={matchEmailFocus && !validMatchEmail ? "instructions" : "offscreen"} style={{marginBottom: '0.5rem'}}>
                                    Deben coincidir los correos electrónicos.
                                </p>
                            </div>

                        </div>
                        <div className='row-double-input'>
                            <div className={(!passwordFocus && password && !validPassword) ||
                                (!passwordFocus && passwordTouched && formData.password === '') ? 'input-short1 invalidInput' : (passwordFocus && !validPassword) ? 'input-short1 height-recalculada' : 'input-short1'}>
                                <label htmlFor='password'>Crear una contraseña para la cuenta <span className="red">*</span> </label>
                                <input
                                    defaultValue={formData.password}
                                    type={showPassword ? 'text' : 'password'}
                                    id='password'
                                    disabled={isLoggedIn === 'isLoggedIn'}
                                    required
                                    onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
                                        setPassword(event.target.value)
                                        handleInputChange(event, 'password')
                                    }}
                                    onInput={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        setPassword(event.target.value)
                                        handleInputChange(event, 'password')
                                    }}
                                    aria-invalid={validPassword ? "false" : "true"}
                                    aria-describedby="passwordnote"
                                    onFocus={() => {
                                        setPasswordFocus(true)
                                        setPasswordTouched(true)
                                    }}
                                    onBlur={() => {
                                        setPasswordFocus(false)
                                        CheckIsValid()
                                    }}
                                />
                                <IconButton className={'reveal-password1'}
                                    onClick={handleClickShowPassword}
                                >
                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                                <p id="passwordnote" className={passwordFocus && !validPassword ? "instructions" : "offscreen"} style={{marginTop: '-2.5rem', fontSize:'0.7rem'}}>
                                    Mínimo 8 caracteres.<br />
                                    Debe incluir mayúsculas, al menos un número y un caracter especial.<br />
                                    Caracteres permitidos:
                                    <span aria-label="exclamation mark">!</span>
                                    <span aria-label="at symbol">@</span>
                                    <span aria-label="hashtag">#</span>
                                    <span aria-label="dollar sign">$</span>
                                    <span aria-label="percent">%</span>
                                    <span aria-label="start">*</span>
                                    <span aria-label="start">-</span>
                                </p>
                            </div>

                            <div className={(!matchFocus && matchPwd && !validMatch) ||
                                (!matchFocus && matchTouched && formData.confirmPassword === '') ? 'input-short2 invalidInput' : 'input-short2'}>
                                <label htmlFor='confirmPassword'>Confirmar contraseña <span className="red">*</span></label>
                                <input
                                    type={showPassword ? 'text' : 'password'}
                                    id='confirmPassword'
                                    onPaste={handlePaste}
                                    disabled={isLoggedIn === 'isLoggedIn'}
                                    defaultValue={formData.confirmPassword}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
                                        setMatchPwd(event.target.value)
                                        handleInputChange(event, 'confirmPassword')
                                    }}
                                    onInput={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        setMatchPwd(event.target.value)
                                        handleInputChange(event, 'confirmPassword')
                                    }}
                                    aria-invalid={validMatch ? "false" : "true"}
                                    aria-describedby="matchPasswordNote"
                                    onFocus={() => {
                                        setMatchFocus(true)
                                        setMatchTouched(true)
                                    }}
                                    onBlur={() => {
                                        setMatchFocus(false)
                                        CheckIsValid()
                                    }}
                                    required
                                />
                                <IconButton className={'reveal-password2'}
                                    onClick={handleClickShowPassword}
                                >
                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                                <p id="matchPasswordNote" className={matchFocus && !validMatch ? "instructions" : "offscreen"} style={{marginTop: '-2.5rem'}}>
                                    Las contraseñas deben coincidir.
                                </p>
                            </div>
                        </div>
                    </form>
                </div>

            </div>
        </div>
    )
}

export default FacturationForm
