import "./ContactSpecialistPsychology.css";
import { useState, useEffect } from "react";
import Chat from "./Chat";
import Hint from "../../diet/contact-specialist/Hint";
import PrivateLoading from "../../../../shared/loading/PrivateLoading";
import usePsiMessageQuery from "../../../../../hooks/usePsiMessageQuery";
import { useQueryClient } from "react-query";
import { specialistAssignedSubscriptionService } from "../../../../../services/getPsiSpecialistService";

interface Mensaje {
  mensaje: string;
  es_cliente: string;
}

interface ClientChat {
  mensajes: Mensaje[];
}

const ContactSpecialistPsychologySuscription = () => {
  const [specialistAssigned, setSpecialistAssigned] = useState(true);

  useEffect(() => {
    async function isSpecialistAssigned() {
      const response = await specialistAssignedSubscriptionService();
      setSpecialistAssigned(response.data["employee"]);
    }
    isSpecialistAssigned();
  }, []);

  const queryClient = useQueryClient();
  const {
    data,
    isLoading: loading,
    error,
    refetch,
    //    setData
  } = usePsiMessageQuery('psi_subscription_ind');

  const onNewClientChat = (newClientChat: ClientChat) => {
    queryClient.setQueryData("clientMessages", newClientChat);
  }

  return (
    <div className="container_specialist_psychology">
      <h1 className="title_specialist_psychology">CONTACTA CON TU ESPECIALISTA</h1>
      {loading && <PrivateLoading />}
      {!loading && !specialistAssigned &&
        <div className="not_chat_container">
          <p className="not_chat_text">El chat aún no está disponible. Una vez te hayamos asignado un especialista, podrás
            ponerte en contacto con él para resolver todas tus dudas.</p>
        </div>
      }
      {!loading && specialistAssigned &&
        <>
          <div className={"hints-only-mobile_psychology"}>
            <Hint text={"Envía como máximo 2 dudas por mensaje"} width={"700px"} />
          </div>
          {data ? 
            <Chat messages={data} onNewClientChat={onNewClientChat} /> 
            : <PrivateLoading />
          }
          <div className={"hints-only-desktop_psychology"}>
            <Hint text={"Envía como máximo 2 dudas por mensaje"} width={"700px"} />
          </div>
        </>
      }
    </div>
  );
};

export default ContactSpecialistPsychologySuscription;
