import React, { useEffect, useState, createRef } from "react";
import { useNavigate } from "react-router-dom";
import { Select, MenuItem} from '@mui/material';
import MediaQuery from "react-responsive";
import Questions from '../../../../shared/questions/Questions';
import { CircularProgress } from '@mui/material';

import './BuySubscriptions.css';

import PersonalDataBuySubscriptions from './PersonalDataBuySubscriptions';
import SportActivityBuySubscriptions from './SportActivityBuySubscriptions';
import FeedingDataBuySubscriptions from './FeedingDataBuySubscriptions';

import {NoClientPlanNewSubscriptionService} from '../../../../../services/planNewSubscriptionService';

import { PersonalData } from '../../../../../models/formsModels/PersonalData';
import { SportActivityData } from "../../../../../models/formsModels/SportActivityData";
import { FeedingData } from "../../../../../models/formsModels/FeedingData";

import nextArrow from '../../../../../assets/img/arrow-right-form.svg';
import nextArrowGrey from '../../../../../assets/img/arrow-right-form-grey.svg';
import previousArrow from '../../../../../assets/img/arrow-left-form.svg';

import partyIcon from '../../../../../assets/img/fin-suscripcion-icon.png';
import infoIcon from '../../../../../assets/img/info-icon-form.svg';
import screenShotExample from '../../../../../assets/img/Screenshot-example-form.svg';
import def_img from '../../../../../assets/img/default_form_image.png';

const BuySubscriptionsVitalWellness = () => {

    const [selectedTypeForm, setSelectedTypeForm] = useState('die_mes_psi');
    const [beginForm, setBeginForm] = useState(false);
    const [next, setNext] = useState(1);
    const [feeding, setFeeding] = useState(1);
    const [validationPassOne, setValidationPassOne] = useState(false);

    const [responseReceived, setResponseReceived] = useState(false);
    const [error, setError] = useState([]);
    const [sending, setSending] = useState(false);

    const [personalData, setPersonalData] = useState<PersonalData>({
        nombre: '',
        apellidos: '',
        email: '',
        dptelefono: '',
        dpnacimiento: '',
        dpaltura: '',
        dppeso: '',
        dpsexo: '',
        dpmetapas: '',
        dpmetapadetalle: '',
        dpmembarazada: '',
        dpmtrimestre:'',
    });

    const [sportActivityData, setSportActivityData] = useState<SportActivityData>({
        hactividad:'',
        henergia:'',
        hformafisica:'',
        hsueno:'',
        hobjetivo:'',
        nivelafisica:'',
        hdiadia:'',
        hdespertar:'',
        hacostar:'',
        hentrenar:'',
        nivelentreno:'',
        hfumador:'',
        hdlunes:false,
        hdlunesact:'',
        hdluneshora:'00:00',
        hdmartes:false,
        hdmartesact:'',
        hdmarteshora:'00:00',
        hdmiercoles:false,
        hdmiercolesact:'',
        hdmiercoleshora:'00:00',
        hdjueves:false,
        hdjuevesact:'',
        hdjueveshora:'00:00',
        hdviernes:false,
        hdviernesact:'',
        hdvierneshora:'00:00',
        hdsabado:false,
        hdsabadoact:'',
        hdsabadohora:'00:00',
        hddomingo:false,
        hddomingoact:'',
        hddomingohora:'00:00',
        helunes:false,
        helunesact:'',
        heluneshora:'00:00',
        hemartes:false,
        hemartesact:'',
        hemarteshora:'00:00',
        hemiercoles:false,
        hemiercolesact:'',
        hemiercoleshora:'00:00',
        hejueves:false,
        hejuevesact:'',
        hejueveshora:'00:00',
        heviernes:false,
        heviernesact:'',
        hevierneshora:'00:00',
        hesabado:false,
        hesabadoact:'',
        hesabadohora:'00:00',
        hedomingo:false,
        hedomingoact:'',
        hedomingohora:'00:00'
    });

    const [feedingData, setFeedingData] = useState<FeedingData>({

        aresidencia:'',
        asuper:'',
        aenfermedades:'',
        aalergias:'',
        aaversion:'',
        galimentos:'',
        acocinar:'',
        tiempoc:'',
        momentodia:'',
        sencilloelaborado:'',
        asuplementacion:'',
        asuplementacion_actual:'',
        asuplementacioni:'',
        gcomidas:'',
        avegetariano:'',
        aveghuevos:'',
        aveglacteos:'',
        avegpescado:'',
        avegmarisco:'',
        apicar:'',
        qpicas:'',
        detalles_plan:'',
        cnewsletter:false,
        cprivacidad:false
    });
    const [frontImage, setFrontImage] = useState<File | null>(null);
    const [sideImage, setSideImage] = useState<File | null>(null);
    const [backImage, setBackImage] = useState<File | null>(null);
    const [frontImageUrl, setFrontImageUrl] = useState<string | ArrayBuffer | null>(null);
    const [sideImageUrl, setSideImageUrl] = useState<string | ArrayBuffer | null>(null);
    const [backImageUrl, setBackImageUrl] = useState<string | ArrayBuffer | null>(null);

    const miComponenteRef = createRef<HTMLDivElement>();

    const navigate = useNavigate();

    useEffect(() => {
      CheckIsValid();
    },[frontImage,sideImage,backImage,feedingData['cprivacidad']]);


    const CheckIsValid = () => {
      const isValid = (
        (feedingData['cprivacidad'])&&
        (frontImage!=null)&&
        (sideImage!=null)&&
        (backImage!=null)
      );
      setValidationPassOne(isValid);
    };
  
    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
    }

    const sendForm = async () =>{
      setError([]);
      if(validationPassOne){
        setSending(true);
        const createNewPlanResponse = await NoClientPlanNewSubscriptionService({
          selectedTypeFormToSend:selectedTypeForm,
          personalDataToSend:personalData,
          sportActivityDataToSend:sportActivityData,
          feedingDataToSend:feedingData
        },frontImage,sideImage,backImage);
        setSending(false);
        if(createNewPlanResponse.data['planNuevo']==="exito") setResponseReceived(true);
        if(createNewPlanResponse.data["planNuevo"]!=="exito") setError( createNewPlanResponse.data["planNuevo"]); 
      }
    }

    

    const handleFileChange = (event:React.ChangeEvent<HTMLInputElement>, imageType:String) => {
      if(event.target.files){
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onloadend = () => {
            switch (imageType) {
                    case 'front':
                        setFrontImage(file);
                        setFrontImageUrl(reader.result);
                        break;
                    case 'side':
                        setSideImage(file);
                        setSideImageUrl(reader.result);
                        break;
                    case 'back':
                        setBackImage(file);
                        setBackImageUrl(reader.result);
                        break;
                    default:
                        break;
            }
        }
        reader.readAsDataURL(file);
        
      }
    };
    

    const handleInputChangeCheckBox = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { checked,name  } = event.target as HTMLInputElement;
        setFeedingData((prevFormData: FeedingData) => ({ ...prevFormData, [name]: checked }));
    };

    return (
      <>
        <div className="vikika-suscription-forms" ref={miComponenteRef}>
        {!beginForm &&(
        <div className="form-chapt1">
                                <h2>CUESTIONARIO</h2>
            <div className="cuestionary-selection">
                <label htmlFor="plan_a_comprar">*¿Qué plan te interesa?</label>
                  <Select 
                  id="plan_a_comprar" 
                  defaultValue="die_mes_psi" 
                  className="cuestionary-selection-select">
                      <MenuItem value="die_mes_psi" className="cuestionary-selection-option">PLAN VITAL WELLNESS</MenuItem>
                  </Select> 
                <hr />
                    <button className="form-button-right" onClick={()=>setBeginForm(true)}>Siguiente <img src={nextArrow} alt={'next'} /></button> 
                
                
            </div>           
        </div>
        )}
        {beginForm && !responseReceived  &&(

          
            <div className="begin-form">
                <h2>CUESTIONARIO INICIAL</h2>
                <div className="data-form-titles">
                  <div className="data-form-titles-checks">
                    <span className='success-step sucess-step-icon'>{next > 1 ? '✔' : 1}</span>
                    <p className='success-step-p1'>Datos personales</p>
                  </div>
                  {next===1 ? (
                      <span className={beginForm ? 'line-progress line-progress-active' : 'line-progress'}></span>
                    ):(
                      <span className={beginForm ? 'line-progress line-progress-active-full' : 'line-progress'}></span>
                    )
                  }

                  <div className="data-form-titles-checks">
                    <span className={next>1 ? 'success-step sucess-step-icon' : 'sucess-step-icon'}>{next>2  ? '✔' : 2}</span>
                    <p className={next>1 ? 'success-step-p2' : ''}>Actividad diaria y deportiva</p>
                  </div>
                  {next===1 && (
                    <span className={next === 1 as any ? 'line-progress' : 'line-progress line-progress-active'}></span>
                  )}
                  {next=== 2 &&(
                    <span className={next === 3 as any ? 'line-progress line-progress-active' : 'line-progress line-progress-active'}></span>
                  )}
                  {next=== 3 && feeding!==3 && (
                    <span className={next === 3 as any ? 'line-progress line-progress-active' : 'line-progress line-progress-active'}></span>
                  )}
                  {feeding===3 && (
                    <span className={next === 3 as any ? 'line-progress line-progress-active-full' : 'line-progress line-progress-active'}></span>
                  )}

                  <div className="data-form-titles-checks">
                    <span className={next>2 ? 'success-step sucess-step-icon' : 'sucess-step-icon'}>{feeding===3 ? '✔' : 3}</span>
                    <p className={next>2 ? 'success-step-p3' : ''}>Alimentación</p>
                  </div>
                </div>
                <form onSubmit={handleSubmit}>
                    {next===1 && (
                        <PersonalDataBuySubscriptions miComponenteRef={miComponenteRef} setNext={setNext} personalData={personalData} setPersonalData={setPersonalData}/>
                    )}
                    {next===2 && (
                        <SportActivityBuySubscriptions miComponenteRef={miComponenteRef} setNext={setNext} selectedTypeForm={selectedTypeForm} sportActivityData={sportActivityData} setSportActivityData={setSportActivityData}/>
                    )}
                    {next===3 && (
                      <>
                        <FeedingDataBuySubscriptions miComponenteRef={miComponenteRef} setNext={setNext} feeding={feeding} setFeeding={setFeeding} feedingData={feedingData} setFeedingData={setFeedingData}/>   
                        {feeding===3 && (
                        <>
                          <h3><img src={partyIcon} alt={'party icon'} />¡YA CASI TERMINAMOS !<img src={partyIcon} alt={'party icon'} /></h3>
                          <p className="photos-description">Para darte un mejor servicio necesitamos <span className="photos-description-bold"><u>tres fotos tuyas</u></span>, una <span className="photos-description-bold">de frente</span>, 
                          otra <span className="photos-description-bold">perfil</span> y otra de <span className="photos-description-bold">espalda</span>.
                          Las fotos deben ser en traje de baño o ropa ajustada, en lugar con mucha luz y <span className="photos-description-bold">sobre 
                          un fondo liso y blanco</span> para poder ver lo mejor posible tu físico. Recomendamos 
                          que alguien te tome las fotos. Si no es posible, coloca el movil en un lugar fijo y utiliza el temporizador.
                          </p>
                          <a href="https://www.vikika.es/blog/las-claves-para-hacerte-bien-las-fotos-del-antes-y-despues/" rel="noreferrer" target="_blank">+ Información para realizar tus fotos adecuadamente aquí.</a>
                          <h4>Ejemplo de fotos correctas y incorrectas</h4>
                          <img src={screenShotExample} alt=" fotos correctas y incorrectas" className="photo-change"/>
                          <h3 id="your-photos-title">¡TUS FOTOS!</h3>
                          <div className='page3-form-container'>

                          <div className="image-file-container">
                              <div className='img-section-1 img-file-section'>
                                <label htmlFor='img-person-1' className='custom-file-upload' style={{ backgroundImage: `url(${frontImageUrl})`, backgroundSize:'cover', backgroundRepeat:'no-repeat', backgroundPosition:'center'}}>
                                {frontImage ? null : <img src={def_img} alt="default img" style={{width:'80%',marginLeft:'10px',marginTop:'20px'}}/>}  
                                </label>
                                <input
                                  type='file'
                                  id='img-person-1'
                                  accept='image/gif, image/jpeg,image/png'
                                  onChange={(e) => handleFileChange(e, 'front')}
                                />
                                <p>*FRONT</p>
                              </div>

                              <div className='img-section-2 img-file-section'>
                                <label htmlFor='img-person-2' className='custom-file-upload' style={{ backgroundImage: `url(${sideImageUrl})`, backgroundSize:'cover', backgroundRepeat:'no-repeat', backgroundPosition:'center'}}>
                                {sideImage ? null : <img src={def_img} alt="default img" style={{width:'80%',marginLeft:'10px',marginTop:'20px'}}/>}
                                </label>
                                <input
                                  type='file'
                                  id='img-person-2'
                                  accept='image/gif, image/jpeg,image/png'
                                  onChange={(e) => handleFileChange(e, 'side')}
                                />
                                <p>*SIDE</p>
                              </div>

                              <div className='img-section-3 img-file-section'>
                                <label htmlFor='img-person-3' className='custom-file-upload' style={{ backgroundImage: `url(${backImageUrl})`, backgroundSize:'cover', backgroundRepeat:'no-repeat', backgroundPosition:'center'}}>
                                {backImage ? null : <img src={def_img} alt="default img" style={{width:'80%',marginLeft:'10px',marginTop:'20px'}}/>}
                                </label>
                                <input
                                  type='file'
                                  id='img-person-3'
                                  accept='image/gif, image/jpeg,image/png'
                                  onChange={(e) => handleFileChange(e, 'back')}
                                />
                                <p>*BACK</p>
                              </div>
                            </div> 
                            <span className="instructions-images" style={{fontSize:'10px'}}> <img src={infoIcon} alt="icono de informacion" /> Cada foto debe ser estar en formato GIF, JPG, JPEG o PNG para asegurarnos que se envían correctamente. </span>
                          </div>

                          <div className="accept-box">
                            <input 
                            type="checkbox"
                            name="cnewsletter"
                            id="comunicaciones" 
                            checked={feedingData['cnewsletter']} 
                            onChange={handleInputChangeCheckBox}
                            /> 
                            <label htmlFor="comunicaciones"> Acepto recibir comunicaciones informativas</label>
                          </div>

                          <div className="accept-box">
                            <input 
                            type="checkbox"
                            name="cprivacidad"
                            id="privacidad"                         
                            checked={feedingData['cprivacidad']} 
                            onChange={handleInputChangeCheckBox}
                            required
                            /> 
                            <label htmlFor="privacidad"> *He leido y acepto la <a href="/condiciones-generales" rel="noreferrer" target="_blank">política de uso y conformidad legal</a></label>
                          </div>
                          <p id="support-form-contact"> <img src={infoIcon} alt="icono de informacion" /> ¿Tienes algún problema con el formulario?. Contacta con soporte técnico</p>
                          <p id="support-form-contact-text"> Le informamos que los datos personales facilitados 
                            serán responsabilidad de COSTA LIFE FIT S.L con la finalidad de gestión de las compraventas. Encargos, presupuestos o prestación 
                            de servicios bajo la legitimimación otorga por su consentimiento expreso o bien del propio interesado. No se cederán datos 
                            a terceros salvo obligaciones legales. En cuanto a sus derechos podrá acceder, retificar y suprimir los datos, limitarlos o 
                            incluso oponerse a su tratamiento. Más información sobre protección de datos en <a href="https://vikika.es/privacidad" rel="noreferrer" target="_blank">www.vikika.es</a>
                          </p>

                          {error.length !== 0 &&
                            error.map((message,index)=><p key={index} className="instructions-send-form">{message}</p>)
                          }

                          {sending?
                            <>
                              <div className={'form-data-processing'}>
                              <CircularProgress color="inherit" size={20} thickness={3}/>
                              <p className="instructions-send-form">Procesando, por favor espere.</p>
                              </div> 
                            </> 
                            :
                            <></>
                          }


                          <hr />
                        </>
                        )}
                        <div className="button-navigation-form">
                          {feeding===3?
                            <button onClick={()=>{
                              setNext(3); 
                              setFeeding(1); 
                              miComponenteRef.current?.scrollIntoView({block: "start",behavior: 'smooth'}); 
                            }} className="questionnaire-button-left"><img src={previousArrow} alt={'previous'} /> Anterior</button>:
                            <button onClick={()=>{
                              setNext(2); 
                              setFeeding(1); 
                              miComponenteRef.current?.scrollIntoView({block: "start",behavior: 'smooth'}); 
                            }} className="questionnaire-button-left"><img src={previousArrow} alt={'previous'} /> Anterior</button>
                          }
                          {feeding===3?
                            <button disabled={!validationPassOne} onClick={()=>{sendForm()}} className="questionnaire-button-right send-button" type="submit" value="Enviar cuestionario"  >ENVIAR CUESTIONARIO</button>:
                            <></>  
                          }
                        </div>  
                      </>
                    )}
                </form>  
            </div>
        )}
        {responseReceived &&(
          <div className="begin-form final-form">
            <h2>CUESTIONARIO INICIAL</h2>
            <div className="final-container">
              <span className='final-icon'>✔</span>
              <h4 className='final-header'>¡GRACIAS POR CONTRATAR TU PLAN!</h4>
              <p className='final-text'>Revisa el buzon de correo no deseado. <strong>Recuerda aceptar nuestra dirección de correo electrónico: noresponder@vikika.es</strong></p>
              <button className="final-button" onClick={()=>navigate('/')}>ENTENDIDO</button>
            </div>
          </div>
        )}
        </div>
        <MediaQuery maxWidth={768}>
          <Questions showAlgunaDuda={false}/>
        </MediaQuery>
        <MediaQuery minWidth={768}>
          <Questions />
        </MediaQuery>
      </>
    )
} 

export default BuySubscriptionsVitalWellness