import { useEffect, RefObject } from "react";

import { FeedingData } from "../../../../../models/formsModels/ReviewFeedingData";

import previousArrow from '../../../../../assets/img/arrow-left-form.svg';
import nextArrow from '../../../../../assets/img/arrow-right-form.svg'; 
import './FeedingDataReviewSubscriptions.css';

interface FeedingDataProps {
  feedingData: FeedingData;
  setFeedingData: (value: any) => void;
  setNext: (value: any) => void;
  miComponenteRef:RefObject<HTMLDivElement>;
}

const FeedingDataReviewSubscriptions = (feedingDataProps: FeedingDataProps) => {


    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { value,name } = event.target;
        feedingDataProps.setFeedingData((prevFormData: FeedingData) => ({ ...prevFormData, [name]: value }));
    };

    useEffect(() => {
        feedingDataProps.miComponenteRef.current?.scrollIntoView({block: "start",behavior: 'smooth'});
    },[]);

    return (
      <>
        <div className="feeding-data-activity">
         <h4>¿Quieres cambiar algún alimento o receta? <span className="feeding-data-header-one">Intentaremos buscar un alimento con valores nutricionales y características similares, para cambiarlo en la medida de los posible.</span></h4>
         <div className="food-fields">
            <div className="food-groups">
                <label htmlFor="alimento1Cliente" className="data-label">Alimento/receta 1</label>
                <input type="text" 
                name="alimento1" 
                id="alimento1Cliente" 
                placeholder="Max 150 palabras" 
                defaultValue={feedingDataProps.feedingData.alimento1}
                onChange={handleInputChange}
                />
            </div>
            
            <div className="food-groups">
                <label htmlFor="motivo1Cliente" className="data-label">Motivo 1</label>
                <input type="text" 
                name="motivo1" 
                id="motivo1Cliente" 
                placeholder="Max 150 palabras" 
                defaultValue={feedingDataProps.feedingData.motivo1}
                onChange={handleInputChange}
                />
            </div>  
         </div>
         <div className="food-fields">
            <div className="food-groups">
                <label htmlFor="alimento2Cliente" className="data-label">Alimento/receta 2</label>
                <input type="text" 
                name="alimento2" 
                id="alimento2Cliente" 
                placeholder="Max 150 palabras" 
                defaultValue={feedingDataProps.feedingData.alimento2}
                onChange={handleInputChange}
                />
            </div>
            
            <div className="food-groups">
                <label htmlFor="motivo2Cliente" className="data-label">Motivo 2</label>
                <input type="text" 
                name="motivo2" 
                id="motivo2Cliente" 
                placeholder="Max 150 palabras" 
                defaultValue={feedingDataProps.feedingData.motivo2}
                onChange={handleInputChange}
                />
            </div>  
         </div>
         <div className="food-fields">
            <div className="food-groups">
                <label htmlFor="alimento3Cliente" className="data-label">Alimento/receta 3</label>
                <input type="text" 
                name="alimento3" 
                id="alimento3Cliente" 
                placeholder="Max 150 palabras" 
                defaultValue={feedingDataProps.feedingData.alimento3}
                onChange={handleInputChange}
                />
            </div>
            
            <div className="food-groups">
                <label htmlFor="motivo3Cliente" className="data-label">Motivo 3</label>
                <input type="text" 
                name="motivo3" 
                id="motivo3Cliente" 
                placeholder="Max 150 palabras" 
                defaultValue={feedingDataProps.feedingData.motivo3}
                onChange={handleInputChange}
                />
            </div>  
         </div>
        </div>
        <hr/>
        <div className="button-navigation-form">
            <button  className="prev-form-button" onClick={()=>feedingDataProps.setNext(1)}><img src={previousArrow} alt={'previous'} /> Anterior</button>
            <button onClick={()=>feedingDataProps.setNext(3)}  className="nxt-form-button">Siguiente <img src={nextArrow} alt={'next'} /></button>
        </div>   
     </>
    );
}

export default FeedingDataReviewSubscriptions;