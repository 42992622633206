import React, { useState} from 'react';
import {
    PaymentElement,
    useStripe, useElements,
} from '@stripe/react-stripe-js';
import { useNavigate } from "react-router-dom";

import './CardForm.css';
import {purchaseCompleted, addPaymentInfo} from "../../../../utils/FacebookPixelEvents";
import { FacturationData } from "../../../../models/FacturationData";
import { processOrder } from "../../../../services/processOrder";
import ToastMsgComponent from '../../../shared/toast/ToastMsg';
import Loading from '../../../shared/loading/Loading';
import {PartnerData} from "../../../../models/PartnerData";
import {Typography} from "@mui/material";
import iconCheck from '../../../../assets/img/mapas-y-banderas_1.png';
import {loginService} from "../../../../services/loginService";
import {productsService} from "../../../../services/productsService";
import {createOrder} from "../../../../services/createOrder";
import {couponValidate} from "../../../../services/couponValidate";
import {isAxiosError} from "axios";

interface CardFormProps {
    facturationData: FacturationData;
    partnerData: PartnerData;
    payment_intent: string;
    client_secret: string;
    selectedProduct: any;
    cuponData: any;
    setCuponData: (value: any) => void;
}

const CardForm = (cardFormProps: CardFormProps) => {

    const stripe = useStripe();
    const elements = useElements();
    const navigate = useNavigate();
    const [showFailurePaymentToast, setShowFailurePaymentToast] = useState(false);
    const [showFailureOrderToast, setShowFailureOrderToast] = useState(false);
    const [showFailureGenericToast, setShowFailureGenericToast] = useState(false);
    const [showFailureCreateOrderToast, setShowFailureCreateOrderToast] = useState(false);
    const [showFailureCreateOrderCuponToast, setShowFailureCreateOrderCuponToast] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [acceptedPrivacyPolicy, setAcceptedPrivacyPolicy] = useState<boolean>(false);
    const [showModalSuccess, setShowModalSuccess] = useState(false);
    const [disableButtonOnPayment, setDisableButtonOnPayment] = useState(false);
    const [cardComplete, setCardComplete] = useState(false);

    const handleCardChange = (event: any) => {
        setCardComplete(event.complete);
    };

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { checked } = event.target;
        setAcceptedPrivacyPolicy( checked );
      };

    const getProductPrice = () => {
        if (cardFormProps.selectedProduct.productSubscription) {
            return cardFormProps.cuponData?.discount
                ? cardFormProps.selectedProduct.productSubscriptionPrice + cardFormProps.selectedProduct.productPrice - cardFormProps.cuponData.discount
                : cardFormProps.selectedProduct.productSubscriptionPrice + cardFormProps.selectedProduct.productPrice
        }
        return cardFormProps.cuponData?.discount
            ? cardFormProps.selectedProduct.productPrice - cardFormProps.cuponData.discount
            : cardFormProps.selectedProduct.productPrice
    }

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {

        event.preventDefault();
        setDisableButtonOnPayment(true);

        if (!stripe || !elements) {
            setDisableButtonOnPayment(false);
            return;
        }
        setIsLoading(true);
        addPaymentInfo();
        try {
            const createOrderResponse = await createOrder({
                wp_customer_id: localStorage.getItem('wp_customer_id'),
                stripe_customer_id: localStorage.getItem('stripe_customer_id'),
                payment_intent_id: localStorage.getItem('payment_intent'),
                price: getProductPrice(),
                email: cardFormProps.facturationData.email,
                first_name: cardFormProps.facturationData.firstName,
                last_name: cardFormProps.facturationData.lastName,
                address_1: cardFormProps.facturationData.address_1,
                address_2: cardFormProps.facturationData.address_2,
                city: cardFormProps.facturationData.city,
                postcode: cardFormProps.facturationData.postalCode,
                state:cardFormProps.facturationData.state,
                country: cardFormProps.facturationData.country,
                phone: cardFormProps.facturationData.phone,
                product_id: cardFormProps.selectedProduct.productId,
                variant_id: cardFormProps.selectedProduct.variantId,
                partner_name: cardFormProps.partnerData.partner_name,
                partner_last_name: cardFormProps.partnerData.partner_last_name,
                partner_email: cardFormProps.partnerData.partner_email,
                coupon_code: cardFormProps.cuponData?.cupon_code,
            });
            if (!createOrderResponse) {
                setIsLoading(false);
                setShowFailureCreateOrderToast(true);
                setTimeout(() => {
                    setShowFailureCreateOrderToast(false);
                }, 10000);
                console.log('create order error');
                return;
            }
        } catch (error) {
            console.log('entro en el catch con error: ', error);
            if (isAxiosError(error)) {
                console.log("error: ", error.response);
                if (error.response && error.response.status && error.response.status === 400) {
                    setShowFailureCreateOrderCuponToast(true);
                    setTimeout(() => {
                        setShowFailureCreateOrderCuponToast(false);
                    }, 10000);
                    cardFormProps.setCuponData(null);
                    const elemento = document.querySelector('input#cupon-value-input') as HTMLInputElement | null;
                    if (elemento) {
                        elemento.value = '';
                        console.log('elemento: ', elemento);
                    } else {
                        console.log('elemento no encontrado');
                    }
                } else {
                    setShowFailureCreateOrderToast(true);
                    setTimeout(() => {
                        setShowFailureCreateOrderToast(false);
                    }, 10000);
                }
                setIsLoading(false);
                setDisableButtonOnPayment(false);
                return;
            }
            return;
        }
        try {
            let stripeError;
            if (getProductPrice() > 0) {
                const { error } = await stripe.confirmPayment({ elements, redirect: 'if_required' });
                stripeError = error;
            }

            if (stripeError) {
                setDisableButtonOnPayment(false);
                setIsLoading(false);
                setShowFailurePaymentToast(true);
                setTimeout(() => {
                    setShowFailurePaymentToast(false);
                }, 10000);
                console.log('process card error', stripeError);
                return;
            } else {
                setIsLoading(true);
                const processOrderResponse = await processOrder({
                    wp_customer_id: localStorage.getItem('wp_customer_id'),
                    stripe_customer_id: localStorage.getItem('stripe_customer_id'),
                    payment_intent_id: localStorage.getItem('payment_intent'),
                    first_name: cardFormProps.facturationData.firstName,
                    last_name: cardFormProps.facturationData.lastName,
                    address_1: cardFormProps.facturationData.address_1,
                    address_2: cardFormProps.facturationData.address_2,
                    city: cardFormProps.facturationData.city,
                    postcode: cardFormProps.facturationData.postalCode,
                    country: cardFormProps.facturationData.country,
                    email: cardFormProps.facturationData.email,
                    phone: cardFormProps.facturationData.phone,
                    state:cardFormProps.facturationData.state,
                    partner_name: cardFormProps.partnerData.partner_name,
                    partner_last_name: cardFormProps.partnerData.partner_last_name,
                    partner_email: cardFormProps.partnerData.partner_email,
                    product_id: cardFormProps.selectedProduct.productId,
                    variant_id: cardFormProps.selectedProduct.variantId,
                    product_subscription: cardFormProps.selectedProduct.productSubscription,
                    quantity: 1,
                    price: getProductPrice(),
                    cupon_code: cardFormProps.cuponData?.cupon_code,
                })
                setIsLoading(false);
                setDisableButtonOnPayment(false);
                if (!processOrderResponse) {
                    console.log('processOrderResponse error');
                    setShowFailureOrderToast(true);
                    setTimeout(() => {
                        setShowFailureOrderToast(false);
                    }, 10000);
                    return;
                } else {
                    try {
                        localStorage.removeItem('payment_intent');
                        localStorage.removeItem('client_secret');
                        localStorage.removeItem('facturationData');
                        localStorage.removeItem('savedSelectedProduct');
                        localStorage.removeItem('wp_customer_status');
                        localStorage.removeItem('wp_customer_id');
                        setShowModalSuccess(true);
                        purchaseCompleted(getProductPrice());
                    } catch (error) {
                        console.log('clean storage error', error);
                        setShowModalSuccess(true);
                        purchaseCompleted(getProductPrice());
                        return;
                    }
                }
            }
        } catch (error) {
            console.log('generic error', error);
            setIsLoading(false);
            setDisableButtonOnPayment(false);
            setShowFailureGenericToast(true);
            setTimeout(() => {
                setShowFailureGenericToast(false);
            }, 10000);
            return;
        }
    };

    const handleFinishShopping = async () => {
        setIsLoading(true);
        setTimeout(async () => {
            try {
                const status = localStorage.getItem('status');
                if (status === 'isLoggedIn') {  
                    navigate('/plans_subscriptions');
                } else {
                    const responseLogin = await loginService({username: cardFormProps.facturationData.email, password: cardFormProps.facturationData.password});
                    setIsLoading(false);
                    if (responseLogin && responseLogin.data) {
                        localStorage.setItem('status', 'isLoggedIn');
                        console.log(responseLogin.data);
                        localStorage.setItem('id', responseLogin.data.id);
                        localStorage.setItem('registered_date', responseLogin.data.registered_date);
                        localStorage.setItem('email', responseLogin.data.email);
                        localStorage.setItem('nicename', responseLogin.data.nicename);
                        localStorage.setItem('firstName', responseLogin.data.billing_info.first_name);
                        localStorage.setItem('lastName', responseLogin.data.billing_info.last_name);
                        localStorage.setItem('displayName', responseLogin.data.displayName);
                        localStorage.setItem('token', responseLogin.data.token);
                        localStorage.setItem('address_1', responseLogin.data.billing_info.address_1);
                        localStorage.setItem('address_2', responseLogin.data.billing_info.address_2);
                        localStorage.setItem('city', responseLogin.data.billing_info.city);
                        localStorage.setItem('state', responseLogin.data.billing_info.state);
                        localStorage.setItem('postcode', responseLogin.data.billing_info.postcode);
                        localStorage.setItem('country', responseLogin.data.billing_info.country);
                        localStorage.setItem('tokenExpiration', responseLogin.data.tokenExpiration);
                        localStorage.setItem('phone', responseLogin.data.billing_info.phone);
                        localStorage.setItem('pon_user_info', JSON.stringify(responseLogin.data.pon_user_info));
                        const products= await productsService();
                        localStorage.setItem('products', JSON.stringify(products));
                        //navigate('/plans_subscriptions');
                        navigate('/private');
                        //window.location.href = '/private'
                    } else {
                        navigate('/');
                    }
                }
            } catch (error) {
                setIsLoading(false);
                navigate('/');
            }
        }, 3000);
    }

    return (
        <>
            {isLoading && (<Loading/>)}
            {showFailureCreateOrderCuponToast && (
                <ToastMsgComponent
                    type='danger'
                    titleHeader='Cupón no aplicable'
                    info='El cupón que has entrado no es aplicable a esta compra, lo hemos eliminado, por favor vuelve a intentarlo'
                    position='top-end' />
            )}
            {showFailurePaymentToast && (
                <ToastMsgComponent
                    type='danger'
                    titleHeader='Pago incorrecto'
                    info='Ha habido un error al procesar tu tarjeta'
                    position='top-end' />
            )}
            {showFailureOrderToast && (
                <ToastMsgComponent
                    type='danger'
                    titleHeader='Pedido incorrecto'
                    info='Ha habido un error al procesar tu pedido'
                    position='top-end' />
            )}
            {showFailureCreateOrderToast && (
                <ToastMsgComponent
                    type='danger'
                    titleHeader='Pedido incorrecto'
                    info='Ha habido un error al procesar tu pedido'
                    position='top-end' />
            )}
            {showFailureGenericToast && (
                <ToastMsgComponent
                    type='danger'
                    titleHeader='Error'
                    info='Ha ocurrido un error'
                    position='top-end' />
            )}
            {showModalSuccess && (
                <div className={'modal-success-container'}>
                    <div className={'modal-success'}>
                        <img src={iconCheck} alt={'checked'} className={'modal-success-image'}/>
                        <h1 className={'custom-modal-title-unified'}>¡MUCHAS GRACIAS!</h1>
                        <Typography className={'modal-success-text'}>
                            Tu pedido ha sido procesado correctamente. Revisa tu correo electrónico donde podras encontrar la confirmación y datos de la compra.
                        </Typography>
                        <button className={'modal-success-button'} onClick={handleFinishShopping}>
                            Entendido
                        </button>
                    </div>
                </div>
            )}
            <div className='method-title'>
                <p>MÉTODO DE PAGO</p>
            </div>
            <div className='method-form-container' id={'payment-card-form'}>
                <div className='method-form-container-header'></div>
                <div className='form-method'>
                    <form onSubmit={handleSubmit} >
                        <PaymentElement onChange={handleCardChange}/>
                        <div className='method-form-bottom stripe-text' >Tus datos personales se utilizarán para procesar tu pedido, mejorar tu experiencia en esta web y otros propósitos descritos en nuestra política de privacidad.</div>

                        <div className='method-form-bottom-policy' >
                            <label>
                                <input
                                    style={{marginTop: '-7px'}}
                                    type="checkbox"
                                    checked={acceptedPrivacyPolicy}
                                    onChange={handleCheckboxChange}
                                />
                                <div className = 'space stripe-text' >He leído y estoy de acuerdo con los <a href='/condiciones-generales' target='_blank'>términos y condiciones y la politica de privacidad</a> de la web<span className="red">*</span></div>
                            </label>
                        </div>
                        <button disabled = {!acceptedPrivacyPolicy || disableButtonOnPayment || !cardComplete} type="submit" className='payment-button' >
                            Pagar
                        </button>
                    </form>
                </div>
            </div>
        </>
    )
}

export default CardForm




