
import axios from "../api/axios";
import { CHANGE_PASSWORD } from "../utils/constants";

export async function changePassword(newpass:string,rpass:string) {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': "application/json"
      },
    };
    const requestData = {
      password_1: newpass,
      password_2: rpass,
    };
    try {
      const response = await axios.put(CHANGE_PASSWORD,requestData,config);
      console.log(response);
        return response.data;
      } catch (error) {
        console.log("Error al resetear la contraseña: ", error);
        throw error;
      }
}