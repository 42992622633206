import './Succesfull.css'
import { useState } from 'react';
import Vector from '../../../../assets/img/Vector (1).png'

interface SuccessfullProps {
    message: string; // Define el tipo de 'message' como string
}


const Successfull = ({ message}: SuccessfullProps)=>{
    const [isVisible, setIsVisible] = useState(true); // Initialize with true to show the container
    const [isVisible2, setIsVisible2] = useState(true); // Initialize with true to show the container

const handleCrossClick = () => {
    setIsVisible(false); // Set isVisible to false when the cross is clicked 
       setIsVisible2(false);

};
return (
    <>
        {(isVisible || isVisible2) && ( // Render the container only if isVisible is true
            <div className='main-success-container-security'>
                <div className="succes-container-security">
                    <div className="success-text-message">
                        {message}
                    </div>
                    <div className="cross-sign-security" onClick={handleCrossClick}>
                        <img src={Vector} alt="imagen seguridad alerta"/>
                    </div>
                </div>
            </div>
         )}
    </>
);
}

export default Successfull