import React from "react";
import "./modalCites.css";

function ModalCites() {
  return (
    <div className="title_cites">
      <h1>
        <b>ACTUALMENTE NO TIENES CITAS PENDIENTES QUE AGENDAR </b>
      </h1>
    </div>
  );
}

export default ModalCites;
