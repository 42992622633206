import Slider, { Settings } from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import "./Banner.css";
import {slidesService} from "../../../../services/slidesService";
import {useEffect, useState} from "react";
import {SlideType} from "../../../../models/SlydeType";
import BannerSlide from "./banner-slide/BannerSlide";

const Banner = () => {
  const [slides, setSlides] = useState<SlideType[]>([]);

  const settings: Settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 9000,
    arrows: false,
    appendDots: dots => (
      <div
        style={{
          backgroundColor: "rgba(0, 0, 0, 0)",
          padding: "20px"
        }}
      >
        <ul style={{ margin: "0px" }}> {dots} </ul>
      </div>
    ),
    customPaging: (i) => <div className="custom-dot"></div>,
  };

  useEffect(() => {
    async function fetchSlides() {
      const slides = await slidesService();
      setSlides(slides);
    }
    fetchSlides();
  }, []);

  return (
    <Slider {...settings}>
      {slides.map((slide, index) => (
        <BannerSlide
          key={index+Math.random()}
          index={index}
          title={slide.title}
          subtitle={slide.subtitle}
          button_href={slide.button_href}
          // button_href={
          //   index === 3
          //     ? "/productos/talleres"
          //     : index === 2
          //     ? "/productos/psicologia"
          //     :  index === 1
          //     ? "/productos/dieta"
          //     : "#product_cards"
          // }
          button_text={index === 3 ? "Próximamente" : slide.button_text}
          image={slide.image}
        />
      ))}
    </Slider>
  );
}

export default Banner





