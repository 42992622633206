import './Cart.css'
interface CartProps {
  selectedProduct: any;
  productToShow: any;
  cuponData: any;
  setCuponData: (value: any) => void;
}
const Cart = (cartProps: CartProps) => {  
  const getProductPrice = () => {
    if (cartProps.selectedProduct.productSubscription) {
      return cartProps.cuponData?.discount
          ? cartProps.selectedProduct.productSubscriptionPrice + cartProps.selectedProduct.productPrice - cartProps.cuponData.discount
          : cartProps.selectedProduct.productSubscriptionPrice + cartProps.selectedProduct.productPrice
    }
    return cartProps.cuponData
      ? cartProps.selectedProduct.productPrice - cartProps.cuponData.discount
      : cartProps.selectedProduct.productPrice
  }

  return (
    <div className="cart-container">
      {cartProps.selectedProduct && cartProps.selectedProduct.name && (
        <div className='cart-product'>
          <div className='cart-product-image'>
            <div className='cart-image'>
              <img
                src={cartProps.productToShow.image}
                alt={cartProps.productToShow} className="cart"/>
            </div>
            <div className="cart-product-name">
              <p>{cartProps.selectedProduct.name} {cartProps.selectedProduct.isEnPareja && (
                <span> en pareja</span>
              )}</p>
              <div className='cart-product-quantity'>
                <p style={{marginTop: '-2px', textTransform: 'lowercase'}}>x1</p>
                {cartProps.selectedProduct.value && cartProps.selectedProduct.value.option_name && (
                  <p style={{ fontSize: 14, textTransform: 'capitalize', marginTop: '1px' }}>+ {cartProps.selectedProduct.value.option_name}</p>
                )}
              </div>
            </div>
          </div>
          <div className={`cart-price-container ${!cartProps.selectedProduct.productSubscription ? "withoutPrice" : ""}`}>
            <div className='product-price'>
              <p>{getProductPrice().toLocaleString('es-ES', { minimumFractionDigits: 2 })}€</p>
            </div>
            {cartProps.selectedProduct.productSubscription === true && (
              <p style={{fontSize: 11}}>Después {cartProps.selectedProduct.productPrice}€/ cada mes (impuestos incluidos)</p>
            )}
            {cartProps.selectedProduct.name === "Plan de Psicología Suscripción" && (
                          <p style={{fontSize: '11.24px', marginTop: '10px'}}>Renovación automatica cada 30 días </p>
                        )}
          </div>
        </div>
      )}
    </div>

  )
}

export default Cart
