import ReactPixel from 'react-facebook-pixel';
const id=process.env.REACT_FACEBOOK_PIXEL_ID!;
ReactPixel.init('6821017527961991');

export const trackView=()=>{
    //ReactPixel.pageView();
}

export const purchaseCompleted=(cost:number)=>{
    ReactPixel.track('Purchase', {currency: "EUR", value: cost,eventID: Math.random()});
}

export const addToCart=()=>{
    ReactPixel.track('AddToCart',{eventID: Math.random()});
}

export const addPaymentInfo=()=>{
    ReactPixel.track('AddPaymentInfo',{eventID: Math.random()});
}