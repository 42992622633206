import { useNavigate, useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import "./PlanDetails.css";
import Questions from '../../shared/questions/Questions';
import MenuProduct from './menu/MenuProduct';
import MenuProductDiet from './menu/MenuProductDiet';
import TrainerTeam from './trainerteam/TrainerTeam';
import { getTeamMembersService } from '../../../services/teamService';
import { Member } from '../../../models/TeamMember';
import ProductTestimonials from './producttestimonial/ProductTestimonials';
import Interesting from './interesting/Interesting';
import { ProductType } from '../../../models/ProductType';
import ProductVariants from './productvariants/ProductVariants';
import { productDetailService } from "../../../services/productDetailService";
import { ProductDetailData } from "../../../models/ProductDetailData";
import HeaderProductDiet from '../../../assets/img/header-product.jpg'
import HeaderProductPsychology from '../../../assets/img/header-psicology.jpg'
import Megafono from '../../../assets/img/megafono 1.svg';
import HeaderProductWorkshop from '../../../assets/img/header-workshop.jpg';
import iconEcologyBookWorkshop from '../../../assets/img/iconoir_ecology-book.svg';
import Loading from '../../shared/loading/Loading';
import DescriptionAccordion from './descriptionaccordion/DescriptionAccordion';
import {productsService} from "../../../services/productsService";

const PlanDetails = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingToo, setIsLoadingToo] = useState(false);
    const [myProduct, setMyProduct] = useState<ProductDetailData>();
    const [myProduct2, setMyProduct2] = useState<ProductDetailData>();
    const [trainers, setTrainers] = useState<Member[]>([]);
    const [products, setProducts] = useState<ProductType[]>([]);
    const [selectedPosition, setSelectedPosition] = useState(-1);
    const [categoryRoute, setCategoryRoute] = useState('');
    const { id } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        async function fetchProducts() {
            const savedProducts = localStorage.getItem('products');
            if (savedProducts) {
                const parsedSavedProducts = JSON.parse(savedProducts);
                setProducts(parsedSavedProducts);
            } else {
                const products = await productsService();
                setProducts(products);
                if (products.length > 0) {
                    const productsString = JSON.stringify(products);
                    localStorage.setItem('products', productsString);
                    const savedProducts = localStorage.getItem('products');
                    const parsedSavedProducts = JSON.parse(savedProducts!);
                    setProducts(parsedSavedProducts);
                }
            }
        }
        fetchProducts();
    }, [])

    //funcion que recibe una posicion y expande el accordion en esa posicion
    const handleAccordionClick = (position: number) => {
        setSelectedPosition(position);
    }

    useEffect(() => {
      if (id == 'dieta'){
        setCategoryRoute('diet')
      }
      if (id == 'psicologia'){
        setCategoryRoute('psychology')
      }
      if (id == 'talleres'){
        setCategoryRoute('workshop')
      }
    }, [id]);

    useEffect(() => {
      async function fetchProductData() {
        try {
          setIsLoading(true);
          const productData = await productDetailService(categoryRoute);
          setIsLoading(false);
          setMyProduct(productData[0]);
          setMyProduct2(productData[1]);
          console.log(myProduct2?.description);
        } catch (error) {
            setIsLoading(false);
        }
      }
      async function fetchTeamMembers() {
          try {
            setIsLoadingToo(true);
            const members = await getTeamMembersService();
            setIsLoadingToo(false);
            setTrainers(members.filter(member => (member.role === categoryRoute)));
          } catch (e) {
              setIsLoadingToo(false);
          }
      }
      if (id !== 'talleres' && categoryRoute){
          fetchProductData();
          fetchTeamMembers();
      }
    }, [categoryRoute]);

    const handleProductClick = (product: ProductType) => {
        navigate(`/pago`);
    };

    if (!categoryRoute) {
      return null; 
    }

    return (
      <>
      <Helmet>
        <link rel="canonical" href={`${process.env.REACT_APP_FRONT_URL}`+"/product_details"} />
        
        {id === "dieta" && (
        <>
          <title>Plan Dieta | Nutrición más entrenador personal online</title>
          <meta name="description" content="Alcanza metas con nuestro plan de dieta y entrenador personal online. Nutrición y entrenamiento virtual para lograr resultados."/>
          <meta name="keywords" content="nutrición, dieta, entrenamiento personalizado online"/>
        </>
        )}
        {id === "psicologia" && (
        <>
          <title>Psicología online  | Expertos en psicología de pareja y emocional</title>
          <meta name="description" content="Consulta psicología online con nuestros expertos en psicología de pareja y emocional. ¡Hazte de Vikika Team!"/>
          <meta name="keywords" content="psicologia deportiva, psicologia de pareja"/>
        </>
        )}
        
      </Helmet>
      <main className="main-content">
        {(isLoading || isLoadingToo) && <Loading />}
        <div className="product-details-container ">
          <div className="product-details-header-container">
            <div
              className={
                id !== "talleres"
                  ? "product-details-header-text"
                  : "product-details-header-text-workshop"
              }
            >
              {id === "dieta" && <p className="header-text-title">PLAN DIETA</p>}

              {id === "psicologia" && <p className="header-text-title">PLAN PSICOLOGÍA </p>}

              {id === "talleres" && (
                <>
                  <img src={Megafono} className={'megafono'} />
                  <p className={"workshop-title"}>PRÓXIMAMENTE </p>
                  <p className={"workshop-subtitle"}>
                    ¡ SE AVECINAN NOVEDADES !{" "}
                  </p>
                  <p className={"workshop-text"}>
                    Estamos deseando poder darte a conocer el nuevo proyecto de
                    Vikika Team. ¡Sigue nuestras redes para enterarte de todo!{" "}
                  </p>
                </>
              )}

              {id !== "talleres" &&
                myProduct !== undefined &&
                myProduct.slogan && (
                  <div className='slogan-container'>
                    <h1 className="header-text-small">{myProduct.slogan} </h1>
                  </div>

                )}
            </div>
            <div
              className="header-img"
              style={{
                backgroundImage:
                  id === "dieta"
                    ? `url(${HeaderProductDiet})`
                    : id === "psicologia"
                    ? `url(${HeaderProductPsychology})`
                    : `url(${HeaderProductWorkshop})`,
              }}
            >
              <div className="mask"></div>
            </div>
          </div>
          
          {id !== "talleres" && (
            <>
              <div className="description-product-container">
                <div className="description-variants-content">
                  {id && id === "dieta" && (
                    <div className="description-variants-content-1">
                      {myProduct !== undefined && (
                        <>
                          <h1>{myProduct.description}</h1>
                        </>
                      )}
                    </div>
                  )}
                  <div className="description-variants-content-2">
                    {myProduct !== undefined && id === "dieta" && (
                      <div
                        className="variations-container"
                        style={{ marginLeft: "2vw" }}
                      >
                        <ProductVariants />
                      </div>
                    )}
                  </div>
                </div>
                <div className="description-row">
                  <div className="accordion-details">
                    <h2>DESCRIPCIÓN</h2>
                    {myProduct !== undefined && (
                      <DescriptionAccordion
                        product={myProduct}
                        selectedPosition={selectedPosition}
                      />
                    )}
                  </div>

                  {myProduct !== undefined && id === "dieta" && (
                    <MenuProductDiet
                      product={myProduct}
                      product2={myProduct2}
                      openAcordeonSection={handleAccordionClick}
                    />
                  )}
                  {myProduct !== undefined && id === "psicologia" && (
                    <MenuProduct
                      product={myProduct}
                      product2={myProduct2}
                      openAcordeonSection={handleAccordionClick}
                    />
                  )}
                </div>
                <div className="our-team">
                  <div className="title">
                    <h2>CONOCE A NUESTRO EQUIPO</h2>
                  </div>
                  <div className="our-team-content">
                    <TrainerTeam trainers={trainers} />
                  </div>
                </div>
                <div className="team-other-products-row">
                  <div className="our-testimonials">
                    <div className="title-sesion">
                      <h2>¿QUÉ OPINAN DE NOSOTROS?</h2>
                    </div>
                    <div
                      className="product-testimonials"
                      style={{ width: "95%" }}
                    >
                      <ProductTestimonials category={categoryRoute} />
                    </div>
                  </div>
                  <div className="others-products">
                    <div className="title-sesion">
                      <h2>TAMBIÉN TE PUEDE INTERESAR </h2>
                    </div>
                    <div className="product-cards-containers">
                      {products && id === "dieta" &&
                        // eslint-disable-next-line  array-callback-return
                        products.map((product) => {
                          if (product.category !== 'diet') {
                            return (
                              <Interesting
                                key={product.id}
                                {...product}
                                onClick={() => handleProductClick(product)}
                              />
                            );
                          }
                        })}
                      {products && id === "psicologia" &&
                        // eslint-disable-next-line  array-callback-return
                        products.map((product) => {
                          if (product.category !== 'psychology') {
                            return (
                              <Interesting
                                key={product.id}
                                {...product}
                                onClick={() => handleProductClick(product)}
                              />
                            );
                          }
                        })}
                      {products && id === "talleres" &&
                        // eslint-disable-next-line  array-callback-return
                        products.map((product) => {
                          if (product.category !== 'workshop') {
                            return (
                              <Interesting
                                key={product.id}
                                {...product}
                                onClick={() => handleProductClick(product)}
                              />
                            );
                          }
                        })}
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}

          {id === "talleres" && (
            <>
              <div className='workshop-container'>
                <div className='workshop-container-title'>
                  <img src={iconEcologyBookWorkshop} alt="icon workshop"/>
                  <h6>¿Qué son los talleres de Vikika Team?</h6>
                </div>
                <p>En Vikika Team somos muy conscientes de la <b>importancia de aprender</b> sobre determinados 
                  temas para disfrutar de un <b>estilo de vida saludable</b>.</p>
                <br/>
                <p>Y es que hay quienes están decididos 
                  a mejorar sus hábitos, pero no saben cómo hacerlo o por dónde empezar. ¿Te sientes 
                  identificado? ¡Que no cunda el pánico! A través de nuestros talleres podrás adquirir  
                  <b> información muy útil e interesante sobre nutrición, entrenamiento, psicología… pilares 
                  fundamentales para preservar una buena salud.</b> Además del aspecto teórico, te propondremos 
                  ejercicios para poner en práctica lo aprendido y comprobar que una vida saludable es 
                  divertida, sencilla y apasionante.</p>
                <p>Resuelve tus dudas de una vez por todas de la mano de los mejores <b>profesionales especializados 
                  en cada materia</b>.</p> 
                <br/>
                <p>Si quieres saber más, <b>deja tu correo electrónico en el siguiente enlace</b> y recibirás toda 
                  la información necesaria una vez comiencen los talleres de Vikika Team. &nbsp;<a href="https://form.typeform.com/to/eY7aBI4A">Quiero saber más</a>
                </p>
                <h2>¡A por todas!</h2>
                
              </div>
              <div style={{ paddingTop: "70px" }}>
                <Questions />
              </div>
            </>

          )}
        </div>
      </main>
      </>
    );


}

export default PlanDetails
