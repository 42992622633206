import React from "react";

interface BrandProps {
    image: any;
}

const Brand = (brandProps: BrandProps) => {
    return (
        <div>
            <img src={brandProps.image} className="slider" alt="Slider" />
        </div>
    );
}

export default Brand;
