import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import {trackView} from "../../../utils/FacebookPixelEvents";
import "./Privacy.css";

function Privacy() {
  
  useEffect(() => {
    trackView();
  }, []);

  return (
    <>
    <Helmet>
      <link rel="canonical" href={`${process.env.REACT_APP_FRONT_URL}`+"/privacidad"} />
    </Helmet>
    <div className="privacy-container">
      <h2>Política de privacidad</h2>
      <p>
        Esta política expresa cómo se tratará y protegerá la información
        personal de todas las personas que se relacionan con COSTA LIFE FIT,
        S.L. a través de este sitio web. Por favor, debes leer todos los
        apartados del Aviso Legal, de la Política de cookies, en su caso, y de
        la presente Política de Privacidad antes de utilizar esta web. </p>
      <p> De conformidad con lo dispuesto en el Reglamento UE 2016/679, del
        Parlamento Europeo y del Consejo del 27 de abril de 2016 (RGPD) y la Ley
        Orgánica 3/2018, del 5 de diciembre, de Protección de Datos Personales y
        garantía de los derechos digitales, COSTA LIFE FIT, S.L., te informa de
        que mediante la aceptación de esta Política de privacidad, prestas tu
        consentimiento expreso, informado, libre e inequívoco para que los datos
        que proporcionas, y sobre los que se aplican las medidas de seguridad,
        técnicas y organizativas previstas en la normativa vigente, sean
        tratados por COSTA LIFE FIT, S.L., como responsable de tratamiento. </p>
      <p> Los datos de carácter personal que pudiésemos recabar directamente del
        interesado a través de (en adelante, la “Web”) serán tratados de forma
        confidencial y quedarán incorporados a la correspondiente actividad de
        tratamiento titularidad de COSTA LIFE FIT, S.L.
      </p>

      <p className={'strong'}><strong>¿QUIÉN ES EL RESPONSABLE DEL TRATAMIENTO?</strong></p>
      <ul>
        <li>Responsable del Tratamiento: COSTA LIFE FIT, S.L.</li>
        <li>CIF: B87767257</li>
        {/* <li>Dirección: CALLE ALCALÁ, Nº 155 MADRID 28009 </li> */}
        <li>Correo electrónico: direccion@vikika.es</li>
        <li>Teléfono: 689 98 51 44</li>
      </ul>
      <p>El acceso voluntario a nuestra Web, le atribuye la
        condición de usuario, e implica la aceptación, desde dicho acceso y/o
        uso, de la presente Política de Privacidad.</p>


      <p className={'strong'}><strong>¿QUÉ DATOS PERSONALES SE RECOGEN EN ESTA WEB?</strong></p>
      <p>
        Para las finalidades establecidas en esta Política de Privacidad, COSTA
        LIFE FIT, S.L. recaba y trata los datos personales que se detallan a
        continuación, que dependerán de los diferentes productos o servicios que
        solicitan en esta Web: </p>
      <ul>
        <li>Datos identificativos: nombre, apellidos. </li>
        <li>Datos
          de contacto: dirección postal, correo electrónico, número de teléfono
          móvil.</li>
        <li>Datos contractuales: datos de operaciones contractuales, DNI y
          datos de pago.</li>
        <li>Datos navegación: direccion IP, tipo e identificación del
          dispositivo, tipo de navegador, dominio a través del cual accede a la
          Web, datos de navegación, actividad en la Web.</li>
      </ul>


      <p className={'strong'}><strong>¿CON QUÉ BASE LEGAL SE TRATAN ESOS DATOS?</strong></p>
      <p>
        Tratamos tus datos personales con las siguientes bases legales: </p>

      <ul>
        <li>La
          ejecución de un contrato con COSTA LIFE FIT S.L., para la contratación
          de servicios y/o productos y gestionar los servicios solicitados.</li>
        <li>El
          consentimiento del usuario en relación al contacto, la suscripción a
          contenidos y el envió de comunicaciones comerciales, via mail, cookies o
          sistemas de mensajería.</li>
        <li>El interés legítimo del responsable del
          tratamiento para proteger a los usuarios de la Web de COSTA LIFE FIT,
          S.L. de abusos y fraudes en el uso de nuestros servicios. COSTA LIFE
          FIT, S.L. está legitimada para tratar sus datos para poder llevar a cabo
          la prestación de sus servicios.</li>
        </ul>
      <p>De tal manera, pone a disposición, a
        través de este documento, la información que regula el acceso,
        contenidos y uso de la Web.
      </p>


      <p className={'strong'}><strong>¿CON QUÉ FINALIDADES TRATAMOS TUS DATOS?</strong></p>
      <p>
        En esta Web, pueden existir diferentes formularios. En cada uno de ellos
        la información que recoja se utilizará de la siguiente manera:</p>
      <ul>
        <li>Formularios de suscripción a contenidos: Los datos facilitados serán
          utilizados exclusivamente para activar la suscripción, enviar la
          newsletter y mantener actualizado al usuario sobre novedades y ofertas
          puntuales, exclusivas para nuestros suscriptores.</li>
        <li>Formulario de comentarios: Los datos personales introducidos en el formulario para insertar estos comentarios será utilizado exclusivamente para moderarlos y publicarlos.</li>
        <li>Formulario de contacto: En los formularios para la realización de consultas, sugerencias o contacto profesional se utilizará la direccion de correo para responder las mismas y enviar la información que el usuario requiera a través de la Web.</li>
        <li>Formulario de registro: En este caso, solicitaremos tus datos personales para que puedas crear una cuenta en nuestro sistema y poder acceder a sus funcionalidades.</li>
        <li>Formulario de venta: Trataremos tus datos para gestionar la compra de nuestros productos y/o servicios, incluyendo la gestión del pedido, el seguimiento del servicio contratado (el plan adquirido), el pago y todas las operaciones relacionadas con la contratación del servicio o producto elegido.</li>
      </ul>
        <p>El Responsable podrá
        contactar con los usuarios a través de correo electrónico, WhatsApp o
        llamada (conforme a los datos de contacto facilitados), pudiendo el
        usuario, una vez creado su perfil en la Web, configurar sus preferencias
        sobre la preferencia en la vía de comunicación para que COSTA LIFE FIT,
        S.L. contacte con él. En todo caso, la comunicación vía correo
          electrónico será necesaria para los suscriptores.</p>
      <p>El usuario podrá
        oponerse al envío de comunicaciones comerciales en cualquier momento,
        remitiendo un correo electrónico a direccion@vikika.es.
      </p>


      <p className={'strong'}><strong>¿SE CEDEN SUS DATOS PERSONALES A TERCEROS?</strong></p>
      <p>
        No se cederán datos a terceras salvo obligación legal o contractual. No
        hay previsión de transferencia de datos a terceros países.
      </p>


      <p className={'strong'}><strong>¿TIEMPO DE CONSERVACIÓN DE LOS DATOS PERSONALES?</strong></p>
      <p>
        Los datos personales facilitados se conservarán durante el menor tiempo
        posible teniendo en cuenta los motivos por los que se necesita el
        tratamiento de los datos, así como las obligaciones legales de conservar
        los datos durante un tiempo determinado, como la legislación laboral,
        fiscal o de lucha contra el fraude que exigen la conservación de los
        datos personales durante un periodo determinado o mientras el interesado
        no retire su consentimiento. </p>
      <p>Posteriormente, los datos serán suprimidos
        conforme a lo dispuesto en la normativa de protección de datos lo que
        implica su bloqueo, estando disponibles tan solo a solicitud de jueces y
        tribunales, el Ministerio Fiscal o las Administraciones Públicas
        competentes durante el plazo de prescripción de las acciones que
        pudieran derivar y, transcurrido éste serán eliminados por completo.
      </p>


      <p className={'strong'}><strong>¿CUÁLES SON SUS DERECHOS CUANDO NOS FACILITA SUS DATOS?</strong></p>
      <p>
        Cualquier persona tiene derecho a obtener confirmación sobre si en COSTA
        LIFE FIT, S.L. estamos tratando datos personales que les conciernan o
        no. En este sentido, el interesado tiene derecho a solicitar: </p>
      <ul>
        <li><strong>Acceso.-</strong>
          El interesado tendrá derecho a obtener del responsable del tratamiento
          confirmación de si se están tratando o no los datos que le conciernan,
          así como información detallada acerca de determinados aspectos del
          tratamiento que se esté llevando a cabo.</li>
        <li><strong>Rectificación .- </strong>El interesado
          tendrá derecho a obtener la rectificación de los datos personales
          inexactos que le concierne o que se completen aquellos que fueran
          incompletos.</li>
        <li><strong>Supresión.- </strong>El interesado tendrá derecho a solicitar la
          supresión de sus datos personales, en todo caso la supresión estará
          sujeta a los límites establecidos en la norma reguladora.</li>
        <li><strong>Limitación de
          su tratamiento.- </strong>El interesado tendrá derecho a solicitar la limitación
          respecto al tratamiento de sus datos de carácter personal</li>
        <li><strong>Oposición al
          tratamiento. – </strong>En determinadas circunstancias y por motivos relacionados
          con su situación particular, los interesados podrán oponerse al
          tratamiento de sus datos. La entidad dejará de tratar los datos, salvo
          por motivos legítimos, imperiosos, o el ejercicio o la defensa de
          posibles reclamaciones.</li>
        <li><strong>Derecho a la portabilidad de sus datos. – </strong>Es
          decir, tendrá derecho a recibir los datos personales que le incumban,
          que haya facilitado a un responsable del tratamiento, en un formato
          estructurado, de uso común y lectura mecánica y a transmitirlos a otro
          responsable del tratamiento. </li>
      </ul>
           <p>Si el interesado ha otorgado su
        consentimiento para alguna finalidad concreta, tiene derecho a retirarlo
        en cualquier momento, sin que ello afecte a la licitud del tratamiento
        basado en el consentimiento previo a su retirada. En el caso de retirar
        el consentimiento a la suscripción (darse de baja), COSTA LIFE FIT, S.L.
        se reserva el derecho de contactar con el interesado con el objetivo de
        conocer los motivos de dicha baja y así mejorar la calidad del servicio.</p>
      <p>
        Para poder ejercer cualquiera de los derechos descritos anteriormente,
        deberá enviar un correo electrónico a direccion@vikika.es (asunto:
        DERECHOS) o bien mandando un escrito a la dirección indicada de COSTA
        LIFE FIT, S.L., indicando el derecho que el Usuario quiere ejercer,
        identificándose correctamente y adjuntando copia del DNI/NIE o cualquier
        documento identificativo, e indicando domicilio a efectos de
        notificaciones. </p>
      <p>Por último, si el usuario considera que han sido
        vulnerados sus derechos en lo concerniente a la protección de sus datos
        personales, especialmente cuando no haya quedado satisfecho con la
        respuesta al ejercicio de sus derechos, podrá presentar una reclamación
        ante la autoridad de control en materia de protección de datos,
        concretamente, ante la Agencia Española de Protección de Datos
        (www.aepd.es).
      </p>


      <p className={'strong'}><strong>NOTIFICACIÓN Y DECLARACIÓN DE BRECHAS</strong></p>
      <p>
        En COSTA LIFE FIT, S.L. asumimos medidas de seguridad adecuadas al nivel
        de riesgo para proteger la información personal contra la pérdida, el
        uso indebido y el acceso no autorizado, su divulgación, alteración y
        destrucción, teniendo en cuenta los riesgos que conlleva el
        procesamiento y la naturaleza de la información personal; no obstante,
        si COSTA LIFE FIT, S.L. determina que los datos personales que trata han
        sido malversados, se han visto expuestos por una brecha de seguridad o
        adquiridos de forma incorrecta por un tercero, COSTA LIFE FIT, S.L.
        actuará de forma inmediata teniendo en cuenta lo establecido por el RGPD
        y las autoridades de protección de datos competentes.
      </p>


      <p className={'strong'}><strong>SECRETO Y SEGURIDAD DE LOS DATOS</strong></p>
      <p>
        COSTA LIFE FIT, S.L. se compromete al uso y tratamiento de los datos
        personales del usuario, respetando su confidencialidad, de acuerdo con
        la finalidad de aquellos; así como a dar cumplimiento a su obligación de
        guardarlos y adoptar todas las medidas para evitar la alteración,
        pérdida, tratamiento o acceso no autorizado, de conformidad con lo
        establecido en la normativa vigente de protección de datos. </p>
      <p>COSTA LIFE
        FIT, S.L. no puede garantizar la absoluta inexpugnabilidad de Internet,
        ni, por tanto, la violación de los datos mediante accesos fraudulentos a
        ellos por parte de terceros. </p>
      <p>Con respecto a la confidencialidad del
        procesamiento, COSTA LIFE FIT, S.L. se asegurará de que cualquier
        persona que esté autorizada por COSTA LIFE FIT, S.L. para procesar los
        datos del cliente (incluido su personal, colaboradores y prestadores),
        se encuentre bajo la debida obligación de confidencialidad (ya sea un
        deber contractual o legal) y a que mantengan el mismo nivel de
        privacidad que aplica COSTA LIFE FIT, S.L.
      </p>


      <p className={'strong'}><strong>EXACTITUD Y VERACIDAD DE LOS DATOS</strong></p>
      <p>
        El usuario es el único responsable de la veracidad y corrección de los
        datos facilitados, exonerando a COSTA LIFE FIT, S.L. de cualquier
        responsabilidad al respecto. Los usuarios garantizan y responden, en
        cualquier caso, de la exactitud, vigencia y autenticidad de los datos
        personales facilitados, y se comprometen a mantenerlos debidamente
        actualizados. COSTA LIFE FIT, S.L. se reserva el derecho a excluir de
        los servicios registrados a todo usuario que haya facilitado datos
        falsos, sin perjuicio de las demás acciones que procedan en derecho.
      </p>


      <p className={'strong'}><strong>DATOS PERSONALES DE TERCEROS</strong></p>
      <p>
        En el caso de que los datos personales aportados pertenecieran a un
        tercero, como usuario garantizas que has informado a dicho tercero de
        esta Política de Privacidad y ha obtenidos su autorización para
        facilitar sus datos a COSTA LIFE FIT S.L. con las finalidades señaladas.
        Igualmente, garantizas que los datos aportados son exactos y
        actualizados, siendo responsable de cualquier daño o perjuicio, directo
        o indirecto, que pudiera ocasionarse como consecuencia del
        incumplimiento de tal obligación.
      </p>


      <p className={'strong'}><strong>CAMBIOS EN LA POLÍTICA DE PRIVACIDAD</strong></p>
      <p>
        COSTA LIFE FIT, S.L. se reserva el derecho a modificar la presente
        política para adaptarla a novedades legislativas o jurisprudenciales,
        así como a prácticas de la industria. En dichos supuestos, anunciará en
        esta página los cambios introducidos con razonable antelación a su
        puesta en práctica.
      </p>


      <p className={'strong'}><strong>INFORMACIÓN ADICIONAL SOBRE PROTECCIÓN DE DATOS</strong></p>
      <p>
        Si necesita información adicional sobre protección de datos, puede
        enviarnos un correo electrónico a direccion@vikika.es y, en función del
        tipo de relación que tenga con nuestra entidad y el tratamiento de datos
        personales que realicemos, le facilitaremos la información adicional que
        corresponda.
      </p>


      <p className={'strong'}><strong>LINKS A PÁGINAS WEB</strong></p>
      <p>
        EL Sitio Web podría contener links a páginas web de compañías y
        entidades de terceros. COSTA LIFE FIT, S.L. no se hace responsable de
        las políticas legales que estas compañías puedan tener. En tal caso,
        aconsejamos que el usuario se informe detenidamente de las condiciones
        que ofrecen estas páginas web.
      </p>
    </div>
    </>
  );
}

export default Privacy;
