import { useState, useEffect, RefObject } from "react";

import { InterestData } from "../../../../../models/formsModels/RenewInterestData";

import nextArrow from '../../../../../assets/img/arrow-right-form.svg';
import './InterestDataRenewSubscriptions.css';

interface InterestDataProps {
  interestData: InterestData;
  setInterestData: (value: any) => void;
  setNext: (value: any) => void;
  setDataInterestStep: (value: any) => void;
  miComponenteRef:RefObject<HTMLDivElement>;
}

const InterestDataRenewSubscriptions = (interestDataProps: InterestDataProps) => {


    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { value,name } = event.target;
        interestDataProps.setInterestData((prevFormData: InterestData) => ({ ...prevFormData, [name]: value }));
    };

    useEffect(() => {
        interestDataProps.miComponenteRef.current?.scrollIntoView({block: "start",behavior: 'smooth'});
    },[]);

    return (
      <>
        <div className="interest-data-activity">
            <label htmlFor="otrosdatosCliente" className="data-label">Datos de interés (cambios en tus horarios, material extra, sensaciones, etc...)</label><br></br>
            <input 
                type="text" 
                name="otrosdatos" 
                id="otrosdatosCliente" 
                placeholder="Max 150 palabras" 
                className=''
                value={interestDataProps.interestData.otrosdatos}
                onChange={handleInputChange}
            /><br></br>
            <label htmlFor="obstaculobloqueoCliente" className="data-label">¿Has tenido algún obstaculo /bloqueo que te haya impedido ser constante y avanzar?</label><br></br>
            <input 
                type="text" 
                name="obstaculobloqueo" 
                id="obstaculobloqueoCliente" 
                placeholder="Max 150 palabras" 
                className=''
                value={interestDataProps.interestData.obstaculobloqueo}
                onChange={handleInputChange}
            /><br></br>
            <label htmlFor="mayorcapacidadCliente" className="data-label">¿Cómo te sientes para seguir siendo constante hacia tu objetivo después de estas 4 semanas?</label><br></br>
            <input 
                type="text" 
                name="mayorcapacidad" 
                id="mayorcapacidadCliente" 
                placeholder="Max 150 palabras" 
                className=''
                value={interestDataProps.interestData.mayorcapacidad}
                onChange={handleInputChange}
            /><br></br>
            <label htmlFor="mejorahabitosCliente" className="data-label">¿Has notado mejora en tus hábitos/calidad de vida en general? (mejor descanso, emociones...)</label><br></br>
            <input 
                type="text" 
                name="mejorahabitos" 
                id="mejorahabitosCliente" 
                placeholder="Max 150 palabras" 
                className=''
                value={interestDataProps.interestData.mejorahabitos}
                onChange={handleInputChange}
            /><br></br>
            <label htmlFor="ayudaavanzarCliente" className="data-label">¿Algún aspecto que consideres o necesites en el que podamos ayudarte para seguir avanzado?</label><br></br>
            <input 
                type="text" 
                name="ayudaavanzar" 
                id="ayudaavanzarCliente" 
                placeholder="Max 150 palabras" 
                className=''
                value={interestDataProps.interestData.ayudaavanzar}
                onChange={handleInputChange}
            /><br></br>
        </div>
        
        <div className="page-manager">
            <span className="current">1</span><span className="not-current"></span>
            <button onClick={()=>{
                interestDataProps.setDataInterestStep(2);
                interestDataProps.miComponenteRef.current?.scrollIntoView({block: "start",behavior: 'smooth'});
            }}>{<img src={nextArrow} alt={'next'} />}</button>
        </div>
      </>
    );
}

export default InterestDataRenewSubscriptions;