import axiosCodeigniter from "../api/axiosCodeigniter";
import {GET_TRANSFORMATIONS_URL} from "../utils/constants";
import { TransformationData } from "../models/TransformationData";
import dayjs from 'dayjs';
import 'dayjs/locale/es';



const formatDate = (seconds: string) => {
    const date = dayjs.unix(Number(seconds));
    const formatedDate = date.locale('es').format('MMMM YYYY'); 
    return formatedDate.charAt(0).toUpperCase() + formatedDate.slice(1);   
}


export async function getTransformationsService(): Promise<TransformationData[]> {
    
    const token = localStorage.getItem('token');
    const headers =  {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }
    const baseUrl = `${process.env.REACT_APP_CODEIGNITER_BASE_URL}`
    const response = await axiosCodeigniter.get(`${GET_TRANSFORMATIONS_URL}`, headers);
    var collectionPhotos: TransformationData[] =[];
    
    const colecciones = Object.keys(response.data.myTransformation); 
    colecciones.map((key) => (
        collectionPhotos.push({
            frontImage: `${baseUrl}${response.data.myTransformation[key].fotoFrente}`,
            sideImage: `${baseUrl}${response.data.myTransformation[key].fotoPerfil}`,
            backImage: `${baseUrl}${response.data.myTransformation[key].fotoEspalda}`,            
            title: formatDate(response.data.myTransformation[key].fecha)
        })
    ));

    return collectionPhotos;
    
}
