import axios from "../api/axios";
import axiosCodeigniter from "../api/axiosCodeigniter";
import { GET_MY_NOTIFICATIONS } from "../utils/constants";
export interface NotificationModel {
    title: string;
    subtitle: string;
    cta: string;
}

export async function NotificationsService(): Promise<NotificationModel[]> {
  const token = localStorage.getItem("token");
  const type_plan_str = localStorage.getItem("pon_user_info_diet");
  const typePlanArray = type_plan_str !== null ? JSON.parse(type_plan_str) : null;
  const lastTypePlan = typePlanArray !== null ? typePlanArray[typePlanArray.length - 1].typePlan : "";
  
const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        "type_plan":lastTypePlan,
      }
    };
  
    try {
      console.log('PLAN ES: ' + lastTypePlan);
      const response = await axiosCodeigniter.get(GET_MY_NOTIFICATIONS, config);
      console.log(response.data);
      return response.data;
    } catch (error) {
      console.log("Error al recuperar los mensajes", error);
      throw error;
    }
}
