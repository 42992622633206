import { useState, useEffect } from 'react';
import ProductCard from './productCard/ProductCard';
import { productsService } from '../../../../services/productsService';
import { ProductType } from "../../../../models/ProductType";
import './Products.css'

const Products = () => {

    const [products, setProducts] = useState<ProductType[]>([]);

    useEffect(() => {
        async function fetchProducts() {
            const products = await productsService();
            setProducts(products);
            if (products.length > 0) {
                const productsString = JSON.stringify(products);
                localStorage.setItem('products', productsString);
            }
        }
        fetchProducts()
        
    }, []);

    return (
        <>
            <div className='title-home' id='product_cards'>
                <p>NUESTROS PRODUCTOS</p>
            </div>
            <div className='cards-containers'>
                <div className='product-container'>
                    {
                        products.map((product) =>
                            <ProductCard
                                key={product.id}
                                {...product}
                            />
                        )
                    }

                </div>
            </div>

        </>
    )
}

export default Products
