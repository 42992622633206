import * as Sentry from '@sentry/react';
import {useEffect} from 'react';
import {createRoutesFromChildren, matchRoutes, useLocation, useNavigationType} from 'react-router-dom';

import {SENTRY_DSN, SENTRY_ENVIRONMENT} from '../config';

Sentry.init({
    dsn: SENTRY_DSN,
    environment: SENTRY_ENVIRONMENT,
    integrations: [new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(useEffect, useLocation, useNavigationType, createRoutesFromChildren, matchRoutes),
    }), new Sentry.Replay(),],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    beforeSend: (event) => {
        const displayName = localStorage.getItem('displayName');
        const email = localStorage.getItem('email');

        if (email) {
            Sentry.setUser({email: email});
        }

        if (displayName) {
            Sentry.setUser({username: displayName});
        }


        return event;
    },
});